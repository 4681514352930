
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import SysAppService from "@/apis.machine/SysAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoFormCompany, {
  DtoFormCompanyMiezzFormItems,
} from "@/models.machine/DtoFormCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyForm from "../CompanyForm.vue";
@Options({
  components: {
    CompanyForm,
  },
})
export default class ServiceProviderCompanyAdd extends Vue {
  type = EnumCompanyType.ServiceProvider;
  form = new MiezzForm<DtoFormCompany, string>(
    "ServiceProviderManagement_Company_Add"
  );
  companyTypes?: UiSelectOption<string>[] = [];

  created(): void {
    this.form.Items = DtoFormCompanyMiezzFormItems.copy().filter(
      (it) =>
        it.Prop != "JoinCompanyTypes" &&
        it.Prop != "ServiceProviderCompanyId" &&
        it.Prop != "SalesmanId" && it.Prop != "Source"
    );
    CompanyTypeAppService.GetSelect({ type: this.type }).then((r) => {
      this.companyTypes = r.data.Data;
      this.form.Data.JoinCompanyTypes = [
        { CompanyTypeId: this.companyTypes?.[0].Value },
      ];
    });
  }
}
