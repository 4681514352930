import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_history = _resolveComponent("miezz-history")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'FieldValues')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              labelWidth: "0px",
              style: {"margin-bottom":"0px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data.FieldValues, (field, field_index) => {
                  return (_openBlock(), _createElementBlock("div", { key: field_index }, [
                    _createVNode(_component_el_form_item, {
                      label: field.Name,
                      labelWidth: "150px"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(field.Label), 1)
                      ]),
                      _: 2
                    }, 1032, ["label"])
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["prop"]))
          : (item.Prop == 'Files')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_upload, {
                    modelValue: _ctx.detail.Data.Files,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail.Data.Files) = $event)),
                    prop: "File",
                    preview: true
                  }, null, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : (item.Prop == 'Items')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  prop: item.Prop,
                  labelWidth: "0px"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", null, [
                      _createTextVNode(" 合计： "),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totals, (total, total_index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          style: {"margin-right":"5px","color":"red"},
                          key: total_index
                        }, _toDisplayString(total.CurrencySystem) + " " + _toDisplayString(total.Amount), 1))
                      }), 128))
                    ]),
                    _createVNode(_component_miezz_page_list, {
                      modelValue: _ctx.list,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.list) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["prop"]))
              : (item.Prop == 'Histories')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 3,
                    prop: item.Prop,
                    labelWidth: "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_history, {
                        modelValue: _ctx.histories,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.histories) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}