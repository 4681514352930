
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElTable, FormInstance } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import PriceTableColumnAppService from "@/apis.machine/PriceTableColumnAppService";
import DtoPageListSearchPriceTableColumn from "@/models.machine/DtoPageListSearchPriceTableColumn";
import DtoPageListPriceTableColumn from "@/models.machine/DtoPageListPriceTableColumn";
import DtoListItemPriceTableColumn, {
  DtoListItemPriceTableColumnMiezzListItems,
} from "@/models.machine/DtoListItemPriceTableColumn";
import ParamPriceFieldAppService from "@/apis.machine/ParamPriceFieldAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { DtoFormPriceTableColumnRules } from "@/models.machine/DtoFormPriceTableColumn";
import { EnumParamPriceFieldType } from "@/models.machine/EnumParamPriceFieldType";

export default class PriceTableColumnCrud extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() tableId?: string;
  @Prop() write?: boolean;
  @Prop() ids: string[] = [];

  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchPriceTableColumn,
    DtoListItemPriceTableColumn,
    string
  >();
  listRef?: InstanceType<typeof ElTable>;
  /**表单模态框 */
  fields: UiSelectOption<string>[] = [];
  EnumParamPriceFieldType = EnumParamPriceFieldType;
  rules?: any;

  /**创建时 */
  created(): void {
    this.rules = DtoFormPriceTableColumnRules;
    this.rules.Name = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            console.log("信息不能为空", value);
            callback(new Error("信息不能为空"));
          } else if (value.length > 256) {
            callback(new Error("文本长度不能超过 256 个字符"));
          } else if (
            (this.list.Data?.Items?.filter((it) => it.Name == value).length ??
              0) > 1
          ) {
            callback(new Error("信息重复，请重新填写"));
          } else {
            callback();
          }
        },
        trigger: "blur",
      },
    ];
    //列表-传参
    this.list.Dto = new DtoPageListSearchPriceTableColumn();
    this.list.Dto.TableId = this.tableId;
    this.list.Dto.PageSize = 99999;
    //列表-数据-初始
    this.list.Data = new DtoPageListPriceTableColumn();
    this.list.Data.Items = [];
    //列表-列配置
    this.list.Items = DtoListItemPriceTableColumnMiezzListItems;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "rank",
        Type: "default",
        Size: "small",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Size: "small",
      },
    ];
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "text",
      },
    ];
    this.modelValue.Width = "60%";
    this.modelValue.HandleClick = this.handleClick;
    //列表-底部按钮
    if (this.write) {
      this.modelValue.Buttons = [
        {
          Code: "submit",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modelValue.Buttons = [
        {
          Code: "choose",
          Label: "选择",
          Type: "primary",
          Size: "small",
        },
      ];
    }

    ParamPriceFieldAppService.GetSelect({
      Types: [this.EnumParamPriceFieldType.Description],
    }).then((r) => {
      this.fields = r.data.Data ?? [];
    });

    this.handleGet();
  }

  mounted(): void {
    this.listRef = this.$refs["listRef"] as InstanceType<typeof ElTable>;
  }

  /**查询列表 */
  handleGet(): void {
    PriceTableColumnAppService.Get(this.list.Dto).then((r) => {
      if (!this.write) {
        if (this.list.Data && this.list.Data.Items)
          this.list.Data.Items = r.data.Data?.Items?.filter(
            (it: any) => it.Field?.Type == EnumParamPriceFieldType.Description
          );
      } else {
        if (this.list.Data && this.list.Data.Items)
          this.list.Data.Items = r.data.Data?.Items?.filter(
            (it: any) => it.Field?.Code != "Id"
          );
      }

      for (const item of this.list.Data?.Items ?? []) {
        if (this.ids && this.ids.contains(item.Id)) {
          this.$nextTick(() => {
            this.listRef?.toggleRowSelection(item, true);
          });
        }
      }
    });
  }

  handleSelectable(row: DtoListItemPriceTableColumn): boolean {
    return row.Field?.Type == EnumParamPriceFieldType.Description;
  }

  /**事件-选中行之前 */
  @Emit()
  handleSelect(rows: any[]): void {
    this.list.SelectRows = rows;
    this.list.SelectIds = rows.map((it) => it.Id) as string[];
  }

  @Watch("list.Data")
  onDataChanged(): void {
    this.list.DragSort(".el-table__body-wrapper tbody", (items) => {
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        element.Sort = i;
      }
      this.$nextTick(() => {
        if (this.list.Data) this.list.Data.Items = items;
      });
    });
  }

  handleFieldChange(row: DtoListItemPriceTableColumn): void {
    const field = this.fields.firstOrDefault((it) => it.Value == row.FieldId);
    if (field) {
      row.Name = field.Label;
    }
  }

  handleShowChanged(e: boolean): void {
    if (e) {
      ElMessage.warning("该列信息在价格表被添加到“服务”中时将显示");
    } else {
      ElMessage.warning("该列信息在价格表被添加到“服务”中时将隐藏");
    }
  }

  /**顶部按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    if (btn.Code == "add") {
      const item = new DtoListItemPriceTableColumn();
      item.Show = true;
      item.Sort = 0;
      item.FieldId = "";
      item.FieldType = "描述要素";
      item.Name = "";

      if (this.list.Data?.Items && this.list.Data.Items.length > 0) {
        const sorts =
          this.list.Data?.Items?.map((it) => it.Sort as number).sort(
            (a, b) => a - b
          ) ?? [];
        item.Sort = sorts[sorts.length - 1] + 1;
      }
      this.list.Data?.Items?.push(item);
      complete();
    }
    switch (btn.Code) {
      case "choose":
        // this.list.SelectIds.forEach(ele => {
        //   if (ele && this.ids.indexOf(ele) < 0) this.ids.push(ele);
        // })

        this.handleChoose(this.list.SelectIds);
        this.modelValue.Show = false;
        complete();
        break;
      case "submit":
        (this.$refs["ruleForm"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            PriceTableColumnAppService.PostByTableId(
              this.tableId,
              this.list.Data?.Items
            )
              .then(() => {
                this.modelValue.Show = false;
                complete();
              })
              .catch(complete);
          }
        });
        break;
      default:
        break;
    }
  }

  @Emit()
  handleChoose(ids: string[]): void {
    //
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPriceTableColumn,
    index: number,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "delete":
        this.list.Data?.Items?.splice(index, 1);
        (this.$refs["ruleForm"] as any).validate();
        complete();
        break;
      default:
        break;
    }
  }
}
