import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f707768"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-left":"10px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "where-form-item"
}
const _hoisted_4 = {
  key: 2,
  class: "where-select-label"
}
const _hoisted_5 = { class: "search-buttons" }
const _hoisted_6 = { class: "center" }
const _hoisted_7 = {
  key: 0,
  class: "right"
}
const _hoisted_8 = {
  key: 0,
  class: "split"
}
const _hoisted_9 = {
  key: 1,
  class: "histories",
  style: {"display":"inline-block","width":"208px","float":"right"}
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_miezz_dynamic_search_relative_date = _resolveComponent("miezz-dynamic-search-relative-date")!
  const _component_miezz_dynamic_search_month_range = _resolveComponent("miezz-dynamic-search-month-range")!
  const _component_miezz_dynamic_search_date_time_range = _resolveComponent("miezz-dynamic-search-date-time-range")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_check_tag = _resolveComponent("el-check-tag")!
  const _component_vuedraggable = _resolveComponent("vuedraggable")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.brackets.any((it) => (it.Items?.length ?? 0) > 0))
      ? (_openBlock(), _createBlock(_component_el_form, {
          key: 0,
          ref: "form-dynamic-search",
          model: _ctx.brackets,
          rules: _ctx.searchRules,
          style: {"margin-bottom":"10px","position":"relative","padding":"0px 10px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              style: _normalizeStyle(`width: calc(100% - ${_ctx.disabledHistories ? '0px' : '218px'});`),
              class: "brackets"
            }, [
              _createVNode(_component_el_scrollbar, { "max-height": "150px" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brackets, (bracket, bracketIndex) => {
                    return (_openBlock(), _createBlock(_component_el_form_item, {
                      key: bracketIndex,
                      style: _normalizeStyle(`width: calc(${_ctx.simple ? '100%' : '50%'} - ${
            _ctx.disabledHistories ? '0px' : '10px'
          });`),
                      "label-width": _ctx.simple ? '0px' : bracketIndex == 0 ? '65px' : '146px',
                      class: "bracket"
                    }, {
                      label: _withCtx(() => [
                        (bracketIndex > 0)
                          ? (_openBlock(), _createBlock(_component_el_select, {
                              key: 0,
                              modelValue: bracket.Relation,
                              "onUpdate:modelValue": ($event: any) => ((bracket.Relation) = $event),
                              placeholder: "空值",
                              class: "relation-select"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relations, (relation, relationIndex) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: relationIndex,
                                    label: relation.Label,
                                    value: relation.Value
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.simple ? "" : `第 ${bracketIndex + 1} 组`), 1)
                      ]),
                      default: _withCtx(() => [
                        (bracket.Items && bracket.Items.length > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bracket.Items, (item, i) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: i,
                                  style: _normalizeStyle(`${
                _ctx.simple
                  ? 'width: calc(100% / 3); display: inline-block;'
                  : 'width: 100%; display: block;'
              } ${_ctx.searchRules ? 'margin-bottom: 15px' : 'margin-bottom: 5px'}`)
                                }, [
                                  (item.Where)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        (!_ctx.simple)
                                          ? (_openBlock(), _createBlock(_component_el_select, {
                                              key: 0,
                                              style: _normalizeStyle('visibility:' + (i == 0 ? 'hidden' : 'visible')),
                                              modelValue: item.Relation,
                                              "onUpdate:modelValue": ($event: any) => ((item.Relation) = $event),
                                              placeholder: "空值",
                                              class: "where-relation-select"
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relations, (relation, relationIndex) => {
                                                  return (_openBlock(), _createBlock(_component_el_option, {
                                                    key: relationIndex,
                                                    label: relation.Label,
                                                    value: relation.Value
                                                  }, null, 8, ["label", "value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["style", "modelValue", "onUpdate:modelValue"]))
                                          : _createCommentVNode("", true),
                                        (!_ctx.fixProp)
                                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                                              key: 1,
                                              modelValue: item.Where.Prop,
                                              "onUpdate:modelValue": ($event: any) => ((item.Where.Prop) = $event),
                                              placeholder: "空值",
                                              class: "where-select",
                                              options: _ctx.__items,
                                              valueKey: "Prop",
                                              onHandleVisibleChange: 
                    (show) => _ctx.handleWherePropVisibleChange(show, item)
                  ,
                                              onChange: ($event: any) => (_ctx.handleChangeWhere(bracket, item, i))
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onHandleVisibleChange", "onChange"]))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.items.firstOrDefault((it) => it.Prop == item.Where?.Prop)
                      ?.Label), 1)),
                                        (!(_ctx.fixProp || _ctx.hideCompare))
                                          ? (_openBlock(), _createBlock(_component_el_select, {
                                              key: 3,
                                              modelValue: item.Where.Compare,
                                              "onUpdate:modelValue": ($event: any) => ((item.Where.Compare) = $event),
                                              placeholder: "空值",
                                              class: "compare-select"
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.compares, (compare, compareIndex) => {
                                                  return _withDirectives((_openBlock(), _createBlock(_component_el_option, {
                                                    key: compareIndex,
                                                    label: compare.Label,
                                                    value: compare.Value
                                                  }, null, 8, ["label", "value"])), [
                                                    [_vShow, 
                      !item.Where.Prop ||
                      _ctx._items.filter(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          v.Compares &&
                          compare.Value &&
                          v.Compares.indexOf(compare.Value) > -1
                      ).length > 0
                    ]
                                                  ])
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["modelValue", "onUpdate:modelValue"]))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_el_form_item, {
                                          prop: item.Where.Prop,
                                          style: _normalizeStyle(`margin-bottom: 0px; width: calc(100% - ${
                    !(_ctx.fixProp || _ctx.hideCompare)
                      ? _ctx.simple
                        ? '225px'
                        : '240px'
                      : _ctx.simple
                      ? '130px'
                      : '145px'
                  })`),
                                          class: "where-form-value"
                                        }, {
                                          default: _withCtx(() => [
                                            (
                      !item.Where.Prop ||
                      _ctx._items.filter(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          v.Type == _ctx.EnumUiDynamicSearchItemType.InputText
                      ).length > 0
                    )
                                              ? (_openBlock(), _createBlock(_component_miezz_input, {
                                                  key: 0,
                                                  modelValue: item.Where.Value,
                                                  "onUpdate:modelValue": ($event: any) => ((item.Where.Value) = $event),
                                                  placeholder: "请填写"
                                                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                              : (
                      !item.Where.Prop ||
                      _ctx._items.filter(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          v.Type == _ctx.EnumUiDynamicSearchItemType.InputNumber
                      ).length > 0
                    )
                                                ? (_openBlock(), _createBlock(_component_miezz_input, {
                                                    key: 1,
                                                    modelValue: item.Where.Value,
                                                    "onUpdate:modelValue": ($event: any) => ((item.Where.Value) = $event),
                                                    pattern: "[0-9]",
                                                    placeholder: "请填写"
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                : (
                      !item.Where.Prop ||
                      _ctx._items.filter(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          v.Type == _ctx.EnumUiDynamicSearchItemType.Switch
                      ).length > 0
                    )
                                                  ? (_openBlock(), _createBlock(_component_el_switch, {
                                                      key: 2,
                                                      modelValue: item.Where.Value,
                                                      "onUpdate:modelValue": ($event: any) => ((item.Where.Value) = $event)
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                  : (
                      !item.Where.Prop ||
                      (_ctx._items.filter(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          (v.Type ==
                            _ctx.EnumUiDynamicSearchItemType.DateTimeRange ||
                            v.Type == _ctx.EnumUiDynamicSearchItemType.MonthRange)
                      ).length > 0 &&
                        item.Where?.Compare == _ctx.EnumCompare.Equal)
                    )
                                                    ? (_openBlock(), _createBlock(_component_miezz_dynamic_search_relative_date, {
                                                        key: 3,
                                                        modelValue: item.Where.Value,
                                                        "onUpdate:modelValue": ($event: any) => ((item.Where.Value) = $event),
                                                        month: 
                      _ctx._items.any(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          v.Type == _ctx.EnumUiDynamicSearchItemType.MonthRange
                      )
                    
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "month"]))
                                                    : (
                      !item.Where.Prop ||
                      _ctx._items.filter(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          v.Type == _ctx.EnumUiDynamicSearchItemType.MonthRange
                      ).length > 0
                    )
                                                      ? (_openBlock(), _createBlock(_component_miezz_dynamic_search_month_range, {
                                                          key: 4,
                                                          modelValue: item.Where.Value,
                                                          "onUpdate:modelValue": ($event: any) => ((item.Where.Value) = $event)
                                                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                      : (
                      !item.Where.Prop ||
                      _ctx._items.filter(
                        (v) =>
                          v.Prop == item.Where?.Prop &&
                          v.Type == _ctx.EnumUiDynamicSearchItemType.DateTimeRange
                      ).length > 0
                    )
                                                        ? (_openBlock(), _createBlock(_component_miezz_dynamic_search_date_time_range, {
                                                            key: 5,
                                                            modelValue: item.Where.Value,
                                                            "onUpdate:modelValue": ($event: any) => ((item.Where.Value) = $event)
                                                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                        : (item.Where.Prop)
                                                          ? _renderSlot(_ctx.$slots, "dynamic-search-customize", {
                                                              key: 6,
                                                              model: item
                                                            }, undefined, true)
                                                          : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1032, ["prop", "style"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 4))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["style", "label-width"]))
                  }), 128))
                ]),
                _: 3
              }),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (item, i) => {
                    return (_openBlock(), _createBlock(_component_miezz_button, {
                      key: i,
                      model: item,
                      onHandleClick: _ctx.handleClick
                    }, null, 8, ["model", "onHandleClick"]))
                  }), 128))
                ]),
                (!_ctx.fixProp)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_ctx.btnToggle.MiezzIcon == 'double-up')
                        ? (_openBlock(), _createBlock(_component_miezz_button, {
                            key: 0,
                            model: _ctx.btnAddWhere,
                            onHandleClick: _ctx.handleClick
                          }, null, 8, ["model", "onHandleClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_miezz_button, {
                        model: _ctx.btnToggle,
                        onHandleClick: _ctx.handleClick
                      }, null, 8, ["model", "onHandleClick"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 4),
            (!_ctx.disabledHistories)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8))
              : _createCommentVNode("", true),
            (!_ctx.disabledHistories)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_el_tabs, {
                    modelValue: _ctx.historyTab,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.historyTab) = $event)),
                    type: "card",
                    "tab-position": "top",
                    onTabClick: _ctx.handleHistoryTabClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tab_pane, {
                        label: "公共",
                        name: "Public"
                      }),
                      _createVNode(_component_el_tab_pane, {
                        label: "我的",
                        name: "My"
                      }),
                      (!_ctx.hideHistoryTab && !_ctx.modal)
                        ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                            key: 0,
                            label: "标签",
                            name: "Tab"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onTabClick"]),
                  _createVNode(_component_vuedraggable, {
                    modelValue: _ctx.historyData,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.historyData) = $event)),
                    animation: "300",
                    handle: ".check-tag-option",
                    onEnd: _ctx.hnadleDrop
                  }, {
                    item: _withCtx(({ element }) => [
                      _createVNode(_component_el_check_tag, { class: "check-tag-option" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            onClick: ($event: any) => (_ctx.handleHistoryClick(element.Value))
                          }, _toDisplayString(element.Label), 9, _hoisted_10),
                          (
                (!element.Data.Open || _ctx.powers.contains('Search_Open')) &&
                element.Data.CreatedCompanyId == _ctx.logier?.CompanyId
              )
                            ? (_openBlock(), _createBlock(_component_el_icon, {
                                key: 0,
                                onClick: ($event: any) => (_ctx.handleDeleteHistory(element.Value))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_delete)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onEnd"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["model", "rules"]))
      : _createCommentVNode("", true),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event)),
      onHandleClick: _ctx.handleSave
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_form, {
          modelValue: _ctx.form,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event)),
          ref: "ruleForm"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleClick"])
  ], 64))
}