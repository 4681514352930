
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import OrderPoolExpressAppService from "@/apis.machine/OrderPoolExpressAppService";
import DtoPageListSearchOrderPoolExpress from "@/models.machine/DtoPageListSearchOrderPoolExpress";
import DtoPageListOrderPoolExpress from "@/models.machine/DtoPageListOrderPoolExpress";
import DtoListItemOrderPoolExpress, {
  DtoListItemOrderPoolExpressMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolExpress";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzForm from "@/models/MiezzForm";
import DtoPutOrderPoolExpress_CarryAddress, {
  DtoPutOrderPoolExpress_CarryAddressMiezzFormItems,
} from "@/models.machine/DtoPutOrderPoolExpress_CarryAddress";
import DtoPutOrderPoolExpress_CarryAddress_CompanyAddress, {
  DtoPutOrderPoolExpress_CarryAddress_CompanyAddressRules,
} from "@/models.machine/DtoPutOrderPoolExpress_CarryAddress_CompanyAddress";
import CompanyAddressAppService from "@/apis.machine/CompanyAddressAppService";
import DtoHistoryCompanyAddress_CompanyName from "@/models.machine/DtoHistoryCompanyAddress_CompanyName";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
import DtoCallbackAMapPlaceTextPoi from "@/models.machine/DtoCallbackAMapPlaceTextPoi";
import DtoHistoryCompanyAddress_Contact from "@/models.machine/DtoHistoryCompanyAddress_Contact";
import moment from "moment";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import DtoPutOrderPoolExpress_DeliveryAddress, {
  DtoPutOrderPoolExpress_DeliveryAddressMiezzFormItems,
} from "@/models.machine/DtoPutOrderPoolExpress_DeliveryAddress";
import { DtoPutOrderPoolExpress_DeliveryAddress_CompanyAddressRules } from "@/models.machine/DtoPutOrderPoolExpress_DeliveryAddress_CompanyAddress";
import DtoPutOrderPoolExpress_AllCarryTime, {
  DtoPutOrderPoolExpress_AllCarryTimeMiezzFormItems,
  DtoPutOrderPoolExpress_AllCarryTimeRules,
} from "@/models.machine/DtoPutOrderPoolExpress_AllCarryTime";
import DtoPutOrderPoolExpress_CarryContainerTime, {
  DtoPutOrderPoolExpress_CarryContainerTimeMiezzFormItems,
  DtoPutOrderPoolExpress_CarryContainerTimeRules,
} from "@/models.machine/DtoPutOrderPoolExpress_CarryContainerTime";
import DtoPutOrderPoolExpress_AllDeliveryTime, {
  DtoPutOrderPoolExpress_AllDeliveryTimeMiezzFormItems,
  DtoPutOrderPoolExpress_AllDeliveryTimeRules,
} from "@/models.machine/DtoPutOrderPoolExpress_AllDeliveryTime";
import DtoPutOrderPoolExpress_File, {
  DtoPutOrderPoolExpress_FileMiezzFormItems,
  DtoPutOrderPoolExpress_FileRules,
} from "@/models.machine/DtoPutOrderPoolExpress_File";
import DtoPutOrderPoolExpress_CargoQuantities, {
  DtoPutOrderPoolExpress_CargoQuantitiesRules,
} from "@/models.machine/DtoPutOrderPoolExpress_CargoQuantities";
import OrderPoolCargoQuantityAppService from "@/apis.machine/OrderPoolCargoQuantityAppService";
import DtoPutOrderPoolExpress_Codes, {
  DtoPutOrderPoolExpress_CodesMiezzFormItems,
} from "@/models.machine/DtoPutOrderPoolExpress_Codes";
import DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode from "@/models.machine/DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode";
import DtoHistory from "@/models.machine/DtoHistory";
import Logier from "@/models.machine/Logier";
import DtoListItemOrderPoolCargoQuantity_Shape, {
  DtoListItemOrderPoolCargoQuantity_ShapeMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolCargoQuantity_Shape";
import OrderAppService from "@/apis.machine/OrderAppService";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import PackageEnAppService from "@/apis.machine/PackageEnAppService";
import OrderSummaryTime from "../../OrderSummaryTime.vue";
import MiezzPageListComponent from "@/components/MiezzPageList.vue";
import OrderSupplier from "../../OrderSupplier.vue";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import ExpressTracking from "./ExpressTracking.vue";

@Options({
  components: {
    OrderSummaryTime,
    OrderSupplier,
    ExpressTracking,
  },
})
export default class ExpressPageList extends Vue {
  @Prop() menuTab!: MiezzMenuTab;
  /**分页列表 */
  @Prop() list!: MiezzPageList<
    DtoPageListSearchOrderPoolExpress,
    DtoListItemOrderPoolExpress,
    string
  >;
  @Prop() serviceType!: EnumOrderServiceType;
  @Prop() addPower = "";
  @Prop() editPower = "";
  @Prop() deletePower = "";
  @Prop() poolId?: string;
  @Prop() orderId?: string;

  logier?: Logier;
  EnumCompanyType = EnumCompanyType;
  /**当前Id */
  id?: string = "";
  EnumOrderServiceType = EnumOrderServiceType;
  _orderId?: string = "";
  powers: string[] = [];
  btnAdd = new MiezzButton();
  btnAddMainCode = new MiezzButton();
  btnAddChildrenCode = new MiezzButton();
  histories: DtoHistory[] = [];
  canAdd = false;
  canEdit = false;

  addDeparturePortExpress = false;
  feedbackDeparturePortExpress = false;
  addDestinationPortExpress = false;
  feedbackDestinationPortExpress = false;

  kgs = "kgs";
  cbm = "m³";

  modalTracking = new MiezzModal(MiezzModalType.Dialog);

  /**创建时 */
  created(): void {
    this.addDeparturePortExpress =
      this.$route.query.task == "AddDeparturePortExpress";
    this.feedbackDeparturePortExpress =
      this.$route.query.task == "FeedbackDeparturePortExpress";
    this.addDestinationPortExpress =
      this.$route.query.task == "AddDestinationPortExpress";
    this.feedbackDestinationPortExpress =
      this.$route.query.task == "FeedbackDestinationPortExpress";

    this.logier = CurrentLogier.GetLogier();

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    //列表-传参
    this.list.Dto = new DtoPageListSearchOrderPoolExpress();
    this.list.Dto.PageSize = 99999;
    //列表-数据-初始
    this.list.Data = new DtoPageListOrderPoolExpress();
    //列表-列配置
    this.list.Items = JSON.copy(
      DtoListItemOrderPoolExpressMiezzListItems.filter(
        (it) => !this.logier?.DisabledReadSupplier || it.Prop != "CarryAddress"
      )
    );
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.list.CellClassName = (data: any) => {
        const row = data.row as DtoListItemOrderPoolExpress;
        const add =
          this.addDeparturePortExpress || this.addDestinationPortExpress;
        const feedback =
          this.feedbackDeparturePortExpress ||
          this.feedbackDestinationPortExpress;
        switch (data.column.label) {
          case "提货地址":
            if (add && !row.CarryAddress) {
              return "red";
            }
            break;
          case "货量":
            if (add && !row.HasCargoQuantities) {
              return "red";
            }
            break;
          case "供应商":
            if (add && !row.SupplierCompanyId) {
              return "red";
            }
            break;
          case "交货地址":
            if (add && (!row.DeliveryAddress || !row.PlanDeliveryTime)) {
              return "red";
            }
            break;
          case "实际提取时间":
            if (feedback && !row.AllCarryTime) {
              return "red";
            }
            break;
          case "实际送达时间":
            if (feedback && !row.AllDeliveryTime) {
              return "red";
            }
            break;

          default:
            break;
        }

        return "";
      };
    } else {
      this.list.Items = this.list.Items?.filter(
        (it) => it.Prop != "CarryAddress"
      );
    }
    this.btnAdd = {
      Code: "add",
      Label: "添加",
      Icon: "plus",
      Type: "text",
      // Size: "small",
      PowerCode: this.addPower,
    };
    this.btnAddMainCode = {
      Code: "add-main-code",
      Label: "添加主单",
      Icon: "plus",
      Type: "text",
      // Size: "small",
      PowerCode: this.editPower,
    };
    this.btnAddChildrenCode = {
      Code: "add-children-code",
      Label: "添加",
      Icon: "plus",
      Type: "text",
      // Size: "small",
      PowerCode: this.editPower,
    };

    this.modalTracking.Title = "追踪信息";
    this.modalTracking.Height = "500px";
  }

  /**格式化时间 */
  formatTime(v?: Date): string {
    return v ? moment(v).format("YYYY/MM/DD HH:mm") : "暂无数据";
  }
  /**格式化枚举 */
  formatEnum(options: UiSelectOption<number>[], value?: number): string {
    return (
      options.firstOrDefault((it) => it.Value == value)?.Label ?? "暂无数据"
    );
  }

  /**查询列表 */
  handleGet(): void {
    const dto = JSON.copy(this.list.Dto);
    dto.PoolId = this.poolId;
    dto.OrderId = this.orderId;
    dto.ServiceType = this.serviceType;
    dto.PageSize = 9999999;
    CurrentLogier.GetPowerCodes().then((powers) => {
      this.powers = powers ?? [];
      OrderPoolExpressAppService.Get(dto).then((r) => {
        this._orderId = r.data.Data?.OrderId;
        this.list.Data = r.data.Data;
        this.canAdd = this.powers.contains(this.addPower);
        this.canEdit = this.powers.contains(this.editPower);
        if (this.powers.contains(this.deletePower)) {
          this.list.RowButtons = [
            {
              Code: "delete",
              Title: "删除",
              Icon: "delete",
              Type: "default",
              Size: "small",
              Message: "是否确认删除",
              PowerCode: this.deletePower,
            },
          ];
        }
        if (!this.list.Data?.ShowCarryContainerTime) {
          this.list.Items = this.list.Items?.filter(
            (it) => it.Prop != "CarryContainerTime"
          );
        }
        if (
          this.orderId &&
          this.serviceType == EnumOrderServiceType.DestinationPort &&
          this.canEdit
        ) {
          for (const item of this.list.Items ?? []) {
            switch (item.Prop) {
              case "CarryAddress":
              case "AllCarryTime":
              case "SupplierLabel":
              case "DeliveryAddress":
              case "AllDeliveryTime":
              case "CarryContainerTime":
                item.CustomizeHeader = true;
                break;

              default:
                break;
            }
          }
        }

        this.initCarryAddress();
        this.initCargoQuantities();
        this.initAllCarryTime();
        this.initCarryContainerTime();
        this.initCodes();
        this.initDeliveryAddress();
        this.initAllDeliveryTime();
        this.initFile();
        this.initAdd();

        if (this.list.Data?.StowageOrder == true && this.orderId) {
          OrderAppService.GetClientSelect({
            Id: this.orderId,
            DeparturePortExpress:
              this.serviceType == EnumOrderServiceType.DeparturePort
                ? true
                : undefined,
            DestinationPortExpress:
              this.serviceType == EnumOrderServiceType.DestinationPort
                ? true
                : undefined,
          }).then((r) => {
            this.clients = r.data.Data ?? [];
          });
        }
      });
    });
    OrderPoolExpressAppService.GetHistories(dto).then((r) => {
      this.histories = r.data.Data ?? [];
    });
  }

  /**公司地址-获取公司名称 */
  getConpanyNames(q: string, cb: any): void {
    CompanyAddressAppService.GetCompanyNames(q).then((r) => {
      cb(r.data.Data ?? []);
    });
  }
  /**公司地址-获取坐标 */
  getAreas(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetSelect({
        Keywords: q,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }
  /**公司地址-获取详细地址 */
  getAddress(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetAMapPlaceTextPoiByKeywords({
        Keywords: q,
        Offset: 5,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }
  /**公司地址-获取联系人 */
  getContacts(q: string, item: any, cb: any): void {
    CompanyAddressAppService.GetContacts({
      CompanyName: item.CompanyName,
    }).then((r) => {
      const contacts = r.data.Data ?? [];
      item.Contacts = contacts;
      cb(
        contacts.filter((it) =>
          it.Contact?.toUpperCase()?.contains(q.toUpperCase())
        )
      );
    });
  }

  handleDeleteContact(
    select: DtoHistoryCompanyAddress_Contact,
    value: string,
    item: DtoPutOrderPoolExpress_CarryAddress_CompanyAddress
  ): void {
    CompanyAddressAppService.DeleteContactById(select.Id).then(() => {
      if (item) {
        const old = (
          item.Contacts as DtoHistoryCompanyAddress_Contact[]
        ).firstOrDefault((it) => it.Contact == value);
        if (old?.Contact == select.Contact) {
          item.Contact = undefined;
          item.ContactNumber = undefined;
        } else {
          item.Contact = old?.Contact;
          item.ContactNumber = old?.ContactNumber;
        }
      }
      ElMessage.success("历史记录已删除");
      return;
    });
  }

  /**提柜时间-模态框 */
  modalCarryContainerTime = new MiezzModal(MiezzModalType.Dialog);
  /**提柜时间-表单 */
  formCarryContainerTime = new MiezzForm<
    DtoPutOrderPoolExpress_CarryContainerTime,
    string
  >();
  /**提柜时间-初始化 */
  initCarryContainerTime(): void {
    this.modalCarryContainerTime.Height = "100px";
    if (this.canEdit) {
      this.modalCarryContainerTime.Title = "编辑提柜时间";
      this.modalCarryContainerTime.Buttons = [
        {
          Code: "save-carry-container-time",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalCarryContainerTime.Title = "查看提柜时间";
    }
    this.modalCarryContainerTime.HandleClick = this.handleTopButtonClick;
    this.formCarryContainerTime.Items =
      DtoPutOrderPoolExpress_CarryContainerTimeMiezzFormItems;
    this.formCarryContainerTime.Rules =
      DtoPutOrderPoolExpress_CarryContainerTimeRules;
  }
  handleChangeCarryContainerTime(): void {
    const item = this.list.Data?.Items?.firstOrDefault(
      (it) => it.Id == this.id
    );
    if (
      item &&
      this.formCarryContainerTime.Data.CarryContainerTime &&
      ((item.AllCarryTime &&
        moment(this.formCarryContainerTime.Data.CarryContainerTime) >=
          moment(item.AllCarryTime)) ||
        (item.AllDeliveryTime &&
          moment(this.formCarryContainerTime.Data.CarryContainerTime) >=
            moment(item.AllDeliveryTime)))
    ) {
      ElMessage.warning(
        "提柜时间应早于实际提取时间，实际提取时间应早于实际送达时间"
      );
      this.formCarryContainerTime.Data.CarryContainerTime = undefined;
    }
  }

  /**提货地址与时间-抽屉 */
  modalCarryAddress = new MiezzModal(MiezzModalType.Dialog);
  /**提货地址与时间-表单 */
  formCarryAddress = new MiezzForm<
    DtoPutOrderPoolExpress_CarryAddress,
    string
  >();
  /**提货地址与时间-初始化 */
  initCarryAddress(): void {
    this.modalCarryAddress.Height = "490px";
    if (this.canEdit) {
      this.modalCarryAddress.Title = "编辑提货地址与时间";
      this.modalCarryAddress.Buttons = [
        {
          Code: "save-carry-address",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalCarryAddress.Title = "查看提货地址与时间";
    }
    this.modalCarryAddress.HandleClick = this.handleTopButtonClick;
    this.formCarryAddress.Items =
      DtoPutOrderPoolExpress_CarryAddressMiezzFormItems;
    this.formCarryAddress.Rules =
      DtoPutOrderPoolExpress_CarryAddress_CompanyAddressRules;
    //计划抵达时间应晚于计划提货时间
    this.formCarryAddress.Rules.CarryPlanTime = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            console.log(this.row?.PlanDeliveryTime, value);

            //计划抵达时间应晚于计划提货时间
            if (
              this.row?.PlanDeliveryTime &&
              moment(this.row?.PlanDeliveryTime) <= moment(value)
            ) {
              this.formCarryAddress.Data.CarryPlanTime = undefined;
              return callback(new Error("计划抵达时间应晚于计划提货时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
  }
  /**提货地址与时间-选择公司名称 */
  handleSelectCarryAddressCompanyName(
    select: DtoHistoryCompanyAddress_CompanyName,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (item.CarryAddress) {
      if (select.AreaLabel) {
        item.CarryAddress.AreaLabel = select.AreaLabel;
      } else if (select.AreaCode) {
        CompanyAddressAppService.GetAreaByCode(
          select.AreaCode,
          select.AreaLongitude,
          select.AreaLatitude
        ).then((r) => {
          if (item.CarryAddress) {
            item.CarryAddress.AreaLabel = r.data.Data?.FullName;
            if (!item.CarryAddress.Address && r.data.Data?.DetailedAddress)
              item.CarryAddress.Address = r.data.Data?.DetailedAddress;
          }
        });
      }
      item.CarryAddress.Address = select.Address;
      item.CarryAddress.Contact = select.Contact;
      item.CarryAddress.ContactNumber = select.ContactNumber;
    }
  }
  /**提货地址与时间-选择坐标 */
  handleSelectCarryAddressArea(
    select: DtoSelectOptionParamCountryArea,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (item.CarryAddress) {
      item.CarryAddress.Address = select?.DetailedAddress;
    }
  }
  /**提货地址与时间-选择详细地址 */
  handleSelectCarryAddressAddress(
    select: DtoCallbackAMapPlaceTextPoi,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (select.adcode) {
      CompanyAddressAppService.GetAreaByCode(
        select.adcode,
        select.Longitude,
        select.Latitude
      ).then((r) => {
        if (item.CarryAddress) {
          item.CarryAddress.AreaLabel = r.data.Data?.FullName;
        }
      });
    }
  }
  /**提货地址与时间-选择联系人 */
  handleSelectCarryAddressContact(
    select: DtoHistoryCompanyAddress_Contact,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (item.CarryAddress) {
      item.CarryAddress.ContactNumber = select.ContactNumber;
    }
  }

  /**货量-模态框 */
  modalCargoQuantities = new MiezzModal(MiezzModalType.Dialog);
  /**货量-表单 */
  formCargoQuantities = new MiezzForm<
    DtoPutOrderPoolExpress_CargoQuantities,
    string
  >();
  /**货量-列表配置 */
  listCargoQuantities = new MiezzPageList<
    any,
    DtoListItemOrderPoolCargoQuantity_Shape,
    string
  >();
  packageKinds: UiSelectOption<string>[] = [];
  /**获取英文计价单位 */
  getPackageKinds(q: string, cb: any): void {
    cb(
      this.packageKinds.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }

  handleDeletePackageKindContact(
    select: UiSelectOption<string>,
    value: string
  ): void {
    PackageEnAppService.RemoveHistoryById(select.Value).then(() => {
      const old = this.packageKinds.firstOrDefault((it) => it.Label == value);
      if (old?.Value == select.Value) {
        this.formCargoQuantities.Data.TotalQuantityChargeUnitEn = undefined;
      } else {
        this.formCargoQuantities.Data.TotalQuantityChargeUnitEn = old?.Label;
      }
      this.packageKinds = this.packageKinds.filter(
        (it) => it.Label != select.Label
      );
      ElMessage.success("历史记录已删除");
      return;
    });
  }

  /**货量-初始化 */
  initCargoQuantities(): void {
    this.modalCargoQuantities.Top = "20px";
    this.modalCargoQuantities.Width = "96%";
    this.modalCargoQuantities.Height = "500px";
    this.modalCargoQuantities.ButtonWidth = "100px";
    if (this.canEdit) {
      this.modalCargoQuantities.Title = "编辑货量";
    } else {
      this.modalCargoQuantities.Title = "查看货量";
    }
    this.modalCargoQuantities.HandleClick = this.handleTopButtonClick;
    this.formCargoQuantities.Rules =
      DtoPutOrderPoolExpress_CargoQuantitiesRules;
    this.formCargoQuantities.Rules.DeliveryQuantity = [
      {
        validator: (
          rule: any,
          row: DtoListItemOrderPoolCargoQuantity_Shape,
          callback: any
        ) => {
          if (!this.listCargoQuantities.SelectIds.contains(row.Id)) {
            callback();
          } else if (row.DeliveryQuantity == undefined) {
            return callback(new Error("信息不能为空"));
          } else if (row.DeliveryQuantity <= 0) {
            return callback(new Error("仅可填入>0的正整数"));
          } else if (
            row.DeliveryQuantity >
            (row.TotalQuantity ?? 0) - (row.OccupiedQuantity ?? 0)
          ) {
            return callback(new Error("合计送货数量不能大于总数量"));
          } else {
            row.SurplusQuantity =
              (row.TotalQuantity ?? 0) -
              (row.OccupiedQuantity ?? 0) -
              (row.DeliveryQuantity ?? 0);
            callback();
          }
        },
        trigger: "blur",
      },
    ];

    this.listCargoQuantities.Fix = false;
    this.listCargoQuantities.HideCard = true;
    this.listCargoQuantities.MaxHeight = "400px";
    this.listCargoQuantities.EnabledDynamicSearch = false;
    this.listCargoQuantities.EnabledRadioSearch = false;
    this.listCargoQuantities.EnabledPagination = false;
    this.listCargoQuantities.EnabledSelect = this.canEdit;
    this.listCargoQuantities.Items =
      DtoListItemOrderPoolCargoQuantity_ShapeMiezzListItems;
    this.listCargoQuantities.Data = {
      Items: [],
    };
    if (this.listCargoQuantities.Items) {
      for (const item of this.listCargoQuantities.Items) {
        switch (item.Prop) {
          case "DeliveryQuantity":
            item.CustomizeHeader = true;
            break;
          default:
            break;
        }
      }
    }

    PackageEnAppService.GetSelect({ CompanyId: this.logier?.CompanyId }).then(
      (r) => {
        this.packageKinds = r.data.Data ?? [];
      }
    );
  }
  handleCargoQuantitiesSelect(): void {
    this.$nextTick(() => {
      let change = false;
      for (const item of this.listCargoQuantities.Data?.Items ?? []) {
        if (
          !this.listCargoQuantities.SelectIds.contains(item.Id) &&
          item.DeliveryQuantity
        ) {
          item.DeliveryQuantity = undefined;
          item.SurplusQuantity =
            (item.TotalQuantity ?? 0) - (item.OccupiedQuantity ?? 0);
          change = true;
        }
      }
      if (change) {
        this.handleDeliveryQuantityChange();
      }
    });
  }
  handleDeliveryQuantityChange(): void {
    let totalQuantity = 0;
    let totalGrossWeight = 0;
    let totalVolume = 0;
    for (const row of this.listCargoQuantities.SelectRows) {
      totalQuantity += row.DeliveryQuantity ?? 0;
      totalGrossWeight += (row.DeliveryQuantity ?? 0) * (row.GrossWeight ?? 0);
      totalVolume += (row.DeliveryQuantity ?? 0) * (row.Volume ?? 0);
    }
    this.formCargoQuantities.Data.TotalQuantity = totalQuantity;
    this.formCargoQuantities.Data.TotalGrossWeight = parseFloat(
      totalGrossWeight.toRound(2)
    );
    this.formCargoQuantities.Data.TotalVolume = parseFloat(
      totalVolume.toRound(4)
    );
  }

  /**实际提取时间-模态框 */
  modalAllCarryTime = new MiezzModal(MiezzModalType.Dialog);
  /**实际提取时间-表单 */
  formAllCarryTime = new MiezzForm<
    DtoPutOrderPoolExpress_AllCarryTime,
    string
  >();
  /**实际提取时间-初始化 */
  initAllCarryTime(): void {
    this.modalAllCarryTime.Height = "100px";
    if (this.canEdit) {
      this.modalAllCarryTime.Title = "编辑实际提取时间";
      this.modalAllCarryTime.Buttons = [
        {
          Code: "save-all-carry-time",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalAllCarryTime.Title = "查看实际提取时间";
    }
    this.modalAllCarryTime.HandleClick = this.handleTopButtonClick;
    this.formAllCarryTime.Items =
      DtoPutOrderPoolExpress_AllCarryTimeMiezzFormItems;
    this.formAllCarryTime.Rules = DtoPutOrderPoolExpress_AllCarryTimeRules;
  }
  handleChangeAllCarryTime(): void {
    const item = this.list.Data?.Items?.firstOrDefault(
      (it) => it.Id == this.id
    );
    if (
      item &&
      this.formAllCarryTime.Data.AllCarryTime &&
      ((item.AllDeliveryTime &&
        moment(this.formAllCarryTime.Data.AllCarryTime) >=
          moment(item.AllDeliveryTime)) ||
        (item.CarryContainerTime &&
          moment(this.formAllCarryTime.Data.AllCarryTime) <=
            moment(item.CarryContainerTime)))
    ) {
      ElMessage.warning(
        "提柜时间应早于实际提取时间，实际提取时间应早于实际送达时间"
      );
      this.formAllCarryTime.Data.AllCarryTime = undefined;
    }
  }

  showSupplier = false;
  handleSaveSupplier(data: any, complete: (s: boolean) => void): void {
    OrderPoolExpressAppService.PutSupplier(data)
      .then(() => {
        this.handleGet();
        complete(true);
      })
      .catch(() => complete(false));
  }

  /**快递单号-抽屉 */
  modalCodes = new MiezzModal(MiezzModalType.Dialog);
  /**快递单号-表单 */
  formCodes = new MiezzForm<DtoPutOrderPoolExpress_Codes, string>();
  /**快递单号-初始化 */
  initCodes(): void {
    this.modalCodes.Height = "310px";
    if (this.canEdit) {
      this.modalCodes.Title = "编辑快递单号";
      this.modalCodes.ButtonWidth = "120px";
      this.modalCodes.Buttons = [
        {
          Code: "import-codes",
          Label: "从打单系统导入",
          Type: "primary",
          Size: "small",
        },
        {
          Code: "save-codes",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalCodes.Title = "查看快递单号";
    }
    this.modalCodes.HandleClick = this.handleTopButtonClick;

    this.formCodes.Items = DtoPutOrderPoolExpress_CodesMiezzFormItems;
    this.formCodes.Rules = {
      MainCode: [
        { required: true, message: "信息不能为空", trigger: "blur" },
        {
          validator: (
            rule: any,
            value: DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode,
            callback: any
          ) => {
            if (!value.Code) {
              return callback(new Error("信息不能为空"));
            } else if (
              (this.formCodes.Data.Codes?.filter((it) => it.Code == value.Code)
                .length ?? 0) > 1
            ) {
              return callback(new Error("信息重复，请重新填写"));
            }
            callback();
          },
          trigger: "blur",
        },
      ],
      ChildrenCode: [
        { required: true, message: "信息不能为空", trigger: "blur" },
        {
          validator: (
            rule: any,
            value: DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode,
            callback: any
          ) => {
            const mi = rule.field.split("[")[1].substring(0, 1);
            const main = this.formCodes.Data.Codes
              ? this.formCodes.Data.Codes[mi]
              : undefined;
            if (!value.Code) {
              return callback(new Error("信息不能为空"));
            } else if (
              (main?.Children?.filter((it) => it.Code == value.Code).length ??
                0) > 1
            ) {
              return callback(new Error("信息重复，请重新填写"));
            } else {
              OrderPoolExpressAppService.CheckExistPartCode({
                Id: this.formCodes.Data.Id,
                MainCode: main?.Code,
                PartCode: value.Code,
              })
                .then((r) => {
                  if (r.data.Data) {
                    return callback(new Error("信息重复，请重新填写"));
                  } else {
                    callback();
                  }
                })
                .catch(callback);
            }
          },
          trigger: "blur",
        },
      ],
    };
  }
  handleDeleteMainCode(i: number): void {
    this.formCodes.Data.Codes?.splice(i, 1);
  }
  handleAddChildrenCode(
    main: DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode,
    complete: () => void
  ): void {
    main.Children?.push({});
    complete();
  }
  handleDeleteChildrenCode(
    main: DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode,
    i: number
  ): void {
    main.Children?.splice(i, 1);
  }

  /**交货地址-抽屉 */
  modalDeliveryAddress = new MiezzModal(MiezzModalType.Dialog);
  /**交货地址-表单 */
  formDeliveryAddress = new MiezzForm<
    DtoPutOrderPoolExpress_DeliveryAddress,
    string
  >();
  /**交货地址-初始化 */
  initDeliveryAddress(): void {
    this.modalDeliveryAddress.Height = "310px";
    if (this.canEdit) {
      this.modalDeliveryAddress.Title = "编辑交货地址";
      this.modalDeliveryAddress.Buttons = [
        {
          Code: "save-delivery-address",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalDeliveryAddress.Title = "查看交货地址";
    }
    this.modalDeliveryAddress.HandleClick = this.handleTopButtonClick;
    this.formDeliveryAddress.Items =
      DtoPutOrderPoolExpress_DeliveryAddressMiezzFormItems;
    this.formDeliveryAddress.Rules =
      DtoPutOrderPoolExpress_DeliveryAddress_CompanyAddressRules;
    //计划抵达时间应晚于计划提货时间
    this.formDeliveryAddress.Rules.PlanDeliveryTime = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //计划抵达时间应晚于计划提货时间
            if (
              this.row?.CarryPlanTime &&
              moment(this.row?.CarryPlanTime) >= moment(value)
            ) {
              this.formDeliveryAddress.Data.PlanDeliveryTime = undefined;
              return callback(new Error("计划抵达时间应晚于计划提货时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
  }
  /**交货地址-选择公司名称 */
  handleSelectDeliveryAddressCompanyName(
    select: DtoHistoryCompanyAddress_CompanyName,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (item.DeliveryAddress) {
      if (select.AreaLabel) {
        item.DeliveryAddress.AreaLabel = select.AreaLabel;
      } else if (select.AreaCode) {
        CompanyAddressAppService.GetAreaByCode(
          select.AreaCode,
          select.AreaLongitude,
          select.AreaLatitude
        ).then((r) => {
          if (item.DeliveryAddress) {
            item.DeliveryAddress.AreaLabel = r.data.Data?.FullName;
            if (!item.DeliveryAddress.Address && r.data.Data?.DetailedAddress)
              item.DeliveryAddress.Address = r.data.Data?.DetailedAddress;
          }
        });
      }
      item.DeliveryAddress.Address = select.Address;
      item.DeliveryAddress.Contact = select.Contact;
      item.DeliveryAddress.ContactNumber = select.ContactNumber;
    }
  }
  /**交货地址-选择坐标 */
  handleSelectDeliveryAddressArea(
    select: DtoSelectOptionParamCountryArea,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (item.DeliveryAddress) {
      item.DeliveryAddress.Address = select?.DetailedAddress;
    }
  }
  /**交货地址-选择详细地址 */
  handleSelectDeliveryAddressAddress(
    select: DtoCallbackAMapPlaceTextPoi,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (select.adcode) {
      CompanyAddressAppService.GetAreaByCode(
        select.adcode,
        select.Longitude,
        select.Latitude
      ).then((r) => {
        if (item.DeliveryAddress) {
          item.DeliveryAddress.AreaLabel = r.data.Data?.FullName;
        }
      });
    }
  }
  /**交货地址-选择联系人 */
  handleSelectDeliveryAddressContact(
    select: DtoHistoryCompanyAddress_Contact,
    item: DtoListItemOrderPoolExpress
  ): void {
    if (item.DeliveryAddress) {
      item.DeliveryAddress.ContactNumber = select.ContactNumber;
    }
  }

  /**实际送达时间-模态框 */
  modalAllDeliveryTime = new MiezzModal(MiezzModalType.Dialog);
  /**实际送达时间-表单 */
  formAllDeliveryTime = new MiezzForm<
    DtoPutOrderPoolExpress_AllDeliveryTime,
    string
  >();
  /**实际送达时间-初始化 */
  initAllDeliveryTime(): void {
    this.modalAllDeliveryTime.Title = "编辑实际送达时间";
    this.modalAllDeliveryTime.Height = "100px";
    if (this.canEdit)
      this.modalAllDeliveryTime.Buttons = [
        {
          Code: "save-all-delivery-time",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    this.modalAllDeliveryTime.HandleClick = this.handleTopButtonClick;
    this.formAllDeliveryTime.Items =
      DtoPutOrderPoolExpress_AllDeliveryTimeMiezzFormItems;
    this.formAllDeliveryTime.Rules =
      DtoPutOrderPoolExpress_AllDeliveryTimeRules;
  }
  handleChangeAllDeliveryTime(): void {
    const item = this.list.Data?.Items?.firstOrDefault(
      (it) => it.Id == this.id
    );
    if (
      item &&
      this.formAllDeliveryTime.Data.AllDeliveryTime &&
      ((item.AllCarryTime &&
        moment(this.formAllDeliveryTime.Data.AllDeliveryTime) <=
          moment(item.AllCarryTime)) ||
        (item.CarryContainerTime &&
          moment(this.formAllDeliveryTime.Data.AllDeliveryTime) <=
            moment(item.CarryContainerTime)))
    ) {
      ElMessage.warning(
        "提柜时间应早于实际提取时间，实际提取时间应早于实际送达时间"
      );
      this.formAllDeliveryTime.Data.AllDeliveryTime = undefined;
    }
  }

  /**附件-模态框 */
  modalFile = new MiezzModal(MiezzModalType.Dialog);
  /**附件-表单 */
  formFile = new MiezzForm<DtoPutOrderPoolExpress_File, string>();
  /**附件-初始化 */
  initFile(): void {
    if (this.canEdit) {
      this.modalFile.Title = "编辑附件";
      this.modalFile.Buttons = [
        {
          Code: "save-file",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalFile.Title = "查看附件";
    }
    this.modalFile.HandleClick = this.handleTopButtonClick;
    this.formFile.Items = DtoPutOrderPoolExpress_FileMiezzFormItems;
    this.formFile.Rules = DtoPutOrderPoolExpress_FileRules;
  }

  clients: UiSelectOption<string>[] = [];
  orders: UiSelectOption<string>[] = [];
  modalAdd = new MiezzModal(MiezzModalType.Dialog);
  formAdd = new MiezzForm<
    {
      ClientCompanyId?: string;
      PoolId?: string;
    },
    string
  >();
  initAdd(): void {
    this.modalAdd.Title = "添加";
    this.modalAdd.Buttons = [
      {
        Code: "save-add",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modalAdd.HandleClick = this.handleTopButtonClick;
    this.formAdd.Data = {};
    this.formAdd.Rules = {
      ClientCompanyId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      PoolId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
    };
  }
  @Watch("formAdd.Data.ClientCompanyId")
  onFormAddClientCompanyIdChanged(): void {
    if (this.orderId && this.formAdd.Data.ClientCompanyId) {
      OrderAppService.GetBookingNoSelect({
        Id: this.orderId,
        ClientCompanyId: this.formAdd.Data.ClientCompanyId,
        DeparturePortExpress:
          this.serviceType == EnumOrderServiceType.DeparturePort
            ? true
            : undefined,
        DestinationPortExpress:
          this.serviceType == EnumOrderServiceType.DestinationPort
            ? true
            : undefined,
      }).then((r) => {
        this.orders = r.data.Data ?? [];
        if (this.orders.length == 1) {
          this.formAdd.Data.PoolId = this.orders.firstOrDefault()?.Value;
        }
      });
    } else {
      this.formAdd.Data.PoolId = undefined;
      this.orders = [];
    }
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    if (this.list.Data?.Lock) {
      ElMessage.warning("订单已锁定");
      complete();
      return;
    }
    if (this.list.Data?.Status == EnumOrderPoolStatus.Canceled) {
      ElMessage.warning("委托书已取消，无法修改");
      complete();
      return;
    }
    if (this.list.Data?.OrderStatus == EnumOrderStatus.Canceled) {
      ElMessage.warning("订单已取消，无法修改");
      complete();
      return;
    }
    switch (btn.Code) {
      case "add":
        if (this.list.Data?.StowageOrder == true && this.orderId) {
          this.modalAdd.Show = true;
          this.formAdd.Data = {};
          if (this.clients.length == 1) {
            this.formAdd.Data.ClientCompanyId =
              this.clients.firstOrDefault()?.Value;
          }
          complete();
        } else {
          console.log(this.poolId);
          if (this.list.Data && this.poolId) {
            this.list.Data.PoolId = this.poolId;
          }
          if (this.list.Data?.PoolId) {
            OrderPoolExpressAppService.PostByPoolIdByServiceType(
              this.list.Data.PoolId,
              this.serviceType
            )
              .then(() => {
                this.handleGet();
                ElMessage.success("添加成功");
                complete();
              })
              .catch(complete);
          }
        }
        break;
      case "save-add":
        (this.$refs["ref-form-add"] as any).validate((v: boolean) => {
          if (v) {
            OrderPoolExpressAppService.PostByPoolIdByServiceType(
              this.formAdd.Data.PoolId,
              this.serviceType
            )
              .then(() => {
                this.handleGet();
                this.modalAdd.Show = false;
                ElMessage.success("添加成功");
                complete();
              })
              .catch(complete);
          } else {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          }
        });
        complete();
        break;
      case "save-carry-container-time":
        MiezzForm.submit(this.$refs, "ref-form-carry-container-time", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutCarryContainerTime(
              this.formCarryContainerTime.Data
            )
              .then(() => {
                this.handleGet();
                this.modalCarryContainerTime.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-carry-address":
        MiezzForm.submit(this.$refs, "ref-form-carry-address", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutCarryAddress(
              this.formCarryAddress.Data
            )
              .then(() => {
                this.handleGet();
                this.modalCarryAddress.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "cargo-quantities-import-surplus":
        var listRef = (
          this.$refs["list-cargoQuantities"] as MiezzPageListComponent
        ).listRef;
        var change = false;
        for (const row of this.listCargoQuantities.Data?.Items ?? []) {
          if (!this.listCargoQuantities.SelectIds.contains(row.Id)) {
            listRef?.toggleRowSelection(row, true);
          }
          var deliveryQuantity =
            (row.PackageQuantity ?? 0) - (row.OccupiedQuantity ?? 0);
          if (row.DeliveryQuantity != deliveryQuantity) {
            change = true;
            row.DeliveryQuantity = deliveryQuantity;
          }
          row.SurplusQuantity = 0;
        }
        if (change) {
          this.handleDeliveryQuantityChange();
        }
        complete();
        break;
      case "save-cargo-quantities":
        (this.$refs["ref-form-cargo-quantities"] as any).validate(
          (v: boolean) => {
            if (v) {
              this.formCargoQuantities.Data.CargoQuantities =
                this.listCargoQuantities.SelectRows.map((it) => {
                  return {
                    Id: it.CargoQunantityId,
                    ShapeId: it.Id,
                    Quantity: it.DeliveryQuantity,
                  };
                });
              OrderPoolExpressAppService.PutCargoQuantities(
                this.formCargoQuantities.Data
              )
                .then(() => {
                  this.handleGet();
                  this.modalCargoQuantities.Show = false;
                  ElMessage.success("编辑成功");
                  complete();
                })
                .catch(complete);
            } else {
              setTimeout(() => {
                const error = document.getElementsByClassName("is-error");
                error[0]?.querySelector("input")?.focus();
              }, 1);
              complete();
            }
          }
        );
        complete();
        break;
      case "save-all-carry-time":
        MiezzForm.submit(this.$refs, "ref-form-all-carry-time", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutAllCarryTime(
              this.formAllCarryTime.Data
            )
              .then(() => {
                this.handleGet();
                this.modalAllCarryTime.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "add-main-code":
        this.formCodes.Data.Codes?.push({ Children: [{}] });
        complete();
        break;
      case "import-codes":
        OrderPoolExpressAppService.ImportExpressCodesById(this.row?.Id)
          .then((r) => {
            if (r.data.Data) this.formCodes.Data = r.data.Data;
            complete();
          })
          .catch(complete);
        break;
      case "save-codes":
        MiezzForm.submit(this.$refs, "ref-form-codes", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutCodes(this.formCodes.Data)
              .then(() => {
                this.handleGet();
                this.modalCodes.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-delivery-address":
        MiezzForm.submit(this.$refs, "ref-form-delivery-address", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutDeliveryAddress(
              this.formDeliveryAddress.Data
            )
              .then(() => {
                this.handleGet();
                this.modalDeliveryAddress.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-all-delivery-time":
        MiezzForm.submit(this.$refs, "ref-form-all-delivery-time", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutAllDeliveryTime(
              this.formAllDeliveryTime.Data
            )
              .then(() => {
                this.handleGet();
                this.modalAllDeliveryTime.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-file":
        MiezzForm.submit(this.$refs, "ref-form-file", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutFile(this.formFile.Data)
              .then(() => {
                this.handleGet();
                this.modalFile.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  row?: DtoListItemOrderPoolExpress;
  handleRowCommand(prop: string, row?: DtoListItemOrderPoolExpress): void {
    this.row = row;
    this.id = row?.Id;
    switch (prop) {
      case "CarryContainerTime":
        this.formCarryContainerTime.Data =
          new DtoPutOrderPoolExpress_CarryContainerTime();
        this.formCarryContainerTime.Data.Id = this.id;
        this.formCarryContainerTime.Data.PoolId = this.poolId;
        this.formCarryContainerTime.Data.OrderId = this.orderId;
        this.formCarryContainerTime.Data.ServiceType = this.serviceType;
        this.formCarryContainerTime.Data.CarryContainerTime =
          row?.CarryContainerTime;
        if (
          this.serviceType == EnumOrderServiceType.DestinationPort &&
          !this.formCarryContainerTime.Data.CarryContainerTime
        ) {
          OrderAppService.GetCarryContainerTime(this.orderId, this.poolId).then(
            (r) => {
              this.formCarryContainerTime.Data.CarryContainerTime = r.data.Data;
            }
          );
        }
        this.modalCarryContainerTime.Show = true;
        break;
      case "CarryAddress":
        this.formCarryAddress.Data = new DtoPutOrderPoolExpress_CarryAddress();
        this.formCarryAddress.Data.Id = this.id;
        this.formCarryAddress.Data.PoolId = this.poolId;
        this.formCarryAddress.Data.OrderId = this.orderId;
        this.formCarryAddress.Data.ServiceType = this.serviceType;
        this.formCarryAddress.Data.CarryAddressId = row?.CarryAddressId;
        this.formCarryAddress.Data.CarryAddress = JSON.copy(
          row?.CarryAddress ?? {}
        );
        this.formCarryAddress.Data.CarryPlanTime = row?.CarryPlanTime;
        if (
          this.serviceType == EnumOrderServiceType.DestinationPort &&
          this.formCarryAddress.Data.CarryAddress &&
          !this.formCarryAddress.Data.CarryAddress.Address
        ) {
          const supplier = this.list.Data?.WharfSuppliers?.firstOrDefault(
            (it: any) => it.PoolId == row?.PoolId
          );
          if (supplier) {
            this.formCarryAddress.Data.CarryAddress.CompanyName =
              this.formCarryAddress.Data.CarryAddress.CompanyName ??
              (supplier.NameZh ?? "") +
                (supplier.NameZh && supplier.NameEn ? "/" : "") +
                (supplier.NameEn ?? "");
            this.formCarryAddress.Data.CarryAddress.Address =
              this.formCarryAddress.Data.CarryAddress.Address ??
              (supplier.ReceivingAddressZh ?? "") +
                (supplier.ReceivingAddressZh && supplier.ReceivingAddressEn
                  ? "/"
                  : "") +
                (supplier.ReceivingAddressEn ?? "");
          } else {
            this.formCarryAddress.Data.CarryAddress.Address =
              "暂未获知，待后续补充";
          }
        }
        this.modalCarryAddress.Show = true;
        break;
      case "HasCargoQuantities":
        OrderPoolCargoQuantityAppService.Get({
          PoolId: row?.PoolId,
          ServiceType: row?.ServiceType,
          ExpressId: row?.Id,
        }).then((r) => {
          this.formCargoQuantities.Data =
            new DtoPutOrderPoolExpress_CargoQuantities();
          this.formCargoQuantities.Data.Id = this.id;
          this.formCargoQuantities.Data.TotalQuantity = row?.TotalQuantity;
          this.formCargoQuantities.Data.TotalQuantityChargeUnitEn =
            row?.TotalQuantityChargeUnitEn ?? "CTNS";
          this.formCargoQuantities.Data.TotalGrossWeight =
            row?.TotalGrossWeight;
          this.formCargoQuantities.Data.TotalVolume = row?.TotalVolume;
          if (this.listCargoQuantities.Data) {
            this.listCargoQuantities.Data.Items = r.data.Data?.Items
              ? r.data.Data?.Items.filter(
                  (x) => x.SurplusQuantity != 0 || x.DeliveryQuantity
                )
              : [];
            this.formCargoQuantities.Data.Items =
              this.listCargoQuantities.Data.Items;
          }
          this.listCargoQuantities.SelectIds = [];
          this.listCargoQuantities.DefaultSelectIds =
            r.data.Data?.Items?.filter(
              (it) => (it.DeliveryQuantity ?? 0) > 0
            ).map((it) => it.Id ?? "") ?? [];
          if (this.canEdit) {
            this.modalCargoQuantities.Buttons = [
              {
                Code: "save-cargo-quantities",
                Label: "保存",
                Type: "primary",
                Size: "small",
              },
            ];
            if ((this.listCargoQuantities.Data?.Items?.length ?? 0) > 0) {
              this.modalCargoQuantities.Buttons?.push({
                Code: "cargo-quantities-import-surplus",
                Label: "导入剩余",
                Type: "primary",
                Size: "small",
              });
            }
          }
          this.modalCargoQuantities.Show = true;
        });
        break;
      case "HasCargos":
        var path = `${this.$route.path.substring(
          0,
          this.$route.path.lastIndexOf("/")
        )}/${this.poolId ?? this.orderId}/cargos/${row?.Id}`;
        console.log(path);

        this.$router.push(path);
        break;
      case "AllCarryTime":
        this.formAllCarryTime.Data = new DtoPutOrderPoolExpress_AllCarryTime();
        this.formAllCarryTime.Data.Id = this.id;
        this.formAllCarryTime.Data.PoolId = this.poolId;
        this.formAllCarryTime.Data.OrderId = this.orderId;
        this.formAllCarryTime.Data.ServiceType = this.serviceType;
        this.formAllCarryTime.Data.AllCarryTime = row?.AllCarryTime;
        this.modalAllCarryTime.Show = true;
        break;
      case "SupplierLabel":
        var supplierData = JSON.copy(row ?? {});
        supplierData.PoolId = this.poolId;
        supplierData.OrderId = this.orderId;
        supplierData.ServiceType = this.serviceType;
        (this.$refs["ref-order-supplier"] as OrderSupplier).handleShow(
          supplierData
        );
        break;
      case "HasCodes":
        OrderPoolExpressAppService.GetCodesById(row?.Id).then((r) => {
          this.formCodes.Data = new DtoPutOrderPoolExpress_Codes();
          this.formCodes.Data = r.data.Data as DtoPutOrderPoolExpress_Codes;
          if (this.formCodes.Data.Codes?.length == 0) {
            this.formCodes.Data.Codes?.push({ Children: [{}] });
          }
          this.modalCodes.Show = true;
        });
        break;
      case "DeliveryAddress":
        this.formDeliveryAddress.Data =
          new DtoPutOrderPoolExpress_DeliveryAddress();
        this.formDeliveryAddress.Data.Id = this.id;
        this.formDeliveryAddress.Data.PoolId = this.poolId;
        this.formDeliveryAddress.Data.OrderId = this.orderId;
        this.formDeliveryAddress.Data.ServiceType = this.serviceType;
        this.formDeliveryAddress.Data.PlanDeliveryTime = row?.PlanDeliveryTime;
        this.formDeliveryAddress.Data.DeliveryAddressId =
          row?.DeliveryAddressId;
        this.formDeliveryAddress.Data.DeliveryAddress = JSON.copy(
          row?.DeliveryAddress ?? {}
        );
        this.modalDeliveryAddress.Show = true;
        break;
      case "AllDeliveryTime":
        this.formAllDeliveryTime.Data =
          new DtoPutOrderPoolExpress_AllDeliveryTime();
        this.formAllDeliveryTime.Data.Id = this.id;
        this.formAllDeliveryTime.Data.PoolId = this.poolId;
        this.formAllDeliveryTime.Data.OrderId = this.orderId;
        this.formAllDeliveryTime.Data.ServiceType = this.serviceType;
        this.formAllDeliveryTime.Data.AllDeliveryTime = row?.AllDeliveryTime;
        this.modalAllDeliveryTime.Show = true;
        break;
      case "Files":
        this.formFile.Data = new DtoPutOrderPoolExpress_File();
        this.formFile.Data.Id = this.id;
        this.formFile.Data.Files = JSON.copy(row?.Files ?? []);
        this.modalFile.Show = true;
        break;
      case "Process":
        if (row?.HasCodes) {
          this.modalTracking.Show = true;
        } else {
          ElMessage.warning("暂无数据，请向供应商询问详细进程");
        }
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemOrderPoolExpress,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      case "delete":
        if ((this.list.Data?.Items?.length ?? 0) == 1) {
          ElMessage.warning("至少需要保留一条信息");
          complete();
          return;
        }
        if (this.list.Data?.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.list.Data?.Status == EnumOrderPoolStatus.Canceled) {
          ElMessage.warning("委托书已取消，无法修改");
          complete();
          return;
        }
        if (this.list.Data?.OrderStatus == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        OrderPoolExpressAppService.Delete([row.Id ?? ""]).then(() => {
          ElMessage.success("删除成功");
          this.handleGet();
          complete();
        });
        break;
      default:
        break;
    }
  }
}
