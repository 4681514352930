import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
    return (_openBlock(), _createBlock(_component_el_col, {
      key: i,
      span: item.Span ?? _ctx.defaultSpan ?? 24
    }, {
      default: _withCtx(() => [
        (_ctx.checkShow(item, true))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "form-item", { item: item })
            ]))
          : (_ctx.checkShow(item, false) && item.Prop)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: item.Label,
                labelWidth: item.LabelWidth,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  (item.Type == _ctx.EnumMiezzFormItemType.Pre)
                    ? (_openBlock(), _createElementBlock("pre", _hoisted_2, _toDisplayString(_ctx.handleFormat(item)), 1))
                    : _createCommentVNode("", true),
                  (item.Type == _ctx.EnumMiezzFormItemType.Text)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.handleFormat(item)), 1))
                    : _createCommentVNode("", true),
                  (item.Type == _ctx.EnumMiezzFormItemType.TextColor)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        style: _normalizeStyle(_ctx.modelValue[item.Prop] ? `color: ${_ctx.modelValue[item.Prop]}` : '')
                      }, _toDisplayString(_ctx.handleFormat(item)), 5))
                    : (item.Type == _ctx.EnumMiezzFormItemType.InputText)
                      ? (_openBlock(), _createBlock(_component_miezz_input, {
                          key: 3,
                          modelValue: _ctx.modelValue[item.Prop],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                          placeholder: "请填写",
                          disabled: item.Disabled,
                          onHandleChange: item.HandleChange,
                          onHandleBlur: item.HandleBlur,
                          class: "form-item-input-text"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onHandleChange", "onHandleBlur"]))
                      : (item.Type == _ctx.EnumMiezzFormItemType.InputPassword)
                        ? (_openBlock(), _createBlock(_component_miezz_input, {
                            key: 4,
                            modelValue: _ctx.modelValue[item.Prop],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                            placeholder: "请填写",
                            showPassword: "",
                            disabled: item.Disabled,
                            onHandleChange: item.HandleChange,
                            onHandleBlur: item.HandleBlur,
                            class: "form-item-input-text"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onHandleChange", "onHandleBlur"]))
                        : (item.Type == _ctx.EnumMiezzFormItemType.AreaText)
                          ? (_openBlock(), _createBlock(_component_miezz_input, {
                              key: 5,
                              modelValue: _ctx.modelValue[item.Prop],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                              type: "textarea",
                              rows: 5,
                              trim: false,
                              placeholder: "请填写",
                              disabled: item.Disabled,
                              onHandleChange: item.HandleChange,
                              onHandleBlur: item.HandleBlur,
                              class: "form-item-input-text"
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onHandleChange", "onHandleBlur"]))
                          : (
          item.Type == _ctx.EnumMiezzFormItemType.Select && item.HandleChange
        )
                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                key: 6,
                                modelValue: _ctx.modelValue[item.Prop],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                                options: item.Options,
                                allowCreate: item.AllowCreate,
                                multiple: item.Multiple,
                                disabled: item.Disabled,
                                onHandleChange: item.HandleChange
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "allowCreate", "multiple", "disabled", "onHandleChange"]))
                            : (item.Type == _ctx.EnumMiezzFormItemType.Select)
                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                  key: 7,
                                  modelValue: _ctx.modelValue[item.Prop],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                                  options: item.Options,
                                  allowCreate: item.AllowCreate,
                                  multiple: item.Multiple,
                                  disabled: item.Disabled
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "allowCreate", "multiple", "disabled"]))
                              : (item.Type == _ctx.EnumMiezzFormItemType.Switch)
                                ? (_openBlock(), _createBlock(_component_el_switch, {
                                    key: 8,
                                    modelValue: _ctx.modelValue[item.Prop],
                                    "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                                    disabled: item.Disabled,
                                    onChange: item.HandleChange
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"]))
                                : (item.Type == _ctx.EnumMiezzFormItemType.ColorPicker)
                                  ? (_openBlock(), _createBlock(_component_el_color_picker, {
                                      key: 9,
                                      modelValue: _ctx.modelValue[item.Prop],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                                      predefine: _ctx.predefineColors
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "predefine"]))
                                  : (item.Type == _ctx.EnumMiezzFormItemType.Date)
                                    ? (_openBlock(), _createBlock(_component_el_date_picker, {
                                        key: 10,
                                        modelValue: _ctx.modelValue[item.Prop],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[item.Prop]) = $event),
                                        type: "date",
                                        format: "YYYY/MM/DD",
                                        "value-format": "YYYY/MM/DD",
                                        class: "form-item-date"
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["label", "labelWidth", "prop"]))
            : _createCommentVNode("", true),
        (item.Description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(item.Description), 1))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, 1032, ["span"]))
  }), 128))
}