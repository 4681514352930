
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompany, {
  DtoDetailCompanyMiezzDetailItems,
} from "@/models.machine/DtoDetailCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyDetail from "../../CompanyDetail.vue";
import { CompanySetting } from "../../CompanySetting";
@Options({
  components: {
    CompanyDetail,
  },
})
export default class SupplierCompanyDetail extends Vue {
  type = EnumCompanyType.Supplier;
  detail = new MiezzForm<DtoDetailCompany, string>(
    "SupplierManagement_Company_Detail"
  );
  buttons: MiezzButton[] = [];
  settings: CompanySetting[] = [];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/supplier/company/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Menus = [
      {
        Url: `/supplier/company/detail/${this.$route.params.id}?page=BasicInfo`,
        Label: "公司信息",
        Power: "SupplierManagement_Company_Detail",
      },
    ];
    this.detail.Items = DtoDetailCompanyMiezzDetailItems.filter(
      (it) => it.Prop != "ServiceProviderCompanyId" && it.Prop != "SalesmanLabel" && it.Prop != "Source"
    );
    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "default",
        Size: "small",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "SupplierManagement_Company_Edit",
      },
    ];

    const logier = CurrentLogier.GetLogier();
    if (logier) {
      CurrentLogier.GetMenus().then((r) => {
        const powers = r?.Menus?.map((it) => it.Code) ?? [];
        if (powers.contains("SupplierManagement_Company_Contact")) {
          this.settings.push({
            Name: "通讯录",
            Url: "/supplier/company/contact/${id}",
          });
        }
        if (powers.contains("SupplierManagement_Company_Invoice")) {
          this.settings.push({
            Name: "开票资料",
            Url: "/supplier/company/invoice/${id}",
          });
        }
        if (this.settings.length > 0) {
          this.menuTab.Menus?.push({
            Url: `/supplier/company/detail/${this.$route.params.id}?page=Setting`,
            Label: "相关参数",
            Power: "SupplierManagement_Company_Detail",
          });
        }
      });
    }
  }
}
