import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import WebOptions from "../models.machine/WebOptions";

/**
 * 系统
 * @description 自动生成的代码,请勿修改
 */
export default class SysAppService {
    /**
     * 获取网站信息
     * @returns {AxiosPromise<RESTfulResult<WebOptions>>} 
     */
    static GetWebInfo(): AxiosPromise<RESTfulResult<WebOptions>> {
        const url = `/api/Sys/GetWebInfo`;
        return service.get<RESTfulResult<WebOptions>>(url);
    }

    /**
     * 是否可以注册服务商
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CanRegServiceProvider(): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Sys/CanRegServiceProvider`;
        return service.post<RESTfulResult<boolean>>(url);
    }

}
