
import MiezzMonthChoose from "@/components/MiezzMonthChoose.vue";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { init, EChartsOption } from "echarts";
import DtoRequestOrderPoolContainerTrailerChart, {
  DtoRequestOrderPoolContainerTrailerChartDynamicSearchItems,
} from "@/models.machine/DtoRequestOrderPoolContainerTrailerChart";
import { EnumOrderPoolContainerTrailerChartGroupBy } from "@/models.machine/EnumOrderPoolContainerTrailerChartGroupBy";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import { EnumTransportTypeOption } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoadOption } from "@/models.machine/EnumTransportContainerLoad";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import OrderPoolContainerTrailerAppService from "@/apis.machine/OrderPoolContainerTrailerAppService";
import UiEChartsPie from "@/models.machine/UiEChartsPie";
import UiEChartsBar from "@/models.machine/UiEChartsBar";
import {
  EnumOrderServiceType,
  EnumOrderServiceTypeOption,
} from "@/models.machine/EnumOrderServiceType";
@Options({
  components: {
    MiezzMonthChoose,
  },
})
export default class CountContainerTrailer extends Vue {
  types: {
    code?: string;
    title: string;
    type: "success" | "info";
  }[] = [
    {
      code: "CountTotalNumber",
      title: "票数统计",
      type: "success",
    },
    {
      code: "CountTotalNumber",
      title: "总计",
      type: "info",
    },
    {
      code: "CountCustomerNumber",
      title: "客户",
      type: "info",
    },
    {
      code: "CountSupplierNumber",
      title: "供应商",
      type: "info",
    },
    {
      code: "CountCustomNumber",
      title: "自定义",
      type: "info",
    },
    {
      code: "CountTotalGoods",
      title: "货量统计",
      type: "success",
    },
    {
      code: "CountTotalGoods",
      title: "总计",
      type: "info",
    },
    {
      code: "CountCustomerGoods",
      title: "客户",
      type: "info",
    },
    {
      code: "CountSupplierGoods",
      title: "供应商",
      type: "info",
    },
    {
      code: "CountCustomGoods",
      title: "自定义",
      type: "info",
    },
  ];
  type?: string = "";
  month?: string = "";
  tab?: string = "Number";

  companyId?: string;
  companies: UiSelectOption<string>[] = [];
  supplierId?: string;
  suppliers?: UiSelectOption<string>[] = [];
  dto = new DtoRequestOrderPoolContainerTrailerChart();
  searchs = DtoRequestOrderPoolContainerTrailerChartDynamicSearchItems;
  histories: UiSelectOption<string>[] = [];

  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  serviceType = EnumOrderServiceType.DeparturePort;
  EnumOrderServiceTypeOption = [
    { Label: "装货港", Value: 10, Data: "DeparturePort" },
    { Label: "目的港", Value: 20, Data: "DestinationPort" },
  ];

  containerTypes: UiSelectOption<string>[] = [];
  areas: UiSelectOption<string>[] = [];
  loadingPorts: UiSelectOption<string>[] = [];
  deliveryPorts: UiSelectOption<string>[] = [];

  created(): void {
    this.type = this.$route.query.type as string;
    if (!this.type) {
      this.type = this.types.filter((it) => it.code).firstOrDefault()?.code;
    }
    this.handleChooseType(this.type);

    OrderPoolContainerTrailerAppService.GetClients().then((r) => {
      this.companies = r.data.Data ?? [];
      this.companyId = this.companies.firstOrDefault()?.Value;
    });
    OrderPoolContainerTrailerAppService.GetSuppliers().then((r) => {
      this.suppliers = r.data.Data ?? [];
      this.supplierId = this.suppliers.firstOrDefault()?.Value;
    });
    OrderPoolContainerTrailerAppService.GetContainerTypes().then((r) => {
      this.containerTypes = r.data.Data ?? [];
    });
    OrderPoolContainerTrailerAppService.GetAreas().then((r) => {
      this.areas = r.data.Data ?? [];
    });
    OrderPoolContainerTrailerAppService.GetLoadingPorts().then((r) => {
      this.loadingPorts = r.data.Data ?? [];
    });
    OrderPoolContainerTrailerAppService.GetDeliveryPorts().then((r) => {
      this.deliveryPorts = r.data.Data ?? [];
    });
    this.handleGetHistories();
  }

  handleGetHistories(): void {
    DynamicSearchAppService.Get({
      Code: "Count_Pool",
    }).then((r) => {
      this.histories = r.data.Data ?? [];
    });
  }

  handleChooseType(type?: string): void {
    if (this.type != type) {
      this.type = type;
      this.tab = "Number";
      this.$route.query["type"] = this.type ?? "";
      this.$route.query["serviceType"] = this.serviceType.toString();
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        force: true,
      });
      this.handleChart();
    }
  }

  handleChooseServiceType(): void {
    this.tab = "Number";
    this.$route.query["type"] = this.type ?? "";
    this.$route.query["serviceType"] = this.serviceType.toString();
    this.$router.push({
      path: this.$route.path,
      params: this.$route.params,
      query: this.$route.query,
      force: true,
    });
    this.handleChart();
  }

  @Watch("month")
  onMonthChanged(nv: any, ov: any): void {
    if (nv != ov) {
      this.handleChart();
    }
  }

  countTotalGoodsBarOptions: UiEChartsBar[] = [];
  countCustomerGoodsOptions: UiEChartsPie[] = [];
  countCustomerGoodsBarOptions: UiEChartsBar[] = [];
  countSupplierGoodsOptions: UiEChartsPie[] = [];
  countSupplierGoodsBarOptions: UiEChartsBar[] = [];
  countCustomGoodsBarOptions: UiEChartsBar[] = [];
  handleChart(): void {
    if (this.type && this.month) {
      switch (this.type) {
        case "CountTotalNumber":
          this.getChartBar("CountTotalNumber", "票数总计", {
            Month: this.month,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle,
          });
          break;
        case "CountCustomerNumber":
          this.getChartPie("CountCustomerNumberPie", "客户组成", {
            Month: this.month,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.ClientCompany,
          });
          this.getChartBar("CountCustomerNumberBar", "客户组成", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle,
          });
          break;
        case "CountSupplierNumber":
          this.getChartPie("CountSupplierNumberPie", "供应商组成", {
            Month: this.month,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.SupplierCompany,
          });
          this.getChartBar("CountSupplierNumberBar", "供应商组成", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle,
          });
          break;
        case "CountCustomNumber":
          this.dto.Month = this.month;
          this.dto.GroupBy =
            EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle;
          this.dto.GroupByContainerType = false;
          this.getChartBar("CountCustomNumber", "票数总计", this.dto);
          break;
        case "CountTotalGoods":
          this.getChartBar("CountTotalGoodsBar", "", {
            Month: this.month,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle,
            GroupByContainerType: true,
          });
          break;
        case "CountCustomerGoods":
          this.getChartPie("CountCustomerGoodsPie", "", {
            Month: this.month,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.ClientCompany,
            GroupByContainerType: true,
          });
          this.getChartBar("CountCustomerGoodsBar", "", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle,
            GroupByContainerType: true,
          });
          break;
        case "CountSupplierGoods":
          this.getChartPie("CountSupplierGoodsPie", "", {
            Month: this.month,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.SupplierCompany,
            GroupByContainerType: true,
          });
          this.getChartBar("CountSupplierGoodsBar", "", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle,
            GroupByContainerType: true,
          });
          break;
        case "CountCustomGoods":
          this.dto.Month = this.month;
          this.dto.GroupBy =
            EnumOrderPoolContainerTrailerChartGroupBy.BusinessCycle;
          this.dto.GroupByContainerType = true;
          this.getChartBar("CountCustomGoodsBar", "票数总计", this.dto);
          break;
        default:
          break;
      }
    }
  }

  getChartBar(
    ref: string,
    title: string,
    dto: DtoRequestOrderPoolContainerTrailerChart
  ): void {
    dto.ServiceType = this.serviceType;
    OrderPoolContainerTrailerAppService.GetChartBar(dto).then((r) => {
      const datas = r.data.Data ?? [];
      if (ref == "CountTotalGoodsBar") {
        this.countTotalGoodsBarOptions = datas;
      }
      if (ref == "CountCustomerGoodsBar") {
        this.countCustomerGoodsBarOptions = datas;
      }
      if (ref == "CountSupplierGoodsBar") {
        this.countSupplierGoodsBarOptions = datas;
      }
      if (ref == "CountCustomGoodsBar") {
        this.countCustomGoodsBarOptions = datas;
      }
      for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        const option: EChartsOption = {
          title: {
            text: data.Title ?? title,
            left: "center",
            textStyle: {
              color: "#666",
            },
          },
          xAxis: {
            type: "category",
            data: data.XAxisData,
            axisLabel: { interval: 0 },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: data.SeriesData,
              type: "bar",
              label: {
                show: true,
                position: "top",
              },
              barMaxWidth: 70
            },
          ],
        };
        const _ref = dto.GroupByContainerType ? `${ref}_${index}` : ref;
        this.initChart(_ref, option);
      }
    });
  }

  getChartPie(
    ref: string,
    title: string,
    dto: DtoRequestOrderPoolContainerTrailerChart
  ): void {
    dto.ServiceType = this.serviceType;
    OrderPoolContainerTrailerAppService.GetChartPie(dto).then((r) => {
      const datas = r.data.Data ?? [];
      if (ref == "CountCustomerGoodsPie") {
        this.countCustomerGoodsOptions = datas;
      }
      if (ref == "CountSupplierGoodsPie") {
        this.countSupplierGoodsOptions = datas;
      }
      for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        const option: EChartsOption = {
          title: {
            text: data.Title ?? title,
            left: "center",
            textStyle: {
              color: "#666",
            },
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              label: {
                show: false,
              },
              data: data.SeriesData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        const _ref = dto.GroupByContainerType ? `${ref}_${index}` : ref;
        this.initChart(_ref, option);
      }
    });
  }

  initChart(ref: string, option: EChartsOption): void {
    this.$nextTick(() => {
      const element = document.getElementById(ref) as InstanceType<
        typeof HTMLElement
      >;
      console.log(ref, element);
      if (element) {
        const echarts = init(element);
        echarts.setOption(option);
      }
    });
  }
}
