import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_foreign_multiple_select = _resolveComponent("miezz-foreign-multiple-select")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_miezz_card = _resolveComponent("miezz-card")!

  return (_openBlock(), _createBlock(_component_miezz_card, { fix: true }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_form, {
        ref: "ruleForm",
        modelValue: _ctx.form,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form) = $event))
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'ServiceProviderCompanyId')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_select, {
                    modelValue: _ctx.form.Data[item.Prop],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                    options: _ctx.serviceProviders,
                    disabled: item.Disabled,
                    valueKey: "Id",
                    labelKey: "Name",
                    onChange: _ctx.handleServiceProviderCompanyIdChange
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled", "onChange"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'NameZh')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                prop: "NameZh",
                label: "中文名称"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.form.Data[item.Prop],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                    "fetch-suggestions": _ctx.querySearchNameZh,
                    "trigger-on-focus": false,
                    disabled: item.Disabled,
                    clearable: "",
                    class: "form-item-input-text",
                    valueKey: "NameZh",
                    onHandleSelect: _ctx.handleNameZhSelect
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "disabled", "onHandleSelect"])
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (item.Prop == 'NameEn')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 2,
                prop: "NameEn",
                label: "英文名称"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.form.Data[item.Prop],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event)
                  }, _createSlots({ _: 2 }, [
                    (_ctx.nameEnAbbr)
                      ? {
                          name: "append",
                          fn: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              prop: "NameEnAbbr",
                              label: "缩写",
                              "label-width": "70px"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_miezz_input, {
                                  modelValue: _ctx.form.Data.NameEnAbbr,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.NameEnAbbr) = $event)),
                                  pattern: "[a-z]",
                                  upperCase: true
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (item.Prop == 'ReceivingAddressZh')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 3,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.form.Data.ReceivingAddressZh,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.ReceivingAddressZh) = $event)),
                    placeholder: "请填写中文收件地址",
                    class: "form-item-input-text",
                    style: {"width":"60%","margin-right":"10px"}
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.form.Data.ReceivingAddressZhZipCode,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.ReceivingAddressZhZipCode) = $event)),
                    placeholder: "请填写邮编",
                    class: "form-item-input-text",
                    style: {"width":"calc(40% - 10px)"}
                  }, null, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'ParamCompanyCodeTypeId')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 4,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_select, {
                    modelValue: _ctx.form.Data[item.Prop],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                    options: _ctx.companyCodeTypes
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'ParamCountryId')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 5,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_select, {
                    modelValue: _ctx.form.Data[item.Prop],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                    options: _ctx.countries
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'ParamTaxTypeId')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 6,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_select, {
                    modelValue: _ctx.form.Data[item.Prop],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                    options: _ctx.taxTypes
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'CompanyInvoiceTaxRates')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 7,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_foreign_multiple_select, {
                    modelValue: _ctx.form.Data[item.Prop],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                    options: _ctx.invoiceTaxRates,
                    prop: "ParamInvoiceTaxRateId"
                  }, {
                    default: _withCtx(({ model }) => [
                      _createTextVNode(_toDisplayString(model.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'JoinCompanyTypes')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 8,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  (item.Multiple)
                    ? (_openBlock(), _createBlock(_component_miezz_foreign_multiple_select, {
                        key: 0,
                        modelValue: _ctx.form.Data[item.Prop],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                        options: _ctx.companyTypes,
                        disabled: item.Disabled,
                        clearable: false,
                        prop: "CompanyTypeId"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled"]))
                    : (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 1,
                        modelValue: _ctx.form.Data.CompanyType,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.CompanyType) = $event)),
                        options: _ctx.companyTypes,
                        disabled: item.Disabled,
                        clearable: false,
                        prop: "CompanyTypeId",
                        onChange: _ctx.handleCompanyTypeChange
                      }, null, 8, ["modelValue", "options", "disabled", "onChange"]))
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : (item.Prop == 'SalesmanId')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 9,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_select, {
                      modelValue: _ctx.form.Data.SalesmanId,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.Data.SalesmanId) = $event)),
                      label: _ctx.form.Data.SalesmanLabel,
                      "onUpdate:label": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.Data.SalesmanLabel) = $event)),
                      options: _ctx.filterSalesmans,
                      "filter-Method": _ctx.handleFilterSalesmans,
                      disabled: item.Disabled
                    }, null, 8, ["modelValue", "label", "options", "filter-Method", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : (item.Prop == 'Source')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 10,
                    prop: item.Prop,
                    label: item.Label
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: _ctx.form.Data.Source,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.Data.Source) = $event)),
                        "fetch-suggestions": _ctx.getSources,
                        valueKey: "Label",
                        "trigger-on-focus": true,
                        deleteOption: true,
                        checkDeleteOption: () => true,
                        onHandleDeleteOption: _ctx.handleDeleteSource
                      }, null, 8, ["modelValue", "fetch-suggestions", "checkDeleteOption", "onHandleDeleteOption"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "label"]))
                : _createCommentVNode("", true),
          (item.Prop == 'BusinessLicense')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 11,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_upload, {
                    modelValue: _ctx.form.Data.BusinessLicense,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.Data.BusinessLicense) = $event)),
                    path: "company/business-license",
                    accept: "image/*",
                    maxSize: 2 * 1024 * 1024,
                    preview: 
              _ctx.form.Data.Type != _ctx.EnumCompanyType.Platform &&
              _ctx.form.Data.AuthStatus == _ctx.EnumAuditStatus.Success
            
                  }, null, 8, ["modelValue", "preview"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'Logo')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 12,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_upload, {
                    modelValue: _ctx.form.Data.Logo,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.Data.Logo) = $event)),
                    path: "company/logo",
                    accept: "image/*",
                    maxSize: 2 * 1024 * 1024
                  }, null, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'CompanyFiles')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 13,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_upload, {
                    modelValue: _ctx.form.Data.CompanyFiles,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.Data.CompanyFiles) = $event)),
                    path: "company/file",
                    prop: "File",
                    maxSize: 2 * 1024 * 1024
                  }, null, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_miezz_button_bar, {
        modelValue: _ctx.buttons,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.buttons) = $event)),
        onHandleClick: _ctx.handleClick
      }, null, 8, ["modelValue", "onHandleClick"])
    ]),
    _: 1
  }))
}