
    import moment from "moment";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import CurrentLogier from "@/apis/CurrentLogier";
    import MiezzMonthChoose from "@/components/MiezzMonthChoose.vue";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { init, EChartsOption } from "echarts";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import DtoRequestCount from "@/models.machine/DtoRequestCount";
    import DtoCallbackSalerFormula from "@/models.machine/DtoCallbackSalerFormula";
    import DtoRequestCountPayable from "@/models.machine/DtoRequestCountPayable";
    import DtoCallbackCount from "@/models.machine/DtoCallbackCount";
    import DtoCallbackComposition from "@/models.machine/DtoCallbackComposition";
    import DtoCheckFormula from "@/models.machine/DtoCheckFormula";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
    import { EnumRelation } from "@/models.machine/EnumRelation";
    import { EnumCompare } from "@/models.machine/EnumCompare";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    //import DtoPageListSearchFinance, { DtoPageListSearchFinanceDynamicSearchItems } from "@/models.machine/DtoPageListSearchFinance";
    //import DtoPageListFinance from "@/models.machine/DtoPageListFinanceStat";
    //import DtoListItemFinance, {
    //    DtoListItemFinanceStatMiezzListItems,
    //} from "@/models.machine/DtoListItemFinanceStat";
    import {
        EnumSalerInputMode,
        EnumSalerInputModeOption,
    } from "@/models.machine/EnumSalerInputMode";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";

    import DtoPageListSearchSaler, { DtoPageListSearchSalerDynamicSearchItems } from "@/models.machine/DtoPageListSearchSaler";
    import DtoPageListSaler from "@/models.machine/DtoPageListSaler";
    import DtoListItemSaler, {
        DtoListItemSalerMiezzListItems,
    } from "@/models.machine/DtoListItemSaler";
    import DtoCallbackSalerSettlement from "@/models.machine/DtoCallbackSalerSettlement";
    import DtoRequestSalerSettlement from "@/models.machine/DtoRequestSalerSettlement";
    import DtoRequestOneSalerSettlement from "@/models.machine/DtoRequestOneSalerSettlement";
    import DtoFormSalerSettlement from "@/models.machine/DtoFormSalerSettlement";
    import SalerSettlementHistoryAppService from "@/apis.machine/SalerSettlementHistoryAppService";
    import DtoPageListSearchSalerSettlementHistory, { DtoPageListSearchSalerSettlementHistoryDynamicSearchItems } from "@/models.machine/DtoPageListSearchSalerSettlementHistory";
    import DtoPageListSalerSettlementHistory from "@/models.machine/DtoPageListSalerSettlementHistory";
    import DtoListItemSalerSettlementHistory, {
        DtoListItemSalerSettlementHistoryMiezzListItems,
    } from "@/models.machine/DtoListItemSalerSettlementHistory";
    import { asString } from "html2canvas/dist/types/css/types/color";
    import DtoPageListSearchFinance, { DtoPageListSearchFinanceDynamicSearchItems } from "@/models.machine/DtoPageListSearchFinance";

    @Options({
        components: {
            MiezzMonthChoose,
        },
    })
    export default class FinanceStatOverView extends Vue {
        grossProfitDetailForm: {
            Visible: boolean;
            Title: string;
            Items: any[];
        } = {
                Visible: false,
                Title: "组成",
                Items: [],
            };
        randomPrice = 0;
        isSalerPerformance = true;
        isGrossProfitChart = false;
        isStat = false;
        isHistory = true;
        /**分页列表 */
        historys = new MiezzPageList<DtoPageListSearchSalerSettlementHistory, DtoListItemSalerSettlementHistory, string>();

        settlementForm = new DtoCallbackSalerSettlement();
        settlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: false,
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        grossProfitDetailBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        isFinancialCycle = false;
        settlementTableRandom = 0;
        oneSettlementFormInit = new DtoFormSalerSettlement();
        oneSettlementForm = new DtoFormSalerSettlement();
        oneSettlementBottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            //{
            //    //Icon: "select",
            //    Code: "all",
            //    Label: "全部核销",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue: true,
                //Icon: "select",
                Code: "import",
                Label: "导入待核销",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        settlementDetailBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        oneSettlementRules = {
            Price: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        //this.oneSettlementForm.SurplusPrice = this.oneSettlementFormInit.SurplusPrice;
                        //callback(new Error("信息仅允许填写汉字"));

                        if (this.oneSettlementForm) {
                            var price = this.oneSettlementForm.Price;
                            if (price != undefined) {
                                var result1 = setDecimal(price.toString(), 2, false, true);
                                if (result1 != "") {
                                    this.oneSettlementForm.Price = Number(result1);

                                }
                                else {
                                    this.oneSettlementForm.Price = undefined;
                                }


                                price = 0;
                                //if (this.settlementForm && this.settlementForm.Items && this.oneSettlementForm) {
                                //    for (var it of this.settlementForm.Items) {
                                //        if (it.SelectMonth == this.oneSettlementForm.SelectMonth) {
                                //            if (it.Id != this.oneSettlementForm.Id && it.Price) {
                                //                price += it.Price;
                                //            }
                                //        }
                                //    }
                                //}
                                if (this.oneSettlementForm && this.oneSettlementForm.Price) {
                                    price = this.oneSettlementForm.Price;
                                }

                                //alert(price);
                                if (this.oneSettlementForm && this.oneSettlementForm.NoSettlementPrice) {
                                    this.oneSettlementForm.SurplusPrice = this.oneSettlementForm.NoSettlementPrice - Number(price);
                                    var result = setDecimal(this.oneSettlementForm.SurplusPrice.toString(), 2, false, true);
                                    if (result != "") {
                                        this.oneSettlementForm.SurplusPrice = Number(result);

                                    }
                                    this.randomPrice = Math.random();
                                }

                                //var total = 0;
                                //if(this.settlementForm){
                                //    if(this.settlementForm.Prices){
                                //        for(var it of this.settlementForm.Prices){
                                //            if(it.Month == this.oneSettlementForm.SelectMonth && it.Total){
                                //                total = it.Total;
                                //            }
                                //        }
                                //    }
                                //}



                                //this.oneSettlementForm.ConvertPrice = undefined;
                                if (this.oneSettlementForm && this.oneSettlementForm.SurplusPrice && this.oneSettlementForm.NoSettlementPrice) {



                                    if (this.oneSettlementForm.NoSettlementPrice > 0) {
                                        if (this.oneSettlementForm.Price && this.oneSettlementForm.Price < 0) {
                                            callback(new Error("不能录入<0的金额"));
                                            return false;
                                        }

                                        if (this.oneSettlementForm.SurplusPrice < 0) {
                                            callback(new Error("不能超出待发放金额"));
                                            return false;
                                        }
                                    }
                                    else if (this.oneSettlementForm.NoSettlementPrice < 0) {
                                        if (this.oneSettlementForm.Price && this.oneSettlementForm.Price > 0) {
                                            callback(new Error("不能录入>0的金额"));
                                            return false;
                                        }
                                        if (this.oneSettlementForm.SurplusPrice > 0) {
                                            callback(new Error("不能超出待发放金额"));
                                            return false;
                                        }
                                    }

                                    //price = this.oneSettlementForm.Price / this.oneSettlementForm.Rate;
                                    //result = setDecimal(price.toString(), 2, false, false);
                                    //if (result != "") {
                                    //    this.oneSettlementForm.ConvertPrice = Number(result);

                                    //}
                                    //if (this.oneSettlementForm.ConvertPrice && this.oneSettlementFormInit && this.oneSettlementFormInit.NoSettlementPrice) {
                                    //    this.oneSettlementForm.SurplusPrice = this.oneSettlementFormInit.NoSettlementPrice - this.oneSettlementForm.ConvertPrice;
                                    //}

                                    //price = this.oneSettlementForm.SurplusPrice;
                                    //if (price != undefined) {
                                    //    result = setDecimal(price.toString(), 2, true, true);
                                    //    if (result != "") {
                                    //        this.oneSettlementForm.SurplusPrice = Number(result);
                                    //    }
                                    //    else {
                                    //        this.oneSettlementForm.SurplusPrice = undefined;
                                    //    }
                                    //}

                                    //if ( && this.oneSettlementForm.SurplusPrice && (this.oneSettlementForm.NoSettlementPrice > 0 && this.oneSettlementForm.SurplusPrice < 0 || this.oneSettlementForm.NoSettlementPrice < 0 && this.oneSettlementForm.SurplusPrice > 0)) {
                                    //    callback(new Error("不能大于待发放金额"));
                                    //    return false;
                                    //}
                                }
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                }
            ],
            SettlementDate: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
            CollectionTitle: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
            PaymentTitle: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
        };

        checkResult = new DtoCheckFormula();

        //币制
        currencys: UiSelectOption<string>[] = [];
        currencyAll: UiSelectOption<string>[] = [];
        EnumSalerInputModeOption = EnumSalerInputModeOption;
        isLeft = false;
        /**分页列表 */
        /*        list = new MiezzPageList<DtoPageListSearchFinance, DtoListItemFinance, string>("FinancialManagement");*/
        items = new MiezzPageList<DtoPageListSearchSaler, DtoListItemSaler, string>("FinancialManagement");
        isLoading = false;
        month?: string = "";
        type?: string = "0";
        companyKey = 0;
        salerId?: string = "";
        companyName?: string = "";
        bookingNo?: string = "";
        companys?: UiSelectOption<string>[] = [];
        visible = false;
        selectSalerId?: string = "";
        salerFormulas?: UiSelectOption<string>[] = [];
         detailForm: {
            Visible: boolean;
            Title: string;
            Items: any[];
            Details: any[];
            CurrentSystemId: string | undefined;
        } = {
                Visible: false,
                Title: "组成",
                Items: [],
                Details: [],
                CurrentSystemId: undefined,
            };
        pattern = "";
        formulaInit = "";
        formulaForm = new DtoCallbackSalerFormula();
        formulaBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "save",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "submit",
                Label: "引用",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "reset",
                Label: "重置",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];


        search: any[] = [];
        left: any[] = [];
        right: any[] = [];
        detailBottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                IsBlue: true,
                Code: "submit",
                Label: "搜索相关账单",
                Type: "primary",
                Size: "small",
            },
            {
                //Icon:"CloseBold",
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        quoteBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];


        FinancialManagement_StatisticalAnalysis_Overview = false;
        FinancialManagement_StatisticalAnalysis_SalespersonRelated = false;
        FinancialManagement_StatisticalAnalysis_CustomerRelated = false;

        FinancialManagement_StatisticalAnalysis_SalespersonRelated_CollectionProgress = false;
        FinancialManagement_StatisticalAnalysis_SalespersonRelated_GrossProfit = false;
        FinancialManagement_StatisticalAnalysis_SalespersonRelated_SalespersonPerformance = false;

        FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditCalculationFormula = false;
        FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditPayableAmount = false;
        FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditActualPaymentAmount = false;


        result = new DtoCallbackCount();
        detail = new DtoCallbackComposition();

        countForm = new DtoRequestCountPayable();
        countBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        handleClickGrossProfitDetail(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.grossProfitDetailForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.isLoading = true;
                    if (this.settlementForm && this.settlementForm.Items) {
                        for (var s of this.settlementForm.Items) {
                            if (s.SettlementDateString != null && s.SettlementDateString != undefined && s.SettlementDateString != "") {
                                s.SettlementDate = new Date(s.SettlementDateString);
                            }
                        }
                    }

                    FinanceAppService.SaveSalerSettlement(this.settlementForm).then((r) => {
                        this.isLoading = false;
                        ElMessage.success("发放成功");
                        this.settlementForm.Visiable = false;
                        this.isInit = true;
                        this.onStat();
                        complete();
                    })

                    break;
                case "close":
                    this.settlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onNoBill(): void {
            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessCycle",
                    Compare: EnumCompare.Between,
                    Value: this.month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: this.month,
                    },
                };
            }
            var leftSearch = [];
            var rightSearch = [];
            leftSearch.push(date);

            //DynamicSearch_CollectionSubmitStatus
            if (this.type == "" || this.type == "0") {
                rightSearch.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_CollectionSubmitStatus",
                        Compare: EnumCompare.Equal,
                        Value: "1",
                    },
                });
            }
            else {
                rightSearch.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_PaymentSubmitStatus",
                        Compare: EnumCompare.Equal,
                        Value: "1",
                    },
                });
            }

            leftSearch.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_SalesmanId",
                    Compare: EnumCompare.Equal,
                    Value: this.salerId,
                },
            });

            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: leftSearch,
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: rightSearch,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/order/pool?IsStat=true&DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }

        onDeleteSettlement(index: number): void {
            if (this.settlementForm && this.settlementForm.Items) {
                if (this.settlementForm.NoSettlementPrice == null || this.settlementForm.NoSettlementPrice == undefined) {
                    this.settlementForm.NoSettlementPrice = 0;
                }
                var price = this.settlementForm.Items[index].ConvertPrice;
                if (price) {
                    this.settlementForm.NoSettlementPrice = this.settlementForm.NoSettlementPrice + price;
                    if (this.settlementForm.NoSettlementPrice) {
                        this.settlementForm.NoSettlementPrice = Number(this.settlementForm.NoSettlementPrice.toRound(2));
                    }
                }

                this.settlementForm.Items.splice(index, 1);
            }
            this.onSettlementSum();
        }

        settlementVisible = false;
        onSettlementList(): void {
            this.settlementVisible = true;
        }
        handleClickSettlementDetail(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.settlementVisible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onChangeRate(): void {
            if (this.countForm && this.countForm.Rates && this.countForm.Rates.length > 0) {
                for (var it of this.countForm.Rates) {
                    var rate = it.Rate + "";
                    var result = setDecimal(rate, 4, true, true);
                    if (result != "") {
                        it.Rate = Number(result);
                    } else {
                        it.Rate = undefined;
                    }
                }
            }
            this.onCountSalerPrice();
        }

        async onCountSalerPrice(): Promise<void> {
            if (this.countForm && this.countForm.Mode == 20 && this.countForm.Rates && this.countForm.Rates.length > 0) {
                this.countForm.CountType = 1;
                var isCount = true;
                for (var it of this.countForm.Rates) {
                    var rate = it.Rate + "";
                    if (rate == null || rate == undefined || rate == '') {
                        isCount = false;
                    }
                }
                if (isCount == true) {
                    await FinanceAppService.CountPayable(this.countForm).then((r) => {
                        if (r.data.Data) {
                            if (r.data.Data.IsError == true) {
                                ElMessage.warning(r.data.Data.Message);
                            }
                            this.countForm.Price = r.data.Data.Price;
                            this.countForm.IsError = r.data.Data.IsError;
                        }
                    })
                }
            }
        }
        //onChangeCountFormCurrencySystem(): void {
        async onChangeCountFormCurrencySystem(): Promise<void> {
            if (this.countForm && this.countForm.Rates) {
                for (var it of this.countForm.Rates) {
                    if (it.CurrencySystemId == this.countForm.CurrencySystemId) {
                        it.Rate = 1;
                        this.countForm.CurrencySystemName = it.CurrencySystemCode;
                    }
                }

                for (var it1 of this.countForm.Rates) {
                    if (it1.CurrencySystemId != this.countForm.CurrencySystemId) {
                        it1.Rate = undefined;
                        await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(it1.CurrencySystemCode, this.countForm.CurrencySystemName).then(cur => {
                            if (cur.data.Data) {
                                it1.Rate = Number(cur.data.Data);
                            }
                        })
                    }
                }
                await this.onCountSalerPrice();
            }
        }
        handleClickOneSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":

                    MiezzForm.customSubmit(this.$refs, "refOneSettlementForm", (v) => {
                        if (v == true) {
                            var t = JSON.parse(JSON.stringify(this.oneSettlementForm));
                            var exist = false;

                            if (this.settlementForm && this.settlementForm.Items) {
                                for (var it of this.settlementForm.Items) {
                                    if (it.Id == this.oneSettlementForm.Id) {

                                        it.SelectMonth = t.SelectMonth;
                                        it.Price = t.Price;
                                        it.PaymentTitle = t.PaymentTitle;
                                        it.CollectionTitle = t.CollectionTitle;
                                        it.SettlementDateString = moment(t.SettlementDate).format("YYYY/MM/DD HH:mm");
                                        it.ConvertPrice = t.ConvertPrice;
                                        it.Mode = t.Mode;
                                        it.Remark = t.Remark;
                                        it.Months = t.Months;
                                        it.Month = t.SelectMonth;
                                        it.SelectMonth = t.SelectMonth;
                                        it.MonthLabel = t.SelectMonth;
                                        //it.CurrencySystemId = t.BillCurrencySystemId;
                                        exist = true;
                                    }
                                }
                            }
                            if (exist == false) {
                                t.Months = this.oneSettlementForm.Months;
                                t.SelectMonth = this.oneSettlementForm.SelectMonth;
                                t.SettlementDateString = t.SettlementDate as string;
                                t.SettlementDateString = moment(t.SettlementDate).format("YYYY/MM/DD HH:mm");
                                t.CreatedUserName = this.settlementForm.CreatedUserName;
                                t.BillCurrencySystem = this.oneSettlementForm.BillCurrencySystem;
                                t.BillCurrencySystemCode = this.oneSettlementForm.BillCurrencySystemCode;
                                t.BillCurrencySystemId = this.oneSettlementForm.BillCurrencySystemId;
                                //t.CurrencySystemId = this.oneSettlementForm.BillCurrencySystemId;
                                t.ConvertPrice = this.oneSettlementForm.ConvertPrice;
                                t.Month = this.oneSettlementForm.SelectMonth;
                                t.SelectMonth = this.oneSettlementForm.SelectMonth;
                                t.MonthLabel = this.oneSettlementForm.SelectMonth;
                                if (this.settlementForm && this.settlementForm.Items) {
                                    this.settlementForm.Items.push(t);
                                }
                            }
                            //this.settlementForm.NoSettlementPrice = this.oneSettlementForm.SurplusPrice;
                            //this.settlementForm.SettlementPrice = this.oneSettlementForm.SurplusPrice;
                            this.oneSettlementForm.Visiable = false;
                            this.settlementTableRandom = Math.random();
                            this.onSettlementSum();
                            complete();
                        }
                        else {
                            complete();
                        }
                    });

                    break;
                case "import":


                    if (this.oneSettlementForm && this.oneSettlementFormInit) {
                        //if (this.oneSettlementForm && this.oneSettlementForm.Price != null && this.oneSettlementForm.Price != undefined) {
                        //    this.oneSettlementForm.Price = Number(this.oneSettlementForm.Price) + Number(this.oneSettlementFormInit.SurplusPrice);
                        //}
                        //else {
                        //    this.oneSettlementForm.Price = this.oneSettlementFormInit.NoSettlementPrice;
                        //}
                        this.oneSettlementForm.Price = this.oneSettlementFormInit.NoSettlementPrice;
                        this.oneSettlementForm.SurplusPrice = 0;
                        this.oneSettlementForm.CurrencySystemId = this.oneSettlementFormInit.BillCurrencySystemId;
                        this.oneSettlementForm.Rate = 1;
                        this.oneSettlementForm.CurrencySystem = this.oneSettlementFormInit.BillCurrencySystem;
                        this.oneSettlementForm.ConvertPrice = this.oneSettlementFormInit.SurplusPrice;

                        if (this.oneSettlementForm.CollectionTitle == null || this.oneSettlementForm.CollectionTitle == undefined || this.oneSettlementForm.CollectionTitle == "") {
                            this.oneSettlementForm.CollectionTitle = this.settlementForm.CollectionTitle;
                        }
                        if (this.oneSettlementForm.PaymentTitle == null || this.oneSettlementForm.PaymentTitle == undefined || this.oneSettlementForm.PaymentTitle == "") {
                            this.oneSettlementForm.PaymentTitle = this.settlementForm.PaymentTitle;
                        }
                    }
                    complete();

                    break;
                case "close":
                    this.oneSettlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onEditSettlement(row: any) {


            var noSettlementPrice = 0;
            if (this.settlementForm) {

                if (this.settlementForm.Totals) {
                    for (var total of this.settlementForm.Totals) {
                        if (total.Price) {
                            noSettlementPrice += total.Price;
                        }
                    }
                }

                if (this.settlementForm.Items) {
                    for (var it of this.settlementForm.Items) {
                        if (it.Price && it.SelectMonth == row.SelectMonth) {
                            noSettlementPrice = noSettlementPrice - it.Price;
                        }
                    }
                }

            }

            this.oneSettlementForm = new DtoFormSalerSettlement();
            this.oneSettlementForm.Id = row.Id;
            this.oneSettlementForm.SelectMonth = row.SelectMonth;
            this.oneSettlementForm.Months = row.Months;
            this.oneSettlementForm.Price = row.Price;
            this.oneSettlementForm.PaymentTitle = row.PaymentTitle;
            this.oneSettlementForm.CollectionTitle = row.CollectionTitle;
            this.oneSettlementForm.SettlementDate = row.SettlementDate;
            this.oneSettlementForm.Remark = row.Remark;
            this.oneSettlementForm.NoSettlementPrice = noSettlementPrice + row.Price;


            this.oneSettlementForm.BillCurrencySystem = row.BillCurrencySystem;
            this.oneSettlementForm.BillCurrencySystemCode = row.BillCurrencySystemCode;
            this.oneSettlementForm.BillCurrencySystemId = row.BillCurrencySystemId;
            this.oneSettlementForm.CurrencySystemCode = row.BillCurrencySystemCode;
            this.oneSettlementForm.CurrencySystemId = row.BillCurrencySystemId;
            this.oneSettlementForm.ConvertPrice = row.Price;


            if (this.settlementForm) {

                var total1 = 0;
                if (this.settlementForm.Prices) {
                    for (var it1 of this.settlementForm.Prices) {
                        if (it1.Month == row.SelectMonth && it1.Total) {
                            total1 = it1.Total;
                        }
                    }
                }

                if (this.settlementForm.Items) {
                    for (var it2 of this.settlementForm.Items) {
                        if (it2.MonthLabel == row.SelectMonth && it2.Price) {
                            total1 = total1 - it2.Price;
                        }
                    }
                }
                if (row.Price) {
                    this.oneSettlementForm.NoSettlementPrice = total1 + row.Price;
                    this.oneSettlementForm.SurplusPrice = total1;
                    if (this.oneSettlementForm.NoSettlementPrice) {
                        this.oneSettlementForm.NoSettlementPrice = Number(this.oneSettlementForm.NoSettlementPrice.toRound(2));
                    }
                }

                this.oneSettlementForm.SurplusPrice = total1;
                if (this.oneSettlementForm.SurplusPrice) {
                    this.oneSettlementForm.SurplusPrice = Number(this.oneSettlementForm.SurplusPrice.toRound(2));
                }
            }

            this.oneSettlementFormInit = JSON.parse(JSON.stringify(this.oneSettlementForm));

            console.log(this.oneSettlementForm);

            this.oneSettlementForm.Visiable = true;
        }
        onSettlementSum(): void {
            if (this.settlementForm) {

                if (this.settlementForm.Items) {
                    for (var it40 of this.settlementForm.Items) {

                        if (it40.MonthLabel) {
                            var months = it40.MonthLabel.split('-');
                            it40.DisplayMonth = months[0] + "-" + months[1];
                        }
                    }
                }

                this.settlementForm.NoSettlementPriceString = "";
                this.settlementForm.SettlementPriceString = "";
                var codes = [];
                if (this.settlementForm.Totals) {
                    for (var it of this.settlementForm.Totals) {
                        var flag = false;
                        for (var it1 of codes) {
                            if (it.CurrencySystemId == it1) {
                                flag = true;
                            }
                        }
                        if (flag == false) {
                            codes.push(it.CurrencySystemId);
                        }
                    }
                }

                for (var it2 of codes) {
                    var total = 0;
                    var settlement = 0;
                    var noSettleent = 0;
                    var code = "";
                    if (this.settlementForm.Totals) {
                        for (var it3 of this.settlementForm.Totals) {
                            if (it3.CurrencySystemId == it2) {
                                if (it3.Price) {
                                    total += it3.Price;
                                    noSettleent += it3.Price;
                                }
                                if (it3.CurrencySystemCode) {
                                    code = it3.CurrencySystemCode;
                                }
                            }
                        }
                    }

                    if (this.settlementForm.Items) {
                        for (var it4 of this.settlementForm.Items) {
                            if (it4.CurrencySystemId == it2) {
                                if (it4.Price) {
                                    settlement += it4.Price;
                                    noSettleent = noSettleent - it4.Price;
                                }
                            }
                        }
                    }
                    if (settlement != 0) {

                        var result = setDecimal(settlement + "", 2, false, true);
                        if (result != "") {
                            settlement = Number(result);

                        }
                        else {
                            settlement = 0;
                        }
                        this.settlementForm.SettlementPriceString += code + " " + settlement + "   ";
                    }


                    noSettleent = total - settlement;
                    this.settlementForm.IsAdd = false;
                    var result1 = setDecimal(noSettleent + "", 2, false, true);
                    if (result1 != "") {
                        noSettleent = Number(result1);
                        if (noSettleent != 0) {
                            this.settlementForm.IsAdd = true;
                        }
                    }
                    else {
                        noSettleent = 0;
                    }
                    this.settlementForm.NoSettlementPriceString += code + " " + noSettleent + "   ";
                }
            }
        }


        onChangeMonth(): void {
            this.onSettlementSum();
            if (this.settlementForm && this.settlementForm.Prices) {
                for (var it of this.settlementForm.Prices) {
                    if (it.Month == this.oneSettlementForm.SelectMonth) {

                        var total = it.Total;
                        if (this.settlementForm.Items && total) {
                            for (var it1 of this.settlementForm.Items) {
                                if (it1.MonthLabel == it.Month && it1.Price) {
                                    total = total - it1.Price;
                                }
                            }
                        }

                        if (total) {
                            var result = setDecimal(total.toString(), 2, false, false);
                            if (result != "") {
                                total = Number(result)
                            }
                        }


                        var surplus = total;
                        if (this.oneSettlementForm && this.oneSettlementForm.Price && surplus) {
                            surplus = surplus - this.oneSettlementForm.Price;

                            var result1 = setDecimal(surplus.toString(), 2, false, true);
                            if (result1 != "") {
                                surplus = Number(result1)
                            }
                        }


                        this.oneSettlementForm.SurplusPrice = surplus;
                        this.oneSettlementForm.NoSettlementPrice = total;
                        this.oneSettlementFormInit.SurplusPrice = surplus;
                        this.oneSettlementFormInit.NoSettlementPrice = total;





                        this.oneSettlementForm.BillCurrencySystemId = it.CurrencySystemId;
                        this.oneSettlementForm.BillCurrencySystemCode = it.CurrencySystemCode;
                        this.oneSettlementFormInit.BillCurrencySystemId = it.CurrencySystemId;
                        this.oneSettlementFormInit.BillCurrencySystemCode = it.CurrencySystemCode;
                        this.oneSettlementForm.CurrencySystemId = it.CurrencySystemId;
                        this.oneSettlementForm.CurrencySystemCode = it.CurrencySystemCode;
                        this.oneSettlementFormInit.CurrencySystemId = it.CurrencySystemId;
                        this.oneSettlementFormInit.CurrencySystemCode = it.CurrencySystemCode;
                    }
                }
            }
        }

        onAddSettlement(): void {
            var param = new DtoRequestOneSalerSettlement();
            param.PoolId = undefined;
            param.SettlementId = undefined;
            param.SalerId = this.settlementForm.SalerId;
            param.Month = this.month;
            this.isLoading = true;
            FinanceAppService.GetOneSalerSettlement(param).then((r) => {
                this.isLoading = false;
                if (r.data.Data) this.oneSettlementForm = r.data.Data;
                this.oneSettlementFormInit = JSON.parse(JSON.stringify(this.oneSettlementForm));
                //this.oneSettlementForm.PaymentTitle = this.settlementForm.PaymentTitle;
                this.oneSettlementForm.CollectionTitle = this.companyName;
                this.oneSettlementForm.SettlementDate = r.data.Data?.CurrentTime;

                var total = 0;
                if (this.settlementForm && this.settlementForm.Prices) {
                    for (var it of this.settlementForm.Prices) {
                        if (it.Month == this.oneSettlementForm.SelectMonth) {
                            this.oneSettlementForm.SurplusPrice = it.NoSettlementPrice;
                            this.oneSettlementForm.NoSettlementPrice = it.NoSettlementPrice;
                            this.oneSettlementFormInit.SurplusPrice = it.NoSettlementPrice;
                            this.oneSettlementFormInit.NoSettlementPrice = it.NoSettlementPrice;
                            this.oneSettlementForm.BillCurrencySystemId = it.CurrencySystemId;
                            this.oneSettlementForm.BillCurrencySystemCode = it.CurrencySystemCode;
                            this.oneSettlementFormInit.BillCurrencySystemId = it.CurrencySystemId;
                            this.oneSettlementFormInit.BillCurrencySystemCode = it.CurrencySystemCode;
                            if (it.Total) {
                                total = it.Total;
                            }
                        }
                    }
                }

                if (this.settlementForm && this.settlementForm.Items) {
                    for (var it1 of this.settlementForm.Items) {
                        if (it1.MonthLabel == this.oneSettlementForm.SelectMonth && it1.Price) {
                            total = total - it1.Price;
                        }
                    }
                }

                var result = setDecimal(total.toString(), 2, false, true);
                if (result != "") {
                    total = Number(result);

                }

                this.oneSettlementForm.NoSettlementPrice = total;
                this.oneSettlementForm.SurplusPrice = total;
                this.oneSettlementFormInit.NoSettlementPrice = total;
                this.oneSettlementFormInit.SurplusPrice = total;

                console.log(this.oneSettlementForm);

                //this.$nextTick(() => {
                //    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "Price");
                //    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "Rate");
                //    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "CollectionTitle");
                //    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "PaymentTitle");
                //    MiezzForm.clearCustomValidate(this.$refs, "refOneSettlementForm", "SettlementDate");
                //});
            })
        }
        onClientCompany(row: any) {
            window.open(
                "/#/customer/company/credit-and-accounting-period/" + row.ClientCompanyId,
                "_blank"
            );
        }
        onSettlement(row: any) {
            if ((row.Payment == null || row.Payment == undefined || row.Payment.length <= 0) && (row.PaymentString == null || row.PaymentString == undefined || row.PaymentString == "")) {
                ElMessage.warning("未录入应发金额，无法进行发放");
                return false;
            }

            var param = new DtoRequestSalerSettlement();
            if (this.isSalerPerformance == false) {
                param.PoolId = row.PoolId;
            }
            param.SalerId = this.salerId;
            param.Month = this.month;
            FinanceAppService.GetSalerSettlement(param).then((r) => {
                if (r.data.Data) this.settlementForm = r.data.Data;
                this.onSettlementSum();
                this.settlementForm.Visiable = true;
            })



            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = 1;
                this.historys.Dto.PageSize = 999999999;
                this.historys.Dto.Month = this.month;
                this.historys.Dto.SalerId = this.salerId;
            }
            this.isHistory = true;
            SalerSettlementHistoryAppService.Get(this.historys.Dto).then((r) => {
                this.historys.Data = r.data.Data;
            });

        }

        onChangePrice() {
            //this.oneSettlementForm.SurplusPrice = this.oneSettlementFormInit.SurplusPrice;
            //if (this.oneSettlementForm) {
            //    this.oneSettlementForm.Rate = 1;
            //    var price = this.oneSettlementForm.Price;
            //    if (price != undefined) {
            //        var result = setDecimal(price.toString(), 2, false,true);
            //        if (result != "") {
            //            this.oneSettlementForm.Price = Number(result);

            //        }
            //        else {
            //            this.oneSettlementForm.Price = undefined;
            //        }

            //    }
            //    else {
            //        if (this.oneSettlementForm.SurplusPrice && this.oneSettlementFormInit.ConvertPrice) {
            //            this.oneSettlementForm.SurplusPrice = this.oneSettlementForm.SurplusPrice + this.oneSettlementFormInit.ConvertPrice;

            //            price = this.oneSettlementForm.SurplusPrice;
            //            result = setDecimal(price.toString(), 2, true, true);
            //            if (result != "") {
            //                this.oneSettlementForm.SurplusPrice = Number(result);
            //            }
            //            else {
            //                this.oneSettlementForm.SurplusPrice = undefined;
            //            }
            //        }
            //    }
            //}

            //console.log(this.oneSettlementForm);

            var price = 0;
            if (this.settlementForm && this.settlementForm.Items && this.oneSettlementForm) {
                for (var it of this.settlementForm.Items) {
                    if (it.SelectMonth == this.oneSettlementForm.SelectMonth) {
                        if (it.Id != this.oneSettlementForm.Id && it.Price) {
                            price += it.Price;
                        }
                    }
                }
            }
            if (this.oneSettlementForm && this.oneSettlementForm.Price) {
                price += this.oneSettlementForm.Price;
            }

            if (this.oneSettlementForm && this.oneSettlementForm.NoSettlementPrice) {
                this.oneSettlementForm.SurplusPrice = this.oneSettlementForm.NoSettlementPrice - Number(price);

                var result = setDecimal(this.oneSettlementForm.SurplusPrice.toString(), 2, false, true);
                if (result != "") {
                    this.oneSettlementForm.SurplusPrice = Number(result);

                }
                this.randomPrice = Math.random();
            }
        }

        isInit = true;
        created(): void {
            this.FinancialManagement_StatisticalAnalysis_Overview = false;
            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated = false;
            this.FinancialManagement_StatisticalAnalysis_CustomerRelated = false;

            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditCalculationFormula = false;
            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditPayableAmount = false;
            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditActualPaymentAmount = false;

            //列表-传参
            this.historys.Dto = new DtoPageListSearchSalerSettlementHistory();
            //列表-动态搜索-项
            this.historys.DynamicSearchItems = DtoPageListSearchSalerSettlementHistoryDynamicSearchItems;
            //列表-数据-初始
            this.historys.Data = new DtoPageListSalerSettlementHistory();

            ////列表-传参
            //this.list.Dto = new DtoPageListSearchFinance();
            ////列表-动态搜索-项
            //this.list.DynamicSearchItems = DtoPageListSearchFinanceDynamicSearchItems;
            ////列表-数据-初始
            //this.list.Data = new DtoPageListFinance();
            ////列表-列配置
            ////this.list.Items = DtoListItemFinanceMiezzListItems;
            ////列表-列配置
            ////this.list.Items = DtoListItemProductMiezzListItems;
            //this.list.Fix = false;
            //this.list.EnabledDynamicSearch = false;
            //this.list.EnabledRadioSearch = false;
            //this.list.EnabledRadioSearchAll = false;
            //this.list.EnabledSelect = false;
            //this.list.Items = DtoListItemFinanceStatMiezzListItems?.map((it) => {
            //    const item = Object.copy(it);
            //    if (item.Prop == "AccountPeriod") {
            //        item.Width = 110;
            //    }
            //    if (item.Prop == "BookingNo") {
            //        item.Width = 100;
            //    }
            //    if (item.Prop == "TypeString") {
            //        item.Width = 40;
            //    }
            //    if (item.Prop == "CurrencySystemId") {
            //        item.Width = 40;
            //    }
            //    return item;
            //});


            //列表-传参
            this.items.Dto = new DtoPageListSearchSaler();
            //列表-动态搜索-项
            this.items.DynamicSearchItems = DtoPageListSearchSalerDynamicSearchItems;
            //列表-数据-初始
            this.items.Data = new DtoPageListSaler();
            this.items.Fix = false;
            this.items.EnabledDynamicSearch = false;
            this.items.EnabledRadioSearch = false;
            this.items.EnabledRadioSearchAll = false;
            //this.items.Items = DtoListItemSalerMiezzListItems;
            //列表-列配置
            this.items.Items = [];
            var items = DtoListItemSalerMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "PaymentString") {
                    item.CustomizeHeader = true;
                    return item;
                }
                else {
                    return item;
                }

            });
            if (items && this.items.Items) {
                for (var it of items) {
                    if (it != undefined) {
                        this.items.Items.push(it);
                    }
                }
            }

            this.items.BottomButtons.push({
                Code: "export",
                Label: "导出账单",
                Icon: "download",
                Type: "text",
                Size: "small",
                Hide: false,
                Options: [
                    {
                        Code: "10",
                        Label: "应收账单",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "20",
                        Label: "应付账单",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "30",
                        Label: "业务员账单",
                        Size: "small",
                        Hide: false,
                    },
                    {
                        Code: "40",
                        Label: "导出全部",
                        Size: "small",
                        Hide: false,
                    }
                ],
            });



            ParamCurrencySystemAppService.GetSelect().then(currency => {
                if (currency.data.Data) {
                    this.currencyAll = currency.data.Data;
                    this.currencys = currency.data.Data;
                }


                this.salerId = this.$route.query.salerId as string;
                FinanceAppService.GetSaler().then((r) => {
                    if (r.data.Data) {
                        this.companys = r.data.Data;

                    }
                    CurrentLogier.GetPowerCodes().then((r) => {
                        if (r?.contains("FinancialManagement_StatisticalAnalysis_Overview")) {
                            this.FinancialManagement_StatisticalAnalysis_Overview = true;
                        }
                        if (
                            r?.contains(
                                "FinancialManagement_StatisticalAnalysis_SalespersonRelated"
                            )
                        ) {
                            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated = true;
                        }
                        if (r?.contains("FinancialManagement_StatisticalAnalysis_CustomerRelated")) {
                            this.FinancialManagement_StatisticalAnalysis_CustomerRelated = true;
                        }


                        if (r?.contains("FinancialManagement_StatisticalAnalysis_SalespersonRelated_CollectionProgress")) {
                            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_CollectionProgress = true;
                        }
                        if (r?.contains("FinancialManagement_StatisticalAnalysis_SalespersonRelated_GrossProfit")) {
                            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_GrossProfit = true;
                        }
                        if (r?.contains("FinancialManagement_StatisticalAnalysis_SalespersonRelated_SalespersonPerformance")) {
                            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_SalespersonPerformance = true;
                        }

                        if (r?.contains("FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditCalculationFormula")) {
                            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditCalculationFormula = true;
                        }
                        if (r?.contains("FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditPayableAmount")) {
                            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditPayableAmount = true;
                        }
                        if (r?.contains("FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditActualPaymentAmount")) {
                            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_EditActualPaymentAmount = true;
                        }

                        this.isStat = false;
                        this.onStat();
                    });
                })
            })


        }
        onGrossProfitDetail(): void {
            if (this.grossProfitDetailForm) {
                this.grossProfitDetailForm.Visible = true;
            }
        }
        onCountByMonth(month: any): void {
            this.countForm.SelectMonth = month;
            this.countForm.CurrencySystemId = undefined;
            FinanceAppService.CountPayable(this.countForm).then((r) => {
                if (r.data.Data) {
                    this.countForm = r.data.Data;

                    if (this.currencyAll && this.countForm && this.countForm.Rates) {
                        var item = JSON.parse(JSON.stringify(this.currencyAll));
                        this.currencys = [];
                        for (var it of item) {
                            var flag = false;
                            for (var it1 of this.countForm.Rates) {
                                if (it1.CurrencySystemId == it.Value) {
                                    flag = true;
                                }
                            }
                            if (flag == true) {
                                this.currencys.push(it);
                            }
                        }
                    }

                    this.countForm.Visible = true;

                    if (this.countForm.IsSettlement == true) {
                        if (this.countBottomButtons) {
                            this.countBottomButtons[0].Hide = true;
                        }
                    }
                    else {
                        if (this.countBottomButtons) {
                            this.countBottomButtons[0].Hide = false;
                        }
                    }
                }
            })
        }
        onCount(row: any): void {
            var selectRow = JSON.parse(JSON.stringify(row));
            this.bookingNo = selectRow.BookingNo;
            if (this.isSalerPerformance == false) {
                this.countForm.PoolId = selectRow.PoolId;
            }
            else {
                this.countForm.PoolId = undefined;
            }
            this.countForm.CurrencySystemId = undefined;
            this.countForm.Month = this.month;
            this.countForm.SalerId = this.salerId;
            this.countForm.CountType = 0;
            this.countForm.Collection = selectRow.Collection;
            this.countForm.Collectioned = selectRow.Collectioned;
            this.countForm.Cost = selectRow.Cost;
            this.countForm.Interest = selectRow.Interest;
            this.countForm.Payment = selectRow.Payment;
            this.countForm.Settlement = selectRow.Settlement;
            this.countForm.Actual = selectRow.Actual;
            this.countForm.Price = undefined;
            this.countForm.SelectMonth = undefined;
            this.countForm.BadCollection = selectRow.BacCollection;
            this.countForm.BadPayment = selectRow.BadPayment;
            if (this.month && this.month?.indexOf(",") < 0) {
                this.countForm.SelectMonth = this.month + "-01";
            }
            FinanceAppService.CountPayable(this.countForm).then((r) => {
                if (r.data.Data) {
                    this.countForm = r.data.Data;

                    if (this.currencyAll && this.countForm && this.countForm.Rates) {
                        var item = JSON.parse(JSON.stringify(this.currencyAll));
                        this.currencys = [];
                        for (var it of item) {
                            var flag = false;
                            for (var it1 of this.countForm.Rates) {
                                if (it1.CurrencySystemId == it.Value) {
                                    flag = true;
                                }
                            }
                            if (flag == true) {
                                this.currencys.push(it);
                            }
                        }
                    }

                    this.countForm.Visible = true;

                    if (this.countForm.IsSettlement == true) {
                        if (this.countBottomButtons) {
                            this.countBottomButtons[0].Hide = true;
                        }
                    }
                    else {
                        if (this.countBottomButtons) {
                            this.countBottomButtons[0].Hide = false;
                        }
                    }

                    //if (this.countForm.Mode != 10) {
                    //    this.onChangeCountFormCurrencySystem()
                    //}

                    if (this.currencys && this.currencys.length <= 0 && this.countBottomButtons) {
                        this.countBottomButtons[0].Hide = true;
                    }


                }
            })
        }
        handleClickCount(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":

                    if (this.countForm) {
                        if (this.countForm.IsFormula == false && this.countForm.Mode == 20) {
                            ElMessage.warning("当前没有设置公式，不能选择公式录入");
                            complete();
                            return;
                        }
                    }
                    MiezzForm.customSubmit(this.$refs, "refCountForm", (v) => {
                        if (v == true) {

                            if (this.countForm.Mode == 20) {
                                this.countForm.CountType = 2;
                                var isCount = true;
                                if (this.countForm && this.countForm.Rates && this.countForm.Rates.length > 0) {
                                    for (var it of this.countForm.Rates) {

                                        if (it.Rate == null || it.Rate == undefined) {
                                            isCount = false;
                                        }
                                        else {
                                            var rate = it.Rate + "";
                                            if (rate == "") {
                                                isCount = false;
                                            }
                                        }
                                    }
                                }
                                if (isCount == false) {
                                    ElMessage.warning("汇率不能为空");
                                    complete();
                                    return;
                                }

                                if (isCount == true) {
                                    FinanceAppService.CountPayable(this.countForm).then((r) => {
                                        this.countForm.Visible = false;
                                        this.isInit = true;
                                        this.onStat();
                                        complete();
                                    })
                                }
                            }
                            else {
                                this.countForm.CountType = 2;
                                FinanceAppService.CountPayable(this.countForm).then((r) => {
                                    this.countForm.Visible = false;
                                    this.isInit = true;
                                    this.onStat();
                                    complete();
                                })
                            }
                            complete();
                        }
                        else {
                            complete();
                        }
                    });


                    break;
                case "close":
                    this.countForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        insertTextAtCursor(text: string): void {
            const inputElement = this.$refs["inputRef"] as any;
            const startPos = inputElement.selectionStart;
            const endPos = inputElement.selectionEnd;
            const currentValue = inputElement.value;
            // 在光标位置插入文本
            this.formulaForm.Formula = currentValue.substring(0, startPos) + text + currentValue.substring(endPos, currentValue.length);
            // 更新光标位置到插入文本之后
            this.$nextTick(() => {
                inputElement.selectionStart = startPos + text.length;
                inputElement.selectionEnd = startPos + text.length;
                this.handleGetResult();
            });
        }
        onClick(val: string): void {
            this.insertTextAtCursor(val);
            //console.log(window.getSelection);
            //if (window.getSelection) {
            //    let selection = window.getSelection();
            //    console.log(selection);
            //    // 判断选区是否包含在输入框内部
            //    if (selection != null && selection != undefined && this.$el.contains(selection.anchorNode)) {
            //        //this.cursorPosition = `光标位于第 ${selection.focusOffset} 个字符`;
            //        alert(`光标位于第 ${selection.focusOffset} 个字符`);
            //    } else {
            //        if (this.formulaForm.Formula == null || this.formulaForm.Formula == undefined) {
            //            this.formulaForm.Formula = "";
            //        }
            //        this.formulaForm.Formula += val;
            //    }
            //    this.handleGetResult();
            //}
            //else {
            //    if (this.formulaForm.Formula == null || this.formulaForm.Formula == undefined) {
            //        this.formulaForm.Formula = "";
            //    }
            //    this.formulaForm.Formula += val;
            //}
        }
        onChangeSaler(): void {
            if (this.selectSalerId != null && this.selectSalerId != undefined && this.salerFormulas) {
                for (var it of this.salerFormulas) {
                    if (it.Value == this.selectSalerId) {
                        this.formulaForm.Formula = it.Data as string;
                    }
                }
            }
        }
        onClearCompany() {
            //this.$refs.YourRefName.$el.click()
            console.log("123");
        }
        handleGetResult(callback?: () => void): void {
            if (this.formulaForm.Formula) {
                if (this.pattern) {
                    const regExp = new RegExp(this.pattern, "ig");
                    this.formulaForm.Formula = this.formulaForm.Formula.match(regExp)?.join("");
                }
                this.formulaForm.Formula = TrimSpace(this.formulaForm.Formula as string);
                this.checkResult = new DtoCheckFormula();
                this.checkResult.Formula = TrimSpace(this.formulaForm.Formula as string);
                this.checkResult.IsError = false;
                this.checkResult.Message = [];
                FinanceAppService.CheckFormulaResult(this.checkResult).then((r) => {
                    if (r.data.Data) {
                        this.checkResult.IsError = r.data.Data.IsError;
                        this.checkResult.Message = r.data.Data.Message;
                    }
                })
            } else {
                this.result = [];
                if (callback) callback();
            }
        }

        handleKeyupResult(e: any): void {
            if (e.keyCode == 13) {
                // 取消光标
                e.target.blur();
            }
        }
        onFormula() {
            this.formulaForm = new DtoCallbackSalerFormula();
            this.formulaInit = "";
            this.pattern = "[\\0-\\9\\.\\-\\（\\）\\(\\)\\+\\-\\×\\*\\÷\u5e94\u4ed8\u5e94\u6536\u6210\u672c\u5df2\u6536\u7ed3\u7b97\u4ef7\u903e\u671f\u5229\u606f\u574f\u8d26]";
            FinanceAppService.GetSalerFormulaBySalerId(this.salerId).then((r) => {
                if (r.data.Data) {
                    this.formulaForm = r.data.Data;
                    this.formulaForm.SalerId = this.salerId;
                    this.formulaForm.Visible = true;
                    if (this.formulaForm.Formula != null && this.formulaForm.Formula != undefined) {
                        this.formulaInit = this.formulaForm.Formula;
                    }
                }
            })
        }
        handleClickQuote(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.onChangeSaler();
                    this.visible = false;
                    complete();
                    break;
                case "close":
                    this.visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        handleClickFormula(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "save":
                    MiezzForm.customSubmit(this.$refs, "refformulaForm", (v) => {
                        if (v == true) {

                            this.checkResult = new DtoCheckFormula();
                            this.checkResult.Formula = TrimSpace(this.formulaForm.Formula as string);
                            this.checkResult.IsError = false;
                            this.checkResult.Message = [];
                            FinanceAppService.CheckFormulaResult(this.checkResult).then((r) => {
                                if (r.data.Data) {
                                    this.checkResult.IsError = r.data.Data.IsError;
                                    this.checkResult.Message = r.data.Data.Message;
                                    if (this.checkResult.IsError == false) {
                                        FinanceAppService.SaveSalerFormula(this.formulaForm).then((r) => {
                                            ElMessage.success("保存成功");
                                            this.formulaForm.Visible = false;
                                            complete();
                                        })
                                    }
                                }
                                complete();
                            })
                            complete();
                        }
                        else {
                            complete();
                        }
                    });


                    break;
                case "submit":
                    this.selectSalerId = undefined;
                    this.salerFormulas = [];
                    FinanceAppService.GetFormulaSalersBySalerId(this.salerId).then((r) => {
                        if (r.data.Data) {
                            this.salerFormulas = r.data.Data;
                            this.visible = true;
                        }
                        complete();
                    })
                    complete();
                    break;
                case "reset":
                    this.formulaForm.Formula = this.formulaInit;
                    complete();
                    break;
                case "close":
                    this.formulaForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        querySearchCompany(queryString: string, cb: any) {
            var restaurants = this.companys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.companys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.companys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.companys) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.companys.length; i++) {
                            if (i < 10) {
                                results.push(this.companys[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        //onChangeSaler(): void {
        //    this.salerId = item.Value;
        //}
        handleSelectCompany(item: UiSelectOption<string>) {

            this.salerId = item.Value;
            this.companyName = item.Label;

            //this.$nextTick(() => {
            //    this.$router.push(
            //        "/finance/stat/saler?Type=" + this.type + "&month=" + this.month + "&SalerId=" + this.salerId
            //    );
            //});

        }

        onChangeSelectSaler(): void {
            //this.$router.push(
            //    "/finance/stat/saler?Type=" + this.type + "&month=" + this.month + "&SalerId=" + this.salerId
            //);

            if (this.type != "2") {
                if (this.isFinancialCycle == false) {
                    this.$router.push(
                        "/finance/stat/saler?type=" + this.type + "&SalerId=" + this.salerId + "&month=" + this.month
                    );
                }
                else {
                    this.$router.push(
                        "/finance/stat/saler?type=" + this.type + "&SalerId=" + this.salerId + "&month=" + this.month + "&isFinancialCycle=true"
                    );
                }
            }
            else {
                this.$router.push(
                    "/finance/stat/saler?type=" + this.type + "&SalerId=" + this.salerId + "&month=" + this.month
                );
            }
        }


        //@Watch("salerId")
        //onSalerIdChanged(nv: any, ov: any): void {
        //    if (this.type == "2") {
        //        this.onStat();
        //    }
        //}


        //@Watch("month")
        //onMonthChanged(nv: any, ov: any): void {
        //    if (this.isInit == false) {
        //        this.onStat();
        //    }
        //}

        //@Watch("type")
        //onTypeChanged(): void {
        //    if (this.isInit == false) {
        //        this.onStat();
        //    }
        //}


        //onChangeType(type: string): void {
        //    this.$router.push(
        //        "/finance/stat/saler?Type=" + type + "&month=" + this.month + "&SalerId=" + this.salerId
        //    );

        //}

        onChangeType(type: string): void {
            //this.type = type;

            if (type != "2") {
                if (this.isFinancialCycle == false) {
                    this.$router.push(
                        "/finance/stat/saler?type=" + type + "&SalerId=" + this.salerId + "&month=" + this.month
                    );
                }
                else {
                    this.$router.push(
                        "/finance/stat/saler?type=" + type + "&SalerId=" + this.salerId + "&month=" + this.month + "&isFinancialCycle=true"
                    );
                }
            }
            else {
                this.$router.push(
                    "/finance/stat/saler?type=" + type + "&SalerId=" + this.salerId + "&month=" + this.month
                );
            }

            //this.onStat();
        }

        onTime(): void {
            if (this.isFinancialCycle == false) {
                this.$router.push(
                    "/finance/stat/saler?type=" + this.type + "&month=" + this.month + "&isFinancialCycle=true"
                );
            }
            else {
                this.$router.push(
                    "/finance/stat/saler?type=" + this.type + "&month=" + this.month + "&isFinancialCycle=false"
                );
            }
        }

        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            if (this.isInit == false) {
                this.isInit = true;
                this.onStat();
            }
        }



        onDetail(
            type: number,
            currencyId: string | undefined,
            currencyCode: string,
            title: string
        ): void {
            this.detailForm.Visible = true;
            this.detailForm.Title = "组成";
            this.getChartPie(
                type,
                currencyId,
                "detailFormPie",
                title + "(币制：" + currencyCode + ")"
            );
        }

        onStat(): void {

            this.$nextTick(() => {

                this.isFinancialCycle = false;
                var isFinancialCycle = this.$route.query.isFinancialCycle as string;
                if (isFinancialCycle == "true") {
                    this.isFinancialCycle = true;
                }

                this.isStat = false;

                var type = this.$route.query.type as string;
                if (type == null || type == undefined || type == "") {
                    type = this.$route.query.Type as string;
                    if (type == null || type == undefined || type == "") {
                        if (this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_CollectionProgress == true) {
                            type = "0";
                        }
                        else if (this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_GrossProfit == true) {
                            type = "3";
                        }
                        else if (this.FinancialManagement_StatisticalAnalysis_SalespersonRelated_SalespersonPerformance == true) {
                            type = "2";
                        }
                    }
                }

                var month = this.$route.query.month as string;
                if (month == null || month == undefined || month == "") {
                    month = this.$route.query.Month as string;
                }

                var salerId = this.$route.query.salerId as string;
                if (salerId == null || salerId == undefined || salerId == "") {
                    salerId = this.$route.query.SalerId as string;
                }

                this.isStat = true;
                if (type != this.type || month != this.month || salerId != this.salerId || this.isInit == true) {

                    //alert("type:" + type + "==" + this.type + " month:" + month + "==" + this.month + " salerId:" + salerId + "==" + this.salerId);
                    this.type = type;
                    this.month = month;
                    this.salerId = salerId;
                    this.isStat = false;
                }

                if (this.isStat == false) {
                    this.isStat = true;

                    if (this.salerId == null || this.salerId == undefined || this.salerId == "") {
                        if (this.companys && this.companys.length > 0) {
                            this.salerId = this.companys[0].Value;
                            this.companyName = this.companys[0].Label;
                        }
                    }
                    else {
                        if (this.companys && this.companys.length > 0) {
                            for (var c of this.companys) {
                                if (c.Value == this.salerId) {
                                    this.salerId = c.Value;
                                    this.companyName = c.Label;
                                }
                            }
                        }
                    }
                    if (this.salerId != null && this.salerId != undefined && this.salerId != "") {
                        if (this.type != "2") {
                            var param = new DtoRequestCount();
                            param.Month = this.month;
                            param.Channel = 1;
                            if (this.type == "") {
                                this.type = "0";
                            }
                            param.Type = Number(this.type);
                            param.SalerId = this.salerId;
                            this.isLoading = true;
                            param.IsFinancialCycle = this.isFinancialCycle;
                            FinanceAppService.Count(param).then((r) => {
                                this.isLoading = false;
                                this.isStat = false;
                                if (r.data.Data) {
                                    this.result = r.data.Data;
                                    if (this.type == "3") {
                                        this.onGrossProfitStat(this.result.GrossProfit);
                                    }
                                }
                                this.isInit = false;
                            });
                        }
                        else {
                            this.isLoading = true;

                            if (this.items.Dto) {
                                // this.items.Dto.Month = this.month;
                                this.items.Dto.Channel = 1;
                                this.items.Dto.Type = Number(this.type);
                                this.items.Dto.SalerId = this.salerId;
                                this.items.Dto.CompanyId = undefined;
                                this.items.Dto.companyId = undefined;
                                this.items.Dto.month = this.month;
                                this.items.IsFinancialCycle = this.isFinancialCycle;
                                //this.items.Dto.month = this.month;
                            }
                            FinanceAppService.GetSalerBill(this.items.Dto).then((r) => {
                                this.isLoading = false;
                                this.isStat = false;
                                if (r.data.Data) this.items.Data = r.data.Data;
                                this.isInit = false;
                            })
                        }


                    }
                }


            });



        }

        
        onClientDetail(row: any): void {
            this.left = [];
            this.right = [];

            var month = this.month;
            if (this.month?.indexOf(",") == -1) {
                month = this.month + "," + this.month;
            }
            if (this.isFinancialCycle == false) {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_BusinessDate",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }
            else {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }



            if (this.CompositionType == 1) {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_InvoiceState",
                        Compare: EnumCompare.NoEqual,
                        Value: "10",
                    },
                });
            }
            else if (this.CompositionType == 2) {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_InvoiceState",
                        Compare: EnumCompare.NoEqual,
                        Value: "30",
                    },
                });
            }
            else if (this.CompositionType == 3) {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "10",
                    },
                });
            }
            else if (this.CompositionType == 4) {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "30",
                    },
                });
            }
            else if (this.CompositionType == 5) {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_OverdueStatus",
                        Compare: EnumCompare.Equal,
                        Value: "10",
                    },
                });

                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "10",
                    },
                });
            }
            else if (this.CompositionType == 6) {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_OverdueStatus",
                        Compare: EnumCompare.Equal,
                        Value: "10",
                    },
                });

                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "30",
                    },
                });
            }




            if (row.Name.indexOf("未分摊") < 0) {

                if (row.Type == 0) {
                    this.left.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "10",
                        },
                    });


                }
                else if (row.Type == 1) {
                    this.left.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "20",
                        },
                    });
                }


                if (row.Type == 3) {
                    this.left.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_ClientCompany",
                            Compare: EnumCompare.Equal,
                            Value: row.CompanyId,
                        },
                    });
                }
                else {
                    this.left.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Object",
                            Compare: EnumCompare.Equal,
                            Value: row.Name,
                        },
                    });
                }




                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SalerId",
                        Compare: EnumCompare.Equal,
                        Value: this.salerId,
                    },
                });

                //this.right.push({
                //    Relation: EnumRelation.And,
                //    Where: {
                //        Prop: "DynamicSearch_SubmitStatus",
                //        Compare: EnumCompare.Equal,
                //        Value: "10",
                //    },
                //});

                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_CurrencySystem",
                        Compare: EnumCompare.Equal,
                        Value: this.detailForm.CurrentSystemId,
                    },
                });

                DynamicSearchAppService.Post({
                    Code: "FinancialManagement_BillSummary",
                    Brackets: [
                        {
                            Relation: EnumRelation.And,
                            Items: this.left,
                        },
                        {
                            Relation: EnumRelation.And,
                            Items: this.right,
                        },
                    ],
                }).then((r) => {
                    if (row.Type == 3) {
                        window.open(
                            `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                            "_blank"
                        );
                    }
                    else {
                        window.open(
                            `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                            "_blank"
                        );
                    }

                });
            }
            else {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_ShareStatus",
                        Compare: EnumCompare.Equal,
                        Value: "10",
                    },
                });

                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_CurrencySystem",
                        Compare: EnumCompare.Equal,
                        Value: this.detailForm.CurrentSystemId,
                    },
                });

                //this.right.push({
                //    Relation: EnumRelation.And,
                //    Where: {
                //        Prop: "DynamicSearch_SubmitStatus",
                //        Compare: EnumCompare.Equal,
                //        Value: "10",
                //    },
                //});

                DynamicSearchAppService.Post({
                    Code: "FinancialManagement_BillSummary",
                    Brackets: [
                        {
                            Relation: EnumRelation.And,
                            Items: this.left,
                        },
                        {
                            Relation: EnumRelation.And,
                            Items: this.right,
                        },
                    ],
                }).then((r) => {
                    if (row.Type == 3) {
                        window.open(
                            `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                            "_blank"
                        );
                    }
                    else {
                        window.open(
                            `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                            "_blank"
                        );
                    }

                });
            }

        }

        onChangeChannel(type: string): void {
            if (type == "0") {
                this.$router.push("/finance/stat/overview");
            } else if (type == "1") {
                this.$router.push("/finance/stat/saler");
            } else if (type == "2") {
                this.$router.push("/finance/stat/customer");
            }
        }

        CompositionType = 0;
        getChartPie(
            type: number,
            currencyId: string | undefined,
            ref: string,
            title: string
        ): void {
            var param = new DtoRequestCount();
            param.Month = this.month;
            param.Channel = 1;
            param.CurrencySystemId = currencyId;
            if (this.type == "") {
                this.type = "0";
            }
            param.CompositionType = type;
            param.Type = Number(this.type);
            param.SalerId = this.salerId;
            param.IsFinancialCycle = this.isFinancialCycle;
            this.CompositionType = type;
            this.isLoading = true;
            FinanceAppService.Composition(param).then((r) => {
                this.isLoading = false;
                if (r.data.Data) {
                    this.detail = r.data.Data;
                    this.detailForm.Items = r.data.Data.Items ?? [];
                    this.detailForm.Details = r.data.Data.Details ?? [];
                    this.detailForm.CurrentSystemId = r.data.Data.CurrencySystemId;
                    const option: EChartsOption = {
                        title: {
                            text: title,
                            left: "center",
                            textStyle: {
                                color: "#666",
                            },
                        },
                        tooltip: {
                            trigger: "item",
                        },
                        legend: {
                            orient: "vertical",
                            left: "left",
                        },
                        series: [
                            {
                                type: "pie",
                                radius: "50%",
                                label: {
                                    show: false,
                                },
                                data: r.data.Data?.SeriesData,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)",
                                    },
                                },
                            },
                        ],
                    };
                    this.initChart(ref, option);
                }
            });
        }


        initChart(ref: string, option: EChartsOption): void {
            this.$nextTick(() => {
                const element = this.$refs[ref] as InstanceType<typeof HTMLElement>;
                if (element) {
                    const echarts = init(element);
                    echarts.setOption(option);
                }
            });
        }
        isString(str: string, row: any | undefined): string {
            if (row != undefined && row != null) {
                if (row.FromId == undefined || row.FromId == null) {
                    if (str == null || str == undefined || str == "") {
                        return "暂无数据";
                    }
                    else {
                        return str;
                    }
                }
                else {
                    if (str == null || str == undefined || str == "") {
                        return "—";
                    }
                    else {
                        return str;
                    }
                }
            }
            else {
                if (str == null || str == undefined || str == "") {
                    return "暂无数据";
                }
                else {
                    return str;
                }
            }
        }
        onSalerShare(): void {
            this.search = [];

            this.search.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_SalerId",
                    Compare: EnumCompare.Equal,
                    Value: this.salerId,
                },
            });

            this.search.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_ShareStatus",
                    Compare: EnumCompare.Equal,
                    Value: "10",
                },
            });


            var month = "";
            if (this.month && this.month?.indexOf(",") >= 0) {
                month = this.month;
            }
            else {
                month = this.month + "," + this.month;
            }

            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessDate",
                    Compare: EnumCompare.Between,
                    Value: month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                }
            }
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            date,
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_Type",
                                    Compare: EnumCompare.Equal,
                                    Value: "20",
                                },
                            },
                        ],
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.search,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true&StatType=9999`,
                    "_blank"
                );
            });
        }
        onShare(): void {
            this.search = [];

            this.search.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_SalerId",
                    Compare: EnumCompare.Equal,
                    Value: this.salerId,
                },
            });

            this.search.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_ShareStatus",
                    Compare: EnumCompare.Equal,
                    Value: "10",
                },
            });

            var month = "";
            if (this.month && this.month?.indexOf(",") >= 0) {
                month = this.month;
            }
            else {
                month = this.month + "," + this.month;
            }
            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessDate",
                    Compare: EnumCompare.Between,
                    Value: month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                }
            }
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            date,
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_Type",
                                    Compare: EnumCompare.Equal,
                                    Value: "20",
                                },
                            },
                        ],
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.search,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true&StatType=9999`,
                    "_blank"
                );
            });
        }
        onPool(row: any): void {
            this.left = [];
            this.right = [];

            this.left.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_Pool",
                    Compare: EnumCompare.Contain,
                    Value: row.BookingNo,
                },
            });

            this.left.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_SalerId",
                    Compare: EnumCompare.Equal,
                    Value: this.salerId,
                },
            });

            var month = this.month;
            if (this.month?.indexOf(",") == -1) {
                month = this.month + "," + this.month;
            }
            if (this.isFinancialCycle == false) {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_BusinessDate",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }
            else {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }


            //this.right.push({
            //    Relation: EnumRelation.And,
            //    Where: {
            //        Prop: "DynamicSearch_SubmitStatus",
            //        Compare: EnumCompare.Equal,
            //        Value: "10",
            //    },
            //});
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: this.left,
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.right,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }
        onBill(type: string, currency: string) {
            this.left = [];
            this.right = [];
            var month = this.month;
            if (this.month?.indexOf(",") == -1) {
                month = this.month + "," + this.month;
            }
      
            if (this.isFinancialCycle == false) {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_BusinessDate",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }
            else {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }
            this.left.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_CurrencySystem",
                    Compare: EnumCompare.Equal,
                    Value: currency,
                },
            });


            if (this.type == "3") {
                if (type == "1") {
                    //利润
                    //this.left.push({
                    //    Relation: EnumRelation.And,
                    //    Where: {
                    //        Prop: "DynamicSearch_SubmitStatus",
                    //        Compare: EnumCompare.Equal,
                    //        Value: "10",
                    //    },
                    //});

                    this.right.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "10",
                        },
                    });
                    this.right.push({
                        Relation: EnumRelation.Or,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "20",
                        },
                    });
                }
                else if (type == "2") {
                    //应收款
                    //this.right.push({
                    //    Relation: EnumRelation.And,
                    //    Where: {
                    //        Prop: "DynamicSearch_SubmitStatus",
                    //        Compare: EnumCompare.Equal,
                    //        Value: "10",
                    //    },
                    //});

                    this.right.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "10",
                        },
                    });
                }
                else if (type == "3") {
                    //应付款
                    //this.right.push({
                    //    Relation: EnumRelation.And,
                    //    Where: {
                    //        Prop: "DynamicSearch_SubmitStatus",
                    //        Compare: EnumCompare.Equal,
                    //        Value: "10",
                    //    },
                    //});
                    this.right.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "20",
                        },
                    });
                }
            }
            else if (this.type == "4") {
                //this.left.push({
                //    Relation: EnumRelation.And,
                //    Where: {
                //        Prop: "DynamicSearch_SubmitStatus",
                //        Compare: EnumCompare.Equal,
                //        Value: "10",
                //    },
                //});
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.Equal,
                        Value: "20",
                    },
                });
                this.right.push({
                    Relation: EnumRelation.Or,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.Equal,
                        Value: "30",
                    },
                });
                if (type == "2") {
                    //应收已发放
                    this.right.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "10",
                        },
                    });
                }
                else if (type == "3") {
                    //应付已发放
                    this.right.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: "20",
                        },
                    });
                }
            }

            this.left.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_SalerId",
                    Compare: EnumCompare.Equal,
                    Value: this.salerId,
                },
            });

            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: this.left,
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.right,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }

        handleClickDetail(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.search = [];
                    if (this.detail.Search?.SettlementType == "30") {
                        this.search = [{
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "20",
                            },
                        },
                        {
                            Relation: EnumRelation.Or,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "30",
                            },
                        }
                        ];
                    }
                    else if (this.detail.Search?.SettlementType == "10") {
                        this.search = [{
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "10",
                            },
                        },
                        {
                            Relation: EnumRelation.Or,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "20",
                            },
                        }
                        ];
                    }

                    if (this.detail.Search?.OverdueType == "10") {
                        this.search.push({
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_OverdueStatus",
                                Compare: EnumCompare.Equal,
                                Value: "10",
                            },
                        });
                    }

                    //this.search.push({
                    //    Relation: EnumRelation.And,
                    //    Where: {
                    //        Prop: "DynamicSearch_SubmitStatus",
                    //        Compare: EnumCompare.Equal,
                    //        Value: "10",
                    //    },
                    //});

                    this.search.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_SalerId",
                            Compare: EnumCompare.Equal,
                            Value: this.salerId,
                        },
                    });
                    var date = {
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_BusinessDate",
                            Compare: EnumCompare.Between,
                            Value: this.detail.Search?.Month,
                        },
                    };
                    if (this.isFinancialCycle == true) {
                        date = {
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_FinancialCycle",
                                Compare: EnumCompare.Between,
                                Value: this.detail.Search?.Month,
                            },
                        };
                    }


                    var left = [];
                    left.push(date);
                    left.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_CurrencySystem",
                            Compare: EnumCompare.Equal,
                            Value: this.detail.Search?.CurrencySystemId,
                        },
                    });

                    left.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_Type",
                            Compare: EnumCompare.Equal,
                            Value: this.detail.Search?.Type,
                        },
                    });
                    if (this.CompositionType == 1) {
                        left.push({
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_InvoiceState",
                                Compare: EnumCompare.NoEqual,
                                Value: "10",
                            },
                        });
                    }
                    else if (this.CompositionType == 2) {
                        left.push({
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_InvoiceState",
                                Compare: EnumCompare.NoEqual,
                                Value: "30",
                            },
                        });
                    }


                    DynamicSearchAppService.Post({
                        Code: "FinancialManagement_BillSummary",
                        Brackets: [
                            {
                                Relation: EnumRelation.And,
                                Items: left,
                            },
                            {
                                Relation: EnumRelation.And,
                                Items: this.search,
                            },
                        ],
                    }).then((r) => {
                        window.open(
                            `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                            "_blank"
                        );
                        //window.location.href = `/#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`;

                        //this.$router.push(
                        //    `/finance/bill/batch/edit/${pre.data.Data}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        //this.$router.push({
                        //    path: `/finance/bill`,
                        //    query: {
                        //        DynamicSearchId: r.data.Data,
                        //        OpenSearch: "true",
                        //        r: Math.random(),
                        //        backUrl: this.$route.fullPath,
                        //    },
                        //});

                        complete();
                    });
                    complete();
                    break;
                case "close":
                    this.detailForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onGrossProfitStat(row: any): void {
            const option: EChartsOption = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                toolbox: {
                    show: true,
                    orient: 'vertical',
                    left: 'right',
                    top: 'center',
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar', 'bar', 'bar', 'stack'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                legend: {
                    data: ['毛利润', '应收', '应付', '应收坏账', '应付坏账', '毛利率']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: row.Months,
                        axisPointer: {
                            type: 'shadow'
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '毛利润',
                        min: row.MinPrice,
                        max: row.MaxPrice,
                        interval: row.IntervalPrice,
                        axisLabel: {
                            formatter: '{value} 元'
                        },

                    },
                    {
                        type: 'value',
                        name: '毛利率',
                        min: row.MinPoint,
                        max: row.MaxPoint,
                        interval: row.IntervalPoint,
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    }
                ],
                series: [
                    {
                        name: '毛利润',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.Price,
                        barMaxWidth: 50
                    },
                    {
                        name: '应收',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.Collection,
                        barMaxWidth: 50
                    },
                    {
                        name: '应付',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.Payment,
                        barMaxWidth: 50
                    },
                    {
                        name: '应收坏账',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.BadCollectionSettlement,
                        barMaxWidth: 50
                    },
                    {
                        name: '应付坏账',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.BadPaymentSettlement,
                        barMaxWidth: 50
                    },
                    {
                        name: '毛利率',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' %';
                            }
                        },
                        data: row.Point,
                    }
                ]
            };
            var ref = "divGrossProfitChart";
            this.onGrossProfitChart(ref, option);
        }
        onGrossProfitChart(ref: string, option: EChartsOption): void {
            this.isGrossProfitChart = true;
            this.$nextTick(() => {
                const element = this.$refs[ref] as InstanceType<typeof HTMLElement>;
                if (element) {
                    const echarts = init(element);
                    echarts.setOption(option);
                }
            });
        }
        onSumLink(): void {

            this.search = [];

            //this.search.push({
            //    Relation: EnumRelation.And,
            //    Where: {
            //        Prop: "DynamicSearch_SubmitStatus",
            //        Compare: EnumCompare.Equal,
            //        Value: "10",
            //    },
            //});
            var month = this.month;
            if (month != undefined && month?.indexOf(",") < 0) {
                month = month + "," + month;
            }
            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessDate",
                    Compare: EnumCompare.Between,
                    Value: month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                };
            }
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            date,
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_SalerId",
                                    Compare: EnumCompare.Equal,
                                    Value: this.salerId,
                                },
                            }
                        ],
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.search,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });


        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "export":
                    //OrderPoolAppService.ExportGoods(this.list.SelectIds)
                    //    .then((r) => {
                    //        window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                    //        ElMessage.success("导出成功");
                    //        complete();
                    //    })
                    //    .catch(complete);


                    var selectId = [];
                    if (btn.Command == "10") {
                        if (this.items && this.items.SelectRows) {
                            for (var row of this.items.SelectRows) {
                                if (row.CollectionId) {
                                    for (var it of row.CollectionId) {
                                        selectId.push(it);
                                    }
                                }
                            }
                        }
                    }
                    else if (btn.Command == "20") {
                        if (this.items && this.items.SelectRows) {
                            for (var row1 of this.items.SelectRows) {
                                if (row1.PaymentId) {
                                    for (var it1 of row1.PaymentId) {
                                        selectId.push(it1);
                                    }
                                }
                            }
                        }
                    }
                    else if (btn.Command == "30") {
                        if (this.items && this.items.SelectRows) {
                            for (var row2 of this.items.SelectRows) {
                                if (row2.SettlementId) {
                                    for (var it2 of row2.SettlementId) {
                                        selectId.push(it2);
                                    }
                                }
                            }
                        }
                    }
                    else if (btn.Command == "40") {
                        if (this.items && this.items.SelectRows) {
                            for (var row3 of this.items.SelectRows) {
                                if (row3.CollectionId) {
                                    for (var it3 of row3.CollectionId) {
                                        selectId.push(it3);
                                    }
                                }
                            }
                        }

                        if (this.items && this.items.SelectRows) {
                            for (var row31 of this.items.SelectRows) {
                                if (row31.PaymentId) {
                                    for (var it31 of row31.PaymentId) {
                                        selectId.push(it31);
                                    }
                                }
                            }
                        }

                        if (this.items && this.items.SelectRows) {
                            for (var row32 of this.items.SelectRows) {
                                if (row32.SettlementId) {
                                    for (var it32 of row32.SettlementId) {
                                        selectId.push(it32);
                                    }
                                }
                            }
                        }
                    }


                    if (this.items.SelectIds == null || this.items.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一条信息");
                        complete();
                        return;
                    }

                    var param10 = new DtoPageListSearchFinance();
                    param10.ExportId = selectId;
                    param10.DynamicSearchId = undefined;
                    param10.CurrentPage = 1;
                    param10.PageSize = 999999;
                    param10.IsStat = true;
                    this.isLoading = true;
                    FinanceAppService.Export(param10)
                        .then((ep) => {
                            this.isLoading = false;
                            window.open(process.env.VUE_APP_BASE_URL + ep.data.Data, "_blank");
                            complete();
                        })
                        .catch(complete);


                    break;
                default:
                    break;
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (
        val: string,
        num: number,
        isZero: boolean,
        isLessZero: boolean
    ) {
        var str = val;
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
            if (isLessZero == true) {
                if (str[0] == "-") {
                    val = "-" + val;
                }
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result =
            Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) +
            "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };

    //去除特殊字符~!@#$^-&*()=|{}':;',\[].<>/?~！@#￥……&*（）——|{}【】'；：""'。，、？
    function trimSpecial(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            str = str.replace(/[\u4e00-\u9fa5]/g, "");
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                } else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                //if (str[j] != " " && str[j] != "~" && str[j] != "!" && str[j] != "@"
                //    && str[j] != "#" && str[j] != "$" && str[j] != "^"
                //    && str[j] != "-" && str[j] != "&" && str[j] != "*"
                //    && str[j] != "(" && str[j] != ")" && str[j] != "="
                //    && str[j] != "|" && str[j] != "{" && str[j] != "}"
                //    && str[j] != "'" && str[j] != ":" && str[j] != "'"
                //    && str[j] != "," && str[j] != "\\" && str[j] != "["
                //    && str[j] != "]" && str[j] != "." && str[j] != "<"
                //    && str[j] != ">" && str[j] != "/" && str[j] != "?"
                //    && str[j] != "~" && str[j] != "！" && str[j] != "@"
                //    && str[j] != "#" && str[j] != "￥" && str[j] != "…"
                //    && str[j] != "&" && str[j] != "*" && str[j] != "（"
                //    && str[j] != "）" && str[j] != "—" && str[j] != "|"
                //    && str[j] != "{" && str[j] != "}" && str[j] != "【"
                //    && str[j] != "】" && str[j] != "'" && str[j] != "；"
                //    && str[j] != "：" && str[j] != "\"" && str[j] != "\""
                //    && str[j] != "'" && str[j] != "。" && str[j] != "，"
                //    && str[j] != "、" && str[j] != "？"
                //) {
                //    name += str[j];
                //}
                if (str[j] != " ") {
                    name += str[j];
                }
            }

            str = str.replace(/[\u00A0-\u00FF]|[\u0100-\u017F]|[\u0180-\u024F]/g, "?");
            //替换罗马数字到千位
            str = str.replace(
                /(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/g,
                "?"
            );
            //罗马1-9个数位限制和整数位
            str = str.replace(/[\u2160-\u2183]/g, "?");
            //替换中文标点符号
            str = str.replace(
                /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,
                "?"
            );
        }
        return name;
    }

    /*去除字符串中的所有空格 */
    function TrimSpace(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                if (str[j] != " ") {
                    name += str[j];
                }
            }
        }
        return name;
    }
