import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestPoolBill {
    constructor() {
        //无参构造函数
        this.Type = EnumBillType.Collection;
    }

    [proName: string]: any;
    /**
     * 委托书集合
     */
    PoolIds?: string[];
    /**
     * 订单集合
     */
    OrderIds?: string[];
    /**
     * 
     */
    Type?: EnumBillType;
}

/**
 * -表单校验
 */
export const DtoRequestPoolBillRules = {
}
