import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemBill from "./DtoListItemBill";
import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";
import UiSelectOption from "./UiSelectOption";
import DtoListItemBill_OrderPool from "./DtoListItemBill_OrderPool";
import { EnumOrderPoolStatus, EnumOrderPoolStatusOption } from "./EnumOrderPoolStatus";
import UiGroup from "./UiGroup";

/**
 * 委托书账单信息/订单 单项账单信息
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackSingleBill extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Items = [];
        this.IsLock = false;
        this.IsCancel = false;
        this.Accounts = [];
        this.IsDepartureChargeWeight = false;
        this.IsDestinationChargeWeight = false;
        this.IsNotDoTask = false;
        this.IsSumbit = false;
        this.IsRevoke = false;
    }

    [proName: string]: any;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * BookingNo
     */
    BookingNo?: string;
    /**
     * 委托人
     */
    ClientCompany?: string;
    /**
     * 合计
     */
    Total?: string;
    /**
     * 账单明细
     */
    Items?: DtoListItemBill[];
    /**
     * 类型
     */
    Type?: EnumBillType;
    /**
     * 模板名称
     */
    Name?: string;
    /**
     * 是否公开
     */
    IsOpen?: boolean;
    /**
     * 直订订单
     */
    DirectOrder?: boolean;
    /**
     * 订单是否锁住
     */
    IsLock?: boolean;
    /**
     * 委托公司主键
     */
    ClientCompanyId?: string;
    /**
     * 供应商主键
     */
    SupplierCompanyId?: string;
    /**
     * 订单/委托书是否取消
     */
    IsCancel?: boolean;
    /**
     * 查看账期的默认公司主键
     */
    AccountCompanyId?: string;
    /**
     * 查看账期的默认公司的用户主键
     */
    AccountUserId?: string;
    /**
     * 是否有结算价
     */
    IsSaler?: boolean;
    /**
     * 账期选项
     */
    Accounts?: UiSelectOption<string>[];
    /**
     * 委托书
     */
    Pool?: DtoListItemBill_OrderPool;
    /**
     * 业务员主键
     */
    SalerId?: string;
    /**
     * 业务员姓名
     */
    SalerName?: string;
    /**
     * 是否保存
     */
    IsSave?: boolean;
    /**
     * 委托书账单是否锁住
     */
    IsPoolLock?: boolean;
    /**
     * 订单账单是否锁住
     */
    IsOrderLock?: boolean;
    /**
     * 委托书状态
     */
    PoolStatus?: EnumOrderPoolStatus;
    /**
     * 是否显示起运港计费标准
     */
    IsDepartureChargeWeight?: boolean;
    /**
     * 起运港计费标准
     */
    DepartureChargeWeight?: string;
    /**
     * 是否显示目的港计费标准
     */
    IsDestinationChargeWeight?: boolean;
    /**
     * 目的港计费标准
     */
    DestinationChargeWeight?: string;
    /**
     * 是否不执行待办
     */
    IsNotDoTask?: boolean;
    /**
     * 是否提交
     */
    IsSumbit?: boolean;
    /**
     * 是否撤销
     */
    IsRevoke?: boolean;
}

/**
 * 委托书账单信息/订单 单项账单信息-表单校验
 */
export const DtoCallbackSingleBillRules = {
}

/**
 * 委托书账单信息/订单 单项账单信息-设置项
 */
export const DtoCallbackSingleBillMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "OrderId",
    Label: "订单主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PoolId",
    Label: "委托书主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "订单编号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "BookingNo",
    Label: "BookingNo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Total",
    Label: "合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Items",
    Label: "账单明细",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumBillTypeOption,
  },
  {
    Prop: "Name",
    Label: "模板名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsOpen",
    Label: "是否公开",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DirectOrder",
    Label: "直订订单",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsLock",
    Label: "订单是否锁住",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "ClientCompanyId",
    Label: "委托公司主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsCancel",
    Label: "订单/委托书是否取消",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "AccountCompanyId",
    Label: "查看账期的默认公司主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "AccountUserId",
    Label: "查看账期的默认公司的用户主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsSaler",
    Label: "是否有结算价",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "Accounts",
    Label: "账期选项",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Pool",
    Label: "委托书",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SalerId",
    Label: "业务员主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SalerName",
    Label: "业务员姓名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsSave",
    Label: "是否保存",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsPoolLock",
    Label: "委托书账单是否锁住",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsOrderLock",
    Label: "订单账单是否锁住",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "PoolStatus",
    Label: "委托书状态",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumOrderPoolStatusOption,
  },
  {
    Prop: "IsDepartureChargeWeight",
    Label: "是否显示起运港计费标准",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DepartureChargeWeight",
    Label: "起运港计费标准",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsDestinationChargeWeight",
    Label: "是否显示目的港计费标准",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationChargeWeight",
    Label: "目的港计费标准",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsNotDoTask",
    Label: "是否不执行待办",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsSumbit",
    Label: "是否提交",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsRevoke",
    Label: "是否撤销",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
]
