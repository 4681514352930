import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 报价表分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchPriceQuotationSheet extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
}

/**
 * 报价表分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchPriceQuotationSheetRules = {
}

/**
 * 报价表分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchPriceQuotationSheetDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_Company",
    Label: "客户企业",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_User",
    Label: "询价人",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Code",
    Label: "编号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_ServiceRemark",
    Label: "服务内容",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CreatedTime",
    Label: "创建时间",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
    ]
  },
  {
    Prop: "DynamicSearch_ValidDate",
    Label: "价格有效期",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
    ]
  },
  {
    Prop: "DynamicSearch_CreatedCompanyUserId",
    Label: "报价人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Stage",
    Label: "阶段",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Status",
    Label: "状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
]
