import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_choose_power = _resolveComponent("choose-power")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Avatar')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_upload, {
                  modelValue: _ctx.detail.Data.Avatar,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail.Data.Avatar) = $event)),
                  preview: true
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'User')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                (_ctx.detail.Data.UserId)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass('gender_' + _ctx.detail.Data.User?.Gender)
                    }, _toDisplayString(_ctx.detail.Data.User?.Name) + " / " + _toDisplayString(_ctx.detail.Data.User?.PhoneNumber), 3))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'JoinCompanyUserContacts')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              label: item.Label,
              prop: item.Prop,
              labelWidth: "0px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_page_list, {
                  modelValue: _ctx.list,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.list) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'JoinCompanyUserRoles')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 3,
              label: item.Label,
              prop: item.Prop,
              labelWidth: "0px"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data.JoinCompanyUserRoles, (role) => {
                  return (_openBlock(), _createBlock(_component_el_link, {
                    key: role.RoleId,
                    type: "primary",
                    style: {"margin-right":"5px"},
                    underline: false,
                    onClick: ($event: any) => (_ctx.handleShowPower(role.RoleId))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(role.Role?.Name), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128)),
                _createVNode(_component_el_alert, {
                  title: "提示：点击文字可查看该角色的具体权限",
                  type: "info",
                  effect: "light",
                  "show-icon": ""
                })
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.buttons,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.buttons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalPower,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalPower) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_choose_power, {
          modal: _ctx.modalPower,
          "onUpdate:modal": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalPower) = $event)),
          modelValue: _ctx.powerIds,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.powerIds) = $event)),
          tree: _ctx.roleTree,
          showCheck: false
        }, null, 8, ["modal", "modelValue", "tree"]),
        _createVNode(_component_el_alert, {
          style: {"margin-top":"15px"},
          title: "提示：鼠标悬停可查看权限具体说明，如需更改角色的具体权限，请联系系统客服人员",
          type: "info",
          effect: "light",
          "show-icon": ""
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}