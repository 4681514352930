
import { Vue } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import MiezzButton from "@/models/MiezzButton";
import UiGroup from "@/models.machine/UiGroup";
import UiButton from "@/models.machine/UiButton";

export default class MiezzFormComponent extends Vue {
  @Model() modelValue = new MiezzForm();

  form(): any {
    const form = this.$refs["ruleForm"] as any;
    return form;
  }
}
