import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumPriceQuotationSheetStatus, EnumPriceQuotationSheetStatusOption } from "./EnumPriceQuotationSheetStatus";
import DtoDetailPriceQuotationSheet_Company from "./DtoDetailPriceQuotationSheet_Company";
import DtoDetailPriceQuotationSheet_JoinCompanyUser from "./DtoDetailPriceQuotationSheet_JoinCompanyUser";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetFieldValue from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetFieldValue";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetFile from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetFile";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetItem";
import DtoDetailPriceQuotationSheet_TrackRecord from "./DtoDetailPriceQuotationSheet_TrackRecord";
import DtoDetailPriceQuotationSheetSourceSupplier from "./DtoDetailPriceQuotationSheetSourceSupplier";
import UiHistory from "./UiHistory";
import UiGroup from "./UiGroup";

/**
 * 报价表详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPriceQuotationSheet extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.FieldValues = [];
        this.Files = [];
        this.Items = [];
        this.TrackRecords = [];
        this.Suppliers = [];
        this.UiGroups = [{"Code":"BasicInfo","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"QuotationSheet","Label":"\u62A5\u4EF7\u8868","Toggle":true,"Close":false,"Buttons":[]},{"Code":"TrackRecord","Label":"\u8FFD\u8E2A\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]},{"Code":"CostRecord","Label":"\u6210\u672C\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]},{"Code":"History","Label":"\u5386\u53F2\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 状态
     */
    Status?: EnumPriceQuotationSheetStatus;
    /**
     * 报价人
     */
    CreatedCompanyUserId?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 价格有效期
     */
    ValidDateStart?: Date;
    /**
     * 有效期-截止
     */
    ValidDateEnd?: Date;
    /**
     * 客户企业Id
     */
    CompanyId?: string;
    /**
     * 询盘人
     */
    Company?: DtoDetailPriceQuotationSheet_Company;
    /**
     * 联系人Id
     */
    UserId?: string;
    /**
     * 联系人
     */
    User?: DtoDetailPriceQuotationSheet_JoinCompanyUser;
    /**
     * 服务内容
     */
    ServiceRemark?: string;
    /**
     * 要素
     */
    FieldValues?: DtoDetailPriceQuotationSheet_PriceQuotationSheetFieldValue[];
    /**
     * 货物数据
     */
    Weight?: number;
    /**
     * 毛重
     */
    Volume?: number;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 附件
     */
    Files?: DtoDetailPriceQuotationSheet_PriceQuotationSheetFile[];
    /**
     * 项
     */
    Items?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem[];
    /**
     * 追踪记录
     */
    TrackRecords?: DtoDetailPriceQuotationSheet_TrackRecord[];
    /**
     * 成本维护人Id
     */
    InfoCollectorId?: string;
    /**
     * 成本维护人
     */
    InfoCollector?: DtoDetailPriceQuotationSheet_JoinCompanyUser;
    /**
     * 供应商
     */
    Suppliers?: DtoDetailPriceQuotationSheetSourceSupplier[];
    /**
     * 历史记录
     */
    Histories?: UiHistory[];
}

/**
 * 报价表详细-表单校验
 */
export const DtoDetailPriceQuotationSheetRules = {
}

/**
 * 报价表详细-设置项
 */
export const DtoDetailPriceQuotationSheetMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "编号",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Status",
    Label: "状态",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumPriceQuotationSheetStatusOption,
  },
  {
    Prop: "CreatedCompanyUserId",
    Label: "报价人",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ValidDateStart",
    Label: "价格有效期",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "Company",
    Label: "询盘人",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ServiceRemark",
    Label: "服务内容",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FieldValues",
    Label: "要素",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "Weight",
    Label: "货物数据",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Files",
    Label: "附件",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "Items",
    Label: "项",
    Group: "QuotationSheet",
    Customize: true,
  },
  {
    Prop: "TrackRecords",
    Label: "追踪记录",
    Group: "TrackRecord",
    Customize: true,
  },
  {
    Prop: "InfoCollector",
    Label: "成本维护人",
    Group: "CostRecord",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Suppliers",
    Label: "供应商",
    Group: "CostRecord",
    Customize: true,
  },
  {
    Prop: "Histories",
    Label: "历史记录",
    Group: "History",
    Customize: true,
  },
]
