import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamAirlineCompany from "../models.machine/DtoSelectSearchParamAirlineCompany";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamAirlineCompany from "../models.machine/DtoPageListSearchParamAirlineCompany";
import DtoPageListParamAirlineCompany from "../models.machine/DtoPageListParamAirlineCompany";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamAirlineCompany from "../models.machine/DtoDetailParamAirlineCompany";
import DtoFormParamAirlineCompany from "../models.machine/DtoFormParamAirlineCompany";

/**
 * 航空公司
 * @description 自动生成的代码,请勿修改
 */
export default class ParamAirlineCompanyAppService {
    /**
     * 获取航空公司选择器
     * @param {DtoSelectSearchParamAirlineCompany} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamAirlineCompany): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamAirlineCompany/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取航空公司分页列表
     * @param {DtoPageListSearchParamAirlineCompany} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamAirlineCompany>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamAirlineCompany): AxiosPromise<RESTfulResult<DtoPageListParamAirlineCompany>> {
        const url = `/api/ParamAirlineCompany/Get`;
        return service.get<RESTfulResult<DtoPageListParamAirlineCompany>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamAirlineCompany/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取航空公司详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamAirlineCompany>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamAirlineCompany>> {
        const url = `/api/ParamAirlineCompany/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamAirlineCompany>>(url);
    }

    /**
     * 新增/编辑航空公司
     * @param {DtoFormParamAirlineCompany} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamAirlineCompany): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamAirlineCompany/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除航空公司
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamAirlineCompany/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 同步
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamAirlineCompany/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 导出
     * @param {string} [name] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportExceByName(name?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamAirlineCompany/GetExportExce/${name}`;
        return service.get<RESTfulResult<string>>(url);
    }

}
