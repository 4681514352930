
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompany, {
  DtoDetailCompanyMiezzDetailItems,
} from "@/models.machine/DtoDetailCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyDetail from "../../CompanyDetail.vue";
import { CompanySetting } from "../../CompanySetting";
@Options({
  components: {
    CompanyDetail,
  },
})
export default class CustomerServiceProviderCompanyDetail extends Vue {
  type = EnumCompanyType.Customer;
  detail = new MiezzForm<DtoDetailCompany, string>(
    "CustomerManagement_ServiceProvider_Detail"
  );
  buttons: MiezzButton[] = [];
  settings: CompanySetting[] = [];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/customer/company/service-provider/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Menus = [
      {
        Url: `/customer/company/service-provider/detail/${this.$route.params.id}?page=BasicInfo`,
        Label: "公司信息",
        Power: "CustomerManagement_ServiceProvider_Detail",
      },
    ];
    this.detail.Items = DtoDetailCompanyMiezzDetailItems.filter(
      (it) => it.Prop != "ServiceProviderCompanyId" && it.Prop != "Source"
    );
    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
    ];

    const logier = CurrentLogier.GetLogier();
    if (logier) {
      CurrentLogier.GetMenus().then((r) => {
        const powers = r?.Menus?.map((it) => it.Code) ?? [];
        if (powers.contains("CustomerManagement_ServiceProvider_Contact")) {
          this.settings.push({
            Name: "通讯录",
            Url: "/customer/company/service-provider/contact/${id}",
          });
        }
        if (powers.contains("CustomerManagement_ServiceProvider_Invoice")) {
          this.settings.push({
            Name: "开票资料",
            Url: "/customer/company/service-provider/invoice/${id}",
          });
        }
        if (
          powers.contains(
            "CustomerManagement_ServiceProvider_CreditAndAccountingPeriod"
          )
        ) {
          this.settings.push({
            Name: "授信与账期",
            Url: "/customer/company/service-provider/credit-and-accounting-period/${id}",
          });
        }
        if (this.settings.length > 0) {
          this.menuTab.Menus?.push({
            Url: `/customer/company/service-provider/detail/${this.$route.params.id}?page=Setting`,
            Label: "相关参数",
            Power: "CustomerManagement_ServiceProvider_Detail",
          });
        }
      });
    }
  }
}
