import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchFinance from "../models.machine/DtoPageListSearchFinance";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListFinance from "../models.machine/DtoPageListFinance";
import DtoDetailFinance from "../models.machine/DtoDetailFinance";
import DtoFormFinance from "../models.machine/DtoFormFinance";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoRequestFinance from "../models.machine/DtoRequestFinance";
import DtoCallbackSingleBill from "../models.machine/DtoCallbackSingleBill";
import DtoRequestInvoice from "../models.machine/DtoRequestInvoice";
import DtoCallbackCheckInvoice from "../models.machine/DtoCallbackCheckInvoice";
import DtoRequestSettlement from "../models.machine/DtoRequestSettlement";
import DtoRequestOneSettlement from "../models.machine/DtoRequestOneSettlement";
import DtoFormSettlement from "../models.machine/DtoFormSettlement";
import DtoRequestBatchSettlement from "../models.machine/DtoRequestBatchSettlement";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoCallbackBillSum from "../models.machine/DtoCallbackBillSum";
import DtoAssociationInvoice from "../models.machine/DtoAssociationInvoice";
import DtoCallbackStartDate from "../models.machine/DtoCallbackStartDate";
import DtoDetailInvoice from "../models.machine/DtoDetailInvoice";
import DtoListItemFinance from "../models.machine/DtoListItemFinance";
import DtoCallbackStat from "../models.machine/DtoCallbackStat";
import DtoRequestCount from "../models.machine/DtoRequestCount";
import DtoCallbackCount from "../models.machine/DtoCallbackCount";
import DtoCallbackComposition from "../models.machine/DtoCallbackComposition";
import DtoPageListFinanceStat from "../models.machine/DtoPageListFinanceStat";
import DtoCallbackSalerFormula from "../models.machine/DtoCallbackSalerFormula";
import DtoRequestCountPayable from "../models.machine/DtoRequestCountPayable";
import DtoPageListSearchSaler from "../models.machine/DtoPageListSearchSaler";
import DtoPageListSaler from "../models.machine/DtoPageListSaler";
import DtoRequestSalerSettlement from "../models.machine/DtoRequestSalerSettlement";
import DtoCallbackSalerSettlement from "../models.machine/DtoCallbackSalerSettlement";
import DtoRequestOneSalerSettlement from "../models.machine/DtoRequestOneSalerSettlement";
import DtoFormSalerSettlement from "../models.machine/DtoFormSalerSettlement";
import DtoCheckFormula from "../models.machine/DtoCheckFormula";
import DtoCallbackPartSettlement from "../models.machine/DtoCallbackPartSettlement";
import DtoPageListOverviewSaler from "../models.machine/DtoPageListOverviewSaler";
import DtoFormBillCheckStatus from "../models.machine/DtoFormBillCheckStatus";
import DtoFormAccountPeriod from "../models.machine/DtoFormAccountPeriod";
import DtoFormStartDate from "../models.machine/DtoFormStartDate";

/**
 * 财务管理
 * @description 自动生成的代码,请勿修改
 */
export default class FinanceAppService {
    /**
     * 财务管理-账单总表
     * @param {DtoPageListSearchFinance} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListFinance>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchFinance): AxiosPromise<RESTfulResult<DtoPageListFinance>> {
        const url = `/api/Finance/Get`;
        return service.get<RESTfulResult<DtoPageListFinance>>(url, { params: dto });
    }

    /**
     * 财务管理-获取账单详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailFinance>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailFinance>> {
        const url = `/api/Finance/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailFinance>>(url);
    }

    /**
     * 财务管理-添加账单
     * @param {DtoFormFinance} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormFinance): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Finance/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 财务管理-批量删除账单
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 财务管理-获取委托书
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetPools(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetPools`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 财务管理-批量编辑前保存需要编辑的账单
     * @param {DtoRequestFinance} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PreBatchEdit(dto?: DtoRequestFinance): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Finance/PreBatchEdit`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 财务管理-批量编辑
     * @param {DtoCallbackSingleBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static BatchEdit(dto?: DtoCallbackSingleBill): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/BatchEdit`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 财务管理-获取发票
     * @param {DtoRequestFinance} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoRequestInvoice>>} 
     */
    static GetInvoice(dto?: DtoRequestFinance): AxiosPromise<RESTfulResult<DtoRequestInvoice>> {
        const url = `/api/Finance/GetInvoice`;
        return service.post<RESTfulResult<DtoRequestInvoice>>(url, dto);
    }

    /**
     * 财务管理-检查发票号码是否有相同的
     * @param {DtoRequestFinance} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackCheckInvoice>>} 
     */
    static IsSame(dto?: DtoRequestFinance): AxiosPromise<RESTfulResult<DtoCallbackCheckInvoice>> {
        const url = `/api/Finance/IsSame`;
        return service.post<RESTfulResult<DtoCallbackCheckInvoice>>(url, dto);
    }

    /**
     * 财务管理-保存发票
     * @param {DtoRequestInvoice} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static SaveInvoice(dto?: DtoRequestInvoice): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Finance/SaveInvoice`;
        return service.post<RESTfulResult<string[]>>(url, dto);
    }

    /**
     * 
     * @param {DtoRequestInvoice} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static FillBillDatas(dto?: DtoRequestInvoice): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/FillBillDatas`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取核销数据
     * @param {string} [billId] 
     * @returns {AxiosPromise<RESTfulResult<DtoRequestSettlement>>} 
     */
    static GetSettlementByBillId(billId?: string): AxiosPromise<RESTfulResult<DtoRequestSettlement>> {
        const url = `/api/Finance/GetSettlement/${billId}`;
        return service.get<RESTfulResult<DtoRequestSettlement>>(url);
    }

    /**
     * 获取单个核销数据
     * @param {DtoRequestOneSettlement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoFormSettlement>>} 
     */
    static GetOneSettlement(dto?: DtoRequestOneSettlement): AxiosPromise<RESTfulResult<DtoFormSettlement>> {
        const url = `/api/Finance/GetOneSettlement`;
        return service.get<RESTfulResult<DtoFormSettlement>>(url, { params: dto });
    }

    /**
     * 保存核销
     * @param {DtoRequestSettlement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SaveSettlement(dto?: DtoRequestSettlement): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/SaveSettlement`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取批量核销数据
     * @param {DtoRequestFinance} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoRequestBatchSettlement>>} 
     */
    static GetBatchSettlement(dto?: DtoRequestFinance): AxiosPromise<RESTfulResult<DtoRequestBatchSettlement>> {
        const url = `/api/Finance/GetBatchSettlement`;
        return service.post<RESTfulResult<DtoRequestBatchSettlement>>(url, dto);
    }

    /**
     * 获取批量核销数据
     * @param {DtoRequestFinance} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoRequestBatchSettlement>>} 
     */
    static GetBatchSettlementForBad(dto?: DtoRequestFinance): AxiosPromise<RESTfulResult<DtoRequestBatchSettlement>> {
        const url = `/api/Finance/GetBatchSettlementForBad`;
        return service.post<RESTfulResult<DtoRequestBatchSettlement>>(url, dto);
    }

    /**
     * 保存批量核销
     * @param {DtoRequestBatchSettlement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SaveBatchSettlement(dto?: DtoRequestBatchSettlement): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/SaveBatchSettlement`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取装货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取目的港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取最终目的地选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetDeliveryPlaces(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Finance/GetDeliveryPlaces`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取客服选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCustomerServices(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetCustomerServices`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取合计
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackBillSum>>} 
     */
    static GetSum(): AxiosPromise<RESTfulResult<DtoCallbackBillSum>> {
        const url = `/api/Finance/GetSum`;
        return service.get<RESTfulResult<DtoCallbackBillSum>>(url);
    }

    /**
     * 获取对账对象
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetObject(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Finance/GetObject`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取账单制作人集合
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetMaker(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetMaker`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取订单的提单号码
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetBLNO(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Finance/GetBLNO`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取发票
     * @param {DtoAssociationInvoice} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetInvoices(dto?: DtoAssociationInvoice): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetInvoices`;
        return service.post<RESTfulResult<UiSelectOption<string>[]>>(url, dto);
    }

    /**
     * 关联发票
     * @param {DtoAssociationInvoice} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetInvoice(dto?: DtoAssociationInvoice): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/SetInvoice`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 删除发票
     * @param {DtoAssociationInvoice} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RemoveInvoice(dto?: DtoAssociationInvoice): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/RemoveInvoice`;
        return service.delete<RESTfulResult<any>>(url, { data: dto });
    }

    /**
     * 批量删除核销
     * @param {DtoAssociationInvoice} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RemoveSettlement(dto?: DtoAssociationInvoice): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/RemoveSettlement`;
        return service.delete<RESTfulResult<any>>(url, { data: dto });
    }

    /**
     * 获取账期
     * @param {DtoAssociationInvoice} [param] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackStartDate>>} 
     */
    static GetAccountPeriod(param?: DtoAssociationInvoice): AxiosPromise<RESTfulResult<DtoCallbackStartDate>> {
        const url = `/api/Finance/GetAccountPeriod`;
        return service.get<RESTfulResult<DtoCallbackStartDate>>(url, { params: param });
    }

    /**
     * 获取发票
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSalers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetSalers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 根据发票号码获取发票信息
     * @param {DtoDetailInvoice} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailInvoice>>} 
     */
    static GetOneInvoice(dto?: DtoDetailInvoice): AxiosPromise<RESTfulResult<DtoDetailInvoice>> {
        const url = `/api/Finance/GetOneInvoice`;
        return service.post<RESTfulResult<DtoDetailInvoice>>(url, dto);
    }

    /**
     * 导出账单
     * @param {DtoPageListSearchFinance} [param] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Export(param?: DtoPageListSearchFinance): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Finance/Export`;
        return service.post<RESTfulResult<string>>(url, param);
    }

    /**
     * 获取箱号
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetContainer(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Finance/GetContainer`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 统计
     * @param {DtoListItemFinance[]} [rows] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackStat>>} 
     */
    static Stat(rows?: DtoListItemFinance[]): AxiosPromise<RESTfulResult<DtoCallbackStat>> {
        const url = `/api/Finance/Stat`;
        return service.post<RESTfulResult<DtoCallbackStat>>(url, rows);
    }

    /**
     * 统计
     * @param {DtoRequestCount} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackCount>>} 
     */
    static Count(dto?: DtoRequestCount): AxiosPromise<RESTfulResult<DtoCallbackCount>> {
        const url = `/api/Finance/Count`;
        return service.post<RESTfulResult<DtoCallbackCount>>(url, dto);
    }

    /**
     * 各种组成
     * @param {DtoRequestCount} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackComposition>>} 
     */
    static Composition(dto?: DtoRequestCount): AxiosPromise<RESTfulResult<DtoCallbackComposition>> {
        const url = `/api/Finance/Composition`;
        return service.post<RESTfulResult<DtoCallbackComposition>>(url, dto);
    }

    /**
     * 获取客户企业
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCustomer(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetCustomer`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 财务管理-账单总表
     * @param {DtoPageListSearchFinance} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListFinanceStat>>} 分页列表
     */
    static GetCustomerComposition(dto?: DtoPageListSearchFinance): AxiosPromise<RESTfulResult<DtoPageListFinanceStat>> {
        const url = `/api/Finance/GetCustomerComposition`;
        return service.get<RESTfulResult<DtoPageListFinanceStat>>(url, { params: dto });
    }

    /**
     * 获取业务员
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSaler(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetSaler`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取业务员公式
     * @param {string} [SalerId] 业务员主键
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackSalerFormula>>} 
     */
    static GetSalerFormulaBySalerId(SalerId?: string): AxiosPromise<RESTfulResult<DtoCallbackSalerFormula>> {
        const url = `/api/Finance/GetSalerFormula/${SalerId}`;
        return service.get<RESTfulResult<DtoCallbackSalerFormula>>(url);
    }

    /**
     * 保存业务员公式
     * @param {DtoCallbackSalerFormula} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SaveSalerFormula(dto?: DtoCallbackSalerFormula): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/SaveSalerFormula`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取有公式的业务员
     * @param {string} [SalerId] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetFormulaSalersBySalerId(SalerId?: string): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetFormulaSalers/${SalerId}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 计算应发金额
     * @param {DtoRequestCountPayable} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoRequestCountPayable>>} 
     */
    static CountPayable(dto?: DtoRequestCountPayable): AxiosPromise<RESTfulResult<DtoRequestCountPayable>> {
        const url = `/api/Finance/CountPayable`;
        return service.post<RESTfulResult<DtoRequestCountPayable>>(url, dto);
    }

    /**
     * 获取业务员绩效
     * @param {DtoPageListSearchSaler} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListSaler>>} 
     */
    static GetSalerBill(dto?: DtoPageListSearchSaler): AxiosPromise<RESTfulResult<DtoPageListSaler>> {
        const url = `/api/Finance/GetSalerBill`;
        return service.get<RESTfulResult<DtoPageListSaler>>(url, { params: dto });
    }

    /**
     * 获取核销数据
     * @param {DtoRequestSalerSettlement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackSalerSettlement>>} 
     */
    static GetSalerSettlement(dto?: DtoRequestSalerSettlement): AxiosPromise<RESTfulResult<DtoCallbackSalerSettlement>> {
        const url = `/api/Finance/GetSalerSettlement`;
        return service.get<RESTfulResult<DtoCallbackSalerSettlement>>(url, { params: dto });
    }

    /**
     * 获取单个核销数据
     * @param {DtoRequestOneSalerSettlement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoFormSalerSettlement>>} 
     */
    static GetOneSalerSettlement(dto?: DtoRequestOneSalerSettlement): AxiosPromise<RESTfulResult<DtoFormSalerSettlement>> {
        const url = `/api/Finance/GetOneSalerSettlement`;
        return service.get<RESTfulResult<DtoFormSalerSettlement>>(url, { params: dto });
    }

    /**
     * 保存核销
     * @param {DtoCallbackSalerSettlement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SaveSalerSettlement(dto?: DtoCallbackSalerSettlement): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/SaveSalerSettlement`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 计算公式结果
     * @param {DtoCheckFormula} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<DtoCheckFormula>>} 结果
     */
    static CheckFormulaResult(dto?: DtoCheckFormula): AxiosPromise<RESTfulResult<DtoCheckFormula>> {
        const url = `/api/Finance/CheckFormulaResult`;
        return service.post<RESTfulResult<DtoCheckFormula>>(url, dto);
    }

    /**
     * 获取委托客服
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetPoolCustomers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Finance/GetPoolCustomers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 批量部分核销
     * @param {DtoCallbackPartSettlement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackPartSettlement>>} 
     */
    static PartSettlement(dto?: DtoCallbackPartSettlement): AxiosPromise<RESTfulResult<DtoCallbackPartSettlement>> {
        const url = `/api/Finance/PartSettlement`;
        return service.post<RESTfulResult<DtoCallbackPartSettlement>>(url, dto);
    }

    /**
     * 获取业务员绩效
     * @param {DtoPageListSearchSaler} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOverviewSaler>>} 
     */
    static GetOverviewSalerBill(dto?: DtoPageListSearchSaler): AxiosPromise<RESTfulResult<DtoPageListOverviewSaler>> {
        const url = `/api/Finance/GetOverviewSalerBill`;
        return service.get<RESTfulResult<DtoPageListOverviewSaler>>(url, { params: dto });
    }

    /**
     * 设置对账状态
     * @param {DtoFormBillCheckStatus} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CheckStatus(dto?: DtoFormBillCheckStatus): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/CheckStatus`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取对账状态
     * @param {DtoFormBillCheckStatus} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoFormBillCheckStatus>>} 
     */
    static GetCheckStatus(dto?: DtoFormBillCheckStatus): AxiosPromise<RESTfulResult<DtoFormBillCheckStatus>> {
        const url = `/api/Finance/GetCheckStatus`;
        return service.post<RESTfulResult<DtoFormBillCheckStatus>>(url, dto);
    }

    /**
     * 修改账期
     * @param {DtoFormAccountPeriod} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateAccountPeriod(dto?: DtoFormAccountPeriod): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/UpdateAccountPeriod`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 修改记账起始时间
     * @param {DtoFormStartDate} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateStartDate(dto?: DtoFormStartDate): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/UpdateStartDate`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 修改财务周期
     * @param {DtoFormStartDate} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateFinancialCycle(dto?: DtoFormStartDate): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Finance/UpdateFinancialCycle`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取服务内容
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetServiceRemarks(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Finance/GetServiceRemarks`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

}
