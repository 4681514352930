
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import PriceQuotationSheetAppService from "@/apis.machine/PriceQuotationSheetAppService";
import DtoDetailPriceQuotationSheet, {
  DtoDetailPriceQuotationSheetMiezzDetailItems,
} from "@/models.machine/DtoDetailPriceQuotationSheet";
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import { EnumTransportTypeOption } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoadOption } from "@/models.machine/EnumTransportContainerLoad";
import moment from "moment";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem, {
  DtoDetailPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems,
} from "@/models.machine/DtoDetailPriceQuotationSheet_PriceQuotationSheetItem";
import MiezzPageList from "@/models/MiezzPageList";
import UiHistory from "@/models.machine/UiHistory";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";

export default class PriceInquiryDetail extends Vue {
  id?: string;
  logier?: Logier;

  menuTab = new MiezzMenuTab();
  detail = new MiezzForm<DtoDetailPriceQuotationSheet, string>();
  list = new MiezzPageList<
    any,
    DtoDetailPriceQuotationSheet_PriceQuotationSheetItem,
    string
  >();
  totals: { CurrencySystem: string; Amount: number }[] = [];
  histories: UiHistory[] = [];

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    this.id = this.$route.params.id as string;
    this.menuTab.Back = true;
    this.menuTab.Url = `/price/inquiry/${this.id}`;
    this.menuTab.Menus = [
      {
        Label: "我的询盘",
        Url: `/price/inquiry/${this.id}`,
        Power: "PriceManagement_Inquiry",
      },
    ];

    //详细-数据-初始化
    const data = new DtoDetailPriceQuotationSheet();
    //详细-元素配置
    data.UiGroups = new DtoDetailPriceQuotationSheet().UiGroups?.filter(
      (it) => it.Code != "TrackRecord" && it.Code != "CostRecord"
    );
    this.detail.Items = DtoDetailPriceQuotationSheetMiezzDetailItems.filter(
      (it) =>
        it.Prop != "TrackRecords" &&
        it.Prop != "InfoCollector" &&
        it.Prop != "Suppliers" &&
        it.Prop != "Status"
    );
    this.detail.Data = data;
    for (const item of this.detail.Items) {
      switch (item.Prop) {
        case "CreatedCompanyUserId":
          item.HandleFormat = () => {
            return this.detail.Data.CreatedCompanyUserId
              ? `${this.detail.Data.CreatedUserName}/${
                  this.detail.Data.CreatedPhoneNumber ?? "暂无数据"
                }`
              : "暂无数据";
          };
          break;
        case "Company":
          item.HandleFormat = () => {
            return this.detail.Data.Company
              ? `${this.detail.Data.User?.Name ?? "暂无数据"}/${
                  this.detail.Data.User?.User?.PhoneNumber ?? "暂无数据"
                }`
              : "暂无数据";
          };
          break;
        case "TransportDirection":
          item.HandleFormat = () => {
            var labels: (string | undefined)[] = [
              EnumTransportDirectionOption.firstOrDefault(
                (it) => it.Value == this.detail.Data.TransportDirection
              )?.Label,
              EnumTransportTypeOption.firstOrDefault(
                (it) => it.Value == this.detail.Data.TransportType
              )?.Label,
              EnumTransportContainerLoadOption.firstOrDefault(
                (it) => it.Value == this.detail.Data.TransportContainerLoad
              )?.Label,
            ];
            labels = labels.filter((it) => it).map((it) => `【${it}】`);
            return labels.length > 0 ? labels.join("") : "暂无数据";
          };
          break;
        case "Fields":
          item.HandleFormat = () => {
            return this.detail.Data.FieldValues?.map(
              (it) => `【${it.Field?.Name}：${it.Name}】`
            ).join("");
          };
          break;
        case "ValidDateStart":
          item.HandleFormat = () => {
            return this.detail.Data.ValidDateStart &&
              this.detail.Data.ValidDateEnd
              ? `${
                  moment(this.detail.Data.ValidDateStart)?.format(
                    "YYYY/MM/DD"
                  ) ?? "暂无数据"
                } - ${
                  moment(this.detail.Data.ValidDateEnd)?.format("YYYY/MM/DD") ??
                  "暂无数据"
                }`
              : "暂无数据";
          };
          break;
        case "Weight":
          item.HandleFormat = () => {
            return (
              (this.detail.Data.Weight != undefined
                ? `${this.detail.Data.Weight}KGS`
                : "") +
              (this.detail.Data.Weight != undefined &&
              this.detail.Data.Volume != undefined
                ? "/"
                : "") +
              (this.detail.Data.Volume != undefined
                ? `${this.detail.Data.Volume}CBM`
                : "") +
              (this.detail.Data.Volume != undefined &&
              this.detail.Data.PackagesNumber != undefined
                ? "/"
                : "") +
              (this.detail.Data.PackagesNumber != undefined
                ? `${this.detail.Data.PackagesNumber}${this.detail.Data.PackagesKind}`
                : "")
            );
          };
          break;
        default:
          break;
      }
    }

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Items =
      DtoDetailPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems;
    this.list.Data = {
      Items: [],
    };
    this.list.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "Cost":
          return row.Cost?.FullName ?? "暂无数据";
        case "CurrencySystem":
          return row.CurrencySystem?.Code ?? "暂无数据";
        case "FirstChargingUnit":
          return `${row.FirstChargingUnit?.FullName ?? ""}`;
        case "SecondChargingUnit":
          return `${row.SecondChargingNumber ?? ""} ${
            row.SecondChargingUnit?.FullName ?? ""
          }`;
        default:
          break;
      }
    };

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      PriceQuotationSheetAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          const data = r.data.Data;
          data.UiGroups = new DtoDetailPriceQuotationSheet().UiGroups?.filter(
            (it) => it.Code != "TrackRecord" && it.Code != "CostRecord"
          );
          this.detail.Data = data;
          if (this.list.Data) this.list.Data.Items = r.data.Data.Items;
          this.handleCalc();

          if (r.data.Extras?.Message) {
            ElMessage.warning(r.data.Extras.Message);
          }
        }
      });
      PriceQuotationSheetAppService.GetHistoiesById(this.id).then((r) => {
        this.histories = r.data.Data ?? [];
      });
    }
  }

  handleCalc(): void {
    this.totals = [];
    for (const item of this.list.Data?.Items ?? []) {
      item.Total = undefined;
      if (
        item.Amount != undefined &&
        (item.FirstChargingNumber != undefined ||
          item.SecondChargingNumber != undefined)
      ) {
        if (
          item.FirstChargingNumber != undefined &&
          item.SecondChargingNumber != undefined
        ) {
          item.Total =
            item.Amount * item.FirstChargingNumber * item.SecondChargingNumber;
        } else if (item.FirstChargingNumber != undefined) {
          item.Total = item.Amount * item.FirstChargingNumber;
        } else if (item.SecondChargingNumber != undefined) {
          item.Total = item.Amount * item.SecondChargingNumber;
        }
      }
      if (item.Total && item.CurrencySystem?.Code) {
        const code = item.CurrencySystem.Code;
        let total = this.totals.firstOrDefault(
          (it) => it.CurrencySystem == code
        );
        if (total) {
          total.Amount += item.Total;
        } else {
          total = {
            CurrencySystem: code,
            Amount: item.Total,
          };
          this.totals.push(total);
        }
      }
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        CurrentLogier.LinkWithBack(`/price/quotation-sheet/edit/${this.id}`);
        complete();
        break;
      case "delete":
        PriceQuotationSheetAppService.Delete([this.id as string])
          .then(() => {
            CurrentLogier.Back();
            complete();
          })
          .catch(complete);
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
