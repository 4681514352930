import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import DtoDetailCompany_ParamCompanyCodeType from "./DtoDetailCompany_ParamCompanyCodeType";
import DtoDetailCompany_ParamCountry from "./DtoDetailCompany_ParamCountry";
import DtoDetailCompany_ParamTaxType from "./DtoDetailCompany_ParamTaxType";
import DtoDetailCompany_CompanyInvoiceTaxRate from "./DtoDetailCompany_CompanyInvoiceTaxRate";
import DtoDetailCompany_JoinCompanyType from "./DtoDetailCompany_JoinCompanyType";
import { EnumCompanyStatus, EnumCompanyStatusOption } from "./EnumCompanyStatus";
import { EnumAuditStatus, EnumAuditStatusOption } from "./EnumAuditStatus";
import DtoFormCompany_CompanyFile from "./DtoFormCompany_CompanyFile";
import UiGroup from "./UiGroup";

/**
 * 公司详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompany extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.CompanyInvoiceTaxRates = [];
        this.JoinCompanyTypes = [];
        this.AuthStatus = EnumAuditStatus.No;
        this.CompanyFiles = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCompanyType;
    /**
     * 公司名称Id
     */
    CompanyNameId?: string;
    /**
     * 创建公司Id
     */
    CreatedCompanyId?: string;
    /**
     * 服务商
     */
    ServiceProviderCompanyId?: string;
    /**
     * 所属服务商中文名称
     */
    ServiceProviderCompanyNameZh?: string;
    /**
     * 所属服务商英文名称
     */
    ServiceProviderCompanyNameEn?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 公司英文缩写
     */
    NameEnAbbr?: string;
    /**
     * 中文注册地址
     */
    RegisteredAddressZh?: string;
    /**
     * 英文注册地址
     */
    RegisteredAddressEn?: string;
    /**
     * 中文收件地址
     */
    ReceivingAddressZh?: string;
    /**
     * 中文收件地址-邮编
     */
    ReceivingAddressZhZipCode?: string;
    /**
     * 英文收件地址
     */
    ReceivingAddressEn?: string;
    /**
     * 企业代码类型Id
     */
    ParamCompanyCodeTypeId?: string;
    /**
     * 企业代码类型
     */
    ParamCompanyCodeType?: DtoDetailCompany_ParamCompanyCodeType;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 注册地所属国家
     */
    ParamCountryId?: string;
    /**
     * 注册地所属国家
     */
    ParamCountry?: DtoDetailCompany_ParamCountry;
    /**
     * 所属国海关编码
     */
    CustomsCode?: string;
    /**
     * 纳税人类型Id
     */
    ParamTaxTypeId?: string;
    /**
     * 纳税人类型
     */
    ParamTaxType?: DtoDetailCompany_ParamTaxType;
    /**
     * 开票税率
     */
    CompanyInvoiceTaxRates?: DtoDetailCompany_CompanyInvoiceTaxRate[];
    /**
     * 电话
     */
    Tel?: string;
    /**
     * 传真
     */
    Fax?: string;
    /**
     * 邮箱
     */
    Email?: string;
    /**
     * 网址
     */
    Url?: string;
    /**
     * 公司类型
     */
    JoinCompanyTypes?: DtoDetailCompany_JoinCompanyType[];
    /**
     * 公司状态
     */
    Status?: EnumCompanyStatus;
    /**
     * 业务员Id
     */
    SalesmanId?: string;
    /**
     * 业务员
     */
    SalesmanLabel?: string;
    /**
     * 客户来源
     */
    Source?: string;
    /**
     * 认证状态
     */
    AuthStatus?: EnumAuditStatus;
    /**
     * 营业执照
     */
    BusinessLicense?: string;
    /**
     * logo
     */
    Logo?: string;
    /**
     * 附件
     */
    CompanyFiles?: DtoFormCompany_CompanyFile[];
}

/**
 * 公司详细-表单校验
 */
export const DtoDetailCompanyRules = {
}

/**
 * 公司详细-设置项
 */
export const DtoDetailCompanyMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ServiceProviderCompanyId",
    Label: "服务商",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "RegisteredAddressZh",
    Label: "中文注册地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "RegisteredAddressEn",
    Label: "英文注册地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ReceivingAddressZh",
    Label: "中文收件地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ReceivingAddressEn",
    Label: "英文收件地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ParamCompanyCodeType",
    Label: "企业代码类型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "企业代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ParamCountry",
    Label: "注册地所属国家",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CustomsCode",
    Label: "所属国海关编码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ParamTaxType",
    Label: "纳税人类型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CompanyInvoiceTaxRates",
    Label: "开票税率",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Tel",
    Label: "电话",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Fax",
    Label: "传真",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Email",
    Label: "邮箱",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Url",
    Label: "网址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "JoinCompanyTypes",
    Label: "公司类型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Status",
    Label: "公司状态",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyStatusOption,
  },
  {
    Prop: "SalesmanLabel",
    Label: "业务员",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Source",
    Label: "客户来源",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "BusinessLicense",
    Label: "营业执照",
    Customize: true,
  },
  {
    Prop: "Logo",
    Label: "logo",
    Customize: true,
  },
  {
    Prop: "CompanyFiles",
    Label: "附件",
    Customize: true,
  },
]
