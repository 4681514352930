import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoRequestPriceQuotationSheetFieldValue from "../models.machine/DtoRequestPriceQuotationSheetFieldValue";
import DtoPageListSearchPriceQuotationSheet from "../models.machine/DtoPageListSearchPriceQuotationSheet";
import DtoPageListPriceQuotationSheet from "../models.machine/DtoPageListPriceQuotationSheet";
import DtoDetailPriceQuotationSheet from "../models.machine/DtoDetailPriceQuotationSheet";
import DtoFormPriceQuotationSheet from "../models.machine/DtoFormPriceQuotationSheet";
import UiHistory from "../models.machine/UiHistory";
import DtoRequestPriceQuotationSheetChangeStage from "../models.machine/DtoRequestPriceQuotationSheetChangeStage";

/**
 * 报价表
 * @description 自动生成的代码,请勿修改
 */
export default class PriceQuotationSheetAppService {
    /**
     * 获取服务类型下拉框数据
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 服务类型下拉框数据
     */
    static GetServices(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PriceQuotationSheet/GetServices`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取要素下拉框数据
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 要素下拉框数据
     */
    static GetFields(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/PriceQuotationSheet/GetFields`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 查询要素值下拉框数据
     * @param {DtoRequestPriceQuotationSheetFieldValue} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 要素值下拉框数据
     */
    static GetFieldValues(dto?: DtoRequestPriceQuotationSheetFieldValue): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/PriceQuotationSheet/GetFieldValues`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取报价表分页列表
     * @param {DtoPageListSearchPriceQuotationSheet} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPriceQuotationSheet>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPriceQuotationSheet): AxiosPromise<RESTfulResult<DtoPageListPriceQuotationSheet>> {
        const url = `/api/PriceQuotationSheet/Get`;
        return service.get<RESTfulResult<DtoPageListPriceQuotationSheet>>(url, { params: dto });
    }

    /**
     * 获取报价表详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPriceQuotationSheet>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPriceQuotationSheet>> {
        const url = `/api/PriceQuotationSheet/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPriceQuotationSheet>>(url);
    }

    /**
     * 添加报价表
     * @param {DtoFormPriceQuotationSheet} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPriceQuotationSheet): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceQuotationSheet/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 复制
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CopyById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceQuotationSheet/Copy/${id}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 批量删除报价表
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceQuotationSheet/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 查询历史记录
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<UiHistory[]>>} 历史记录
     */
    static GetHistoiesById(id?: string): AxiosPromise<RESTfulResult<UiHistory[]>> {
        const url = `/api/PriceQuotationSheet/GetHistoies/${id}`;
        return service.get<RESTfulResult<UiHistory[]>>(url);
    }

    /**
     * 导出
     * @param {string} [code] 站点代码
     * @param {string} [order] 
     * @param {string} [no] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ExportByCodeByOrderByNo(code?: string, order?: string, no?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceQuotationSheet/Export/${code}/${order}/${no}`;
        return service.get<RESTfulResult<any>>(url);
    }

    /**
     * 导出压缩包
     * @param {string[]} [ids] 报价表Id
     * @returns {AxiosPromise<RESTfulResult<string>>} 地址
     */
    static ExportZip(ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceQuotationSheet/ExportZip`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 改变阶段
     * @param {DtoRequestPriceQuotationSheetChangeStage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ChangeStage(dto?: DtoRequestPriceQuotationSheetChangeStage): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceQuotationSheet/ChangeStage`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 发送
     * @param {string[]} [ids] 报价表Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SendAsync(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceQuotationSheet/Send`;
        return service.post<RESTfulResult<any>>(url, ids);
    }

}
