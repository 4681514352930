import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumGender, EnumGenderOption } from "./EnumGender";
import DtoDetailCompanyContact_ContactNumber from "./DtoDetailCompanyContact_ContactNumber";
import UiGroup from "./UiGroup";

/**
 * 通讯录详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyContact extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Numbers = [];
        this.UiGroups = [{"Code":"BaseInfo","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"ContactNumber","Label":"\u8054\u7CFB\u65B9\u5F0F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"Remark","Label":"\u5907\u6CE8","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 姓名
     */
    Name?: string;
    /**
     * 性别
     */
    Gender?: EnumGender;
    /**
     * 生日
     */
    Birthday?: Date;
    /**
     * 籍贯
     */
    NativePlace?: string;
    /**
     * 部门
     */
    Department?: string;
    /**
     * 职务
     */
    Job?: string;
    /**
     * 头像
     */
    Avatar?: string;
    /**
     * 
     */
    Numbers?: DtoDetailCompanyContact_ContactNumber[];
    /**
     * 
     */
    Remark?: string;
}

/**
 * 通讯录详细-表单校验
 */
export const DtoDetailCompanyContactRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
}

/**
 * 通讯录详细-设置项
 */
export const DtoDetailCompanyContactMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "姓名",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Gender",
    Label: "性别",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumGenderOption,
  },
  {
    Prop: "Birthday",
    Label: "生日",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "NativePlace",
    Label: "籍贯",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Department",
    Label: "部门",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Job",
    Label: "职务",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Avatar",
    Label: "头像",
    Group: "BaseInfo",
    Customize: true,
  },
  {
    Prop: "Numbers",
    Label: "",
    Group: "ContactNumber",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "",
    Group: "Remark",
    Span: 24,
    Type: EnumMiezzFormItemType.Text,
  },
]
