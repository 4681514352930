
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import DtoDetailCompany from "@/models.machine/DtoDetailCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { CompanySetting } from "./CompanySetting";
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";

export default class CompanyDetail extends Vue {
  @Prop() menuTab?: MiezzMenuTab;
  @Prop() type?: EnumCompanyType;
  @Prop() detail!: MiezzForm<DtoDetailCompany, string>;
  @Prop() buttons: MiezzButton[] = [];
  @Prop() onGet?: (model: DtoDetailCompany) => void;
  @Prop() settings: CompanySetting[] = [];
  @Prop() nameEnAbbr?: boolean;

  id?: string;
  tab = "BasicInfo";

  /**创建时 */
  created(): void {
    this.tab = this.$route.query.page as string;
    const logier = CurrentLogier.GetLogier();
    this.id = this.$route.params.id as string;
    if (!this.id) this.id = logier?.CompanyId;
    this.detail.DefaultSpan = 12;
    this.detail.Data = new DtoDetailCompany();

    this.$nextTick(() => {
      for (const item of this.detail.Items ?? []) {
        switch (item.Prop) {
          case "NameEn":
            item.HandleFormat = () => {
              return this.nameEnAbbr
                ? `${this.detail.Data.NameEn ?? "暂无数据"}/${
                    this.detail.Data.NameEnAbbr ?? "暂无数据"
                  }`
                : this.detail.Data.NameEn ?? "暂无数据";
            };
            break;
          case "ServiceProviderCompanyId":
            item.HandleFormat = () =>
              this.detail.Data.ServiceProviderCompanyId
                ? `${
                    this.detail.Data.ServiceProviderCompanyNameZh ?? "暂无数据"
                  }/${
                    this.detail.Data.ServiceProviderCompanyNameEn ?? "暂无数据"
                  }`
                : "暂无数据";
            break;
          case "ParamCompanyCodeType":
            item.HandleFormat = () => {
              const data = this.detail.Data.ParamCompanyCodeType;
              return data?.ParamCountry
                ? `${data?.ParamCountry?.NameZh ?? "暂无数据"}/${
                    data?.ParamCountry?.NameEn ?? "暂无数据"
                  }/${data?.CountryCode ?? "暂无数据"}/${
                    data?.CompanyCode ?? "暂无数据"
                  }`
                : undefined;
            };
            break;
          case "ParamCountry":
            item.HandleFormat = () => {
              const data = this.detail.Data.ParamCountry;
              return data
                ? `${data?.NameZh ?? "暂无数据"}/${data?.NameEn ?? "暂无数据"}`
                : undefined;
            };
            break;
          case "ParamTaxType":
            item.HandleFormat = () => {
              const data = this.detail.Data.ParamTaxType;
              return data ? data.FullName ?? "暂无数据" : undefined;
            };
            break;
          case "CompanyInvoiceTaxRates":
            item.HandleFormat = () => {
              return this.detail.Data.CompanyInvoiceTaxRates?.map(
                (it) => `【${it.ParamInvoiceTaxRate?.FullName ?? "暂无数据"}】`
              ).join("");
            };
            break;
          case "JoinCompanyTypes":
            item.HandleFormat = () => {
              return this.detail.Data.JoinCompanyTypes?.map(
                (it) => `【${it.CompanyType?.Name}】`
              ).join("");
            };
            break;
          default:
            break;
        }
      }
    });

    CompanyAppService.Get(this.id).then((r) => {
      if (r.data.Data) {
        this.detail.Data = r.data.Data;
        if (this.onGet) {
          this.onGet(this.detail.Data);
        }
      }
    });
  }

  handleLinkAfter(route: any): void {
    this.tab = route.query.page as string;
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    const listUrl = this.$route.path.substring(
      0,
      this.$route.path.indexOf("/detail")
    );
    switch (btn.Code) {
      case "edit":
        this.$router.push(`${listUrl}/edit/${this.id}`);
        complete();
        break;
      case "delete":
        ElMessageBox.confirm("是否确认将该信息删除？", "提醒", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            CompanyAppService.Delete([this.detail.Data.Id as string])
              .then(() => {
                this.$router.push(listUrl);
                complete();
              })
              .catch(complete);
          })
          .catch(() => {
            complete();
          });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }

  @Emit()
  handleRowDbClick(row: any): void {
    if (row.Url) {
      const url = row.Url.replace("${id}", this.id ?? "");
      this.$router.push(url);
    }
  }

  handleSettingClick(
    btn: MiezzButton,
    row: CompanySetting,
    complete: () => void
  ): void {
    if (btn.Code == "link" && row.Url) {
      const url = row.Url.replace("${id}", this.id ?? "");
      this.$router.push(url);
    }
    complete();
  }
}
