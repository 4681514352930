import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1e1fc7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right" }
const _hoisted_2 = {
  ref: "CountTotalNumber",
  class: "count"
}
const _hoisted_3 = {
  ref: "CountCustomerNumberPie",
  class: "count"
}
const _hoisted_4 = {
  ref: "CountCustomerNumberBar",
  class: "count"
}
const _hoisted_5 = {
  ref: "CountSupplierNumberPie",
  class: "count"
}
const _hoisted_6 = {
  ref: "CountSupplierNumberBar",
  class: "count"
}
const _hoisted_7 = {
  key: 0,
  ref: "CountCustomNumber",
  class: "count"
}
const _hoisted_8 = {
  key: 1,
  ref: "CountCustomGoodsWeightBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_9 = {
  key: 2,
  ref: "CountCustomGoodsVolumeBar",
  class: "count",
  style: {"width":"50%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_miezz_month_choose = _resolveComponent("miezz-month-choose")!
  const _component_miezz_card = _resolveComponent("miezz-card")!
  const _component_miezz_dynamic_search = _resolveComponent("miezz-dynamic-search")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, { class: "left" }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_select, {
          modelValue: _ctx.serviceType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.serviceType) = $event)),
          options: _ctx.EnumOrderServiceTypeOption,
          clearable: "",
          onHandleChange: _ctx.handleChooseServiceType,
          style: {"margin-bottom":"10px"}
        }, null, 8, ["modelValue", "options", "onHandleChange"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (item, i) => {
          return (_openBlock(), _createBlock(_component_el_alert, {
            key: i,
            title: item.title,
            type: item.type,
            effect: "dark",
            closable: false,
            center: item.type == 'success',
            class: _normalizeClass(item.code == _ctx.type ? 'active' : ''),
            onClick: ($event: any) => (_ctx.handleChooseType(item.code))
          }, null, 8, ["title", "type", "center", "class", "onClick"]))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_miezz_month_choose, {
        modelValue: _ctx.month,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.month) = $event))
      }, null, 8, ["modelValue"]),
      (_ctx.type == 'CountTotalNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_2, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomerNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_3, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomerNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 2 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.companyId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.companyId) = $event)),
                  options: _ctx.companies,
                  className: "choose",
                  onHandleChange: _ctx.handleChart
                }, null, 8, ["modelValue", "options", "onHandleChange"])
              ]),
              _createElementVNode("div", _hoisted_4, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountSupplierNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 3 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_5, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountSupplierNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 4 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.supplierId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.supplierId) = $event)),
                  options: _ctx.suppliers,
                  className: "choose",
                  onHandleChange: _ctx.handleChart
                }, null, 8, ["modelValue", "options", "onHandleChange"])
              ]),
              _createElementVNode("div", _hoisted_6, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_dynamic_search, {
                ref: "miezz-dynamic-search",
                modelValue: _ctx.dto.DynamicSearchId,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dto.DynamicSearchId) = $event)),
                items: _ctx.searchs,
                simple: false,
                fixProp: false,
                hideCompare: false,
                histories: _ctx.histories,
                code: "Count_Pool",
                onHandleSearch: _ctx.handleChart,
                onHandleGetHistories: _ctx.handleGetHistories
              }, {
                "dynamic-search-customize": _withCtx(({ model }) => [
                  (model.Where.Prop == 'DynamicSearch_ClientCompanyId')
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 0,
                        modelValue: model.Where.Value,
                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                        options: _ctx.companies,
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : _createCommentVNode("", true),
                  (model.Where.Prop == 'DynamicSearch_SupplierCompanyId')
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 1,
                        modelValue: model.Where.Value,
                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                        options: _ctx.suppliers,
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : (model.Where.Prop == 'DynamicSearch_Area')
                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                          key: 2,
                          modelValue: model.Where.Value,
                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                          options: _ctx.areas,
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                      : _createCommentVNode("", true),
                  (model.Where.Prop == 'DynamicSearch_LoadingPortId')
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 3,
                        modelValue: model.Where.Value,
                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                        options: _ctx.loadingPorts,
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : (model.Where.Prop == 'DynamicSearch_DeliveryPortId')
                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                          key: 4,
                          modelValue: model.Where.Value,
                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                          options: _ctx.deliveryPorts,
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                      : (model.Where.Prop == 'DynamicSearch_TransportDirection')
                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                            key: 5,
                            modelValue: model.Where.Value,
                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                            options: _ctx.EnumTransportDirectionOption,
                            number: true,
                            clearable: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                        : (
                model.Where.Prop == 'DynamicSearch_TransportContainerLoad'
              )
                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                              key: 6,
                              modelValue: model.Where.Value,
                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                              options: _ctx.EnumTransportContainerLoadOption,
                              number: true,
                              clearable: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                          : (
                model.Where.Prop == 'DynamicSearch_DeparturePortDrawback'
              )
                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                key: 7,
                                modelValue: model.Where.Value,
                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                options: _ctx.yesno,
                                clearable: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                            : (
                model.Where.Prop ==
                'DynamicSearch_DestinationPortCustomsClearanceHeaderProvider'
              )
                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                  key: 8,
                                  modelValue: model.Where.Value,
                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                  options: _ctx.EnumCustomsClearanceHeaderProviderOption,
                                  clearable: ""
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                              : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "items", "histories", "onHandleSearch", "onHandleGetHistories"]),
              (_ctx.type == 'CountCustomNumber')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, null, 512))
                : _createCommentVNode("", true),
              (_ctx.type == 'CountCustomGoods')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, null, 512))
                : _createCommentVNode("", true),
              (_ctx.type == 'CountCustomGoods')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}