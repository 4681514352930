
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoFormCompany, {
  DtoFormCompanyMiezzFormItems,
} from "@/models.machine/DtoFormCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyForm from "../CompanyForm.vue";
@Options({
  components: {
    CompanyForm,
  },
})
export default class CustomerCompanyEdit extends Vue {
  type = EnumCompanyType.Customer;
  form = new MiezzForm<DtoFormCompany, string>(
    "CustomerManagement_Company_Edit"
  );
  companyTypes?: UiSelectOption<string>[] = [];

  created(): void {
    CurrentLogier.GetMenus().then((r) => {
      const logier = CurrentLogier.GetLogier();
      let items = DtoFormCompanyMiezzFormItems.copy() as MiezzFormItem[];
      if (logier?.CompanyType == EnumCompanyType.ServiceProvider) {
        items = items.filter((it) => it.Prop != "ServiceProviderCompanyId");
      }
      if (
        r?.Menus?.all(
          (it) => it.Code != "CustomerManagement_Company_UpdateStatus"
        )
      ) {
        items = items.filter((it) => it.Prop != "Status");
      }
      for (const item of items) {
        switch (item.Prop) {
          case "JoinCompanyTypes":
            item.Disabled = true;
            break;
          case "ServiceProviderCompanyId":
            item.Disabled = true;
            item.Label = "关联服务商";
            break;
          default:
            break;
        }
      }
      this.form.Items = items;
    });

    CompanyTypeAppService.GetSelect({ type: this.type }).then((r) => {
      this.companyTypes = r.data.Data;
    });
  }
}
