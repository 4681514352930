
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import PriceAppService from "@/apis.machine/PriceAppService";
import DtoListItemPriceCalc, {
  DtoListItemPriceCalcMiezzListItems,
} from "@/models.machine/DtoListItemPriceCalc";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import PriceQuotationSheetAppService from "@/apis.machine/PriceQuotationSheetAppService";
import DtoFormPriceQuotationSheet from "@/models.machine/DtoFormPriceQuotationSheet";
import MiezzListItem from "@/models/MiezzListItem";
import DtoRequestPriceCalc from "@/models.machine/DtoRequestPriceCalc";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import DtoFormPriceQuotationSheetSource from "@/models.machine/DtoFormPriceQuotationSheetSource";
export default class PriceCalc extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() serviceId?: string;
  @Prop() companyId?: string;
  @Prop() salesmanId?: string;
  @Prop() amountType?: EnumPriceAmountType;
  @Prop() ids?: string[] = [];
  @Prop() save?: boolean;

  list = new MiezzPageList<any, DtoListItemPriceCalc, string>();
  ItemReset: MiezzListItem[] = [];

  totals: { CurrencySystem: string; Amount: number }[] = [];
  units: UiSelectOption<string>[] = [];
  currencySystems: UiSelectOption<string>[] = [];
  loading = true;

  modalSupplier = new MiezzModal(MiezzModalType.Dialog);
  modalDataSupplier: {
    Data: UiSelectOption<string>[];
  } = {
    Data: [],
  };
  btnDeleteSupplier: MiezzButton = {
    Code: "delete-supplier",
    Title: "删除",
    Icon: "delete",
    Type: "text",
    Size: "small",
  };
  btnAddSupplier: MiezzButton = {
    Code: "add-supplier",
    Label: "添加",
    Icon: "plus",
    Type: "text",
  };
  rules: any = {};
  suppliers: DtoSelectOptionCompany[] = [];

  created(): void {
    this.modelValue.Title = "计算器";
    this.modelValue.Width = "90%";
    this.modelValue.Height = window.innerHeight * 0.8 + "px";
    this.modelValue.AlignCenter = true;
    this.modelValue.Buttons = [
      {
        Code: "save",
        Label: "保存为价格表",
        Type: "primary",
        Size: "small",
        Hide: !this.save,
        Width: "100px",
        Options: [
          {
            Code: "save-quotation",
            Label: "保存为报价",
          },
          {
            Code: "save-cost",
            Label: "保存为成本",
          },
        ],
      },
      {
        Code: "reset",
        Label: "重置",
        Type: "primary",
        Size: "small",
        Width: "100px",
      },
      {
        Code: "clear-close",
        Label: "清除并关闭",
        Type: "primary",
        Size: "small",
        Width: "100px",
      },
    ];
    this.modelValue.HandleClick = this.handleClick;

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.DisabledCreatedHandleGet = true;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.DisabledRouter = true;
    this.list.EnabledPagination = false;
    this.list.Items = DtoListItemPriceCalcMiezzListItems;
    for (const item of this.list.Items) {
      switch (item.Prop) {
        case "Description":
          item.DisabledTooltip = true;
          break;

        default:
          break;
      }
    }
    this.list.Dto = new DtoRequestPriceCalc();
    this.list.Data = {
      Items: [],
    };
    this.list.RowButtons = [
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
      },
    ];

    this.handleGetListData();

    this.modalSupplier.Title = "添加供应商";
    this.modalSupplier.Width = "700px";
    this.modalSupplier.Height = "300px";
    this.modalSupplier.HandleClick = this.handleClick;
    this.modalSupplier.Buttons = [
      {
        Code: "add-supplier-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.rules.SupplierName = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            return callback(new Error("信息不能为空"));
          } else if (
            (this.modalDataSupplier.Data.map((d) => d.Value).filter(
              (it) => it == value
            ).length ?? 0) > 1
          ) {
            callback(new Error("信息重复，请重新填写"));
          }
          callback();
        },
        required: true,
        trigger: "blur",
      },
    ];
    CompanyAppService.GetSelect({ CompanyType: EnumCompanyType.Supplier }).then(
      (r) => {
        this.suppliers = r.data.Data ?? [];
      }
    );
  }

  querySearchSuppliers(queryString: string, cb: any): void {
    if (queryString) {
      cb(
        this.suppliers.filter(
          (it) =>
            (this.modalDataSupplier.Data.all((d) => d.Value != it.Name) ||
              it.Name == queryString) &&
            it.Name?.contains(queryString)
        )
      );
    } else {
      cb(
        this.suppliers.filter(
          (it) =>
            this.modalDataSupplier.Data.all((d) => d.Value != it.Name) ||
            it.Name == queryString
        )
      );
    }
  }

  handleDeleteSupplier(index: number, complete: () => void): void {
    this.modalDataSupplier.Data?.splice(index, 1);
    complete();
  }

  handleGetListData(): void {
    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.currencySystems = (r.data.Data ?? []).map((it) => {
        it.Label = it.Data.Code;
        return it;
      });
    });
    ParamTransportChargeUnitAppService.GetSelect().then((r) => {
      this.units = r.data.Data ?? [];
    });

    this.list.Dto.Ids = this.ids;
    this.list.Dto.AmountType = this.amountType;
    this.list.Dto.CompanyId = this.companyId;
    this.list.Dto.SalesmanId = this.salesmanId;
    PriceAppService.GetCalcList({
      Ids: this.ids,
      AmountType: this.amountType,
      CompanyId: this.companyId,
      SalesmanId: this.salesmanId,
    }).then((r) => {
      this.list.Data = r.data.Data;
      this.ItemReset = JSON.parse(JSON.stringify(this.list.Data));
      this.loading = false;
    });
  }

  getTotal(): void {
    this.$nextTick(() => {
      this.totals = [];
      for (const item of this.list.Data?.Items ?? []) {
        item.Total = undefined;
        if (
          item.Amount != undefined &&
          (item.FirstChargingNumber != undefined ||
            item.SecondChargingNumber != undefined)
        ) {
          if (
            item.FirstChargingNumber != undefined &&
            item.SecondChargingNumber != undefined
          ) {
            item.Total =
              item.Amount *
              item.FirstChargingNumber *
              item.SecondChargingNumber;
          } else if (item.FirstChargingNumber != undefined) {
            item.Total = item.Amount * item.FirstChargingNumber;
          } else if (item.SecondChargingNumber != undefined) {
            item.Total = item.Amount * item.SecondChargingNumber;
          }
        }
        if (item.Total) {
          let total = this.totals.firstOrDefault(
            (it) => it.CurrencySystem == item.CurrencySystem
          );
          if (total) {
            total.Amount += item.Total;
          } else {
            total = {
              CurrencySystem: item.CurrencySystem as string,
              Amount: item.Total,
            };
            this.totals.push(total);
          }
        }
      }
    });
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPriceCalc,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //详细
      case "delete":
        if (this.list.Data) {
          this.list.Data.Items?.splice(index, 1);
          if (this.list.Data.Items?.length == 0) {
            this.handleClear();
          }
        }
        complete();
        break;
      default:
        break;
    }
  }

  /**顶部按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add-supplier":
        this.modalDataSupplier.Data.push({});
        complete();
        break;
      case "add-supplier-submit":
        (this.$refs["ruleFormSupplier"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document
                .getElementsByClassName("rule-form-supplier")[0]
                .getElementsByClassName("el-table__body")[0]
                .getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            this.getTotal();
            const dto = new DtoFormPriceQuotationSheet();
            dto.CompanyId = this.companyId;
            dto.ServiceId = this.serviceId;
            PriceQuotationSheetAppService.Post(dto)
              .then((r) => {
                const dto2 = new DtoFormPriceQuotationSheetSource();
                dto2.Suppliers = this.modalDataSupplier.Data.map((it) => {
                  return {
                    Id: r.data.Data,
                    SupplierName: it.Value,
                    Items: this.list.Data?.Items?.map((it) => {
                      return {
                        CostId: it.CostId,
                        CurrencySystemId: it.CurrencySystemId,
                        Amount: it.Amount,
                        FirstChargingUnitId: it.FirstChargingUnitId,
                        FirstChargingNumber: it.FirstChargingNumber,
                        SecondChargingUnitId: it.SecondChargingUnitId,
                        SecondChargingNumber: it.SecondChargingNumber,
                        Description: it.Description,
                      };
                    }),
                  };
                });
                PriceQuotationSheetAppService.Post(dto2)
                  .then(() => {
                    ElMessage.success("保存成功");
                    this.modalSupplier.Show = false;
                    complete();
                  })
                  .catch(complete);
              })
              .catch(complete);
          }
        });
        break;
      case "save":
        (this.$refs["list-form"] as any).validate(async (v: boolean) => {
          if (v) {
            this.getTotal();
            if (btn.Command == "save-quotation") {
              const dto = new DtoFormPriceQuotationSheet();
              if (this.amountType != EnumPriceAmountType.Cost) {
                dto.CompanyId = this.companyId;
              }
              dto.ServiceId = this.serviceId;
              dto.Items = this.list.Data?.Items?.map((it) => {
                return {
                  CostId: it.CostId,
                  CurrencySystemId: it.CurrencySystemId,
                  Amount: it.Amount,
                  FirstChargingUnitId: it.FirstChargingUnitId,
                  FirstChargingNumber: it.FirstChargingNumber,
                  SecondChargingUnitId: it.SecondChargingUnitId,
                  SecondChargingNumber: it.SecondChargingNumber,
                  Description: it.Description,
                };
              });
              PriceQuotationSheetAppService.Post(dto)
                .then(() => {
                  ElMessage.success("保存成功");
                  complete();
                })
                .catch(complete);
            } else {
              this.modalDataSupplier.Data = [];
              this.modalSupplier.Show = true;
              complete();
            }
          } else {
            complete();
          }
        });
        break;
      case "reset":
        this.totals = [];
        this.list.Data = JSON.parse(JSON.stringify(this.ItemReset));
        complete();
        break;
      case "clear-close":
        for (const item of this.list.Data?.Items ?? []) {
          item.Total = undefined;
          item.FirstChargingNumber = undefined;
          item.SecondChargingNumber = undefined;
          this.totals = [];
        }
        if (btn.Code == "clear-close") {
          this.handleClear();
        }
        complete();
        break;
      case "close":
        this.modelValue.Show = false;
        complete();
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  @Emit()
  handleSubmit(): void {
    this.modelValue.Show = false;
  }

  @Emit()
  handleClear(): void {
    this.modelValue.Show = false;
  }
}
