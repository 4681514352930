import UiPageList from "./UiPageList";
import DtoListItemOrderPoolContainerTrailer from "./DtoListItemOrderPoolContainerTrailer";
import { EnumOrderPoolStatus, EnumOrderPoolStatusOption } from "./EnumOrderPoolStatus";
import { EnumOrderStatus, EnumOrderStatusOption } from "./EnumOrderStatus";
import DtoPageListOrderPoolContainerTrailer_WharfSupplier from "./DtoPageListOrderPoolContainerTrailer_WharfSupplier";

/**
 * 委托书-集装箱拖车分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderPoolContainerTrailer extends UiPageList<DtoListItemOrderPoolContainerTrailer, string> {
    constructor() {
        //无参构造函数
        super()
        this.Lock = false;
        this.WharfSuppliers = [];
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 配载订单
     */
    StowageOrder?: boolean;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 锁定
     */
    Lock?: boolean;
    /**
     * 状态
     */
    Status?: EnumOrderPoolStatus;
    /**
     * 订单状态
     */
    OrderStatus?: EnumOrderStatus;
    /**
     * 仓库供应商
     */
    WharfSuppliers?: DtoPageListOrderPoolContainerTrailer_WharfSupplier[];
}

/**
 * 委托书-集装箱拖车分页列表-表单校验
 */
export const DtoPageListOrderPoolContainerTrailerRules = {
}
