
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompany, {
  DtoDetailCompanyMiezzDetailItems,
} from "@/models.machine/DtoDetailCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyDetail from "../../CompanyDetail.vue";
import { CompanySetting } from "../../CompanySetting";
@Options({
  components: {
    CompanyDetail,
  },
})
export default class SupplierPublicCompanyDetail extends Vue {
  type = EnumCompanyType.Supplier;
  detail = new MiezzForm<DtoDetailCompany, string>(
    "SupplierManagement_Public_Detail"
  );
  buttons: MiezzButton[] = [];
  settings: CompanySetting[] = [];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/supplier/company/public/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Menus = [
      {
        Url: `/supplier/company/public/detail/${this.$route.params.id}?page=BasicInfo`,
        Label: "公司信息",
        Power: "SupplierManagement_Public_Detail",
      },
    ];
    let items = DtoDetailCompanyMiezzDetailItems.copy() as MiezzFormItem[];
    items = items.filter(
      (it) =>
        it.Prop != "ServiceProviderCompanyId" && it.Prop != "SalesmanLabel" && it.Prop != "Source"
    );
    for (const item of items) {
      switch (item.Prop) {
        case "JoinCompanyTypes":
          item.Label = "服务类型";
          break;

        default:
          break;
      }
    }
    this.detail.Items = items;
    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
    ];

    const logier = CurrentLogier.GetLogier();
    if (logier) {
      CurrentLogier.GetMenus().then((r) => {
        const powers = r?.Menus?.map((it) => it.Code) ?? [];
        if (powers.contains("SupplierManagement_Public_Contact")) {
          this.settings.push({
            Name: "通讯录",
            Url: "/supplier/company/public/contact/${id}",
          });
        }
        if (powers.contains("SupplierManagement_Public_Invoice")) {
          this.settings.push({
            Name: "开票资料",
            Url: "/supplier/company/public/invoice/${id}",
          });
        }
        if (
          powers.contains("SupplierManagement_Public_CreditAndAccountingPeriod")
        ) {
          this.settings.push({
            Name: "授信与账期",
            Url: "/supplier/company/public/credit-and-accounting-period/${id}",
          });
          }

          if (powers.contains("SupplierManagement_Public_RulesForBillingWeightvolume")) {
              this.settings.push({
                  Name: "计费重量/体积规则",
                  Url: "/supplier/company/public/charge/${id}",
              });
          }

        if (this.settings.length > 0) {
          this.menuTab.Menus?.push({
            Url: `/supplier/company/public/detail/${this.$route.params.id}?page=Setting`,
            Label: "相关参数",
            Power: "SupplierManagement_Public_Detail",
          });
        }
      });
    }
  }
}
