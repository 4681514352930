import UiPageList from "./UiPageList";
import DtoListItemOrderPoolTrunk from "./DtoListItemOrderPoolTrunk";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumOrderPoolStatus, EnumOrderPoolStatusOption } from "./EnumOrderPoolStatus";
import { EnumOrderStatus, EnumOrderStatusOption } from "./EnumOrderStatus";
import DtoPageListOrderPoolTrunk_WharfSupplier from "./DtoPageListOrderPoolTrunk_WharfSupplier";

/**
 * 委托书-干线物流分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderPoolTrunk extends UiPageList<DtoListItemOrderPoolTrunk, string> {
    constructor() {
        //无参构造函数
        super()
        this.Lock = false;
        this.ShowCarryContainerTime = false;
        this.WharfSuppliers = [];
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 配载订单
     */
    StowageOrder?: boolean;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 委托书Id（当直订订单时）
     */
    PoolId?: string;
    /**
     * 锁定
     */
    Lock?: boolean;
    /**
     * 状态
     */
    Status?: EnumOrderPoolStatus;
    /**
     * 订单状态
     */
    OrderStatus?: EnumOrderStatus;
    /**
     * 显示提柜时间
     */
    ShowCarryContainerTime?: boolean;
    /**
     * 仓库供应商
     */
    WharfSuppliers?: DtoPageListOrderPoolTrunk_WharfSupplier[];
}

/**
 * 委托书-干线物流分页列表-表单校验
 */
export const DtoPageListOrderPoolTrunkRules = {
}
