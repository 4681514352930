import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormPriceQuotationSheet_PriceQuotationSheetFile from "./DtoFormPriceQuotationSheet_PriceQuotationSheetFile";
import DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier from "./DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceQuotationSheetSource extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Files = [];
        this.Suppliers = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 货物数据
     */
    Weight?: number;
    /**
     * 毛重
     */
    Volume?: number;
    /**
     * 附件
     */
    Files?: DtoFormPriceQuotationSheet_PriceQuotationSheetFile[];
    /**
     * 成本维护人
     */
    InfoCollectorId?: string;
    /**
     * 供应商
     */
    Suppliers?: DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier[];
}

/**
 * -表单校验
 */
export const DtoFormPriceQuotationSheetSourceRules = {
}

/**
 * -设置项
 */
export const DtoFormPriceQuotationSheetSourceMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Weight",
    Label: "货物数据",
    Customize: true,
  },
  {
    Prop: "InfoCollectorId",
    Label: "成本维护人",
    Customize: true,
  },
  {
    Prop: "Suppliers",
    Label: "供应商",
    Customize: true,
  },
]
