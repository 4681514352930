import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_set_up = _resolveComponent("price-set-up")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_price_service_form = _resolveComponent("price-service-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_price_set_up, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event)),
      onHandleMenuTabClick: _ctx.handleTopButtonClick
    }, null, 8, ["modelValue", "onHandleMenuTabClick"]),
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleRowButtonClick: _ctx.handleRowButtonClick,
      onHandleBottomButtonClick: _ctx.handleBottomButtonClick,
      onHandleDragSort: _ctx.handleDragSort,
      leftHeaderWidth: 'calc(100% - 190px)'
    }, {
      "card-footer": _withCtx(({ row }) => [
        _createVNode(_component_miezz_button, {
          model: _ctx.detailBtn,
          onHandleClick: (arg1, arg2) => _ctx.handleRowButtonClick(arg1, row, arg2)
        }, null, 8, ["model", "onHandleClick"])
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleRowButtonClick", "onHandleBottomButtonClick", "onHandleDragSort", "leftHeaderWidth"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_price_service_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}