
import MiezzMonthChoose from "@/components/MiezzMonthChoose.vue";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { init, EChartsOption } from "echarts";
import DtoRequestOrderChart, {
  DtoRequestOrderChartDynamicSearchItems,
} from "@/models.machine/DtoRequestOrderChart";
import { EnumOrderChartGroupBy } from "@/models.machine/EnumOrderChartGroupBy";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import { EnumTransportTypeOption } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoadOption } from "@/models.machine/EnumTransportContainerLoad";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import UiEChartsPie from "@/models.machine/UiEChartsPie";
import UiEChartsBar from "@/models.machine/UiEChartsBar";
import OrderAppService from "@/apis.machine/OrderAppService";
import { EnumOrderChartDataSource } from "@/models.machine/EnumOrderChartDataSource";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
@Options({
  components: {
    MiezzMonthChoose,
  },
})
export default class CountOrder extends Vue {
  types: {
    code?: string;
    title: string;
    type: "success" | "info";
  }[] = [
    {
      code: "CountTotalNumber",
      title: "票数统计",
      type: "success",
    },
    {
      code: "CountTotalNumber",
      title: "总计",
      type: "info",
    },
    {
      code: "CountSupplierNumber",
      title: "供应商",
      type: "info",
    },
    {
      code: "CountCustomNumber",
      title: "自定义",
      type: "info",
    },
    {
      code: "CountTotalFclGoods",
      title: "货量统计",
      type: "success",
    },
    {
      code: "CountTotalFclGoods",
      title: "总计-整柜",
      type: "info",
    },
    {
      code: "CountTotalOtherGoods",
      title: "总计-其他",
      type: "info",
    },
    {
      code: "CountSupplierFclGoods",
      title: "供应商-整柜",
      type: "info",
    },
    {
      code: "CountSupplierOtherGoods",
      title: "供应商-其他",
      type: "info",
    },
    {
      code: "CountCustomFclGoods",
      title: "自定义-整柜",
      type: "info",
    },
    {
      code: "CountCustomOtherGoods",
      title: "自定义-其他",
      type: "info",
    },
  ];
  type?: string = "";
  month?: string = "";
  tab?: string = "Number";

  supplierId?: string;
  suppliers?: UiSelectOption<string>[] = [];
  dto = new DtoRequestOrderChart();
  searchs = DtoRequestOrderChartDynamicSearchItems;
  histories: UiSelectOption<string>[] = [];

  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  EnumTransportPaymentTypeOption = EnumTransportPaymentTypeOption;
  loadingPorts: UiSelectOption<string>[] = [];
  deliveryPorts: UiSelectOption<string>[] = [];
  deliveryPlaceLabels: UiSelectOption<string>[] = [];
  shipNames: UiSelectOption<string>[] = [];
  voyageNumbers: UiSelectOption<string>[] = [];
  flights: UiSelectOption<string>[] = [];
  railwayNumbers: UiSelectOption<string>[] = [];
  carriers: UiSelectOption<string>[] = [];

  created(): void {
    this.type = this.$route.query.type as string;
    if (!this.type) {
      this.type = this.types.filter((it) => it.code).firstOrDefault()?.code;
    }
    this.handleChooseType(this.type);

    this.handleGetHistories();

    OrderAppService.GetSuppliers().then((r) => {
      this.suppliers = r.data.Data ?? [];
    });
    OrderAppService.GetLoadingPorts({ CountSearch: true }).then((r) => {
      this.loadingPorts = r.data.Data ?? [];
    });
    OrderAppService.GetDeliveryPorts({ CountSearch: true }).then((r) => {
      this.deliveryPorts = r.data.Data ?? [];
    });
    OrderAppService.GetDeliveryPlaces({ CountSearch: true }).then((r) => {
      this.deliveryPlaceLabels = r.data.Data ?? [];
    });
    OrderAppService.GetShipNames({ CountSearch: true }).then((r) => {
      this.shipNames = r.data.Data ?? [];
    });
    OrderAppService.GetVoyageNumbers({ CountSearch: true }).then((r) => {
      this.voyageNumbers = r.data.Data ?? [];
    });
    OrderAppService.GetFlights({ CountSearch: true }).then((r) => {
      this.flights = r.data.Data ?? [];
    });
    OrderAppService.GetRailwayNumbers({ CountSearch: true }).then((r) => {
      this.railwayNumbers = r.data.Data ?? [];
    });
    OrderAppService.GetCarriers({ CountSearch: true }).then((r) => {
      this.carriers = r.data.Data ?? [];
    });
  }

  handleGetHistories(): void {
    DynamicSearchAppService.Get({
      Code: "Count_Order",
    }).then((r) => {
      this.histories = r.data.Data ?? [];
    });
  }

  handleChooseType(type?: string): void {
    if (this.type != type) {
      this.type = type;
      this.tab = "Number";
      this.$route.query["type"] = this.type ?? "";
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        force: true,
      });
      this.handleChart();
    }
  }

  @Watch("month")
  onMonthChanged(nv: any, ov: any): void {
    if (nv != ov) {
      this.handleChart();
    }
  }

  countTotalFclGoodsBarOptions: UiEChartsBar[] = [];
  countSupplierFclGoodsOptions: UiEChartsPie[] = [];
  countSupplierFclGoodsBarOptions: UiEChartsBar[] = [];
  countCustomFclGoodsBarOptions: UiEChartsBar[] = [];

  handleChart(): void {
    if (this.type && this.month) {
      switch (this.type) {
        case "CountTotalNumber":
          this.getChartBar("CountTotalNumber", "票数总计", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.Number,
          });
          break;
        case "CountSupplierNumber":
          this.getChartPie("CountSupplierNumberPie", "供应商组成", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.SupplierCompany,
            DataSource: EnumOrderChartDataSource.Number,
          });
          this.getChartBar("CountSupplierNumberBar", "供应商组成", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.Number,
          });
          break;
        case "CountCustomNumber":
          this.dto.Month = this.month;
          this.dto.GroupBy = EnumOrderChartGroupBy.BusinessCycle;
          this.dto.DataSource = EnumOrderChartDataSource.Number;
          this.getChartBar("CountCustomNumber", "票数总计", this.dto);
          break;
        case "CountTotalFclGoods":
          this.getChartBar("CountTotalFclGoodsBar", "", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.ContainerType,
            Fcl: true,
          });
          break;
        case "CountSupplierFclGoods":
          this.getChartPie("CountSupplierFclGoodsPie", "", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.SupplierCompany,
            DataSource: EnumOrderChartDataSource.ContainerType,
            Fcl: true,
          });
          this.getChartBar("CountSupplierFclGoodsBar", "", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.ContainerType,
            Fcl: true,
          });
          break;
        case "CountCustomFclGoods":
          this.dto.Month = this.month;
          this.dto.GroupBy = EnumOrderChartGroupBy.BusinessCycle;
          this.dto.DataSource = EnumOrderChartDataSource.ContainerType;
          this.dto.Fcl = true;
          this.getChartBar("CountCustomFclGoodsBar", "票数总计", this.dto);
          break;
        case "CountTotalOtherGoods":
          this.getChartBar("CountTotalGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.Weight,
            Fcl: false,
          });
          this.getChartBar("CountTotalGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.Volume,
            Fcl: false,
          });
          break;
        case "CountSupplierOtherGoods":
          this.getChartPie("CountSupplierGoodsWeightPie", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.SupplierCompany,
            DataSource: EnumOrderChartDataSource.Weight,
            Fcl: false,
          });
          this.getChartPie("CountSupplierGoodsVolumePie", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderChartGroupBy.SupplierCompany,
            DataSource: EnumOrderChartDataSource.Volume,
            Fcl: false,
          });
          this.getChartBar("CountSupplierGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.Weight,
            Fcl: false,
          });
          this.getChartBar("CountSupplierGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderChartGroupBy.BusinessCycle,
            DataSource: EnumOrderChartDataSource.Volume,
            Fcl: false,
          });
          break;
        case "CountCustomOtherGoods":
          this.dto.Month = this.month;
          this.dto.GroupBy = EnumOrderChartGroupBy.BusinessCycle;
          this.dto.DataSource = EnumOrderChartDataSource.Weight;
          this.dto.Fcl = false;
          this.getChartBar("CountCustomGoodsWeightBar", "毛重KGS", this.dto);
          var dto = JSON.copy(this.dto) as DtoRequestOrderChart;
          dto.DataSource = EnumOrderChartDataSource.Volume;
          this.getChartBar("CountCustomGoodsVolumeBar", "体积CBM", dto);
          break;
        default:
          break;
      }
    }
  }

  getChartBar(ref: string, title: string, dto: DtoRequestOrderChart): void {
    OrderAppService.GetChartBar(dto).then((r) => {
      const datas = r.data.Data ?? [];
      if (ref == "CountTotalFclGoodsBar") {
        this.countTotalFclGoodsBarOptions = datas;
      }
      if (ref == "CountSupplierFclGoodsBar") {
        this.countSupplierFclGoodsBarOptions = datas;
      }
      if (ref == "CountCustomFclGoodsBar") {
        this.countCustomFclGoodsBarOptions = datas;
      }
      for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        const option: EChartsOption = {
          title: {
            text: data.Title ?? title,
            left: "center",
            textStyle: {
              color: "#666",
            },
          },
          xAxis: {
            type: "category",
            data: data.XAxisData,
            axisLabel: { interval: 0 },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: data.SeriesData,
              type: "bar",
              label: {
                show: true,
                position: "top",
              },
              barMaxWidth: 70,
            },
          ],
        };
        const _ref =
          dto.DataSource == EnumOrderChartDataSource.ContainerType
            ? `${ref}_${index}`
            : ref;
        this.initChart(_ref, option);
      }
    });
  }

  getChartPie(ref: string, title: string, dto: DtoRequestOrderChart): void {
    OrderAppService.GetChartPie(dto).then((r) => {
      const datas = r.data.Data ?? [];
      if (ref == "CountSupplierFclGoodsPie") {
        this.countSupplierFclGoodsOptions = datas;
      }
      for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        const option: EChartsOption = {
          title: {
            text: data.Title ?? title,
            left: "center",
            textStyle: {
              color: "#666",
            },
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              label: {
                show: false,
              },
              data: data.SeriesData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        const _ref =
          dto.DataSource == EnumOrderChartDataSource.ContainerType
            ? `${ref}_${index}`
            : ref;
        this.initChart(_ref, option);
      }
    });
  }

  initChart(ref: string, option: EChartsOption): void {
    this.$nextTick(() => {
      const element = document.getElementById(ref) as InstanceType<
        typeof HTMLElement
      >;
      console.log(ref, element);
      if (element) {
        const echarts = init(element);
        echarts.setOption(option);
      }
    });
  }
}
