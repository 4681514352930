import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 基础价格表-列表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceTableColumn extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Show = true;
        this.Sort = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 要素名称
     */
    FieldId?: string;
    /**
     * 列名称
     */
    Name?: string;
    /**
     * 在服务中显示
     */
    Show?: boolean;
    /**
     * 排序
     */
    Sort?: number;
}

/**
 * 基础价格表-列表单-表单校验
 */
export const DtoFormPriceTableColumnRules = {
    FieldId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Show: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 基础价格表-列表单-设置项
 */
export const DtoFormPriceTableColumnMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "FieldId",
    Label: "要素名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Name",
    Label: "列名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Show",
    Label: "在服务中显示",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Sort",
    Label: "排序",
    Type: EnumMiezzFormItemType.InputText,
  },
]
