import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemPriceTableColumn_Field from "./DtoListItemPriceTableColumn_Field";

/**
 * 基础价格表-列分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPriceTableColumn extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.FieldType = "计算要素";
        this.Show = false;
        this.Sort = 0;
    }

    [proName: string]: any;
    /**
     * 要素名称
     */
    FieldId?: string;
    /**
     * 要素类型
     */
    FieldType?: string;
    /**
     * 要素
     */
    Field?: DtoListItemPriceTableColumn_Field;
    /**
     * 列名称
     */
    Name?: string;
    /**
     * 在服务中显示
     */
    Show?: boolean;
    /**
     * 排序
     */
    Sort?: number;
}

/**
 * 基础价格表-列分页列表-项-表单校验
 */
export const DtoListItemPriceTableColumnRules = {
}

/**
 * 基础价格表-列分页列表-项-设置项
 */
export const DtoListItemPriceTableColumnMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "FieldId",
    Label: "要素名称",
  },
  {
    Prop: "FieldType",
    Label: "要素类型",
  },
  {
    Prop: "Name",
    Label: "列名称",
  },
  {
    Prop: "Show",
    Label: "在服务中显示",
    MaxWidth: "140px",
    Pipe: EnumPipe.Boolean,
  },
]
