import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchBill from "../models.machine/DtoPageListSearchBill";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListBill from "../models.machine/DtoPageListBill";
import DtoDetailBill from "../models.machine/DtoDetailBill";
import DtoFormBill from "../models.machine/DtoFormBill";
import DtoRequestBill from "../models.machine/DtoRequestBill";
import DtoCallbackBill from "../models.machine/DtoCallbackBill";
import DtoCallbackSingleBill from "../models.machine/DtoCallbackSingleBill";
import DtoListItemBill from "../models.machine/DtoListItemBill";
import { EnumBillType, EnumBillTypeOption } from "../models.machine/EnumBillType";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoCallbackShare from "../models.machine/DtoCallbackShare";
import DtoRequestOrderLock from "../models.machine/DtoRequestOrderLock";
import DtoRequestCompany from "../models.machine/DtoRequestCompany";
import DtoRequestAccount from "../models.machine/DtoRequestAccount";
import DtoRequestTemplate from "../models.machine/DtoRequestTemplate";
import DtoCallbackTemplate from "../models.machine/DtoCallbackTemplate";
import DtoRequestUnlock from "../models.machine/DtoRequestUnlock";
import DtoPutTemplate from "../models.machine/DtoPutTemplate";
import DtoRequestPoolBill from "../models.machine/DtoRequestPoolBill";
import DtoDetailTime from "../models.machine/DtoDetailTime";
import DtoFinishTask from "../models.machine/DtoFinishTask";
import DtoCallbackAccountPeriod from "../models.machine/DtoCallbackAccountPeriod";
import DtoRequestFinishPoolBill from "../models.machine/DtoRequestFinishPoolBill";

/**
 * 账单
 * @description 自动生成的代码,请勿修改
 */
export default class BillAppService {
    /**
     * 获取账单分页列表
     * @param {DtoPageListSearchBill} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListBill>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchBill): AxiosPromise<RESTfulResult<DtoPageListBill>> {
        const url = `/api/Bill/Get`;
        return service.get<RESTfulResult<DtoPageListBill>>(url, { params: dto });
    }

    /**
     * 获取账单详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailBill>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailBill>> {
        const url = `/api/Bill/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailBill>>(url);
    }

    /**
     * 添加账单
     * @param {DtoFormBill} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormBill): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Bill/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除账单
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 账单信息
     * @param {DtoRequestBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackBill>>} 
     */
    static GetBill(dto?: DtoRequestBill): AxiosPromise<RESTfulResult<DtoCallbackBill>> {
        const url = `/api/Bill/GetBill`;
        return service.get<RESTfulResult<DtoCallbackBill>>(url, { params: dto });
    }

    /**
     * 获取货主的账单
     * @param {DtoRequestBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackBill>>} 
     */
    static GetClientBill(dto?: DtoRequestBill): AxiosPromise<RESTfulResult<DtoCallbackBill>> {
        const url = `/api/Bill/GetClientBill`;
        return service.get<RESTfulResult<DtoCallbackBill>>(url, { params: dto });
    }

    /**
     * 单项账单信息
     * @param {DtoRequestBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackSingleBill>>} 
     */
    static GetSingleBill(dto?: DtoRequestBill): AxiosPromise<RESTfulResult<DtoCallbackSingleBill>> {
        const url = `/api/Bill/GetSingleBill`;
        return service.get<RESTfulResult<DtoCallbackSingleBill>>(url, { params: dto });
    }

    /**
     * 手动添加账单
     * @param {DtoRequestBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoListItemBill[]>>} 
     */
    static Add(dto?: DtoRequestBill): AxiosPromise<RESTfulResult<DtoListItemBill[]>> {
        const url = `/api/Bill/Add`;
        return service.post<RESTfulResult<DtoListItemBill[]>>(url, dto);
    }

    /**
     * 导入委托书账单
     * @param {DtoRequestBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ImportPoolCost(dto?: DtoRequestBill): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/ImportPoolCost`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 保存账单
     * @param {DtoCallbackSingleBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static Save(dto?: DtoCallbackSingleBill): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Bill/Save`;
        return service.post<RESTfulResult<string[]>>(url, dto);
    }

    /**
     * 暂存
     * @param {DtoCallbackSingleBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static Temporary(dto?: DtoCallbackSingleBill): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Bill/Temporary`;
        return service.post<RESTfulResult<string[]>>(url, dto);
    }

    /**
     * 保存模板
     * @param {DtoCallbackSingleBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SaveTemplate(dto?: DtoCallbackSingleBill): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/SaveTemplate`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取委托书集合
     * @param {string} [OrderId] 订单主键
     * @param {EnumBillType} [type] 账单类型
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetOrderPoolByOrderIdByType(OrderId?: string, type?: EnumBillType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Bill/GetOrderPool/${OrderId}/${type}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取分摊账单的信息
     * @param {DtoRequestBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackShare>>} 
     */
    static GetShare(dto?: DtoRequestBill): AxiosPromise<RESTfulResult<DtoCallbackShare>> {
        const url = `/api/Bill/GetShare`;
        return service.get<RESTfulResult<DtoCallbackShare>>(url, { params: dto });
    }

    /**
     * 保存分摊账单
     * @param {DtoCallbackShare} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SaveShare(dto?: DtoCallbackShare): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/SaveShare`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 撤销分摊
     * @param {string} [id] 账单主键
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RevokeShareById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/RevokeShare/${id}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 获取订单是否锁定
     * @param {DtoRequestOrderLock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsOrderLock(dto?: DtoRequestOrderLock): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Bill/IsOrderLock`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 获取委托人
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClientCompany(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Bill/GetClientCompany`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取供应商
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSupplier(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Bill/GetSupplier`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取委托人
     * @param {DtoRequestCompany} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClientCompanyUser(dto?: DtoRequestCompany): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Bill/GetClientCompanyUser`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取供应商
     * @param {DtoRequestCompany} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSupplierUser(dto?: DtoRequestCompany): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Bill/GetSupplierUser`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取账期
     * @param {DtoRequestAccount} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetAccount(dto?: DtoRequestAccount): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Bill/GetAccount`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取模板
     * @param {DtoRequestTemplate} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackTemplate>>} 
     */
    static GetTemplate(dto?: DtoRequestTemplate): AxiosPromise<RESTfulResult<DtoCallbackTemplate>> {
        const url = `/api/Bill/GetTemplate`;
        return service.get<RESTfulResult<DtoCallbackTemplate>>(url, { params: dto });
    }

    /**
     * 删除模板
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RemoveTemplateById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/RemoveTemplate/${id}`;
        return service.delete<RESTfulResult<any>>(url);
    }

    /**
     * 解锁账单
     * @param {DtoRequestUnlock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static Unlock(dto?: DtoRequestUnlock): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Bill/Unlock`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 保存模板
     * @param {DtoPutTemplate} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateTemplate(dto?: DtoPutTemplate): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/UpdateTemplate`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 计算计费重量
     * @param {DtoCallbackShare} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackShare>>} 
     */
    static CountWeight(dto?: DtoCallbackShare): AxiosPromise<RESTfulResult<DtoCallbackShare>> {
        const url = `/api/Bill/CountWeight`;
        return service.post<RESTfulResult<DtoCallbackShare>>(url, dto);
    }

    /**
     * 导出账单
     * @param {DtoRequestBill} [param] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Export(param?: DtoRequestBill): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Bill/Export`;
        return service.post<RESTfulResult<string>>(url, param);
    }

    /**
     * 导出委托书的应收账单
     * @param {DtoRequestPoolBill} [param] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PoolExport(param?: DtoRequestPoolBill): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Bill/PoolExport`;
        return service.post<RESTfulResult<string>>(url, param);
    }

    /**
     * 获取业务时间
     * @param {DtoDetailTime} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailTime>>} 
     */
    static GetTime(dto?: DtoDetailTime): AxiosPromise<RESTfulResult<DtoDetailTime>> {
        const url = `/api/Bill/GetTime`;
        return service.get<RESTfulResult<DtoDetailTime>>(url, { params: dto });
    }

    /**
     * 获取新的Guid
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetGuid(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Bill/GetGuid`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 手动完成待办
     * @param {DtoFinishTask} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static FinishTask(dto?: DtoFinishTask): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/FinishTask`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获得账期初始值
     * @param {DtoCallbackAccountPeriod} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackAccountPeriod>>} 
     */
    static GetAccountPerod(dto?: DtoCallbackAccountPeriod): AxiosPromise<RESTfulResult<DtoCallbackAccountPeriod>> {
        const url = `/api/Bill/GetAccountPerod`;
        return service.get<RESTfulResult<DtoCallbackAccountPeriod>>(url, { params: dto });
    }

    /**
     * 判断订单下的委托书账单是否完结
     * @param {DtoRequestFinishPoolBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoRequestFinishPoolBill>>} 
     */
    static IsFinishPoolBill(dto?: DtoRequestFinishPoolBill): AxiosPromise<RESTfulResult<DtoRequestFinishPoolBill>> {
        const url = `/api/Bill/IsFinishPoolBill`;
        return service.post<RESTfulResult<DtoRequestFinishPoolBill>>(url, dto);
    }

    /**
     * 设置记账起始时间/账期的默认值
     * @param {string} [OrderId] 
     * @param {string} [CompanyId] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetDefaultAccountPeriod(OrderId?: string, CompanyId?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/SetDefaultAccountPeriod`;
        const _querys: any = {};
        _querys.OrderId = OrderId
        _querys.CompanyId = CompanyId
        return service.post<RESTfulResult<any>>(url, undefined, { params: _querys });
    }

    /**
     * 检查委托书/订单的待办是否完成，并修改
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CheckFinish(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/CheckFinish`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 撤销提交
     * @param {DtoCallbackSingleBill} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RevokeSave(dto?: DtoCallbackSingleBill): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Bill/RevokeSave`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
