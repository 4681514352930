import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumFinanceStatChannel, EnumFinanceStatChannelOption } from "./EnumFinanceStatChannel";
import { EnumCompositionType, EnumCompositionTypeOption } from "./EnumCompositionType";

/**
 * 账单分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchFinance extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
        this.IsFinancialCycle = false;
        this.IsStat = false;
        this.IsProfit = false;
        this.IsExport = false;
    }

    [proName: string]: any;
    /**
     * 财务周期
     */
    IsFinancialCycle?: boolean;
    /**
     * 统计栏目
     */
    Channel?: EnumFinanceStatChannel;
    /**
     * 统计类型
     */
    StatType?: EnumCompositionType;
    /**
     * 统计月份
     */
    StatMonth?: string;
    /**
     * 批量编辑的主键
     */
    BatchOperationId?: string;
    /**
     * 导出主键
     */
    ExportId?: string[];
    /**
     * 
     */
    CompanyId?: string;
    /**
     * 
     */
    Month?: string;
    /**
     * 是否统计显示
     */
    IsStat?: boolean;
    /**
     * 导出是否显示利润
     */
    IsProfit?: boolean;
    /**
     * 是否导出
     */
    IsExport?: boolean;
}

/**
 * 账单分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchFinanceRules = {
}

/**
 * 账单分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchFinanceDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_Pool",
    Label: "Booking No",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_PoolCode",
    Label: "委托方编号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Order",
    Label: "订单编号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Type",
    Label: "账单类型",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Object",
    Label: "对账对象",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ClientCompany",
    Label: "委托人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CollectionTitle",
    Label: "收款方抬头",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_PaymentTitle",
    Label: "付款方抬头",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_BusinessDate",
    Label: "业务周期",
    Type: EnumUiDynamicSearchItemType.MonthRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_FinancialCycle",
    Label: "财务周期",
    Type: EnumUiDynamicSearchItemType.MonthRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.Equal,
      EnumCompare.NoBetween,
    ]
  },
  {
    Prop: "DynamicSearch_SettlementType",
    Label: "核销状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_InvoiceNumber",
    Label: "发票号码",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_BLNO",
    Label: "提单号码",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_LoadingPortId",
    Label: "装货港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeliveryPortId",
    Label: "目的港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeliveryPlaceLabel",
    Label: "最终目的地",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_EstimatedDepartureDate",
    Label: "ETD",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ActualDepartureDate",
    Label: "ATD",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_EstimatedArrivalDate",
    Label: "ETA",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ActualArrivalDate",
    Label: "ATA",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ServiceRemark",
    Label: "服务内容",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CustomerServiceId",
    Label: "订单客服",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_PoolCustomer",
    Label: "委托书客服",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_MakeUserId",
    Label: "账单执行人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_SalerId",
    Label: "业务员",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_CurrencySystem",
    Label: "币制",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_SubmitStatus",
    Label: "提交状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Cost",
    Label: "费用名称",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_InvoiceState",
    Label: "发票状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_InvoiceSettlementState",
    Label: "发票核销状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_BoxNumber",
    Label: "箱号",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_InvoiceDate",
    Label: "开票日期",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_SettlementDate",
    Label: "核销日期",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_OverdueStatus",
    Label: "是否逾期",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Source",
    Label: "客户来源",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_SettlementColectionTitle",
    Label: "已核销收款抬头",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_SettlementPaymentTitle",
    Label: "已核销付款抬头",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Remark",
    Label: "备注",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_CheckStatus",
    Label: "对账状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ShareStatus",
    Label: "分摊状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_IsBad",
    Label: "是否坏账",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
]
