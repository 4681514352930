import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchDynamicSearch from "../models.machine/DtoSelectSearchDynamicSearch";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoDetailDynamicSearch from "../models.machine/DtoDetailDynamicSearch";
import DtoFormDynamicSearch from "../models.machine/DtoFormDynamicSearch";
import UiDrag from "../models.machine/UiDrag";

/**
 * 动态搜索
 * @description 自动生成的代码,请勿修改
 */
export default class DynamicSearchAppService {
    /**
     * 获取动态搜索分页列表
     * @param {DtoSelectSearchDynamicSearch} [search] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static Get(search?: DtoSelectSearchDynamicSearch): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/DynamicSearch/Get`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取动态搜索详细
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailDynamicSearch>>} 
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailDynamicSearch>> {
        const url = `/api/DynamicSearch/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailDynamicSearch>>(url);
    }

    /**
     * 添加/编辑动态搜索
     * @param {DtoFormDynamicSearch} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Post(dto?: DtoFormDynamicSearch): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/DynamicSearch/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 删除动态搜索
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/DynamicSearch/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/DynamicSearch/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
