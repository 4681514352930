import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormPriceQuotationSheet_PriceQuotationSheetFieldValue from "./DtoFormPriceQuotationSheet_PriceQuotationSheetFieldValue";
import DtoFormPriceQuotationSheet_PriceQuotationSheetFile from "./DtoFormPriceQuotationSheet_PriceQuotationSheetFile";
import DtoFormPriceQuotationSheet_PriceQuotationSheetItem from "./DtoFormPriceQuotationSheet_PriceQuotationSheetItem";
import DtoFormPriceQuotationSheet_TrackRecord from "./DtoFormPriceQuotationSheet_TrackRecord";
import DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier from "./DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier";
import UiGroup from "./UiGroup";

/**
 * 报价表表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceQuotationSheet extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.FieldValues = [];
        this.Files = [];
        this.Items = [];
        this.TrackRecords = [];
        this.Suppliers = [];
        this.UiGroups = [{"Code":"BasicInfo","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"QuotationSheet","Label":"\u62A5\u4EF7\u8868","Toggle":true,"Close":false,"Buttons":[]},{"Code":"TrackRecord","Label":"\u8FFD\u8E2A\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]},{"Code":"CostRecord","Label":"\u6210\u672C\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 报价人
     */
    CreatedCompanyUserId?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 价格有效期
     */
    ValidDateStart?: Date;
    /**
     * 有效期-截止
     */
    ValidDateEnd?: Date;
    /**
     * 客户企业
     */
    CompanyId?: string;
    /**
     * 联系人
     */
    UserId?: string;
    /**
     * 服务类型
     */
    ServiceId?: string;
    /**
     * 服务内容
     */
    ServiceRemark?: string;
    /**
     * 要素
     */
    FieldValues?: DtoFormPriceQuotationSheet_PriceQuotationSheetFieldValue[];
    /**
     * 货物数据
     */
    Weight?: number;
    /**
     * 毛重
     */
    Volume?: number;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 附件
     */
    Files?: DtoFormPriceQuotationSheet_PriceQuotationSheetFile[];
    /**
     * 项
     */
    Items?: DtoFormPriceQuotationSheet_PriceQuotationSheetItem[];
    /**
     * 追踪记录
     */
    TrackRecords?: DtoFormPriceQuotationSheet_TrackRecord[];
    /**
     * 成本维护人
     */
    InfoCollectorId?: string;
    /**
     * 供应商
     */
    Suppliers?: DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier[];
}

/**
 * 报价表表单-表单校验
 */
export const DtoFormPriceQuotationSheetRules = {
    CreatedCompanyUserId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    ServiceRemark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 报价表表单-设置项
 */
export const DtoFormPriceQuotationSheetMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CreatedCompanyUserId",
    Label: "报价人",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "ValidDateStart",
    Label: "价格有效期",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "CompanyId",
    Label: "客户企业",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "UserId",
    Label: "联系人",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "ServiceRemark",
    Label: "服务内容",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "FieldValues",
    Label: "要素",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "Weight",
    Label: "货物数据",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "Files",
    Label: "附件",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "Items",
    Label: "项",
    Group: "QuotationSheet",
    Customize: true,
  },
  {
    Prop: "TrackRecords",
    Label: "追踪记录",
    Group: "TrackRecord",
    Customize: true,
  },
  {
    Prop: "InfoCollectorId",
    Label: "成本维护人",
    Group: "CostRecord",
    Customize: true,
  },
  {
    Prop: "Suppliers",
    Label: "供应商",
    Group: "CostRecord",
    Customize: true,
  },
]
