
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import PriceQuotationSheetAppService from "@/apis.machine/PriceQuotationSheetAppService";
import DtoDetailPriceQuotationSheet, {
  DtoDetailPriceQuotationSheetMiezzDetailItems,
} from "@/models.machine/DtoDetailPriceQuotationSheet";
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import { EnumTransportTypeOption } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoadOption } from "@/models.machine/EnumTransportContainerLoad";
import moment from "moment";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem, {
  DtoDetailPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems,
} from "@/models.machine/DtoDetailPriceQuotationSheet_PriceQuotationSheetItem";
import MiezzPageList from "@/models/MiezzPageList";
import UiHistory from "@/models.machine/UiHistory";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import Logier from "@/models.machine/Logier";
import DtoDetailPriceQuotationSheet_TrackRecord, {
  DtoDetailPriceQuotationSheet_TrackRecordMiezzListItems,
} from "@/models.machine/DtoDetailPriceQuotationSheet_TrackRecord";

export default class PriceQuotationSheetDetail extends Vue {
  id?: string;
  logier?: Logier;
  menuTab = new MiezzMenuTab();

  detail = new MiezzForm<DtoDetailPriceQuotationSheet, string>();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "edit",
      Title: "编辑",
      Icon: "edit",
      Type: "default",
      Size: "small",
      PowerCode: "PriceManagement_QuotationSheet_Edit",
    },
  ];
  list = new MiezzPageList<
    any,
    DtoDetailPriceQuotationSheet_PriceQuotationSheetItem,
    string
  >();
  totals: { CurrencySystem: string; Amount: number }[] = [];
  listTrack = new MiezzPageList<
    any,
    DtoDetailPriceQuotationSheet_TrackRecord,
    string
  >();
  listSupplier = new MiezzPageList<
    any,
    DtoDetailPriceQuotationSheet_PriceQuotationSheetItem,
    string
  >();
  totalsSupplier: { CurrencySystem: string; Amount: number }[] = [];
  histories: UiHistory[] = [];
  supplierName?: string = "";

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    this.id = this.$route.params.id as string;
    this.menuTab.Back = true;
    this.menuTab.Url = `/price/quotation-sheet/${this.id}`;
    this.menuTab.Menus = [
      {
        Label: "详细",
        Url: `/price/quotation-sheet/${this.id}`,
        Power: "PriceManagement_QuotationSheet_Detail",
      },
    ];

    //详细-数据-初始化
    const data = new DtoDetailPriceQuotationSheet();
    //详细-元素配置
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      data.UiGroups = new DtoDetailPriceQuotationSheet().UiGroups;
      this.detail.Items = DtoDetailPriceQuotationSheetMiezzDetailItems;
    } else {
      data.UiGroups = new DtoDetailPriceQuotationSheet().UiGroups?.filter(
        (it) => it.Code != "TrackRecord" && it.Code != "CostRecord"
      );
      this.detail.Items = DtoDetailPriceQuotationSheetMiezzDetailItems.filter(
        (it) =>
          it.Prop != "TrackRecords" &&
          it.Prop != "InfoCollector" &&
          it.Prop != "Suppliers"
      );
    }
    this.detail.Data = data;
    for (const item of this.detail.Items) {
      switch (item.Prop) {
        case "CreatedCompanyUserId":
          item.HandleFormat = () => {
            return this.detail.Data.CreatedCompanyUserId
              ? `${this.detail.Data.CreatedUserName}/${
                  this.detail.Data.CreatedPhoneNumber ?? "暂无数据"
                }`
              : "暂无数据";
          };
          break;
        case "Company":
          item.HandleFormat = () => {
            return this.detail.Data.Company
              ? `${this.detail.Data.Company?.NameZh ?? "暂无数据"}/${
                  this.detail.Data.User?.Name ?? "暂无数据"
                }/${this.detail.Data.User?.User?.PhoneNumber ?? "暂无数据"}`
              : "暂无数据";
          };
          break;
        case "TransportDirection":
          item.HandleFormat = () => {
            var labels: (string | undefined)[] = [
              EnumTransportDirectionOption.firstOrDefault(
                (it) => it.Value == this.detail.Data.TransportDirection
              )?.Label,
              EnumTransportTypeOption.firstOrDefault(
                (it) => it.Value == this.detail.Data.TransportType
              )?.Label,
              EnumTransportContainerLoadOption.firstOrDefault(
                (it) => it.Value == this.detail.Data.TransportContainerLoad
              )?.Label,
            ];
            labels = labels.filter((it) => it).map((it) => `【${it}】`);
            return labels.length > 0 ? labels.join("") : "暂无数据";
          };
          break;
        case "Fields":
          item.HandleFormat = () => {
            return this.detail.Data.FieldValues?.map(
              (it) => `【${it.Field?.Name}：${it.Name}】`
            ).join("");
          };
          break;
        case "ValidDateStart":
          item.HandleFormat = () => {
            return this.detail.Data.ValidDateStart &&
              this.detail.Data.ValidDateEnd
              ? `${
                  moment(this.detail.Data.ValidDateStart)?.format(
                    "YYYY/MM/DD"
                  ) ?? "暂无数据"
                } - ${
                  moment(this.detail.Data.ValidDateEnd)?.format("YYYY/MM/DD") ??
                  "暂无数据"
                }`
              : "暂无数据";
          };
          break;
        case "Weight":
          item.HandleFormat = () => {
            return (
              (this.detail.Data.Weight != undefined
                ? `${this.detail.Data.Weight}KGS`
                : "") +
              (this.detail.Data.Weight != undefined &&
              this.detail.Data.Volume != undefined
                ? "/"
                : "") +
              (this.detail.Data.Volume != undefined
                ? `${this.detail.Data.Volume}CBM`
                : "") +
              (this.detail.Data.Volume != undefined &&
              this.detail.Data.PackagesNumber != undefined
                ? "/"
                : "") +
              (this.detail.Data.PackagesNumber != undefined
                ? `${this.detail.Data.PackagesNumber}${this.detail.Data.PackagesKind}`
                : "")
            );
          };
          break;
        case "InfoCollector":
          item.HandleFormat = () => {
            return (
              (this.detail.Data.InfoCollector?.Name ?? "暂无数据") +
              (this.detail.Data.InfoCollector?.User?.PhoneNumber
                ? "/" + this.detail.Data.InfoCollector?.User?.PhoneNumber
                : "")
            );
          };
          break;
        default:
          break;
      }
    }

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Items =
      DtoDetailPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems;
    this.list.Data = {
      Items: [],
    };
    this.list.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "Cost":
          return row.Cost?.FullName ?? "暂无数据";
        case "CurrencySystem":
          return row.CurrencySystem?.Code ?? "暂无数据";
        case "FirstChargingUnit":
          return row.FirstChargingUnit?.FullName ?? "暂无数据";
        case "SecondChargingUnit":
          return row.SecondChargingUnit?.FullName ?? "暂无数据";
        case "Total":
          return row.Total?.toFixed(2);
        default:
          break;
      }
    };

    this.listTrack.Fix = false;
    this.listTrack.HideCard = true;
    this.listTrack.EnabledDynamicSearch = false;
    this.listTrack.EnabledRadioSearch = false;
    this.listTrack.EnabledSelect = false;
    this.listTrack.EnabledPagination = false;
    this.listTrack.Items =
      DtoDetailPriceQuotationSheet_TrackRecordMiezzListItems;
    this.listTrack.Data = {
      Items: [],
    };

    this.listSupplier.Fix = false;
    this.listSupplier.HideCard = true;
    this.listSupplier.EnabledDynamicSearch = false;
    this.listSupplier.EnabledRadioSearch = false;
    this.listSupplier.EnabledSelect = false;
    this.listSupplier.EnabledPagination = false;
    this.listSupplier.Items =
      DtoDetailPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems;
    this.listSupplier.Data = {
      Items: [],
    };
    this.listSupplier.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "Cost":
          return row.Cost?.FullName ?? "暂无数据";
        case "CurrencySystem":
          return row.CurrencySystem?.Code ?? "暂无数据";
        case "FirstChargingUnit":
          return row.FirstChargingUnit?.FullName ?? "暂无数据";
        case "SecondChargingUnit":
          return row.SecondChargingUnit?.FullName ?? "暂无数据";
        case "Total":
          return row.Total?.toFixed(2);
        default:
          break;
      }
    };

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      PriceQuotationSheetAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          const data = r.data.Data;
          if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
            data.UiGroups = new DtoDetailPriceQuotationSheet().UiGroups;
          } else {
            data.UiGroups = new DtoDetailPriceQuotationSheet().UiGroups?.filter(
              (it) => it.Code != "TrackRecord" && it.Code != "CostRecord"
            );
          }
          this.detail.Data = data;
          if (this.list.Data) this.list.Data.Items = r.data.Data.Items;
          if (this.listTrack.Data)
            this.listTrack.Data.Items = r.data.Data.TrackRecords;
          this.supplierName =
            this.detail.Data.Suppliers?.firstOrDefault()?.SupplierName;
          this.handleCalc();
          this.handleCalcSupplier();
        }
      });
      PriceQuotationSheetAppService.GetHistoiesById(this.id).then((r) => {
        this.histories = r.data.Data ?? [];
      });
    }
  }

  @Watch("supplierName")
  onSupplierIdChanged(): void {
    this.listSupplier.Data = {
      Items:
        this.detail.Data.Suppliers?.firstOrDefault(
          (it) => it.SupplierName == this.supplierName
        )?.Items ?? [],
    };
    this.handleCalcSupplier();
  }

  handleCalc(): void {
    this.totals = [];
    for (const item of this.list.Data?.Items ?? []) {
      item.Total = undefined;
      if (
        item.Amount != undefined &&
        (item.FirstChargingNumber != undefined ||
          item.SecondChargingNumber != undefined)
      ) {
        if (
          item.FirstChargingNumber != undefined &&
          item.SecondChargingNumber != undefined
        ) {
          item.Total =
            item.Amount * item.FirstChargingNumber * item.SecondChargingNumber;
        } else if (item.FirstChargingNumber != undefined) {
          item.Total = item.Amount * item.FirstChargingNumber;
        } else if (item.SecondChargingNumber != undefined) {
          item.Total = item.Amount * item.SecondChargingNumber;
        }
      }
      if (item.Total && item.CurrencySystem?.Code) {
        const code = item.CurrencySystem.Code;
        let total = this.totals.firstOrDefault(
          (it) => it.CurrencySystem == code
        );
        if (total) {
          total.Amount += item.Total;
        } else {
          total = {
            CurrencySystem: code,
            Amount: item.Total,
          };
          this.totals.push(total);
        }
      }
    }
  }

  handleCalcSupplier(): void {
    this.totalsSupplier = [];
    for (const item of this.listSupplier.Data?.Items ?? []) {
      item.Total = undefined;
      if (
        item.Amount != undefined &&
        (item.FirstChargingNumber != undefined ||
          item.SecondChargingNumber != undefined)
      ) {
        if (
          item.FirstChargingNumber != undefined &&
          item.SecondChargingNumber != undefined
        ) {
          item.Total =
            item.Amount * item.FirstChargingNumber * item.SecondChargingNumber;
        } else if (item.FirstChargingNumber != undefined) {
          item.Total = item.Amount * item.FirstChargingNumber;
        } else if (item.SecondChargingNumber != undefined) {
          item.Total = item.Amount * item.SecondChargingNumber;
        }
      }
      if (item.Total && item.CurrencySystem?.Code) {
        const code = item.CurrencySystem.Code;
        let total = this.totalsSupplier.firstOrDefault(
          (it) => it.CurrencySystem == code
        );
        if (total) {
          total.Amount += item.Total;
        } else {
          total = {
            CurrencySystem: code,
            Amount: item.Total,
          };
          this.totalsSupplier.push(total);
        }
      }
    }
  }
  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        CurrentLogier.LinkWithBack(
          `/price/quotation-sheet/edit/${this.id}?supplierName=${this.supplierName}`
        );
        complete();
        break;
      case "delete":
        PriceQuotationSheetAppService.Delete([this.id as string])
          .then(() => {
            CurrentLogier.Back();
            complete();
          })
          .catch(complete);
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
