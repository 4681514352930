import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_form_item = _resolveComponent("miezz-form-item")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_miezz_card = _resolveComponent("miezz-card")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleForm",
    class: "ruleForm",
    model: _ctx.modelValue.Data,
    rules: _ctx.modelValue.Rules,
    "label-width": _ctx.modelValue.LabelWidth ? _ctx.modelValue.LabelWidth : '150px',
    inline: false,
    "label-position": _ctx.modelValue.LabelPosition
  }, {
    default: _withCtx(() => [
      (_ctx.modelValue.Data.UiGroups && _ctx.modelValue.Data.UiGroups.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.Data.UiGroups, (g, gi) => {
              return (_openBlock(), _createBlock(_component_miezz_card, {
                shadow: "always",
                key: gi,
                class: _normalizeClass(`form-group ${g.Hide ? 'hide' : 'show'}`),
                title: g.Label,
                description: g.Description,
                isTitle: _ctx.modelValue.IsTitle,
                fix: _ctx.modelValue.Data.UiGroups.length == 1,
                fixTop: _ctx.modelValue.FixTop,
                topButtons: g.Buttons,
                toggle: g.Toggle
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, { style: {"padding-right":"20px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_form_item, {
                        modelValue: _ctx.modelValue.Data,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.Data) = $event)),
                        defaultSpan: _ctx.modelValue.DefaultSpan,
                        powerCode: _ctx.modelValue.PowerCode,
                        items: _ctx.modelValue.Items?.filter((it) => it.Group == g.Code)
                      }, {
                        "form-item": _withCtx(({ item }) => [
                          _renderSlot(_ctx.$slots, "form-item", { item: item })
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "defaultSpan", "powerCode", "items"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["class", "title", "description", "isTitle", "fix", "fixTop", "topButtons", "toggle"]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_el_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_form_item, {
                modelValue: _ctx.modelValue.Data,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.Data) = $event)),
                defaultSpan: _ctx.modelValue.DefaultSpan,
                powerCode: _ctx.modelValue.PowerCode,
                items: _ctx.modelValue.Items
              }, {
                "form-item": _withCtx(({ item }) => [
                  _renderSlot(_ctx.$slots, "form-item", { item: item })
                ]),
                _: 3
              }, 8, ["modelValue", "defaultSpan", "powerCode", "items"])
            ]),
            _: 3
          }))
    ]),
    _: 3
  }, 8, ["model", "rules", "label-width", "label-position"]))
}