import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";
import UiGroup from "./UiGroup";

/**
 * 查询条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestBill extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsSave = false;
        this.Visiable = false;
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 类型
     */
    Type?: EnumBillType;
    /**
     * 是否直接保存
     */
    IsSave?: boolean;
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
    /**
     * 模板名称
     */
    Name?: string;
    /**
     * 账单主键集合
     */
    BillIds?: string[];
    /**
     * 是否分摊
     */
    IsShare?: boolean;
    /**
     * 是否导入
     */
    IsImport?: boolean;
    /**
     * 导出类型
     */
    ExportType?: EnumBillType;
    /**
     * 是否需要查询账期
     */
    IsAccount?: boolean;
    /**
     * 数量
     */
    Number?: number;
    /**
     * 能不能导出应收账单
     */
    IsCollection?: boolean;
    /**
     * 能不能导出应付账单
     */
    IsPayment?: boolean;
    /**
     * 能不能导出结算价账单
     */
    IsSaler?: boolean;
}

/**
 * 查询条件-表单校验
 */
export const DtoRequestBillRules = {
}

/**
 * 查询条件-设置项
 */
export const DtoRequestBillMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "PoolId",
    Label: "委托书主键",
    Customize: true,
  },
  {
    Prop: "OrderId",
    Label: "订单主键",
    Customize: true,
  },
  {
    Prop: "Type",
    Label: "类型",
    Customize: true,
  },
  {
    Prop: "IsSave",
    Label: "是否直接保存",
    Customize: true,
  },
  {
    Prop: "Visiable",
    Label: "弹出框是否显示",
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "模板名称",
    Customize: true,
  },
  {
    Prop: "BillIds",
    Label: "账单主键集合",
    Customize: true,
  },
  {
    Prop: "IsShare",
    Label: "是否分摊",
    Customize: true,
  },
]
