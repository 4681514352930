
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";
import DtoPageListSearchPriceService from "@/models.machine/DtoPageListSearchPriceService";
import DtoPageListPriceService from "@/models.machine/DtoPageListPriceService";
import DtoListItemPriceService, {
  DtoListItemPriceServiceMiezzListItems,
} from "@/models.machine/DtoListItemPriceService";
import PriceServiceForm from "./PriceServiceForm.vue";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import PriceSetUp from "../PriceSetUp.vue";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import CurrentLogier from "@/apis/CurrentLogier";
@Options({
  components: {
    PriceSetUp,
    PriceServiceForm,
  },
})
export default class PriceServicePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchPriceService,
    DtoListItemPriceService,
    string
  >("PriceManagement_SetUp_Service");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();
  detailBtn: MiezzButton = {
    Code: "detail",
    Label: "查看详情",
    Type: "text",
    Size: "small",
    CancelPadding: true,
    PowerCode: "PriceManagement_SetUp_Service_Detail",
  };
  menuTab = new MiezzMenuTab();

  /**创建时 */
  created(): void {
    this.list.Type = MiezzPageListType.Card;
    this.list.FixTop = "30px";
    this.list.CardTitleProp = "Name";
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.CardSpan = 20;
    //列表-传参
    this.list.Dto = new DtoPageListSearchPriceService();
    //列表-数据-初始
    this.list.Data = new DtoPageListPriceService();
    //列表-列配置
    this.list.Items = DtoListItemPriceServiceMiezzListItems;
    //列表-顶部按钮
    this.menuTab.RightButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Service_AddEdit",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "on-off",
        Title: "开启/关闭",
        Type: "checkbox",
        Size: "small",
        CheckProp: "Open",
        PowerCode: "PriceManagement_SetUp_Service_OnOff",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Service_AddEdit",
      },
      // {
      //   Code: "delete",
      //   Title: "删除",
      //   Icon: "delete",
      //   Type: "default",
      //   Message: "是否确认删除",
      //   Size: "small",
      //   PowerCode: "PriceManagement_SetUp_Service_Delete",
      // },
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        PowerCode: "PriceManagement_SetUp_Service_Delete",
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    PriceServiceAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增服务种类";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPriceService,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      case "detail":
        CurrentLogier.LinkWithBack(`/price/setup/service/detail/${this.id}`);
        complete();
        break;
      case "on-off":
        PriceServiceAppService.OpenByIdByOpen(this.id, btn.Check)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑服务种类-${row.Name}`;
        complete();
        break;
      case "delete":
        PriceServiceAppService.Delete([this.id as string])
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        ElMessageBox.confirm("是否确认将该信息删除？", "提醒", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            PriceServiceAppService.Delete(this.list.SelectIds)
              .then(() => {
                this.handleGet();
                complete();
              })
              .catch(complete);
          })
          .catch(() => {
            complete();
          });
        break;
      default:
        break;
    }
  }

  /**拖曳排序 */
  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    PriceServiceAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  /**提交表单回调 */
  handleSubmit(): void {
    // this.modalForm.Show = false;
    this.handleGet();
  }
}
