
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import PriceQuotationSheetAppService from "@/apis.machine/PriceQuotationSheetAppService";
import DtoPageListSearchPriceQuotationSheet, {
  DtoPageListSearchPriceQuotationSheetDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchPriceQuotationSheet";
import DtoPageListPriceQuotationSheet from "@/models.machine/DtoPageListPriceQuotationSheet";
import DtoListItemPriceQuotationSheet, {
  DtoListItemPriceQuotationSheetMiezzListItems,
} from "@/models.machine/DtoListItemPriceQuotationSheet";
import moment from "moment";
import CurrentLogier from "@/apis/CurrentLogier";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumPriceQuotationSheetStatusOption } from "@/models.machine/EnumPriceQuotationSheetStatus";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import { EnumCompare } from "@/models.machine/EnumCompare";
import { EnumUiDynamicSearchItemType } from "@/models.machine/EnumUiDynamicSearchItemType";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import Logier from "@/models.machine/Logier";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
export default class PriceInquiryPageList extends Vue {
  @Prop() fixTop?: string;
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchPriceQuotationSheet,
    DtoListItemPriceQuotationSheet,
    string
  >("PriceManagement_Inquiry");
  /**当前Id */
  id?: string;
  logier?: Logier;

  users: UiSelectOption<string>[] = [];
  types: UiSelectOptionWithStringValue[] = [];
  EnumPriceQuotationSheetStatusOption = EnumPriceQuotationSheetStatusOption;
  serviceRemarks: UiSelectOptionWithStringValue[] = [];

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    this.list.FixTop = this.fixTop;
    this.list.Back = this.$route.query.backUrl != undefined;
    //列表-传参
    this.list.Dto = new DtoPageListSearchPriceQuotationSheet();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchPriceQuotationSheetDynamicSearchItems.copy().filter(
        (it) =>
          it.Prop != "DynamicSearch_Company" &&
          it.Prop != "DynamicSearch_Status"
      );
    const createdUserId = this.list.DynamicSearchItems.firstOrDefault(
      (it) => it.Prop == "DynamicSearch_CreatedCompanyUserId"
    );
    if (createdUserId) {
      createdUserId.Type = EnumUiDynamicSearchItemType.InputText;
      createdUserId.Compares = [EnumCompare.Contain, EnumCompare.NoContain];
    }
    //列表-数据-初始
    this.list.Data = new DtoPageListPriceQuotationSheet();
    //列表-列配置
    this.list.Items = DtoListItemPriceQuotationSheetMiezzListItems.filter(
      (it) =>
        it.Prop != "InfoCollector" &&
        it.Prop != "Company" &&
        it.Prop != "Status"
    );
    this.list.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "User":
          return row.User
            ? `${row.User?.Name ?? "暂无数据"}/${
                row.User?.User?.PhoneNumber ?? "暂无数据"
              }`
            : "暂无数据";
        case "CreatedUserName":
          return `${row.CreatedUserName ?? "暂无数据"}/${
            row.CreatedPhoneNumber ?? "暂无数据"
          }`;
        case "ServiceId":
          return row.Service?.Name;
        case "ValidDateStart":
          return row.ValidDateStart && row.ValidDateEnd
            ? `${
                moment(row.ValidDateStart)?.format("YYYY/MM/DD") ?? "暂无数据"
              } - ${
                moment(row.ValidDateEnd)?.format("YYYY/MM/DD") ?? "暂无数据"
              }`
            : "暂无数据";
        default:
          break;
      }
    };
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Inquiry_Detail",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "PriceManagement_Inquiry_Export",
      },
    ];
    this.list.HandleRowClass = (row) => {
      return row.ChangeContentTime ? "update" : "";
    };

    PriceQuotationSheetAppService.GetServices().then((r) => {
      this.types = r.data.Data ?? [];
    });
    OrderPoolAppService.GetServiceRemarks().then((r) => {
      this.serviceRemarks = (r.data.Data ?? []).filter(
        (it) => it.Label != "其他"
      );
    });
  }

  /**查询列表 */
  handleGet(): void {
    PriceQuotationSheetAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;

      if (
        this.logier?.CompanyType == EnumCompanyType.Customer &&
        this.list.Data?.ChangeContentTime
      ) {
        const changedTimeStr = localStorage.getItem(
          "price-inquiry-changed-time"
        );
        if (
          !changedTimeStr ||
          moment(changedTimeStr) < moment(this.list.Data?.ChangeContentTime)
        ) {
          ElMessage.warning("服务商更新了您的询盘，请及时查看");
          localStorage.setItem(
            "price-inquiry-changed-time",
            this.list.Data?.ChangeContentTime
          );
        }
      }
    });
  }

  handleRowDbClick(row: DtoListItemPriceQuotationSheet): void {
    this.id = row.Id;
    CurrentLogier.LinkWithBack(`/price/inquiry/${this.id}`);
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPriceQuotationSheet,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      //详细
      case "detail":
        CurrentLogier.LinkWithBack(`/price/inquiry/${this.id}`);
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "export":
        PriceQuotationSheetAppService.ExportZip(this.list.SelectIds)
          .then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
