import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66e41a4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_price_voyage_choose = _resolveComponent("price-voyage-choose")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!

  return (_openBlock(), _createBlock(_component_miezz_page_list, {
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
    onHandleGet: _ctx.handleGet,
    onHandleTopButtonClick: _ctx.handleTopButtonClick,
    onHandleRowButtonClick: _ctx.handleRowButtonClick,
    onHandleBottomButtonClick: _ctx.handleBottomButtonClick,
    onHandleChangeWhere: _ctx.handleChangeWhere,
    onHandleRowDbClick: _ctx.handleRowDbClick
  }, {
    "dynamic-search-customize": _withCtx(({ model }) => [
      (model.Where.Prop == 'DynamicSearch_ServiceId')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 0,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.services
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_ServiceId')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 1,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.services
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : (model.Where.Prop == 'DynamicSearch_ServiceRemark')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 2,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.serviceRemarks,
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : (model.Where.Prop == 'DynamicSearch_Status')
            ? (_openBlock(), _createBlock(_component_miezz_select, {
                key: 3,
                modelValue: model.Where.Value,
                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                options: _ctx.EnumPriceQuotationSheetStatusOption,
                clearable: false,
                number: true
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
            : (model.Where.Prop.startsWith('Voyage'))
              ? (_openBlock(), _createBlock(_component_price_voyage_choose, {
                  key: 4,
                  modelValue: model.Where.Value,
                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : (model.Where.Prop.startsWith('SailingDate'))
                ? (_openBlock(), _createBlock(_component_el_date_picker, {
                    key: 5,
                    modelValue: model.Where.Value,
                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                    type: "date",
                    format: "YYYY/MM/DD",
                    "value-format": "YYYY/MM/DD",
                    class: "form-item-date"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createBlock(_component_miezz_select, {
                    key: 6,
                    modelValue: model.Where.Value,
                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                    options: _ctx.searchs[model.Where.Prop],
                    remote: true,
                    remoteMethod: (q) => _ctx.querySearch(model.Where.Prop, q),
                    onFocus: ($event: any) => (_ctx.querySearch(model.Where.Prop, ''))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "remoteMethod", "onFocus"]))
    ]),
    column: _withCtx(({ model, row }) => [
      (
          model.Prop == 'Stage' &&
          _ctx.powerCodes.contains('PriceManagement_QuotationSheet_ChangeStage')
        )
        ? (_openBlock(), _createBlock(_component_miezz_button, {
            key: 0,
            model: {
          Code: 'stage',
          Label:
            _ctx.EnumPriceQuotationSheetStageOption.firstOrDefault(
              (it) => it.Value == row.Stage
            )?.Label ?? '暂无数据',
          Type: 'text',
          Size: 'small',
          Options: _ctx.EnumPriceQuotationSheetStageOption.map((it) => {
            return {
              Code: it.Value?.toString(),
              Label: it.Label,
              Size: 'small',
            };
          }),
        },
            style: {"color":"#fe995b"},
            onHandleClick: (arg1, arg2) => _ctx.handleRowButtonClick(arg1, row, arg2)
          }, null, 8, ["model", "onHandleClick"]))
        : (model.Prop == 'Stage')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.EnumPriceQuotationSheetStageOption.firstOrDefault(
            (it) => it.Value == row.Stage
          )?.Label ?? "暂无数据"), 1))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick", "onHandleChangeWhere", "onHandleRowDbClick"]))
}