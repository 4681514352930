
    import MiezzForm from "@/models/MiezzForm";
    import moment from "moment";
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import DtoAutoAddWharf from "@/models.machine/DtoAutoAddWharf";
    import DtoPageListSearchOrderDepartureWharf, { DtoPageListSearchOrderDepartureWharfDynamicSearchItems } from "@/models.machine/DtoPageListSearchOrderDepartureWharf";
    import DtoPageListOrderDepartureWharf from "@/models.machine/DtoPageListOrderDepartureWharf";
    import DtoListItemOrderDepartureWharf, {
        DtoListItemOrderDepartureWharfMiezzListItems,
    } from "@/models.machine/DtoListItemOrderDepartureWharf";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import {
        EnumOrderServiceType
    } from "@/models.machine/EnumOrderServiceType";
    import { EnumWharfBatchType } from "@/models.machine/EnumWharfBatchType";
    import {
        EStockType
    } from "@/models.machine/EStockType";
    import { ElMessageBox } from "element-plus";
    import DtoPutStockTime, {
        DtoPutStockTimeMiezzFormItems,
        DtoPutStockTimeRules,
    } from "@/models.machine/DtoPutStockTime";
    import DtoBatchWharf, {
        DtoBatchWharfMiezzFormItems,
        DtoBatchWharfRules,
    } from "@/models.machine/DtoBatchWharf";
    import DtoRequestTime from "@/models.machine/DtoRequestTime";
    import DtoSynchronizePoolData from "@/models.machine/DtoSynchronizePoolData";
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import DtoPutOrderDepartureWharfAnnex, {
        DtoPutOrderDepartureWharfAnnexMiezzFormItems,
        DtoPutOrderDepartureWharfAnnexRules,
    } from "@/models.machine/DtoPutOrderDepartureWharfAnnex";
    import OrderAppService from "@/apis.machine/OrderAppService";
    import DtoPageListSearchOrderDepartureWharfHistory, { DtoPageListSearchOrderDepartureWharfHistoryDynamicSearchItems } from "@/models.machine/DtoPageListSearchOrderDepartureWharfHistory";
    import DtoPageListOrderDepartureWharfHistory from "@/models.machine/DtoPageListOrderDepartureWharfHistory";
    import DtoListItemOrderDepartureWharfHistory, {
        DtoListItemOrderDepartureWharfHistoryMiezzListItems,
    } from "@/models.machine/DtoListItemOrderDepartureWharfHistory";
    import OrderDepartureWharfHistoryAppService from "@/apis.machine/OrderDepartureWharfHistoryAppService";
    import DtoRequestBoxSticker from "@/models.machine/DtoRequestBoxSticker";
    import OrderSummaryTime from "../OrderSummaryTime.vue";
    import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
    import Logier from "@/models.machine/Logier";
    @Options({
        components: {
            OrderSummaryTime,
        },
    })
    export default class OrderDepartureWharfPageList extends Vue {
        logier?: Logier;
        EnumCompanyType = EnumCompanyType;
        isHistory = true;
        /**分页列表 */
        historys = new MiezzPageList<DtoPageListSearchOrderDepartureWharfHistory, DtoListItemOrderDepartureWharfHistory, string>();
        isLoading = false;
        client?: OSS;
        menuTab = new MiezzMenuTab();
        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchOrderDepartureWharf, DtoListItemOrderDepartureWharf, string>();
        /*委托书主键 */
        id = "";
        /*订单主键 */
        orderId = "";
        task = "";
        EnumOrderServiceType = EnumOrderServiceType;
        EnumWharfBatchType = EnumWharfBatchType;
        _orderId?: string = "";
        isDetail = true;
        /*具有的权限 */
        isEdit = false;
        isDelete = false;
        selectWharfId?: string;

        batchFromRandom = 0;
        batchForm = new MiezzForm<DtoBatchWharf, string>();

        isLock = false; //订单是否锁定
        paramAutoAdd = new DtoAutoAddWharf();

        /*类型：0表示委托书堆场；1表示订单堆场 */
        wharfType = 0;
        /*true表示委托书或者直订订单，false表示配载订单 */
        directOrder = true;

        //是否有箱贴
        isBoxSticker = false;
        type = EnumOrderServiceType.DeparturePort;
        timeRuleForm = new MiezzForm<DtoPutStockTime, string>();

        planInStockTimeRuleForm = new MiezzForm<DtoPutStockTime, string>();

        batchFromRules = {
            StockTime: [{
                required: true,
                message: "信息不能为空",
                trigger: "blur",
            }],
        };
        timeBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        planInStockTimeBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        oneSupplier = 0;
        batchFormBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        currentAnnex = {};
        annexRuleForm = new MiezzForm<DtoPutOrderDepartureWharfAnnex, string>();
        annexBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        onProcess(row: any): void {
            ElMessage.warning("暂无数据，请向供应商询问详细进程");
        }

        onGoods(row: any): void {
            if (this.wharfType == 0) {
                if (this.type == EnumOrderServiceType.DeparturePort) {
                    let routeUrl = this.$router.resolve({
                        path: "/order/pool/departure-port-wharf/" + row.PoolId + "/cargos/" + row.Id,
                        query: { backUrl: this.$route.fullPath },
                    });
                    //window.open(routeUrl.href);
                    window.location.href = routeUrl.href;
                }
                else if (this.type == EnumOrderServiceType.DestinationPort) {
                    let routeUrl = this.$router.resolve({
                        path: "/order/pool/destination-port-wharf/" + row.PoolId + "/cargos/" + row.Id,
                        query: { backUrl: this.$route.fullPath },
                    });
                    //window.open(routeUrl.href);
                    window.location.href = routeUrl.href;
                }
            }
            else {
                if (this.type == EnumOrderServiceType.DeparturePort) {
                    let routeUrl = this.$router.resolve({
                        path: "/order/departure-port-wharf/" + row.PoolId + "/cargos/" + row.Id,
                        query: { backUrl: this.$route.fullPath },
                    });
                    //window.open(routeUrl.href);
                    window.location.href = routeUrl.href;
                }
                else if (this.type == EnumOrderServiceType.DestinationPort) {
                    let routeUrl = this.$router.resolve({
                        path: "/order/destination-port-wharf/" + row.PoolId + "/cargos/" + row.Id,
                        query: { backUrl: this.$route.fullPath },
                    });
                    //window.open(routeUrl.href);
                    window.location.href = routeUrl.href;
                }
            }
        }

        onBatchInStockTime(): void {

            this.batchForm.Data = new DtoBatchWharf();
            this.batchForm.Data.Type = EnumWharfBatchType.AllInstock;
            this.batchForm.Data.Service = this.type;
            this.batchForm.Data.Title = "批量编辑全部入库日期";
            this.batchForm.Data.Label = "全部入库日期";
            this.batchForm.Data.OrderId = this.orderId;

            OrderDepartureWharfAppService.GetBatch(this.batchForm.Data).then((r) => {
                if (r.data.Data) {
                    if (r.data.Data.IsAllInstock != true) {
                        ElMessage.warning("请先完善入库货量");
                        return;
                    }

                    this.batchForm.Data.StockTime = r.data.Data.CurrentTime;
                    this.batchForm.Data.Visiable = true;
                    this.batchForm.Data.CheckTime = r.data.Data.CheckTime;
                    this.batchForm.Data.IsAllInstock = r.data.Data.IsAllInstock;
                    this.batchForm.Data.IsAllOutstock = r.data.Data.IsAllOutstock;
                    //表单-校验
                    this.batchForm.Rules = DtoBatchWharfRules;
                    //表单-元素配置
                    this.batchForm.Items = DtoBatchWharfMiezzFormItems;
                    this.batchForm.Rules.StockTime = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "change",
                        },
                        {
                            required: true,
                            validator: (rule: any, value: string, callback: any) => {
                                if (this.batchForm.Data.CheckTime == null || this.batchForm.Data.CheckTime == undefined || typeof (this.batchForm.Data.CheckTime) == "undefined" || moment(this.batchForm.Data.CheckTime).isSame(value)) {
                                    callback();
                                    return false;
                                }
                                else if (!moment(this.batchForm.Data.CheckTime).isAfter(value)) {
                                    callback("入库时间不能晚于出库时间！");
                                    return false;
                                }
                                else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ];
                    this.$nextTick(() => {
                        MiezzForm.clearValidate(this.$refs, "refBatchForm", "StockTime");
                        this.batchFromRandom = Math.random();
                    });

                }
            })

        }

        onBatchInStock(): void {


            this.batchForm.Data = new DtoBatchWharf();
            this.batchForm.Data.Type = EnumWharfBatchType.Instock;
            this.batchForm.Data.Service = this.type;
            //this.batchForm.Data.Visiable = true;
            this.batchForm.Data.Title = "全部入库";
            this.batchForm.Data.Label = "入库日期";
            this.batchForm.Data.OrderId = this.orderId;
            ////表单-校验
            //this.batchForm.Rules = DtoBatchWharfRules;
            ////表单-元素配置
            //this.batchForm.Items = DtoBatchWharfMiezzFormItems;
            //this.batchForm.Rules.StockTime = [
            //    {
            //        required: true,
            //        message: "信息不能为空",
            //        trigger: "change",
            //    },
            //];
            //this.$nextTick(() => {
            //    MiezzForm.clearValidate(this.$refs, "refBatchForm", "StockTime");
            //    this.batchFromRandom = Math.random();
            //});

            this.isLoading = true;
            OrderDepartureWharfAppService.BatchEdit(this.batchForm.Data).then((r) => {
                //this.batchForm.Data.Visiable = false;
                ElMessage.success("全部入库成功");
                this.handleGet();
            })
        }

        onBatchOutStock(): void {
            //this.batchForm.Data = new DtoBatchWharf();
            //this.batchForm.Data.Type = EnumWharfBatchType.Outstock;
            //this.batchForm.Data.Service = this.type;
            //this.batchForm.Data.OrderId = this.orderId;
            //this.isLoading = true;
            //OrderDepartureWharfAppService.BatchEdit(this.batchForm.Data)
            //    .then(() => {
            //        ElMessage.success("批量出库成功");
            //        this.handleGet();
            //    })
            //    .catch((err) => {
            //        console.log(err);
            //    });

            this.batchForm.Data = new DtoBatchWharf();
            this.batchForm.Data.Type = EnumWharfBatchType.Outstock;
            this.batchForm.Data.Service = this.type;
            this.batchForm.Data.Title = "全部出库";
            this.batchForm.Data.Label = "出库日期";
            this.batchForm.Data.OrderId = this.orderId;


            OrderDepartureWharfAppService.GetBatch(this.batchForm.Data).then((r) => {
                if (r.data.Data) {
                    if (r.data.Data.IsAllInstock != true) {
                        ElMessage.warning("请先完善入库货量");
                        return;
                    }
                    //this.batchForm.Data.Visiable = true;
                    this.batchForm.Data.CheckTime = r.data.Data.CheckTime;
                    this.batchForm.Data.IsAllInstock = r.data.Data.IsAllInstock;
                    this.batchForm.Data.IsAllOutstock = r.data.Data.IsAllOutstock;


                    this.isLoading = true;
                    OrderDepartureWharfAppService.BatchEdit(this.batchForm.Data).then((r) => {
                        //this.batchForm.Data.Visiable = false;
                        ElMessage.success("全部出库成功");
                        this.handleGet();
                    })

                }
            })
        }

        onBatchOutStockTime(): void {
            //this.batchForm.Data = new DtoBatchWharf();
            //this.batchForm.Data.Type = EnumWharfBatchType.AllOutstock;
            //this.batchForm.Data.Service = this.type;
            //this.batchForm.Data.Visiable = true;
            //this.batchForm.Data.Title = "批量编辑全部出库日期";
            //this.batchForm.Data.Label = "全部出库日期";
            //this.batchForm.Data.OrderId = this.orderId;
            ////表单-校验
            //this.batchForm.Rules = DtoBatchWharfRules;
            ////表单-元素配置
            //this.batchForm.Items = DtoBatchWharfMiezzFormItems;



            this.batchForm.Data = new DtoBatchWharf();
            this.batchForm.Data.Type = EnumWharfBatchType.AllOutstock;
            this.batchForm.Data.Service = this.type;
            this.batchForm.Data.Title = "批量编辑全部出库日期";
            this.batchForm.Data.Label = "全部出库日期";
            this.batchForm.Data.OrderId = this.orderId;

            OrderDepartureWharfAppService.GetBatch(this.batchForm.Data).then((r) => {
                if (r.data.Data) {

                    if (r.data.Data.IsAllOutstock != true) {
                        ElMessage.warning("请先完善出库货量");
                        return;
                    }

                    this.batchForm.Data.Visiable = true;
                    this.batchForm.Data.CheckTime = r.data.Data.CheckTime;
                    this.batchForm.Data.IsAllInstock = r.data.Data.IsAllInstock;
                    this.batchForm.Data.IsAllOutstock = r.data.Data.IsAllOutstock;
                    //表单-校验
                    this.batchForm.Rules = DtoBatchWharfRules;
                    //表单-元素配置
                    this.batchForm.Items = DtoBatchWharfMiezzFormItems;
                    this.batchForm.Data.StockTime = r.data.Data.CurrentTime;
                    this.batchForm.Rules.StockTime = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "change",
                        },
                        {
                            required: true,
                            validator: (rule: any, value: string, callback: any) => {
                                if (this.batchForm.Data.CheckTime == null || this.batchForm.Data.CheckTime == undefined || typeof (this.batchForm.Data.CheckTime) == "undefined" || moment(this.batchForm.Data.CheckTime).isSame(value)) {
                                    callback();
                                    return false;
                                }
                                else if (!moment(this.batchForm.Data.CheckTime).isBefore(value)) {
                                    callback("出库时间不能早于入库时间！");
                                    return false;
                                }
                                else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ];
                    this.$nextTick(() => {
                        MiezzForm.clearValidate(this.$refs, "refBatchForm", "StockTime");
                        this.batchFromRandom = Math.random();
                    });
                }
            })
        }
        onPlanInStockTime(row: any): void {
 
            this.planInStockTimeRuleForm.Data = new DtoPutStockTime();
            //表单-校验
            this.planInStockTimeRuleForm.Rules = DtoPutStockTimeRules;
            //表单-元素配置
            this.planInStockTimeRuleForm.Items = DtoPutStockTimeMiezzFormItems;

            this.planInStockTimeRuleForm.Data.OrderDepartureWharfId = row.Id;
            this.planInStockTimeRuleForm.Data.Label = "计划入货时间";
            this.planInStockTimeRuleForm.Data.Title = "计划入货时间";
            this.planInStockTimeRuleForm.Data.Time = row.PlanInStockTime;
            this.planInStockTimeRuleForm.Data.CheckTime = row.PlanInStockTime;
            this.planInStockTimeRuleForm.Data.Visiable = true;

         

            this.$nextTick(() => {
                this.planInStockTimeRuleForm.Rules.Time = [
                    {
                        required: true,
                        message: "信息不能为空",
                        trigger: "change",
                    },
                ];
                MiezzForm.clearValidate(this.$refs, "refPlanInStockTimeRuleForm", "Time");
                this.batchFromRandom = Math.random();
            });
        }

        onInStockTime(row: any): void {
            if (row.SumQuantityInStock == null || row.SumQuantityInStock == undefined) {
                ElMessage.warning("请先完善入库货量");
                return;
            }

            var param = new DtoRequestTime();
            param.IsInStock = true;
            param.OrderDepartureWharfId = row.Id;
            OrderDepartureWharfAppService.GetTime(param).then((r) => {
                if (r.data.Data) {
                    this.timeRuleForm.Data = new DtoPutStockTime();
                    //表单-校验
                    this.timeRuleForm.Rules = DtoPutStockTimeRules;
                    //表单-元素配置
                    this.timeRuleForm.Items = DtoPutStockTimeMiezzFormItems;

                    this.timeRuleForm.Data.OrderDepartureWharfId = row.Id;
                    this.timeRuleForm.Data.Label = "入库日期";
                    this.timeRuleForm.Data.Title = "全部入库日期";
                    this.timeRuleForm.Data.Time = r.data.Data.CallbackTime;
                    this.timeRuleForm.Data.CheckTime = r.data.Data.CheckTime;
                    this.timeRuleForm.Data.Type = EStockType.In;
                    this.timeRuleForm.Data.Visiable = true;

                    this.timeRuleForm.Rules.Time = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "change",
                        },
                        {
                            required: true,
                            validator: (rule: any, value: string, callback: any) => {
                                if (this.timeRuleForm.Data.CheckTime == null || this.timeRuleForm.Data.CheckTime == undefined || typeof (this.timeRuleForm.Data.CheckTime) == "undefined" || moment(this.timeRuleForm.Data.CheckTime).isSame(value)) {
                                    callback();
                                    return false;
                                }
                                else if (!moment(this.timeRuleForm.Data.CheckTime).isAfter(value)) {
                                    callback("入库时间不能晚于出库时间！");
                                    return false;
                                }
                                else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ];
                }
            })
        }

        onOutStockTime(row: any): void {



            if (row.SumQuantityOutStock == null || row.SumQuantityOutStock == undefined) {
                ElMessage.warning("请先完善出库货量");
                return;
            }

            var param = new DtoRequestTime();
            param.IsInStock = false;
            param.OrderDepartureWharfId = row.Id;
            OrderDepartureWharfAppService.GetTime(param).then((r) => {
                if (r.data.Data) {
                    this.timeRuleForm.Data = new DtoPutStockTime();
                    //表单-校验
                    this.timeRuleForm.Rules = DtoPutStockTimeRules;
                    //表单-元素配置
                    this.timeRuleForm.Items = DtoPutStockTimeMiezzFormItems;

                    this.timeRuleForm.Data.OrderDepartureWharfId = row.Id;
                    this.timeRuleForm.Data.Label = "出库日期";
                    this.timeRuleForm.Data.Title = "全部出库日期";
                    this.timeRuleForm.Data.Time = r.data.Data.CallbackTime;
                    this.timeRuleForm.Data.Type = EStockType.Out;
                    this.timeRuleForm.Data.CheckTime = r.data.Data.CheckTime;

                    this.timeRuleForm.Rules.Time = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "change",
                        },
                        {
                            required: true,
                            validator: (rule: any, value: string, callback: any) => {
                                if (this.timeRuleForm.Data.CheckTime == null || this.timeRuleForm.Data.CheckTime == undefined || typeof (this.timeRuleForm.Data.CheckTime) == "undefined" || moment(this.timeRuleForm.Data.CheckTime).isSame(value)) {
                                    callback();
                                    return false;
                                }
                                else if (!moment(value).isAfter(this.timeRuleForm.Data.CheckTime)) {
                                    callback("出库时间不能早于入库时间！");
                                    return false;
                                }
                                else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ];

                    this.timeRuleForm.Data.Visiable = true;
                }
            })
        }

        handleClickBatchForm(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.id != null && this.id != undefined && this.id != "") {
                        this.stateRequestParam.PoolId.push(this.id as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {
                                MiezzForm.submit(this.$refs, "refBatchForm", (v) => {
                                    if (v == true) {

                                        this.isLoading = true;
                                        OrderDepartureWharfAppService.BatchEdit(this.batchForm.Data).then((r) => {
                                            this.batchForm.Data.Visiable = false;
                                            if (this.batchForm.Data.Type == EnumWharfBatchType.AllInstock) {
                                                ElMessage.success("批量编辑全部入库日期成功");
                                            }
                                            else if (this.batchForm.Data.Type == EnumWharfBatchType.AllOutstock) {
                                                ElMessage.success("批量编辑全部出库日期成功");
                                            }
                                            this.handleGet();
                                            complete();
                                        })

                                    }
                                    else {
                                        complete();
                                    }
                                });
                            }
                        }
                    })

                    break;
                case "close":
                    this.batchForm.Data.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickPlanInStockTime(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.id != null && this.id != undefined && this.id != "") {
                        this.stateRequestParam.PoolId.push(this.id as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {
                                MiezzForm.submit(this.$refs, "refPlanInStockTimeRuleForm", (v) => {
                                    if (v == true) {

                                        OrderDepartureWharfAppService.EditPlanInStockTime(this.planInStockTimeRuleForm.Data).then((r) => {
                                            this.planInStockTimeRuleForm.Data.Visiable = false;
                                            if (this.planInStockTimeRuleForm.Data.Type == EStockType.In) {
                                                ElMessage.success("编辑计划入货时间成功");
                                            }
                                            else {
                                                ElMessage.success("编辑计划入货时间成功");
                                            }
                                            this.handleGet();
                                            complete();
                                        })

                                    }
                                    else {
                                        complete();
                                    }
                                });
                            }
                        }
                    })

                    break;
                case "close":
                    this.planInStockTimeRuleForm.Data.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickTime(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.id != null && this.id != undefined && this.id != "") {
                        this.stateRequestParam.PoolId.push(this.id as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {
                                MiezzForm.submit(this.$refs, "refTimeRuleForm", (v) => {
                                    if (v == true) {

                                        OrderDepartureWharfAppService.EditTime(this.timeRuleForm.Data).then((r) => {
                                            this.timeRuleForm.Data.Visiable = false;
                                            if (this.timeRuleForm.Data.Type == EStockType.In) {
                                                ElMessage.success("编辑全部入库日期成功");
                                            }
                                            else {
                                                ElMessage.success("编辑全部出库日期成功");
                                            }
                                            this.handleGet();
                                            complete();
                                        })

                                    }
                                    else {
                                        complete();
                                    }
                                });
                            }
                        }
                    })

                    break;
                case "close":
                    this.timeRuleForm.Data.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickAnnex(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.id != null && this.id != undefined && this.id != "") {
                        this.stateRequestParam.PoolId.push(this.id as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {
                                MiezzForm.submit(this.$refs, "refAnnexRuleForm", (v) => {
                                    if (v == true) {
                                        OrderDepartureWharfAppService.EditAnnex(this.annexRuleForm.Data).then((r) => {
                                            this.annexRuleForm.Data.Visiable = false;
                                            ElMessage.success("编辑附件成功");
                                            this.handleGet();
                                            complete();
                                        })

                                    }
                                    else {
                                        complete();
                                    }
                                });
                            }
                        }
                    })

                    break;
                case "close":
                    this.annexRuleForm.Data.Visiable = false;
                    this.handleGet();
                    complete();
                    break;
                default:
                    break;
            }
        }


        /**创建时 */
        created(): void {
            this.logier = CurrentLogier.GetLogier();
            this.task = this.$route.query.task as string;
            this.client = AliyunOSS.GetClient();

            //列表-传参
            this.historys.Dto = new DtoPageListSearchOrderDepartureWharfHistory();
            //列表-动态搜索-项
            this.historys.DynamicSearchItems = DtoPageListSearchOrderDepartureWharfHistoryDynamicSearchItems;
            //列表-数据-初始
            this.historys.Data = new DtoPageListOrderDepartureWharfHistory();


            if (this.$route && this.$route.matched) {
                for (var matched of this.$route.matched) {
                    if (matched.path.toLowerCase() == "/pool/departure/wharf/:id") {
                        this.type = EnumOrderServiceType.DeparturePort;
                        this.id = this.$route.params.id as string;
                        this.wharfType = 0;

                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = this.id;
                            this.historys.Dto.OrderId = undefined;
                        }
                    }
                    else if (matched.path.toLowerCase() == "/pool/destination/wharf/:id") {
                        this.type = EnumOrderServiceType.DestinationPort;
                        this.id = this.$route.params.id as string;
                        this.wharfType = 0;

                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = this.id;
                            this.historys.Dto.OrderId = undefined;
                        }
                    }
                    else if (matched.path.toLowerCase() == "/order/departure/wharf/:id") {
                        this.type = EnumOrderServiceType.DeparturePort;
                        this.orderId = this.$route.params.id as string;
                        this.wharfType = 1;

                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = undefined;
                            this.historys.Dto.OrderId = this.orderId;
                        }
                    }
                    else if (matched.path.toLowerCase() == "/order/destination/wharf/:id") {
                        this.type = EnumOrderServiceType.DestinationPort;
                        this.orderId = this.$route.params.id as string;
                        this.wharfType = 1;

                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = undefined;
                            this.historys.Dto.OrderId = this.orderId;
                        }
                    }
                }
            }

            this.isEdit = false;
            this.isDelete = false;
            this.isDetail = true;
            this.menuTab.Back = true;
            if (this.wharfType == 0) {
                if (this.type == EnumOrderServiceType.DeparturePort) {
                    this.menuTab.Url = `/pool/departure/wharf/${this.id}`;
                    this.menuTab.Menus = [
                        {
                            Label: "装货港-仓库/堆场/码头",
                            Url: `/pool/departure/wharf/${this.id}`,
                            Power: "OrderManagement_Pool_Summary_DeparturePortWarehouse",
                        },
                    ];
                }
                else if (this.type == EnumOrderServiceType.DestinationPort) {
                    this.menuTab.Url = `/pool/destination/wharf/${this.id}`;
                    this.menuTab.Menus = [
                        {
                            Label: "目的港-仓库/堆场/码头",
                            Url: `/pool/destination/wharf/${this.id}`,
                            Power: "OrderManagement_Pool_Summary_DestinationPortWarehouse",
                        },
                    ];
                }

                CurrentLogier.GetPowerCodes().then((r) => {
                    if (this.type == EnumOrderServiceType.DeparturePort) {

                        if (r?.contains("OrderManagement_Pool_Summary_DeparturePortWarehouse_AddTo")) {
                            this.isEdit = true;
                            this.isDetail = false;
                        }
                        if (r?.contains("OrderManagement_Pool_Summary_DeparturePortWarehouse_Delete")) {
                            this.isDelete = true;
                        }
                    }
                    else if (this.type == EnumOrderServiceType.DestinationPort) {
                        if (r?.contains("OrderManagement_Pool_Summary_DestinationPortWarehouse_AddTo")) {
                            this.isEdit = true;
                            this.isDetail = false;
                        }
                        if (r?.contains("OrderManagement_Pool_Summary_DestinationPortWarehouse_Delete")) {
                            this.isDelete = true;
                        }
                    }

                });
            }
            else if (this.wharfType == 1) {
                if (this.type == EnumOrderServiceType.DeparturePort) {
                    this.menuTab.Url = `/order/departure/wharf/${this.id}`;
                    this.menuTab.Menus = [
                        {
                            Label: "装货港-仓库/堆场/码头",
                            Url: `/order/departure/wharf/${this.id}`,
                            Power: "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf",
                        },
                    ];
                }
                else if (this.type == EnumOrderServiceType.DestinationPort) {
                    this.menuTab.Url = `/order/destination/wharf/${this.id}`;
                    this.menuTab.Menus = [
                        {
                            Label: "目的港-仓库/堆场/码头",
                            Url: `/order/destination/wharf/${this.id}`,
                            Power: "OrderManagement_Order_Summary_DestinationPortWarehouseStorageYardWharf",
                        },
                    ];
                }

                CurrentLogier.GetPowerCodes().then((r) => {
                    if (this.type == EnumOrderServiceType.DeparturePort) {

                        if (r?.contains("OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_AddTo")) {
                            this.isEdit = true;
                            this.isDetail = false;
                        }
                        if (r?.contains("OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_Delete")) {
                            this.isDelete = true;
                        }
                    }
                    else if (this.type == EnumOrderServiceType.DestinationPort) {
                        if (r?.contains("OrderManagement_Order_Summary_DestinationPortWarehouseStorageYardWharf_AddTo")) {
                            this.isEdit = true;
                            this.isDetail = false;
                        }
                        if (r?.contains("OrderManagement_Order_Summary_DestinationPortWarehouseStorageYardWharf_Delete")) {
                            this.isDelete = true;
                        }
                    }

                });
            }





            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchOrderDepartureWharf();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchOrderDepartureWharfDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListOrderDepartureWharf();
            //列表-列配置
            this.list.Items = DtoListItemOrderDepartureWharfMiezzListItems;
            //列表-顶部按钮
            this.list.TopButtons = [
            ];
            //列表-行按钮
            this.list.RowButtons = [
            ];
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    Message: "是否确认删除",
                },
            ];


            //this.isEdit = false;
            //this.isDelete = false;
            //this.isDetail = true;
            //CurrentLogier.GetPowerCodes().then((r) => {
            //    if (this.type == EnumOrderServiceType.DeparturePort) {

            //        if (r?.contains("OrderManagement_Pool_Summary_DeparturePortWarehouse_AddTo")) {
            //            this.isEdit = true;
            //            this.isDetail = false;
            //        }
            //        if (r?.contains("OrderManagement_Pool_Summary_DeparturePortWarehouse_Delete")) {
            //            this.isDelete = true;
            //        }
            //    }
            //    else if (this.type == EnumOrderServiceType.DestinationPort) {
            //        if (r?.contains("OrderManagement_Pool_Summary_DestinationPortWarehouse_AddTo")) {
            //            this.isEdit = true;
            //            this.isDetail = false;
            //        }
            //        if (r?.contains("OrderManagement_Pool_Summary_DestinationPortWarehouse_Delete")) {
            //            this.isDelete = true;
            //        }
            //    }

            //});

            this.list.FixTop = "40px";
            this.handleGet();


        }
        onAutoAddPlan(): void {
            this.handleGet();
        }

        /**查询列表 */
        handleGet(): void {
            if (this.list && this.list.Dto) {
                this.list.Dto.CurrentPage = 1;
                this.list.Dto.PageSize = 9999999;
                this.list.Dto.Type = this.type;
                if (this.wharfType == 0) {
                    this.list.Dto.OrderPoolId = this.id;
                    this.list.Dto.OrderId = undefined;
                }
                else if (this.wharfType == 1) {
                    this.list.Dto.OrderId = this.orderId;
                    this.list.Dto.OrderPoolId = undefined;
                }
            }
            this.isLoading = true;
            if (this.wharfType == 0) {
                //this.paramAutoAdd = new DtoAutoAddWharf();
                //this.paramAutoAdd.PoolId = this.id;
                //this.paramAutoAdd.Type = this.type;
                //this.paramAutoAdd.OrderId = undefined;
                //OrderDepartureWharfAppService.AutoAdd(this.paramAutoAdd).then((re) => {

                //})

                OrderDepartureWharfAppService.Get(this.list.Dto).then((r) => {
                    this._orderId = r.data.Data?.OrderId;
                    this.list.Data = r.data.Data;
                    this.onList();
                    this.isLoading = false;
                });

                if (this.historys.Dto) {
                    this.historys.Dto.CurrentPage = 1;
                    this.historys.Dto.PageSize = 5;
                    this.historys.Dto.Service = this.type;
                }
                OrderDepartureWharfHistoryAppService.Get(this.historys.Dto).then((r) => {
                    this.historys.Data = r.data.Data;
                });
            }
            else {

                //this.paramAutoAdd = new DtoAutoAddWharf();
                //this.paramAutoAdd.PoolId = undefined;
                //this.paramAutoAdd.Type = this.type;
                //this.paramAutoAdd.OrderId = this.orderId;
                //OrderDepartureWharfAppService.AutoAdd(this.paramAutoAdd).then((re) => {

                //})

                OrderDepartureWharfAppService.Get(this.list.Dto).then((r) => {
                    this._orderId = r.data.Data?.OrderId;
                    this.list.Data = r.data.Data;
                    this.onList();
                    this.isLoading = false;
                });
                if (this.historys.Dto) {
                    this.historys.Dto.CurrentPage = 1;
                    this.historys.Dto.PageSize = 5;
                    this.historys.Dto.Service = this.type;
                }
                OrderDepartureWharfHistoryAppService.Get(this.historys.Dto).then((r) => {
                    this.historys.Data = r.data.Data;
                });

                //OrderAppService.GetSummaryById(this.orderId).then((order) => {
                //    if (order.data.Data) {
                //        if (order.data.Data.DirectOrder != undefined) {
                //            this.directOrder = order.data.Data.DirectOrder as boolean;
                //        }
                //        if (this.directOrder == true && order.data.Data.Pools) {
                //            this.id = order.data.Data.Pools[0].Id as string;
                //            OrderDepartureWharfAppService.AutoAddByOrderPoolIdByType(this.id, this.type).then((re) => {
                //                OrderDepartureWharfAppService.Get(this.list.Dto).then((r) => {
                //                    this.list.Data = r.data.Data;
                //                    this.onList();
                //                });
                //            })
                //        }
                //        else {
                //            OrderDepartureWharfAppService.Get(this.list.Dto).then((r) => {
                //                this.list.Data = r.data.Data;
                //                this.onList();
                //            });
                //        }

                //        //this.directOrder = false;
                //        //OrderDepartureWharfAppService.Get(this.list.Dto).then((r) => {
                //        //    this.list.Data = r.data.Data;
                //        //    this.onList();
                //        //});
                //    }
                //})
            }

        }

        onList(): void {
            if (this.list.Data && this.list.Data.Items) {
                for (var it of this.list.Data.Items) {
                    it.InStockTimeString = "暂无数据";
                    it.OutStockTimeString = "暂无数据";
                    it.PlanInStockTimeString = "暂无数据";
                    it.IsInStockTime = true;
                    it.Supplier = "";
                    if (this.logier && this.logier.DisabledReadSupplier == true) {
                        if(this.logier.ServiceProviderCompanyNameZh != null && this.logier.ServiceProviderCompanyNameZh != undefined && this.logier.ServiceProviderCompanyNameZh != ""){
                            it.Supplier = this.logier.ServiceProviderCompanyNameZh;
                        }
                        else{
                            it.Supplier = this.logier.ServiceProviderCompanyNameEn;
                        }
                    }
                    else {
                        if (it.SupplierCompanyLabel != undefined && it.SupplierCompanyLabel != "" && it.SupplierCompanyContactLabel != undefined && it.SupplierCompanyContactLabel != "") {
                            it.Supplier = it.SupplierCompanyLabel + "/" + it.SupplierCompanyContactLabel;
                        } else {
                            if (it.SupplierCompanyLabel != undefined && it.SupplierCompanyLabel != "")
                                it.Supplier = it.SupplierCompanyLabel;
                            else if (it.SupplierCompanyContactLabel != undefined && it.SupplierCompanyContactLabel != "")
                                it.Supplier = it.SupplierCompanyContactLabel;
                        }
                    }

                    if (it.InStocks != undefined && it.InStocks.length > 0) {
                        it.IsInStockTime = false;
                    }
                    it.IsOutStockTime = true;
                    if (it.OutStocks != undefined && it.OutStocks.length > 0) {
                        it.IsOutStockTime = false;
                    }
                    if (it.InStockTime != undefined && it.InStockTime != null) {
                        it.InStockTimeString = moment(it.InStockTime).format("YYYY/MM/DD HH:mm");
                    }
                    if (it.OutStockTime != undefined && it.OutStockTime != null) {
                        it.OutStockTimeString = moment(it.OutStockTime).format("YYYY/MM/DD HH:mm");
                    }
                    if (it.PlanInStockTime != undefined && it.PlanInStockTime != null) {
                        it.PlanInStockTimeString = moment(it.PlanInStockTime).format("YYYY/MM/DD HH:mm");
                    }
                }
            }
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    complete();
                    break;
                default:
                    break;
            }
        }
        onMoreHistyory(): void {
            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = 1;
                this.historys.Dto.PageSize = 99999999;
            }
            this.isLoading = true;
            OrderDepartureWharfHistoryAppService.All(this.historys.Dto).then((r) => {
                this.isLoading = false;
                this.historys.Data = r.data.Data;
            });

        }

        onDelete(row: any): void {
            var count = 0;
            if (this.list && this.list.Data && this.list.Data.Items && row.IsShiftout!=true) {
                //ElMessage.warning("至少需要保留一条信息");
                //return;

                for (var it of this.list.Data.Items) {
                    if (it.IsShiftout != true) {
                        count++;
                    }
                }
            }
            if (count == 1) {
                ElMessage.warning("至少需要保留一条信息");
                return;
            }
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.id != null && this.id != undefined && this.id != "") {
                this.stateRequestParam.PoolId.push(this.id as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {
                        ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                            confirmButtonText: "确认",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {

                                OrderDepartureWharfAppService.Delete([row.Id as string])
                                    .then(() => {
                                        ElMessage.success("删除成功");
                                        this.handleGet();
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });

                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
            })
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemOrderDepartureWharf,
            complete: () => void
        ): void {
            switch (btn.Code) {
                default:
                    break;
            }
        }
        onAnnex(row: any): void {
            this.id = row.PoolId;


            this.currentAnnex = row;
            //OrderDepartureWharfAppService.IsBoxStickerByOrderPoolId(this.id).then((box) => {
            //    if (box.data.Data) this.isBoxSticker = box.data.Data;

            //})

            OrderDepartureWharfAppService.GetAnnexsByOrderDepartureWharfId(row.Id).then((r) => {

                if (r.data.Data != undefined) this.annexRuleForm.Data = r.data.Data;

                this.annexRuleForm.Data.IsDetail = this.isDetail;
                this.annexRuleForm.Data.AddDeparturePortWarehouse = row.AddDeparturePortWarehouse;

                //表单-校验
                this.annexRuleForm.Rules = DtoPutOrderDepartureWharfAnnexRules;
                //表单-元素配置
                this.annexRuleForm.Items = DtoPutOrderDepartureWharfAnnexMiezzFormItems;


                if (this.isDetail == true && this.annexBottomButtons) {

                    this.annexBottomButtons[0].Hide = true;
                }

                if (this.isDetail == true) {
                    if (this.annexRuleForm.Data.Annexs != undefined && this.annexRuleForm.Data.Annexs.length > 0) {
                        for (var it of this.annexRuleForm.Data.Annexs) {
                            it.Url = this.client?.signatureUrl(it.Url as string);
                        }
                    }
                }

                this.annexRuleForm.Data.Visiable = true;


            })


        }
        onInitAnnex() {
            this.onAnnex(this.currentAnnex);
            this.handleGet();
        }
        onBoxSticker(id: string): void {
            const BoxSticker = this.$refs["BoxSticker"] as any;
            BoxSticker.onInit(id);
        }
        onBoxStickerExport(row: any): void {
            this.isLoading = true;
            var param = new DtoRequestBoxSticker();
            param.OrderPoolId = this.id;
            param.OrderDepartureWharfId = row.OrderDepartureWharfId;
            OrderDepartureWharfAppService.BoxStickerExport(param)
                .then((r) => {
                    this.isLoading = false;
                    window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");

                    if (this.list.Data && this.list.Data.isAddStock) {
                        ElMessage.warning("当前委托书通过“库存建单”生成，如已在之前使用过箱贴，请勿重复生成");
                    }
                }).catch((err) => {
                    console.log(err);
                });
        }
        onWarehousingExport(row: any): void {
            this.isLoading = true;
            OrderDepartureWharfAppService.ExportWarehousingByOrderDepartureWharfId(row.OrderDepartureWharfId)
                .then((r) => {
                    this.isLoading = false;
                    window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                }).catch((err) => {
                    console.log(err);
                });
        }
        onWarehousing(id: string): void {
            const Warehousing = this.$refs["Warehousing"] as any;
            Warehousing.onInit(id);

        }
        onAdd(): void {
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.id != null && this.id != undefined && this.id != "") {
                this.stateRequestParam.PoolId.push(this.id as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {
                        if (this.id != null && this.id != undefined && this.id != "") {
                            OrderDepartureWharfAppService.GetFinishByPoolIdByType(this.id, this.type).then((r) => {
                                if (r.data.Data == false) {
                                    const WharfPlan = this.$refs["WharfPlan"] as any;
                                    WharfPlan.onInit(undefined, this.type, this.directOrder, this.id, this.orderId);
                                }
                                else {
                                    var url = encodeURIComponent(this.$route.fullPath);
                                    ElMessage.warning({
                                        customClass: "testMessage",
                                        dangerouslyUseHTMLString: true,
                                        message: '当前清单的货物计划送货数量已全部分配完成，如需添加请先至<a href="/#/order/pool/edit/' + this.id + '?from=wharf&backUrl=' + url + '" style="font-weight:900;" >委托书</a>中修改货物数据。',
                                    })
                                    return;
                                }
                            })
                        }
                        else {
                            const WharfPlan = this.$refs["WharfPlan"] as any;
                            WharfPlan.onInit(undefined, this.type, this.directOrder, this.id, this.orderId);
                        }
                    }
                }
            })

        }
        onPlan(row: any): void {

            const WharfPlan = this.$refs["WharfPlan"] as any;
            WharfPlan.onInit(row.Id, this.type, this.directOrder, row.PoolId, row.OrderId);
        }
        onInStock(row: any): void {
            this.id = row.PoolId;

            this.$nextTick(() => {
                const WharfInStock = this.$refs["WharfInStock"] as any;
                WharfInStock.onInit(row.Id, this.type);
            });


        }

        onBatchSupplier(): void {
            this.oneSupplier = 1;
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.id != null && this.id != undefined && this.id != "") {
                this.stateRequestParam.PoolId.push(this.id as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    this.isLock = false;
                    if (this.stateCallbback && this.stateCallbback.IsLock) {
                        this.isLock = this.stateCallbback.IsLock;
                    }
                    const Supplier = this.$refs["Supplier"] as any;
                    Supplier.onInit(undefined);
                }
            })
        }

        onSupplier(row: any): void {

            this.oneSupplier = 0;
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.id != null && this.id != undefined && this.id != "") {
                this.stateRequestParam.PoolId.push(this.id as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    this.isLock = false;
                    if (this.stateCallbback && this.stateCallbback.IsLock) {
                        this.isLock = this.stateCallbback.IsLock;
                    }

                    this.selectWharfId = row.Id;
                    const Supplier = this.$refs["Supplier"] as any;
                    Supplier.onInit(row);
                }
            })

        }
        onSelectSupplier(row: any): void {

            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.id != null && this.id != undefined && this.id != "") {
                this.stateRequestParam.PoolId.push(this.id as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {
                        if (this.oneSupplier == 0) {
                            OrderDepartureWharfAppService.EditSupplierByOrderDepartureWharfId(this.selectWharfId, row)
                                .then(() => {
                                    ElMessage.success("修改供应商成功");
                                    this.handleGet();
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                        else {
                            this.batchForm.Data = new DtoBatchWharf();
                            this.batchForm.Data.Type = EnumWharfBatchType.Supplier;
                            this.batchForm.Data.Service = this.type;
                            this.batchForm.Data.OrderId = this.orderId;
                            this.batchForm.Data.SupplierSource = row.SupplierSource;
                            this.batchForm.Data.SupplierCompanyTypeId = row.SupplierCompanyTypeId;
                            this.batchForm.Data.SupplierCompanyTypeLabel = row.SupplierCompanyTypeLabel;
                            this.batchForm.Data.SupplierCompanyId = row.SupplierCompanyId;
                            this.batchForm.Data.SupplierCompanyLabel = row.SupplierCompanyLabel;
                            this.batchForm.Data.SupplierCompanyContactLabel = row.SupplierCompanyContactLabel;
                            OrderDepartureWharfAppService.BatchEdit(this.batchForm.Data)
                                .then(() => {
                                    ElMessage.success("批量修改供应商成功");
                                    this.handleGet();
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }

                    }
                }
            })

        }
        onOutStock(row: any): void {
            this.id = row.PoolId;

            if (row.SumQuantityInStock == null || row.SumQuantityInStock == undefined) {
                ElMessage.warning("请先完善入库货量");
                return;
            }
            this.$nextTick(() => {
                const WharfOutStock = this.$refs["WharfOutStock"] as any;
                WharfOutStock.onInit(row.Id);
            });

        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.id != null && this.id != undefined && this.id != "") {
                        this.stateRequestParam.PoolId.push(this.id as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {
                                OrderDepartureWharfAppService.Delete(this.list.SelectIds)
                                    .then(() => {
                                        this.handleGet();
                                        complete();
                                    })
                                    .catch(complete);
                            }
                        }
                    })

                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.handleGet();
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
        onSelectPlan(row: any): void {
            row.Type = this.type;
            this.isLoading = true;
            OrderDepartureWharfAppService.Post(row)
                .then(() => {
                    this.isLoading = false;
                    if (row.Id != undefined) {
                        ElMessage.success("编辑成功");
                    }
                    else {
                        ElMessage.success("新增成功");
                    }
                    

                    var param = new DtoSynchronizePoolData();
                    if (this.wharfType == 0) {
                        param.PoolId = this.id;
                    }
                    else if (this.wharfType == 1) {
                        param.PoolId = this.orderId;
                    }
                    param.Type = this.type;
                    this.isLoading = true;
                    OrderDepartureWharfAppService.SynchronizePoolData(param).then((r) => {
                        if (r.data.Data?.IsTip == true) {
                            var url = encodeURIComponent(this.$route.fullPath);
                            ElMessage.warning({
                                customClass: "testMessage",
                                dangerouslyUseHTMLString: true,
                                message: '委托书货物数据与仓库中的计划货量数据不一致，如需修改请点击<a href="/#/order/pool/edit/' + this.id + '?backUrl=' + url + '" style="font-weight:900;" >这里</a>修改',
                            })
                        }
                        this.handleSubmit();
                    })
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        }
        onSelectInStock(row: any): void {

            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.id != null && this.id != undefined && this.id != "") {
                this.stateRequestParam.PoolId.push(this.id as string);
            }
            this.isLoading = true;
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        this.isLoading = false;
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        this.isLoading = false;
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        this.isLoading = false;
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {

                        this.$nextTick(() => {

                            var message = document.getElementsByClassName("testMessage") as any;
                            console.log(message);
                            if (message.length > 0 && message[0].style) {
                                message[0].style = "display:none;";
                            }

                            OrderDepartureWharfAppService.InStock(row)
                                .then((r) => {
                                    this.isLoading = false;
                                    if (r.data.Data == true) {
                                        ElMessage.success("入库成功");
                                        this.handleSubmit();
                                    }
                                    else if (r.data.Data == false) {
                                        ElMessage.warning("入库失败");
                                    }
                                })
                                .catch((err) => {
                                    this.isLoading = false;
                                    console.log(err);
                                });
                        });

                    }
                }
            })



        }
        onSelectOutStock(row: any): void {

            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.id != null && this.id != undefined && this.id != "") {
                this.stateRequestParam.PoolId.push(this.id as string);
            }
            this.isLoading = true;
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {
                        OrderDepartureWharfAppService.OutStock(row)
                            .then((r) => {
                                this.isLoading = false;
                                if (r.data.Data == true) {
                                    ElMessage.success("出库成功");
                                    this.handleSubmit();
                                }
                                else if (r.data.Data == false) {
                                    ElMessage.warning("出库失败");
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
            })
        }

        cellClassName(row: any) {

            if (this.list.Data && this.list.Data.AddDeparturePortWarehouse == true && this.task == "AddDeparturePortWarehouse") {
                if (row.column.property == "Supplier" && (row.row.Supplier == null || row.row.Supplier == undefined || row.row.Supplier == "") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }

                if (row.column.property == "AnnexString" && row.row.IsShiftout != true) {
                    var flag = false;
                    if (this.list.Data && this.list.Data.Items) {
                        for (var it of this.list.Data.Items) {
                            if (it.Id == row.row.Id && row.row.IsShiftout != true) {
                                if (it.ReceivingDeadline != null && it.ReceivingDeadline != undefined) {
                                    flag = true;
                                }
                            }
                        }
                    }
                    if (flag == false) {
                        return {
                            "text-align": 'center',
                            "border": "1px solid red",
                            "z-index": "1000",
                        }
                    }

                }
            }

            if (this.list.Data && this.list.Data.AddDestinationPortWarehouse == true && this.task == "AddDestinationPortWarehouse") {
                if (row.column.property == "Supplier" && (row.row.Supplier == null || row.row.Supplier == undefined || row.row.Supplier == "" || row.row.Supplier == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }

                if (row.column.property == "SumQuantity" && (row.row.SumQuantity == null || row.row.SumQuantity == undefined || row.row.SumQuantity == "" || row.row.SumQuantity == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }

                /*if (row.column.property == "AnnexString") {
                    var flag10 = false;
                    if (this.list.Data && this.list.Data.Items) {
                        for (var it10 of this.list.Data.Items) {
                            if (it10.Id == row.row.Id) {
                                if (it10.WarehousingCode != null && it10.WarehousingCode != undefined && it10.WarehousingCode !="") {
                                    flag10 = true;
                                }
                            }
                        }
                    }
                    if (flag10 == false) {
                        return {
                            "text-align": 'center',
                            "border": "1px solid red",
                            "z-index": "1000",
                        }
                    }
 
                }*/
            }

            if (this.list.Data && this.list.Data.FeedbackDeparturePortWarehouseWarehousing == true && this.task == "FeedbackDeparturePortWarehouseWarehousing") {
                if (row.column.property == "SumQuantityInStock" && (row.row.SumQuantityInStock == null || row.row.SumQuantityInStock == undefined || row.row.SumQuantityInStock == "" || row.row.SumQuantityInStock == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
                if (row.column.property == "InStockTimeString" && (row.row.InStockTimeString == null || row.row.InStockTimeString == undefined || row.row.InStockTimeString == "" || row.row.InStockTimeString == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
            }

            if (this.list.Data && this.list.Data.FeedbackDestinationPortWarehouseWarehousing == true && this.task == "FeedbackDestinationPortWarehouseWarehousing") {
                if (row.column.property == "SumQuantityInStock" && (row.row.SumQuantityInStock == null || row.row.SumQuantityInStock == undefined || row.row.SumQuantityInStock == "" || row.row.SumQuantityInStock == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
                if (row.column.property == "InStockTimeString" && (row.row.InStockTimeString == null || row.row.InStockTimeString == undefined || row.row.InStockTimeString == "" || row.row.InStockTimeString == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
            }

            if (this.list.Data && this.list.Data.FeedbackDeparturePortWarehouseExWarehouse == true && this.task == "FeedbackDeparturePortWarehouseExWarehouse") {
                if (row.column.property == "SumQuantityOutStock" && (row.row.SumQuantityOutStock == null || row.row.SumQuantityOutStock == undefined || row.row.SumQuantityOutStock == "" || row.row.SumQuantityOutStock == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
                if (row.column.property == "OutStockTimeString" && (row.row.OutStockTimeString == null || row.row.OutStockTimeString == undefined || row.row.OutStockTimeString == "" || row.row.OutStockTimeString == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
            }

            if (this.list.Data && this.list.Data.FeedbackDestinationPortWarehouseExWarehouse == true && this.task == "FeedbackDestinationPortWarehouseExWarehouse") {
                if (row.column.property == "SumQuantityOutStock" && (row.row.SumQuantityOutStock == null || row.row.SumQuantityOutStock == undefined || row.row.SumQuantityOutStock == "" || row.row.SumQuantityOutStock == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
                if (row.column.property == "OutStockTimeString" && (row.row.OutStockTimeString == null || row.row.OutStockTimeString == undefined || row.row.OutStockTimeString == "" || row.row.OutStockTimeString == "暂无数据") && row.row.IsShiftout != true) {
                    return {
                        "text-align": 'center',
                        "border": "1px solid red",
                        "z-index": "1000",
                    }
                }
            }

            return {
                "text-align": 'center',
            }
        }
    }
