import MiezzButton from "./MiezzButton";

/**模态框 */
export default class MiezzModal {
    constructor(type?: MiezzModalType) {
        this.Type = type ?? MiezzModalType.Drawer;
        this.Width = "70%";
    }
    /**类型 */
    Type?: MiezzModalType;
    /**是否显示 */
    Show?: boolean = false;
    /**加载 */
    Loading?: boolean = false;
    /**对话框时高度居中 */
    AlignCenter?: boolean = false;
    /**标题 */
    Title?: string;
    Description?: string;
    Top?: string;
    /**宽度 */
    Width?: string | number;
    /**高度 */
    Height?: string | number;
    /**按钮 */
    Buttons?: MiezzButton[];
    ButtonWidth?: string;
    /**按钮点击事件 */
    HandleClick?: (btn: MiezzButton, complete: () => void) => void;
}

/**模态框类型 */
export enum MiezzModalType {
    /**抽屉 */
    Drawer,
    /**对话框 */
    Dialog
}