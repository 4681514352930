
import DtoListItemCompany, {
  DtoListItemCompanyMiezzListItems,
} from "@/models.machine/DtoListItemCompany";
import DtoPageListSearchCompany, {
  DtoPageListSearchCompanyDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzModal from "@/models/MiezzModal";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyPageList from "../CompanyPageList.vue";
@Options({
  components: {
    CompanyPageList,
  },
})
export default class ServiceProviderCompanyPageList extends Vue {
  type = EnumCompanyType.ServiceProvider;
  list = new MiezzPageList<
    DtoPageListSearchCompany,
    DtoListItemCompany,
    string
  >("ServiceProviderManagement_Company");

  created(): void {
    this.list.Items = DtoListItemCompanyMiezzListItems.copy().filter(
      (it) =>
        it.Prop != "JoinCompanyTypes" &&
        it.Prop != "ServiceProviderCompanyId" &&
        it.Prop != "Source"
    );
    for (const item of this.list.Items??[]) {
      switch (item.Prop) {
        case "UserCount":
          item.Customize = true;
          break;
      
        default:
          break;
      }
    }
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompany();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyDynamicSearchItems.copy().filter(
        (it) =>
          it.Prop != "DynamicSearch_JoinCompanyTypes" &&
          it.Prop != "DynamicSearch_ServiceProviderCompanyId"
      );
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "ServiceProviderManagement_Company_Add",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "ServiceProviderManagement_Company_Detail",
      },
      {
        Code: "users",
        Title: "查看用户",
        Icon: "user",
        Type: "default",
        Size: "small",
        PowerCode: "ServiceProviderManagement_User",
      },
      {
        Code: "open-supplier",
        Title: "开通供应商",
        Icon: "link",
        Type: "default",
        Size: "small",
        PowerCode: "ServiceProviderManagement_Company_OpenSupplier",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        Message: "是否确认删除",
        PowerCode: "ServiceProviderManagement_Company_Delete",
      },
      {
        Code: "batch-update-status",
        Label: "状态",
        Icon: "edit",
        Type: "text",
        PowerCode: "ServiceProviderManagement_Company_UpdateStatus",
        Options: [
          {
            Code: "10",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "20",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
      {
        Code: "batch-update-three-api-service",
        Label: "接口服务",
        Icon: "edit",
        Type: "text",
        PowerCode: "ServiceProviderManagement_Company_OpenThreeApiService",
        Options: [
          {
            Code: "True",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "False",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
    ];
  }
}
