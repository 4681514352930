
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import moment from "moment";
    import MiezzPageList from "@/models/MiezzPageList";
    import CurrentLogier from "@/apis/CurrentLogier";
    import MiezzMonthChoose from "@/components/MiezzMonthChoose.vue";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { init, EChartsOption } from "echarts";
    import MiezzButton from "@/models/MiezzButton";
    import DtoRequestCount from "@/models.machine/DtoRequestCount";
    import DtoCallbackCount from "@/models.machine/DtoCallbackCount";
    import DtoCallbackComposition from "@/models.machine/DtoCallbackComposition";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
    import { EnumRelation } from "@/models.machine/EnumRelation";
    import { EnumCompare } from "@/models.machine/EnumCompare";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import DtoPageListSearchFinance, { DtoPageListSearchFinanceDynamicSearchItems } from "@/models.machine/DtoPageListSearchFinance";
    import DtoPageListFinance from "@/models.machine/DtoPageListFinanceStat";
    import DtoRequestFinance from "@/models.machine/DtoRequestFinance";
    import DtoRequestInvoice from "@/models.machine/DtoRequestInvoice";
    import DtoRequestSettlement from "@/models.machine/DtoRequestSettlement";
    import BillHistoryAppService from "@/apis.machine/BillHistoryAppService";
    import DtoPageListSearchBillHistory, { DtoPageListSearchBillHistoryDynamicSearchItems } from "@/models.machine/DtoPageListSearchBillHistory";
    import DtoPageListBillHistory from "@/models.machine/DtoPageListBillHistory";
    import DtoListItemBillHistory, {
        DtoListItemBillHistoryMiezzListItems,
    } from "@/models.machine/DtoListItemBillHistory";
    import DtoListItemFinance, {
        DtoListItemFinanceStatMiezzListItems,
    } from "@/models.machine/DtoListItemFinanceStat";
    import { asString } from "html2canvas/dist/types/css/types/color";

    @Options({
        components: {
            MiezzMonthChoose,
        },
    })
    export default class FinanceStatOverView extends Vue {
        grossProfitDetailForm: {
            Visible: boolean;
            Title: string;
            Items: any[];
        } = {
                Visible: false,
                Title: "组成",
                Items: [],
            };

        isGrossProfitChart = false;
        settlementForm = new DtoRequestSettlement();
        settlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        isHistory = true;
        /**分页列表 */
        historys = new MiezzPageList<DtoPageListSearchBillHistory, DtoListItemBillHistory, string>();

        settlementDetailBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        client?: OSS;
        invoiceForm = new DtoRequestInvoice();
        invoiceFormInit = new DtoRequestInvoice();
        invoiceBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        //是否正在统计
        isStat = false;
        isLeft = false;
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchFinance, DtoListItemFinance, string>("FinancialManagement");
        isLoading = false;
        month?: string = "";
        type?: string = "0";
        companyKey = 0;
        companyId?: string = "";
        companyName?: string = "";
        companys?: UiSelectOption<string>[] = [];
        detailForm: {
            Visible: boolean;
            Title: string;
            Items: any[];
            Details:any[],
        } = {
                Visible: false,
                Title: "组成",
                Items: [],
                Details:[],
            };
        search: any[] = [];
        detailBottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                IsBlue: true,
                Code: "submit",
                Label: "搜索相关账单",
                Type: "primary",
                Size: "small",
            },
            {
                //Icon:"CloseBold",
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        grossProfitDetailBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        FinancialManagement_StatisticalAnalysis_Overview = false;
        FinancialManagement_StatisticalAnalysis_SalespersonRelated = false;
        FinancialManagement_StatisticalAnalysis_CustomerRelated = false;

        FinancialManagement_StatisticalAnalysis_CustomerRelated_CollectionProgress = false;
        FinancialManagement_StatisticalAnalysis_CustomerRelated_CostList = false;
        FinancialManagement_StatisticalAnalysis_CustomerRelated_GrossProfit = false;
        isFinancialCycle = false;
        result = new DtoCallbackCount();
        detail = new DtoCallbackComposition();
        created(): void {
            //列表-传参
            this.historys.Dto = new DtoPageListSearchBillHistory();
            //列表-动态搜索-项
            this.historys.DynamicSearchItems = DtoPageListSearchBillHistoryDynamicSearchItems;
            //列表-数据-初始
            this.historys.Data = new DtoPageListBillHistory();


            this.client = AliyunOSS.GetClient();
            this.FinancialManagement_StatisticalAnalysis_Overview = false;
            this.FinancialManagement_StatisticalAnalysis_SalespersonRelated = false;
            this.FinancialManagement_StatisticalAnalysis_CustomerRelated = false;

            //列表-传参
            this.list.Dto = new DtoPageListSearchFinance();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchFinanceDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListFinance();
            //列表-列配置
            //this.list.Items = DtoListItemFinanceMiezzListItems;
            //列表-列配置
            //this.list.Items = DtoListItemProductMiezzListItems;
            this.list.Fix = false;
            this.list.EnabledDynamicSearch = false;
            this.list.EnabledRadioSearch = false;
            this.list.EnabledRadioSearchAll = false;
            this.list.EnabledSelect = false;
            this.list.Items = DtoListItemFinanceStatMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "AccountPeriod") {
                    item.Width = 110;
                }
                if (item.Prop == "BookingNo") {
                    item.Width = 100;
                }
                if (item.Prop == "TypeString") {
                    item.Width = 40;
                }
                if (item.Prop == "CurrencySystemId") {
                    item.Width = 40;
                }
                return item;
            });

            this.companyId = this.$route.query.companyId as string;
            FinanceAppService.GetCustomer().then((r) => {
                if (r.data.Data) {
                    this.companys = r.data.Data;

                }
                CurrentLogier.GetPowerCodes().then((r) => {
                    if (r?.contains("FinancialManagement_StatisticalAnalysis_Overview")) {
                        this.FinancialManagement_StatisticalAnalysis_Overview = true;
                    }
                    if (
                        r?.contains(
                            "FinancialManagement_StatisticalAnalysis_SalespersonRelated"
                        )
                    ) {
                        this.FinancialManagement_StatisticalAnalysis_SalespersonRelated = true;
                    }
                    if (r?.contains("FinancialManagement_StatisticalAnalysis_CustomerRelated")) {
                        this.FinancialManagement_StatisticalAnalysis_CustomerRelated = true;
                    }

                    if (r?.contains("FinancialManagement_StatisticalAnalysis_CustomerRelated_CollectionProgress")) {
                        this.FinancialManagement_StatisticalAnalysis_CustomerRelated_CollectionProgress = true;
                    }
                    if (r?.contains("FinancialManagement_StatisticalAnalysis_CustomerRelated_CostList")) {
                        this.FinancialManagement_StatisticalAnalysis_CustomerRelated_CostList = true;
                    }
                    if (r?.contains("FinancialManagement_StatisticalAnalysis_CustomerRelated_GrossProfit")) {
                        this.FinancialManagement_StatisticalAnalysis_CustomerRelated_GrossProfit = true;
                    }
                    this.isStat = false;
                    this.onStat();
                });
            })

        }
        onClearCompany() {
            //this.$refs.YourRefName.$el.click()
            console.log("123");
        }

        querySearchCompany(queryString: string, cb: any) {
            var restaurants = this.companys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.companys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.companys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.companys) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.companys.length; i++) {
                            if (i < 10) {
                                results.push(this.companys[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        onShare(): void {
            this.search = [];

            this.search.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_ClientCompany",
                    Compare: EnumCompare.Equal,
                    Value: this.companyId,
                },
            });

            this.search.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_ShareStatus",
                    Compare: EnumCompare.Equal,
                    Value: "10",
                },
            });

            var month = "";
            if (this.month && this.month?.indexOf(",") >= 0) {
                month = this.month;
            }
            else {
                month = this.month + "," + this.month;
            }

            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessDate",
                    Compare: EnumCompare.Between,
                    Value: month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                };
            }
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            date,
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_Type",
                                    Compare: EnumCompare.Equal,
                                    Value: "20",
                                },
                            },
                        ],
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.search,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true&StatType=9999`,
                    "_blank"
                );
            });
        }
        onNoBill(): void {
            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessCycle",
                    Compare: EnumCompare.Between,
                    Value: this.month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: this.month,
                    },
                };
            }
            var leftSearch = [];
            var rightSearch = [];
            leftSearch.push(date);

            //DynamicSearch_CollectionSubmitStatus
            if (this.type == "" || this.type == "0") {
                rightSearch.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_CollectionSubmitStatus",
                        Compare: EnumCompare.Equal,
                        Value: "1",
                    },
                });
            }
            else {
                rightSearch.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_PaymentSubmitStatus",
                        Compare: EnumCompare.Equal,
                        Value: "1",
                    },
                });
            }

            leftSearch.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_ClientUserId",
                    Compare: EnumCompare.Equal,
                    Value: this.companyId,
                },
            });

            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: leftSearch,
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: rightSearch,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/order/pool?IsStat=true&DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }
        handleSelectCompany(item: UiSelectOption<string>) {

            this.companyId = item.Value;
            this.companyName = item.Label;


            this.$router.push(
                "/finance/stat/customer?type=" + this.type + "&month=" + this.month + "&companyId=" + this.companyId
            );


            //this.$nextTick(() => {
            //    this.$router.push(
            //        "/finance/stat/customer?type=" + this.type + "&month=" + this.month + "&companyId=" + this.companyId
            //    );
            //});


        }

        onTime(): void {
            if (this.isFinancialCycle == false) {
                this.$router.push(
                    "/finance/stat/customer?type=" + this.type + "&month=" + this.month + "&isFinancialCycle=true"
                );
            }
            else {
                this.$router.push(
                    "/finance/stat/customer?type=" + this.type + "&month=" + this.month + "&isFinancialCycle=false"
                );
            }
        }


        isInit = true;
        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            if (this.isInit == false) {
                this.isInit = true;
                this.onStat();
            }
        }

        //@Watch("companyId")
        //onCompanyIdChanged(nv: any, ov: any): void {
        //    if (this.isInit == false) {
        //        this.onStat();
        //    }
        //}


        //@Watch("month")
        //onMonthChanged(nv: any, ov: any): void {
        //    if (this.isInit == false) {
        //        this.onStat();
        //    }

        //}

        //@Watch("type")
        //onTypeChanged(): void {
        //    if (this.isInit == false) {
        //        this.onStat();
        //    }
        //}


        //onChangeType(type: string): void {
        //    this.$router.push(
        //        "/finance/stat/customer?type=" + type + "&month=" + this.month + "&companyId=" + this.companyId
        //    );

        //}

        onChangeType(type: string): void {
            //this.type = type;

            if (this.isFinancialCycle == false) {
                this.$router.push(
                    "/finance/stat/customer?type=" + type + "&month=" + this.month + "&companyId=" + this.companyId
                );
            }
            else {
                this.$router.push(
                    "/finance/stat/customer?type=" + type + "&month=" + this.month + "&companyId=" + this.companyId + "&isFinancialCycle=true"
                );
            }

            //this.onStat();
        }

        onChangeSelectCompany(): void {
            //this.$router.push(
            //    "/finance/stat/customer?type=" + this.type + "&month=" + this.month + "&companyId=" + this.companyId
            //);

            if (this.isFinancialCycle == false) {
                this.$router.push(
                    "/finance/stat/customer?type=" + this.type + "&month=" + this.month + "&companyId=" + this.companyId
                );
            }
            else {
                this.$router.push(
                    "/finance/stat/customer?type=" + this.type + "&month=" + this.month + "&companyId=" + this.companyId + "&isFinancialCycle=true"
                );
            }
        }

        onDetail(
            type: number,
            currencyId: string | undefined,
            currencyCode: string,
            title: string
        ): void {
            this.detailForm.Visible = true;
            this.detailForm.Title = "组成";
            this.getChartPie(
                type,
                currencyId,
                "detailFormPie",
                title + "(币制：" + currencyCode + ")"
            );
        }

        onStat(): void {

            this.isFinancialCycle = false;
            var isFinancialCycle = this.$route.query.isFinancialCycle as string;
            if (isFinancialCycle == "true") {
                this.isFinancialCycle = true;
            }


            var type = this.$route.query.type as string;
            if (type == null || type == undefined || type == "") {
                type = this.$route.query.Type as string;
                if (type == null || type == undefined || type == "") {
                    if (this.FinancialManagement_StatisticalAnalysis_CustomerRelated_CollectionProgress == true) {
                        type = "0";
                    }
                    else if (this.FinancialManagement_StatisticalAnalysis_CustomerRelated_CostList == true) {
                        type = "2";
                    }
                    else if (this.FinancialManagement_StatisticalAnalysis_CustomerRelated_GrossProfit == true) {
                        type = "3";
                    }
                }
            }

            var month = this.$route.query.month as string;
            if (month == null || month == undefined || month == "") {
                month = this.$route.query.Month as string;
            }

            var companyId = this.$route.query.companyId;
            if (companyId == null || companyId == undefined || companyId == "") {
                companyId = this.$route.query.CompanyId as string;
            }


            this.isStat = true;
            if (type != this.type || month != this.month || companyId != this.companyId || this.isInit == true) {
                this.type = type;
                this.month = month;
                this.companyId = companyId as string;
                this.isStat = false;
            }

            if (this.isStat == false) {
                this.isStat = true;

                if (this.companyId == null || this.companyId == undefined || this.companyId == "") {
                    if (this.companys && this.companys.length > 0) {
                        this.companyId = this.companys[0].Value;
                        this.companyName = this.companys[0].Label;
                    }
                }
                else {
                    if (this.companys && this.companys.length > 0) {
                        for (var c of this.companys) {
                            if (c.Value == this.companyId) {
                                this.companyId = c.Value;
                                this.companyName = c.Label;
                            }
                        }
                    }
                }


                if (this.companyId != null && this.companyId != undefined && this.companyId != "") {
                    if (this.type != "2") {
                        var param = new DtoRequestCount();
                        param.Month = this.month;
                        param.Channel = 2;
                        if (this.type == "") {
                            this.type = "0";
                        }
                        param.Type = Number(this.type);
                        param.CompanyId = this.companyId;
                        param.IsFinancialCycle = this.isFinancialCycle;
                        this.isLoading = true;
                        FinanceAppService.Count(param).then((r) => {
                            this.isLoading = false;
                            this.isStat = false;
                            this.isInit = false;
                            if (r.data.Data) {
                                this.result = r.data.Data;
                                if (this.type == "3") {
                                    this.onGrossProfitStat(this.result.GrossProfit);
                                }
                            }

                        });
                    }
                    else {
                        this.isLoading = true;
                        if (this.list && this.list.Dto) {
                            this.list.Dto.CompanyId = this.companyId;
                            this.list.Dto.month = this.month;
                            this.list.Dto.IsFinancialCycle = this.isFinancialCycle;
                        }
                        FinanceAppService.GetCustomerComposition(this.list.Dto).then((r) => {
                            this.isLoading = false;
                            this.isStat = false;
                            this.isInit = false;
                            if (r.data.Data) {
                                this.list.Data = r.data.Data;
                            }
                        });
                    }

                }
            }

        }

        onChangeChannel(type: string): void {
            if (type == "0") {
                this.$router.push("/finance/stat/overview");
            } else if (type == "1") {
                this.$router.push("/finance/stat/saler");
            } else if (type == "2") {
                this.$router.push("/finance/stat/customer");
            }
        }
        CompositionType = 0;
        getChartPie(
            type: number,
            currencyId: string | undefined,
            ref: string,
            title: string
        ): void {
            var param = new DtoRequestCount();
            param.Month = this.month;
            param.Channel = 2;
            param.CurrencySystemId = currencyId;
            if (this.type == "") {
                this.type = "0";
            }
            param.CompositionType = type;
            param.Type = Number(this.type);
            param.CompanyId = this.companyId;
            param.IsFinancialCycle = this.isFinancialCycle;
            this.CompositionType = type;
            this.isLoading = true;
            FinanceAppService.Composition(param).then((r) => {
                this.isLoading = false;
                if (r.data.Data) {
                    this.detail = r.data.Data;
                    this.detailForm.Items = r.data.Data.Items ?? [];
                    this.detailForm.Details = r.data.Data.Details ?? [];
                    const option: EChartsOption = {
                        title: {
                            text: title,
                            left: "center",
                            textStyle: {
                                color: "#666",
                            },
                        },
                        tooltip: {
                            trigger: "item",
                        },
                        legend: {
                            orient: "vertical",
                            left: "left",
                        },
                        series: [
                            {
                                type: "pie",
                                radius: "50%",
                                label: {
                                    show: false,
                                },
                                data: r.data.Data?.SeriesData,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)",
                                    },
                                },
                            },
                        ],
                    };
                    this.initChart(ref, option);
                }
            });
        }

        initChart(ref: string, option: EChartsOption): void {
            this.$nextTick(() => {
                const element = this.$refs[ref] as InstanceType<typeof HTMLElement>;
                if (element) {
                    const echarts = init(element);
                    echarts.setOption(option);
                }
            });
        }
        isString(str: string, row: any | undefined): string {
            if (row != undefined && row != null) {
                if (row.FromId == undefined || row.FromId == null) {
                    if (str == null || str == undefined || str == "") {
                        return "暂无数据";
                    }
                    else {
                        return str;
                    }
                }
                else {
                    if (str == null || str == undefined || str == "") {
                        return "—";
                    }
                    else {
                        return str;
                    }
                }
            }
            else {
                if (str == null || str == undefined || str == "") {
                    return "暂无数据";
                }
                else {
                    return str;
                }
            }
        }
        handleClickDetail(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.search = [];
                    if (this.detail.Search?.SettlementType == "30") {
                        this.search = [{
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "20",
                            },
                        },
                        {
                            Relation: EnumRelation.Or,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "30",
                            },
                        }
                        ];
                    }
                    else if (this.detail.Search?.SettlementType == "10") {
                        this.search = [{
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "10",
                            },
                        },
                        {
                            Relation: EnumRelation.Or,
                            Where: {
                                Prop: "DynamicSearch_SettlementType",
                                Compare: EnumCompare.Equal,
                                Value: "20",
                            },
                        }
                        ];
                    }

                    if (this.detail.Search?.OverdueType == "10") {
                        this.search.push({
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_OverdueStatus",
                                Compare: EnumCompare.Equal,
                                Value: "10",
                            },
                        });
                    }

                    //this.search.push({
                    //    Relation: EnumRelation.And,
                    //    Where: {
                    //        Prop: "DynamicSearch_SubmitStatus",
                    //        Compare: EnumCompare.Equal,
                    //        Value: "10",
                    //    },
                    //});

                    this.search.push({
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_ClientCompany",
                            Compare: EnumCompare.Equal,
                            Value: this.companyId,
                        },
                    });

                    var date = {
                        Relation: EnumRelation.And,
                        Where: {
                            Prop: "DynamicSearch_BusinessDate",
                            Compare: EnumCompare.Between,
                            Value: this.detail.Search?.Month,
                        },
                    };
                    if (this.isFinancialCycle == true) {
                        date = {
                            Relation: EnumRelation.And,
                            Where: {
                                Prop: "DynamicSearch_FinancialCycle",
                                Compare: EnumCompare.Between,
                                Value: this.detail.Search?.Month,
                            },
                        };
                    }
                    DynamicSearchAppService.Post({
                        Code: "FinancialManagement_BillSummary",
                        Brackets: [
                            {
                                Relation: EnumRelation.And,
                                Items: [
                                    date,
                                    {
                                        Relation: EnumRelation.And,
                                        Where: {
                                            Prop: "DynamicSearch_CurrencySystem",
                                            Compare: EnumCompare.Equal,
                                            Value: this.detail.Search?.CurrencySystemId,
                                        },
                                    },
                                    {
                                        Relation: EnumRelation.And,
                                        Where: {
                                            Prop: "DynamicSearch_Type",
                                            Compare: EnumCompare.Equal,
                                            Value: this.detail.Search?.Type,
                                        },
                                    },
                                    {
                                        Relation: EnumRelation.And,
                                        Where: {
                                            Prop: "DynamicSearch_InvoiceState",
                                            Compare: EnumCompare.Equal,
                                            Value: this.detail.Search?.InvoiceState,
                                        },
                                    },

                                ],
                            },
                            {
                                Relation: EnumRelation.And,
                                Items: this.search,
                            },
                        ],
                    }).then((r) => {
                        window.open(
                            `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                            "_blank"
                        );
                        //window.location.href = `/#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`;

                        //this.$router.push(
                        //    `/finance/bill/batch/edit/${pre.data.Data}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        //this.$router.push({
                        //    path: `/finance/bill`,
                        //    query: {
                        //        DynamicSearchId: r.data.Data,
                        //        OpenSearch: "true",
                        //        r: Math.random(),
                        //        backUrl: this.$route.fullPath,
                        //    },
                        //});

                        complete();
                    });
                    complete();
                    break;
                case "close":
                    this.detailForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onBillDetail(): void {
            this.search = [];

            this.search.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_ClientCompany",
                    Compare: EnumCompare.Equal,
                    Value: this.companyId,
                },
            });

            var month = "";
            if (this.month && this.month?.indexOf(",") >= 0) {
                month = this.month;
            }
            else {
                month = this.month + "," + this.month;
            }

            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessDate",
                    Compare: EnumCompare.Between,
                    Value: month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                };
            }
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            date,
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_Type",
                                    Compare: EnumCompare.Equal,
                                    Value: "10",
                                },
                            },
                        ],
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.search,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }
        onInvoice(row: any) {

            var param = new DtoRequestFinance();
            param.BillIds = [];
            if (row.FromId == null || row.FromId == undefined || row.FromId == "") {
                param.BillIds.push(row.Id);
            }
            else {
                param.BillIds.push(row.FromId);
            }

            FinanceAppService.GetInvoice(param).then((r) => {
                if (r.data.Data) this.invoiceForm = r.data.Data;
                if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                    for (var it of this.invoiceForm.InvoiceItems) {
                        it.InvoiceDateString = "";
                        if (it.InvoiceDate != null && it.InvoiceDate != undefined) {
                            it.InvoiceDateString = moment(it.InvoiceDate).format("YYYY/MM/DD");
                        }


                        if (it.Annexs != undefined && it.Annexs.length > 0) {
                            for (var annex of it.Annexs) {
                                if (annex.Url != undefined) {
                                    annex.Url = this.client?.signatureUrl(annex.Url as string);
                                }
                            }
                        }
                    }
                    console.log(this.invoiceForm.InvoiceItems);
                }
            })
        }
        handleClickInvoice(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.invoiceForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        settlementVisible = false;
        onSettlementList(): void {
            this.settlementVisible = true;
        }
        handleClickSettlementDetail(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.settlementVisible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onSettlement(row: any) {
            var id = row.Id;
            if (row.FromId == null || row.FromId == undefined || row.FromId == "") {
                id = row.Id;
            }
            else {
                id = row.FromId;
            }
            FinanceAppService.GetSettlementByBillId(id).then((r) => {
                if (r.data.Data) this.settlementForm = r.data.Data;
            })

            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = 1;
                this.historys.Dto.PageSize = 999999999;
                this.historys.Dto.BillId = row.Id;
            }
            this.isHistory = true;
            BillHistoryAppService.Get(this.historys.Dto).then((r) => {
                this.historys.Data = r.data.Data;
            });

        }

        handleClickSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.settlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        left: any[] = [];
        right: any[] = [];
        onPool(row: any): void {
            this.left = [];
            this.right = [];

            this.left.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_Pool",
                    Compare: EnumCompare.Contain,
                    Value: row.BookingNo,
                },
            });

            //this.left.push({
            //    Relation: EnumRelation.And,
            //    Where: {
            //        Prop: "DynamicSearch_ClientCompany",
            //        Compare: EnumCompare.Equal,
            //        Value: this.companyId,
            //    },
            //});


            //this.right.push({
            //    Relation: EnumRelation.And,
            //    Where: {
            //        Prop: "DynamicSearch_SubmitStatus",
            //        Compare: EnumCompare.Equal,
            //        Value: "10",
            //    },
            //});
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: this.left,
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.right,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });
        }

        handleClickGrossProfitDetail(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.grossProfitDetailForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onBill(type: string, currency: string) {
            this.left = [];
            this.right = [];
            var month = this.month;
            if (this.month?.indexOf(",") == -1) {
                month = this.month + "," + this.month;
            }

            this.left.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_ClientCompany",
                    Compare: EnumCompare.Equal,
                    Value: this.companyId,
                },
            });
            if (this.isFinancialCycle == false) {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_BusinessDate",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }
            else {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                });
            }
       

            this.left.push({
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_CurrencySystem",
                    Compare: EnumCompare.Equal,
                    Value: currency,
                },
            });

            //this.right.push({
            //    Relation: EnumRelation.And,
            //    Where: {
            //        Prop: "DynamicSearch_SubmitStatus",
            //        Compare: EnumCompare.Equal,
            //        Value: "10",
            //    },
            //});

            if (type != "7") {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_Type",
                        Compare: EnumCompare.Equal,
                        Value: "10",
                    },
                });
            }


            if (type == "1") {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_InvoiceState",
                        Compare: EnumCompare.NoEqual,
                        Value: "10",
                    },
                });
            }
            else if (type == "2") {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_InvoiceState",
                        Compare: EnumCompare.NoEqual,
                        Value: "30",
                    },
                });
            }
            else if (type == "3") {

                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "10",
                    },
                });
            }
            else if (type == "4") {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "30",
                    },
                });
            }
            else if (type == "5") {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_OverdueStatus",
                        Compare: EnumCompare.Equal,
                        Value: "10",
                    },
                });
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "10",
                    },
                });
            }
            else if (type == "6") {
                this.left.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_OverdueStatus",
                        Compare: EnumCompare.Equal,
                        Value: "10",
                    },
                });
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_SettlementType",
                        Compare: EnumCompare.NoEqual,
                        Value: "30",
                    },
                });
            }


            if (type == "8") {
                this.right.push({
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_IsBad",
                        Compare: EnumCompare.Equal,
                        Value: "10",
                    },
                });
            }



            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: this.left,
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.right,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true&StatMonth=` + this.month + `&Channel=2&StatType=` + Number(type),
                    "_blank"
                );
            });
        }
        onGrossProfitStat(row: any): void {
            const option: EChartsOption = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                toolbox: {
                    show: true,
                    orient: 'vertical',
                    left: 'right',
                    top: 'center',
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar', 'bar', 'bar', 'stack'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                legend: {
                    data: ['毛利润', '应收', '应付', '应收坏账', '应付坏账', '毛利率']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: row.Months,
                        axisPointer: {
                            type: 'shadow'
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '毛利润',
                        min: row.MinPrice,
                        max: row.MaxPrice,
                        interval: row.IntervalPrice,
                        axisLabel: {
                            formatter: '{value} 元'
                        },

                    },
                    {
                        type: 'value',
                        name: '毛利率',
                        min: row.MinPoint,
                        max: row.MaxPoint,
                        interval: row.IntervalPoint,
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    }
                ],
                series: [
                    {
                        name: '毛利润',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.Price,
                        barMaxWidth: 50
                    },
                    {
                        name: '应收',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.Collection,
                        barMaxWidth: 50
                    },
                    {
                        name: '应付',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.Payment,
                        barMaxWidth: 50
                    },
                    {
                        name: '应收坏账',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.BadCollectionSettlement,
                        barMaxWidth: 50
                    },
                    {
                        name: '应付坏账',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' 元';
                            }
                        },
                        data: row.BadPaymentSettlement,
                        barMaxWidth: 50
                    },
                    {
                        name: '毛利率',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value as number + ' %';
                            }
                        },
                        data: row.Point,
                    }
                ]
            };
            var ref = "divGrossProfitChart";
            this.onGrossProfitChart(ref, option);
        }
        onGrossProfitDetail(): void {
            if (this.grossProfitDetailForm) {
                this.grossProfitDetailForm.Visible = true;
            }
        }
        onGrossProfitChart(ref: string, option: EChartsOption): void {
            this.isGrossProfitChart = true;
            this.$nextTick(() => {
                const element = this.$refs[ref] as InstanceType<typeof HTMLElement>;
                if (element) {
                    const echarts = init(element);
                    echarts.setOption(option);
                }
            });
        }
        onSumLink(): void {

            this.search = [];

            //this.search.push({
            //    Relation: EnumRelation.And,
            //    Where: {
            //        Prop: "DynamicSearch_SubmitStatus",
            //        Compare: EnumCompare.Equal,
            //        Value: "10",
            //    },
            //});

            var month = this.month;
            if (month != undefined && month?.indexOf(",") < 0) {
                month = month + "," + month;
            }
            var date = {
                Relation: EnumRelation.And,
                Where: {
                    Prop: "DynamicSearch_BusinessDate",
                    Compare: EnumCompare.Between,
                    Value: month,
                },
            };
            if (this.isFinancialCycle == true) {
                date = {
                    Relation: EnumRelation.And,
                    Where: {
                        Prop: "DynamicSearch_FinancialCycle",
                        Compare: EnumCompare.Between,
                        Value: month,
                    },
                };
            }
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            date,
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_ClientCompany",
                                    Compare: EnumCompare.Equal,
                                    Value: this.companyId,
                                },
                            }
                        ],
                    },
                    {
                        Relation: EnumRelation.And,
                        Items: this.search,
                    },
                ],
            }).then((r) => {
                window.open(
                    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                    "_blank"
                );
            });


        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (
        val: string,
        num: number,
        isZero: boolean,
        isLessZero: boolean
    ) {
        var str = val;
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
            if (isLessZero == true) {
                if (str[0] == "-") {
                    val = "-" + val;
                }
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result =
            Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) +
            "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };

    //去除特殊字符~!@#$^-&*()=|{}':;',\[].<>/?~！@#￥……&*（）——|{}【】'；：""'。，、？
    function trimSpecial(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            str = str.replace(/[\u4e00-\u9fa5]/g, "");
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                } else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                //if (str[j] != " " && str[j] != "~" && str[j] != "!" && str[j] != "@"
                //    && str[j] != "#" && str[j] != "$" && str[j] != "^"
                //    && str[j] != "-" && str[j] != "&" && str[j] != "*"
                //    && str[j] != "(" && str[j] != ")" && str[j] != "="
                //    && str[j] != "|" && str[j] != "{" && str[j] != "}"
                //    && str[j] != "'" && str[j] != ":" && str[j] != "'"
                //    && str[j] != "," && str[j] != "\\" && str[j] != "["
                //    && str[j] != "]" && str[j] != "." && str[j] != "<"
                //    && str[j] != ">" && str[j] != "/" && str[j] != "?"
                //    && str[j] != "~" && str[j] != "！" && str[j] != "@"
                //    && str[j] != "#" && str[j] != "￥" && str[j] != "…"
                //    && str[j] != "&" && str[j] != "*" && str[j] != "（"
                //    && str[j] != "）" && str[j] != "—" && str[j] != "|"
                //    && str[j] != "{" && str[j] != "}" && str[j] != "【"
                //    && str[j] != "】" && str[j] != "'" && str[j] != "；"
                //    && str[j] != "：" && str[j] != "\"" && str[j] != "\""
                //    && str[j] != "'" && str[j] != "。" && str[j] != "，"
                //    && str[j] != "、" && str[j] != "？"
                //) {
                //    name += str[j];
                //}
                if (str[j] != " ") {
                    name += str[j];
                }
            }

            str = str.replace(/[\u00A0-\u00FF]|[\u0100-\u017F]|[\u0180-\u024F]/g, "?");
            //替换罗马数字到千位
            str = str.replace(
                /(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/g,
                "?"
            );
            //罗马1-9个数位限制和整数位
            str = str.replace(/[\u2160-\u2183]/g, "?");
            //替换中文标点符号
            str = str.replace(
                /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,
                "?"
            );
        }
        return name;
    }
