
import { Options, Vue } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import { EnumRelation } from "../models.machine/EnumRelation";
import { EnumCompare } from "../models.machine/EnumCompare";
import { EnumUiDynamicSearchItemType } from "@/models.machine/EnumUiDynamicSearchItemType";
import DynamicSearchBracket from "../models.machine/DynamicSearchBracket";
import DynamicSearchAppService from "../apis.machine/DynamicSearchAppService";
import DynamicSearchBracketItem from "@/models.machine/DynamicSearchBracketItem";
import UiSelectOption from "@/models.machine/UiSelectOption";
import UiPageListSearch_DynamicSearchItem from "../models.machine/UiPageListSearch_DynamicSearchItem";
import MiezzDynamicSearchDateTimeRange from "@/components/MiezzDynamicSearchDateTimeRange.vue";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzForm from "@/models/MiezzForm";
import DtoFormDynamicSearch, {
  DtoFormDynamicSearchMiezzFormItems,
  DtoFormDynamicSearchRules,
} from "@/models.machine/DtoFormDynamicSearch";
import MiezzButton from "@/models/MiezzButton";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import Logier from "@/models.machine/Logier";
import MiezzDynamicSearchMonthRange from "./MiezzDynamicSearchMonthRange.vue";
import vuedraggable from "vuedraggable";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import MiezzDynamicSearchRelativeDate from "./MiezzDynamicSearchRelativeDate.vue";

@Options({
  components: {
    MiezzDynamicSearchMonthRange,
    MiezzDynamicSearchDateTimeRange,
    MiezzDynamicSearchRelativeDate,
    vuedraggable,
  },
})
export default class MiezzDynamicSearchComponent extends Vue {
  /**选中的历史记录 */
  @Model() modelValue?: string = "";
  @Model() modal?: MiezzModal;
  /**编号 */
  @Prop() code?: string;
  @Prop() searchCode?: string;
  /**配置 */
  @Prop() items: UiPageListSearch_DynamicSearchItem[] = [];
  @Prop() fixProp?: boolean;
  @Prop() disabledHistories?: boolean;
  /**历史记录 */
  @Prop() histories?: UiSelectOption<string>[] = [];
  @Prop() hideHistoryTab?: boolean;
  @Prop() simple?: boolean;
  @Prop() searchRules: any;
  @Prop() hideCompare?: boolean;
  @Prop() ignoreResetProps?: string[] = [];

  logier?: Logier;
  id?: string;
  hideProps?: string[] = [];
  _items: UiPageListSearch_DynamicSearchItem[] = [];

  historyData?: UiSelectOption<string>[] = [];
  historyTab?: string = "Public";

  /**表单元素类型 */
  EnumUiDynamicSearchItemType = EnumUiDynamicSearchItemType;
  EnumCompare = EnumCompare;

  /**关系 */
  relations: UiSelectOption<EnumRelation>[] = [
    { Label: "并且", Value: EnumRelation.And },
    { Label: "或者", Value: EnumRelation.Or },
  ];
  /**比较 */
  compares: UiSelectOption<EnumCompare>[] = [
    { Label: "相等", Value: EnumCompare.Equal },
    { Label: "不等", Value: EnumCompare.NoEqual },
    { Label: "大于", Value: EnumCompare.GreaterThan },
    { Label: "大于等于", Value: EnumCompare.GreaterThanEqual },
    { Label: "小于", Value: EnumCompare.LessThan },
    { Label: "小于等于", Value: EnumCompare.LessThanEqual },
    { Label: "包含", Value: EnumCompare.Contain },
    { Label: "不包含", Value: EnumCompare.NoContain },
    { Label: "介于", Value: EnumCompare.Between },
    { Label: "不介于", Value: EnumCompare.NoBetween },
  ];
  /**搜索内容 */
  brackets: DynamicSearchBracket[] = [];

  /**显示模态框-保存历史记录 */
  showDialog = false;
  modalForm = new MiezzModal(MiezzModalType.Dialog);
  /**表单-保存历史记录 */
  form = new MiezzForm<DtoFormDynamicSearch, string>();
  /**表单校验-保存历史记录 */
  rules = {
    Name: [
      {
        required: true,
        message: "请填写名称",
        trigger: "blur",
      },
    ],
  };
  btnToggle: MiezzButton = {
    Code: "toggle",
    MiezzIcon: "double-down",
    Type: "text",
    Size: "small",
  };
  btnAddWhere: MiezzButton = {
    Code: "add-where",
    Icon: "plus",
    Size: "small",
    Type: "text",
  };
  buttons: MiezzButton[] = [
    {
      Code: "search",
      Label: "搜索",
      Type: "primary",
      Size: "small",
      Width: "80px",
    },
  ];
  powers: string[] = [];
  created(): void {
    if (!this.disabledHistories) {
      this.buttons.push({
        Code: "save",
        Label: "保存",
        Type: "primary",
        Size: "small",
        Width: "80px",
      });
    }
    this.buttons.push({
      Code: "reset",
      Label: "重置",
      Type: "default",
      Size: "small",
      Width: "80px",
    });
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetMenus().then((menus) => {
      this.hideProps = menus?.HideMenuFields ?? [];
      this.onItemsChanged();
    });

    this.build(this.modelValue);

    this.modalForm.Title = "保存搜索条件";
    this.modalForm.Height = "170px";
    this.modalForm.Buttons = [
      {
        Code: "submit",
        Label: "提交",
        Type: "primary",
        Size: "small",
      },
    ];
    CurrentLogier.GetPowerCodes().then((powers) => {
      this.powers = powers ?? [];
      let items = DtoFormDynamicSearchMiezzFormItems.copy();
      if (
        (this.logier?.CompanyType != EnumCompanyType.Platform &&
          this.logier?.CompanyType != EnumCompanyType.ServiceProvider) ||
        !powers?.contains("Search_Open")
      ) {
        items = items.filter((it) => it.Prop != "Open");
      }
      if (this.hideHistoryTab || this.modal) {
        items = items.filter((it) => it.Prop != "ShowTab");
      }

      this.form.Items = items;
    });
    this.form.Rules = DtoFormDynamicSearchRules;
    this.form.Rules.Name.push({
      required: true,
      message: "信息不能为空",
      trigger: "blur",
    });

    this.handleHistoryTabClick();

    if (this.modal) {
      this.modal.Title = "搜索";
      this.modal.Width = "80%";
      this.modal.Buttons = this.buttons;
      this.modal.HandleClick = this.handleClick;
    }
  }

  @Watch("items")
  onItemsChanged(): void {
    if (this.hideProps)
      this._items = this.items.filter(
        (it) => it.Prop && !this.hideProps?.contains(`${this.code}|${it.Prop}`)
      );
    else this._items = this.items;
    this.__items = JSON.copy(this.items);
  }

  @Watch("modelValue")
  onModelValueChanged(o?: string, n?: string): void {
    this.build(this.modelValue);
  }

  /**获取历史记录 */
  @Emit()
  handleGetHistories(): void {
    //
  }

  /**删除历史记录 */
  handleDeleteHistory(id?: string): void {
    if (id) {
      if (this.modelValue == id) {
        this.$emit("update:modelValue", null);
      }
      DynamicSearchAppService.Delete([id]).then(this.handleGetHistories);
    }
  }

  @Watch("histories")
  handleHistoryTabClick(): void {
    this.$nextTick(() => {
      switch (this.historyTab) {
        case "Public":
          this.historyData = this.histories?.filter(
            (it) => it.Data.Open && !it.Data.ShowTab
          );
          break;
        case "My":
          this.historyData = this.histories?.filter(
            (it) => !it.Data.Open && !it.Data.ShowTab
          );
          break;
        case "Tab":
          this.historyData = this.histories?.filter((it) => it.Data.ShowTab);
          break;
        default:
          break;
      }
    });
  }

  hnadleDrop(e: any): void {
    if (
      e.oldIndex != e.newIndex &&
      this.historyData &&
      this.historyData.length > 1
    ) {
      const draggingIndex = e.newIndex;
      let dropIndex;
      let type: EnumUiDragType;
      if (draggingIndex == 0) {
        type = EnumUiDragType.Before;
        dropIndex = 1;
      } else {
        type = EnumUiDragType.After;
        dropIndex = draggingIndex - 1;
      }
      const dragging = this.historyData[draggingIndex];
      const drop = this.historyData[dropIndex];
      DynamicSearchAppService.DragSort({
        DraggingId: dragging.Value,
        DropId: drop.Value,
        DragType: type,
        Code: this.code,
        Open:
          this.historyTab == "Public"
            ? true
            : this.historyTab == "My"
            ? false
            : undefined,
        ShowTab: this.historyTab == "Tab",
      }).then(() => {
        this.handleGetHistories();
      });
    }
  }

  /**点击历史记录 */
  handleHistoryClick(id?: string): void {
    this.handleSearch(id);
  }

  build(id?: string, search?: boolean): void {
    if (id) {
      DynamicSearchAppService.GetById(id).then((r) => {
        if (r.data.Data?.Brackets) {
          this.brackets = r.data.Data.Brackets;
          this.handleDefaultBrackets();
          this.handleBracketsBuild(this.brackets);
          if (search) {
            this.handleSearch(id);
          }
        }
      });
    } else {
      this.brackets = [];
      this.handleDefaultBrackets();
      this.handleBracketsBuild(this.brackets);
      if (search) {
        this.handleSearch(undefined);
      }
    }
  }

  handleDefaultBrackets(): void {
    if (!this.fixProp) {
      if (this.simple) {
        if (this.brackets.length == 0) {
          this.handleAddGroup(this.brackets, 1);
        }
        const bracket = this.brackets.firstOrDefault();
        if (bracket) {
          let len = bracket.Items?.length ?? 0;
          const defaultLen = len > 9 ? Math.ceil(len / 3) * 3 : len > 3 ? 9 : 3;
          if (len != defaultLen && len < defaultLen) {
            this.handleAddWhere(bracket, defaultLen - len);
          }
          this.btnToggle.MiezzIcon = len > 3 ? "double-up" : "double-down";
        }
      } else {
        if (this.brackets.length == 0) {
          this.handleAddGroup(this.brackets, 2);
        } else if (this.brackets.length == 1) {
          this.handleAddGroup(this.brackets, 1);
        }

        let defaultLen =
          this.brackets
            .map((it) => it.Items?.length ?? 0)
            .sort((a, b) => b - a)
            .firstOrDefault() ?? 1;
        for (const bracket of this.brackets) {
          const len = bracket.Items?.length ?? 0;
          if (len != defaultLen && len < defaultLen) {
            this.handleAddWhere(bracket, defaultLen - len);
          }
        }

        this.btnToggle.MiezzIcon =
          defaultLen >= 3 ? "double-up" : "double-down";
      }
    }
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    switch (model.Code) {
      case "search":
        if (this.searchRules) {
          (this.$refs["form-dynamic-search"] as any).validate((v: boolean) => {
            if (v) {
              this.handleBeforeSearch();
            }
          });
        } else {
          this.handleBeforeSearch();
        }
        complete();
        break;
      case "reset":
        this.handleReset();
        complete();
        break;
      case "save":
        this.handleShowDialog();
        complete();
        break;
      case "toggle":
        model.MiezzIcon =
          model.MiezzIcon == "double-down" ? "double-up" : "double-down";
        for (const bracket of this.brackets) {
          const len = bracket.Items?.length ?? 0;
          if (model.MiezzIcon == "double-up") {
            this.handleAddWhere(bracket, this.simple ? 9 - len : 3 - len);
          } else {
            if (this.simple) {
              this.handleDeleteWhere(bracket, 3, len - 3);
            } else {
              this.handleDeleteWhere(bracket, 1, len - 1);
            }
          }
        }
        complete();
        break;
      case "add-where":
        for (const bracket of this.brackets) {
          this.handleAddWhere(bracket, this.simple ? 3 : 1);
        }
        complete();
        break;
      default:
        break;
    }
  }

  /**事件-搜索之前 */
  handleBeforeSearch(): void {
    const brackets = this.getBrackets();
    if (!brackets || brackets?.length == 0) {
      this.handleSearch(undefined);
      this.handleBracketsBuild(brackets);
      this.handleClickSearch();
    } else {
      DynamicSearchAppService.Post({
        Code: this.searchCode ?? this.code,
        Brackets: brackets,
      }).then((r) => {
        this.handleSearch(r.data.Data);
        this.handleBracketsBuild(brackets);
        this.handleClickSearch();
      });
    }
  }

  @Emit()
  handleClickSearch(): void {
    //
  }

  /**事件-搜索之前 */
  saveBrackets(brackets: DynamicSearchBracket[], callback: () => void): void {
    this.brackets = brackets;
    DynamicSearchAppService.Post({
      Code: this.searchCode ?? this.code,
      Brackets: brackets,
    }).then((r) => {
      this.$emit("update:modelValue", r.data.Data);
      this.handleBracketsBuild(brackets);
      callback();
    });
  }

  /**搜索 */
  @Emit()
  handleSearch(value?: string): void {
    this.$emit("update:modelValue", value);
    if (this.modal) {
      this.modal.Show = false;
    }
  }

  @Watch("brackets", { deep: true })
  onBracketsChanged(): void {
    this.handleBracketsChange(this.brackets);
  }

  @Emit()
  handleBracketsChange(brackets?: DynamicSearchBracket[]): void {
    //
  }

  @Emit()
  handleBracketsBuild(brackets?: DynamicSearchBracket[]): void {
    //
  }

  /**重置 */
  handleReset(): void {
    console.log(111, this.ignoreResetProps);

    for (const bracket of this.brackets) {
      for (const item of bracket.Items ?? []) {
        if (item.Where && !this.ignoreResetProps?.contains(item.Where.Prop)) {
          item.Where.Value = undefined;
        }
      }
    }
  }

  /**显示模态框-保存历史记录 */
  handleShowDialog(): void {
    this.form.Data = new DtoFormDynamicSearch();
    const brackets = this.getBrackets();
    if (!brackets) return;
    this.modalForm.Show = true;
  }

  /**保存历史记录 */
  handleSave(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v: boolean) => {
          if (v) {
            this.form.Data.Code = this.searchCode ?? this.code;
            this.form.Data.Brackets = this.getBrackets();
            if (this.form.Data.Brackets) {
              DynamicSearchAppService.Post(this.form.Data).then((r) => {
                if (this.form.Data.ShowTab) {
                  this.historyTab = "Tab";
                } else if (this.form.Data.Open) {
                  this.historyTab = "Public";
                } else {
                  this.historyTab = "My";
                }
                this.handleGetHistories();
                this.modalForm.Show = false;
                complete();
              });
            } else complete();
          } else complete();
        });
        break;

      default:
        break;
    }
  }

  /**获取搜索内容 */
  getBrackets(): DynamicSearchBracket[] | undefined {
    const _brackets: DynamicSearchBracket[] = [];

    //去除无效数据
    for (const bracket of this.brackets) {
      if (bracket.Items && bracket.Items.length > 0) {
        const _bracketItems: DynamicSearchBracketItem[] = [];
        for (const item of bracket.Items) {
          if (item.Where && item.Where.Prop && item.Where.Compare) {
            item.Where.Value = item.Where.Value?.toString();
            _bracketItems.push(item);
          }
        }
        if (_bracketItems.length > 0) {
          _brackets.push({ Relation: bracket.Relation, Items: _bracketItems });
        }
      }
    }
    return _brackets;
  }

  /**添加组 */
  handleAddGroup(brackets: DynamicSearchBracket[], len: number): void {
    for (let i = 0; i < len; i++) {
      const bracket = {
        Relation: EnumRelation.And,
        Items: [],
      };
      this.handleAddWhere(bracket, this.simple ? 3 : 1);
      brackets.push(bracket);
    }
  }

  /**删除组 */
  handleDeleteGroup(brackets: DynamicSearchBracket[], index: number): void {
    brackets.splice(index, 1);
  }

  /**添加条件 */
  handleAddWhere(bracket: DynamicSearchBracket, len: number): void {
    if (bracket.Items) {
      for (let i = 0; i < len; i++) {
        bracket.Items.push({
          Relation: EnumRelation.And,
          Where: {},
        });
      }
    }
  }
  /**删除条件 */
  handleDeleteWhere(
    bracket: DynamicSearchBracket,
    index: number,
    count = 1
  ): void {
    if (bracket.Items) {
      bracket.Items.splice(index, count);
    }
  }

  __items: UiPageListSearch_DynamicSearchItem[] = [];
  handleWherePropVisibleChange(
    show: boolean,
    item: DynamicSearchBracketItem
  ): void {
    if (show) {
      if (this.simple) {
        this.__items = this._items.filter(
          (it) =>
            it.Prop == item.Where?.Prop ||
            this.brackets.all((b) =>
              b.Items?.all((i) => i.Where?.Prop != it.Prop)
            )
        );
      } else {
        this.__items = this._items;
      }
    }
  }

  /**改变条件 */
  @Emit()
  handleChangeWhere(
    bracket: DynamicSearchBracket,
    item: DynamicSearchBracketItem,
    i: number
  ): void {
    if (item.Where) {
      const compares =
        this._items.firstOrDefault((it) => it.Prop == item.Where?.Prop)
          ?.Compares ?? [];
      if (compares.length > 0) {
        item.Where.Compare = compares.firstOrDefault();
        var it = compares.firstOrDefault((p) => p == EnumCompare.Contain);
        if (it != null && it != undefined) {
          item.Where.Compare = it;
        }
      } else {
        item.Where.Compare = undefined;
      }
      item.Where.Value = undefined;
    }
  }
}
