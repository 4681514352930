import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamShippingCompany from "../models.machine/DtoSelectSearchParamShippingCompany";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamShippingCompany from "../models.machine/DtoPageListSearchParamShippingCompany";
import DtoPageListParamShippingCompany from "../models.machine/DtoPageListParamShippingCompany";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamShippingCompany from "../models.machine/DtoDetailParamShippingCompany";
import DtoFormParamShippingCompany from "../models.machine/DtoFormParamShippingCompany";

/**
 * 船公司
 * @description 自动生成的代码,请勿修改
 */
export default class ParamShippingCompanyAppService {
    /**
     * 获取船公司选择器
     * @param {DtoSelectSearchParamShippingCompany} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamShippingCompany): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamShippingCompany/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取船公司分页列表
     * @param {DtoPageListSearchParamShippingCompany} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamShippingCompany>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamShippingCompany): AxiosPromise<RESTfulResult<DtoPageListParamShippingCompany>> {
        const url = `/api/ParamShippingCompany/Get`;
        return service.get<RESTfulResult<DtoPageListParamShippingCompany>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamShippingCompany/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取船公司详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamShippingCompany>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamShippingCompany>> {
        const url = `/api/ParamShippingCompany/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamShippingCompany>>(url);
    }

    /**
     * 新增/编辑船公司
     * @param {DtoFormParamShippingCompany} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamShippingCompany): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamShippingCompany/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除船公司
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamShippingCompany/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 同步船公司
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamShippingCompany/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 导出
     * @param {string} [name] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportExceByName(name?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamShippingCompany/GetExportExce/${name}`;
        return service.get<RESTfulResult<string>>(url);
    }

}
