import UiSelectOption from "./UiSelectOption";

/**
 * 发票核销状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumInvoiceSettlementStatus {
    /**
     * 未核销
     */
    No = 0,
    /**
     * 部分核销
     */
    Part = 10,
    /**
     * 已核销
     */
    Did = 20,
}

/**
 * 发票核销状态-选项
 */
export const EnumInvoiceSettlementStatusOption: UiSelectOption<number>[] = [
    { Label: "未核销", Value: 0, Data: "No" },
    { Label: "部分核销", Value: 10, Data: "Part" },
    { Label: "已核销", Value: 20, Data: "Did" },
];
