import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!

  return (_openBlock(), _createBlock(_component_miezz_page_list, {
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
    onHandleGet: _ctx.handleGet,
    onHandleRowButtonClick: _ctx.handleRowButtonClick,
    onHandleBottomButtonClick: _ctx.handleBottomButtonClick,
    onHandleRowDbClick: _ctx.handleRowDbClick
  }, {
    "dynamic-search-customize": _withCtx(({ model }) => [
      (model.Where.Prop == 'DynamicSearch_Type')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 0,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.types
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_Status')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 1,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.EnumPriceQuotationSheetStatusOption,
            number: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : (model.Where.Prop == 'DynamicSearch_ServiceRemark')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 2,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.serviceRemarks,
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onHandleGet", "onHandleRowButtonClick", "onHandleBottomButtonClick", "onHandleRowDbClick"]))
}