import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc20b158"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 4 }
const _hoisted_4 = { key: 5 }
const _hoisted_5 = { key: 7 }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.list,
    rules: _ctx.rules,
    inline: false,
    ref: "ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        ref: "listRef",
        data: _ctx.list.Data?.Items,
        border: "",
        stripe: "",
        onSelectionChange: _ctx.handleSelect
      }, {
        default: _withCtx(() => [
          (!_ctx.write)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                type: "selection",
                width: "55",
                selectable: _ctx.handleSelectable
              }, null, 8, ["selectable"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.Items, (item) => {
            return (_openBlock(), _createBlock(_component_el_table_column, {
              prop: item.Prop,
              key: item.Prop,
              label: item.Label,
              width: item.Width
            }, {
              default: _withCtx(({ row, $index }) => [
                (item.Prop)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 0,
                      prop: `Data.Items[${$index}].${item.Prop}`,
                      rules: _ctx.rules[item.Prop]
                    }, {
                      default: _withCtx(() => [
                        (
                item.Prop == 'FieldId' &&
                row.Field &&
                row.Field.Type != undefined &&
                (row.Field.Type == _ctx.EnumParamPriceFieldType.Id ||
                  row.Field.Type == _ctx.EnumParamPriceFieldType.Cost ||
                  row.Field.Type == _ctx.EnumParamPriceFieldType.Calc)
              )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(row.Field.Name), 1))
                          : (_ctx.write && item.Prop == 'FieldId')
                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                modelValue: row.FieldId,
                                "onUpdate:modelValue": ($event: any) => ((row.FieldId) = $event),
                                options: _ctx.fields,
                                key: row,
                                onHandleChange: ($event: any) => (_ctx.handleFieldChange(row))
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onHandleChange"]))
                            : (item.Prop == 'FieldId')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(row.Field.Name), 1))
                              : (
                _ctx.write &&
                item.Prop == 'Name' &&
                (!row.Field ||
                  (row.Field.Code != 'Id' && row.Field.Code != 'Amount'))
              )
                                ? (_openBlock(), _createBlock(_component_miezz_input, {
                                    key: 3,
                                    modelValue: row.Name,
                                    "onUpdate:modelValue": ($event: any) => ((row.Name) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                : (item.Prop == 'Name')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(row.Name), 1))
                                  : (item.Prop == 'FieldType')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(row.FieldType), 1))
                                    : (
                _ctx.write &&
                item.Prop == 'Show' &&
                (!row.Field ||
                  (row.Field.Code != 'Id'))
              )
                                      ? (_openBlock(), _createBlock(_component_el_switch, {
                                          key: 6,
                                          modelValue: row.Show,
                                          "onUpdate:modelValue": ($event: any) => ((row.Show) = $event),
                                          onChange: _ctx.handleShowChanged
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
                                      : (
                item.Prop == 'Show' &&
                (!row.Field ||
                  (row.Field.Code != 'Id' &&
                    row.Field.Code != 'Amount'))
              )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(row.Show ? "是" : "否"), 1))
                                        : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["prop", "label", "width"]))
          }), 128)),
          (_ctx.write)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 1,
                label: "操作",
                width: "130px"
              }, {
                default: _withCtx(({ row, $index }) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.RowButtons.filter(
              (btn) =>
                btn.Code != 'delete' ||
                !row.Field ||
                row.Field.Type == _ctx.EnumParamPriceFieldType.Description
            ), (btn, btn_i) => {
                    return (_openBlock(), _createBlock(_component_miezz_button, {
                      key: btn_i,
                      model: btn,
                      style: {"margin-left":"10px"},
                      onHandleClick: 
              (arg1, arg2) => _ctx.handleRowButtonClick(arg1, row, $index, arg2)
            
                    }, null, 8, ["model", "onHandleClick"]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["data", "onSelectionChange"]),
      (_ctx.write)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.TopButtons, (btn, btn_i) => {
              return (_openBlock(), _createBlock(_component_miezz_button, {
                key: btn_i,
                model: btn,
                onHandleClick: _ctx.handleClick
              }, null, 8, ["model", "onHandleClick"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}