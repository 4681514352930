
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchOrderPoolProcessCount, {
  DtoPageListSearchOrderPoolProcessCountDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderPoolProcessCount";
import DtoListItemOrderPoolProcessCount, {
  DtoListItemOrderPoolProcessCountMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolProcessCount";
import {
  EnumOrderPoolStatus,
  EnumOrderPoolStatusOption,
} from "@/models.machine/EnumOrderPoolStatus";
import UiSelectOption from "@/models.machine/UiSelectOption";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import DtoPageListOrderPoolProcessCount from "@/models.machine/DtoPageListOrderPoolProcessCount";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import DtoListItemOrderPoolProcessCount_Take, {
  DtoListItemOrderPoolProcessCount_TakeMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolProcessCount_Take";
import DtoListItemOrderPoolProcessCount_Warehouse, {
  DtoListItemOrderPoolProcessCount_WarehouseMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolProcessCount_Warehouse";
import DtoListItemOrderPoolProcessCount_Customs, {
  DtoListItemOrderPoolProcessCount_CustomsMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolProcessCount_Customs";
import DtoListItemOrderPoolProcessCount_Booking, {
  DtoListItemOrderPoolProcessCount_BookingMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolProcessCount_Booking";
import ExpressTracking from "../Order/Pool/ExpressPage/ExpressTracking.vue";
import { EnumOrderStatusOption } from "@/models.machine/EnumOrderStatus";
import MiezzButton from "@/models/MiezzButton";
import MiezzListItem from "@/models/MiezzListItem";
@Options({
  components: {
    ExpressTracking,
  },
})
export default class CountProcess extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchOrderPoolProcessCount,
    DtoListItemOrderPoolProcessCount,
    string
  >("Count_Process");
  EnumOrderPoolStatusOption = EnumOrderPoolStatusOption.filter(
    (it) => it.Value != EnumOrderPoolStatus.WaitingAdd
  );
  EnumOrderStatusOption = EnumOrderStatusOption;
  loadingPorts: UiSelectOption<string>[] = [];
  deliveryPorts: UiSelectOption<string>[] = [];
  deliveryPlaces: UiSelectOptionWithStringValue[] = [];
  shipNames: UiSelectOption<string>[] = [];
  voyageNumbers: UiSelectOption<string>[] = [];
  flights: UiSelectOption<string>[] = [];
  railwayNumbers: UiSelectOption<string>[] = [];
  serviceRemarks: UiSelectOptionWithStringValue[] = [];
  searchClientUsers: UiSelectOption<string>[] = [];
  containerNos: UiSelectOptionWithStringValue[] = [];

  modal = new MiezzModal(MiezzModalType.Dialog);
  modalList = new MiezzPageList<any, any, string>();
  modals = [
    {
      Prop: "DeparturePortTake",
      Title: "装货港提取",
      ListItems: DtoListItemOrderPoolProcessCount_TakeMiezzListItems.filter(
        (it) => it.Prop != "ContainerTypeLabel"
      ),
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Take[] => {
        return row.DeparturePortTakes ?? [];
      },
    },
    {
      Prop: "DeparturePortWarehouse",
      Title: "装货港入库",
      ListItems: DtoListItemOrderPoolProcessCount_WarehouseMiezzListItems,
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Warehouse[] => {
        return row.DeparturePortWarehouses ?? [];
      },
    },
    {
      Prop: "DeparturePortContainer",
      Title: "装货港装柜",
      ListItems: DtoListItemOrderPoolProcessCount_TakeMiezzListItems.filter(
        (it) => it.Prop != "CargoQuantity"
      ),
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Take[] => {
        return row.DeparturePortContainers ?? [];
      },
    },
    {
      Prop: "DeparturePortDeclareCustoms",
      Title: "装货港放行",
      ListItems: DtoListItemOrderPoolProcessCount_CustomsMiezzListItems,
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Customs[] => {
        return row.DeparturePortDeclareCustomsList ?? [];
      },
    },
    {
      Prop: "DeparturePortBooking",
      Title: "装货港开航",
      ListItems: DtoListItemOrderPoolProcessCount_BookingMiezzListItems,
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Booking[] => {
        return row.DeparturePortBookings ?? [];
      },
    },
    {
      Prop: "DestinationPortBooking",
      Title: "目的港抵达",
      ListItems: DtoListItemOrderPoolProcessCount_BookingMiezzListItems,
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Booking[] => {
        return row.DestinationPortBookings ?? [];
      },
    },
    {
      Prop: "DestinationPortDeclareCustoms",
      Title: "目的港放行",
      ListItems: DtoListItemOrderPoolProcessCount_CustomsMiezzListItems,
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Customs[] => {
        return row.DestinationPortDeclareCustomsList ?? [];
      },
    },
    {
      Prop: "DestinationPortContainer",
      Title: "目的港提柜",
      ListItems: DtoListItemOrderPoolProcessCount_TakeMiezzListItems.filter(
        (it) => it.Prop != "CargoQuantity"
      ),
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Take[] => {
        return row.DestinationPortContainers ?? [];
      },
    },
    {
      Prop: "DestinationPortWarehouse",
      Title: "目的港入库",
      ListItems: DtoListItemOrderPoolProcessCount_WarehouseMiezzListItems,
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Warehouse[] => {
        return row.DestinationPortWarehouses ?? [];
      },
    },
    {
      Prop: "DestinationPortTake",
      Title: "目的港提取",
      ListItems: DtoListItemOrderPoolProcessCount_TakeMiezzListItems.filter(
        (it) => it.Prop != "ContainerTypeLabel"
      ),
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Take[] => {
        return row.DestinationPortTakes ?? [];
      },
    },
    {
      Prop: "DestinationPortDeliver",
      Title: "目的港送达",
      ListItems: (
        JSON.copy(
          DtoListItemOrderPoolProcessCount_TakeMiezzListItems
        ) as MiezzListItem[]
      )
        .filter((it) => it.Prop != "ContainerTypeLabel")
        .map((it) => {
          if (it.Prop == "CargoQuantity") it.Label = "箱型/货量";
          return it;
        }),
      GetListData: (
        row: DtoListItemOrderPoolProcessCount
      ): DtoListItemOrderPoolProcessCount_Take[] => {
        for (const item of row.DestinationPortDelivers ?? []) {
          if (item.TypeText == "集装箱拖车") {
            item.CargoQuantity = item.ContainerTypeLabel;
          }
        }
        return row.DestinationPortDelivers ?? [];
      },
    },
  ];

  /**创建时 */
  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchOrderPoolProcessCount();
    //列表-数据-初始
    this.list.Data = new DtoPageListOrderPoolProcessCount();
    this.list.Items = DtoListItemOrderPoolProcessCountMiezzListItems.filter(
      (it) => !it.Hide
    );
    this.list.DynamicSearchItems =
      DtoPageListSearchOrderPoolProcessCountDynamicSearchItems;
    this.list.BottomButtons = [
      {
        Code: "export",
        Label: "导出",
        MiezzIcon: "export",
        Type: "text",
        Size: "small",
        PowerCode: "Count_Process",
      },
    ];
    this.list.CellClassName = (data: any) => {
      const row = data.row as DtoListItemOrderPoolProcessCount;
      const prop = this.modals.firstOrDefault(
        (it) => it.Title == data.column.label
      )?.Prop;
      return row[prop + "Red"] ? "red" : "";
    };
    this.list.HandleRowClass = (row, i) => {
      return "list-row";
    };

    this.modalList.Fix = false;
    this.modalList.HideCard = true;
    this.modalList.EnabledDynamicSearch = false;
    this.modalList.EnabledRadioSearch = false;
    this.modalList.EnabledSelect = false;
    this.modalList.EnabledPagination = false;
    this.modalList.RowButtons = [];

    OrderPoolAppService.GetServiceRemarkSelect().then((r) => {
      this.serviceRemarks = r.data.Data ?? [];
    });
    OrderPoolAppService.GetClientUsers().then((r) => {
      this.searchClientUsers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetLoadingPorts().then((r) => {
      this.loadingPorts = r.data.Data ?? [];
    });
    OrderPoolAppService.GetDeliveryPorts().then((r) => {
      this.deliveryPorts = r.data.Data ?? [];
    });
    OrderPoolAppService.GetDeliveryPlaces().then((r) => {
      this.deliveryPlaces = r.data.Data ?? [];
    });
    OrderPoolAppService.GetShipNames().then((r) => {
      this.shipNames = r.data.Data ?? [];
    });
    OrderPoolAppService.GetVoyageNumbers().then((r) => {
      this.voyageNumbers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetFlights().then((r) => {
      this.flights = r.data.Data ?? [];
    });
    OrderPoolAppService.GetRailwayNumbers().then((r) => {
      this.railwayNumbers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetContainerNoSelect().then((r) => {
      this.containerNos = r.data.Data ?? [];
    });
  }
  /**查询列表 */
  handleGet(): void {
    OrderPoolAppService.GetProcessCount(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleRowCommand(prop: string, row: DtoListItemOrderPoolProcessCount): void {
    const modal = this.modals.firstOrDefault((it) => it.Prop + "Text" == prop);
    this.modal.Title = modal?.Title;
    this.modalList.Items = (JSON.copy(modal?.ListItems) as MiezzListItem[]).map(
      (it) => {
        if (it.Prop == "Code") it.Customize = true;
        return it;
      }
    );
    this.modalList.Data = {
      Items: modal?.GetListData(row),
    };
    this.modalList.CellClassName = (data: any): string => {
      return (data.column.label == "实际货量" &&
        data.row.PlanCargoQuantity &&
        data.row.InStockCargoQuantity &&
        data.row.PlanCargoQuantity != data.row.InStockCargoQuantity) ||
        (data.column.label == "执行时间" && data.row.TimeRed)
        ? "red"
        : "";
    };
    this.modal.Show = true;
  }

  trackingId?: string;
  modalTracking = new MiezzModal(MiezzModalType.Dialog);
  handleProcess(row: any): void {
    if (row.TypeText == "快递" && row.HasCodes) {
      this.trackingId = row.Id;
      this.modalTracking.Title = "追踪信息";
      this.modalTracking.Height = "500px";
      this.modalTracking.Show = true;
    } else {
      ElMessage.warning("暂无数据，请向供应商询问详细进程");
    }
  }

  handleEmptyProcess(): void {
    ElMessage.warning("该委托信息的进程节点没有此环节");
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "export":
        OrderPoolAppService.Export(this.list.SelectRows).then((r) => {
          window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
          ElMessage.success("导出成功");
          complete();
        });
        break;
      default:
        break;
    }
  }
}
